import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TableSkeleton from "shared/components/TableSkeleton";
import {
  editLimit,
  getAllSchoolAccounts,
  schoolStats,
  blockSchool,
  unBlockSchool,
} from "shared/services/adminService";

import {
  headings,
  columnHeadings,
  skeletonInfo,
  filterOptions,
} from "./constants";
import { searchHandlerHelper } from "shared/utils/helpers";
import useWindowSize from "shared/customHooks/windowSize";
import styles from "./style.module.scss";
import { Icons } from "assets";
import Pagination from "shared/components/pagination";
import ItemsPerPage from "shared/components/itemsPerPage";
import NoDataFound from "shared/components/noDataFound";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import useDebounce from "shared/customHooks/useDebounce";
import Filter from "shared/components/filterButton";
import EditSchoolModal from "shared/modals/editSchoolModal";
import { toastMessage } from "shared/components/toast";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "shared/components/customButton";
import CreateTicket from "shared/modals/createTicket";
import CardComp from "shared/components/card";
import SchoolDetailsModal from "shared/modals/schoolDetailsModal";
import Confirmation from "shared/modals/confirmation";

const Schools = () => {
  const navigate = useNavigate();
  const screenWidth = useWindowSize();
  const [search, setSearch] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [schoolAccounts, setSchoolAccounts] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<number | null>(null);
  const [teachersCount, setTeachersCount] = useState<number>(1);
  const [studentsCount, setStudentsCount] = useState<number>(1);
  const [openTicket, setOpenTicket] = useState<boolean>(false);
  const [statsLoader, setStatsLoader] = useState<boolean>(false);
  const [schoolStatsData, setSchoolStatsData] = useState<any>([]);
  const [openSchoolDetailsModal, setOpenSchoolDetailModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [school, setSchool] = useState<any>(null);
  const cardData = [
    {
      title: "Total School Accounts",
      stats: schoolStatsData?.total,
    },
    {
      title: "Total Membership School Accounts",
      stats: 0,
    },
  ];

  useBreadcrumbs({
    title: "School Accounts",
    path: routeConstant.schoolAccounts.path,
  });

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const confirmEditHandler = () => {
    setLoader(true);
    editLimit({
      school_id: schoolId,
      teachers_limit: teachersCount,
      students_limit: studentsCount,
    })
      .then(({ status, data }) => {
        if (status) {
          setLoader(false);
          setEditModal(false);
          getSchoolAccounts();
          setStudentsCount(1);
          setTeachersCount(1);
          toastMessage("success", data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error", err);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getSchoolAccounts = () => {
    setLoader(true);
    getAllSchoolAccounts({
      selectedFilter: selectedFilter?.value,
      search: searchText,
      itemsPerPage,
      activePage,
    })
      .then(({ data, status }) => {
        if (status) {
          const { data: response } = data;
          const {
            current_page,
            last_page,
            per_page,
            total,
            data: accounts,
          } = response;
          //Filtering out only approved and rejected
          setSchoolAccounts(accounts);
          setActivePage(current_page);
          setItemsPerPage(per_page);
          setTotalPage(last_page);
          setTotalItems(total);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error-----", err);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getSchoolStats = () => {
    setStatsLoader(true);
    schoolStats()
      .then(({ data, status }) => {
        if (status) {
          const { data: stats } = data;
          setSchoolStatsData(stats);
          setStatsLoader(false);
        }
      })
      .catch((err: any) => {
        setStatsLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handleAccount = (school: any) => {
    const service = school?.is_blocked === 0 ? blockSchool : unBlockSchool;
    service(school?.id)
      .then(({ data, status }) => {
        if (status) {
          getSchoolAccounts();
          toastMessage("success", data?.message);
          setOpenConfirmationModal(false);
        }
      })
      .catch((err: any) => {
        console.log("Error----", err);
        toastMessage("err", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    // Initial API call here
    getSchoolAccounts();
  }, [selectedFilter, searchText, itemsPerPage, activePage]);

  // Get Stats
  useEffect(() => {
    getSchoolStats();
  }, []);

  return (
    <section>
      <Box>
        <Grid container spacing={2}>
          {cardData?.map((card: any, index) => {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <CardComp
                  isLoading={statsLoader}
                  stats={card.stats}
                  title={card.title}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>{headings.mainHeading}</div>
            <div className={styles.tableSubHeading}>{headings.subHeading}</div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search By School Name"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <div className={styles.filterBox}>
              <Filter
                filterOption={filterOptions}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div>
            {/* <CustomButton
              text="Create Ticket"
              buttonStyle={styles.createBtn}
              handleClick={() => setOpenTicket(true)}
            /> */}
          </Box>
        </Box>
        <TableContainer
          className={styles.container}
          component={Paper}
          sx={
            screenWidth > 990
              ? {
                  width: `${screenWidth - 300}px`,
                }
              : {
                  boxShadow: "none",
                }
          }
        >
          <Table className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                {columnHeadings?.map((heading, index) => {
                  return (
                    <TableCell key={heading.id}>{heading.title}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <TableSkeleton
                  rows={skeletonInfo.rows}
                  cellCount={skeletonInfo.cellCount}
                  actionCount={skeletonInfo.actions}
                  showPersonData={false}
                ></TableSkeleton>
              ) : (
                schoolAccounts?.map((account: any) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ minWidth: "188px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Avatar
                            alt={account?.username}
                            src={account?.school?.profile_picture}
                            sx={{ width: 40, height: 40 }}
                          />
                          <div className={styles.accountInfo}>
                            <span className={styles.fullName}>
                              {account?.school?.school_name
                                ? account?.school?.school_name
                                : "Not Provided"}
                            </span>
                            <span className={styles.email}>
                              {account?.email ? account?.email : "Not Provided"}
                            </span>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {account?.school?.phone
                          ? account?.school.phone
                          : "Not Provided"}
                      </TableCell>

                      <TableCell sx={{ minWidth: "120px" }}>
                        {account?.school?.students_limit
                          ? account?.school?.students_limit
                          : "Not Provided"}
                      </TableCell>
                      <TableCell sx={{ minWidth: "120px" }}>
                        {account?.school?.teachers_limit
                          ? account?.school?.teachers_limit
                          : "Not Provided"}
                      </TableCell>
                      <TableCell sx={{ minWidth: "120px" }}>
                        {account?.status || account?.status === 0 ? (
                          <span
                            className={
                              account?.status === 1
                                ? styles.active
                                : account?.status === 2
                                ? styles.rejected
                                : styles.pending
                            }
                          >
                            {
                              filterOptions?.find(
                                (filter) => filter.value === account?.status
                              )?.option
                            }
                          </span>
                        ) : (
                          "Not Provided"
                        )}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Tooltip title="View">
                            <span
                              onClick={() => {
                                setSchool(account);
                                setOpenSchoolDetailModal(true);
                              }}
                              className={styles.iconWrapper}
                            >
                              <RemoveRedEyeIcon />
                            </span>
                          </Tooltip>
                          <Tooltip title="Edit Students / Teachers">
                            <span
                              className={styles.iconWrapper}
                              onClick={() => {
                                setSchoolId(account?.id);
                                setEditModal(true);
                                setTeachersCount(
                                  account?.school?.teachers_limit
                                );
                                setStudentsCount(
                                  account?.school?.students_limit
                                );
                              }}
                            >
                              <EditIcon />
                            </span>
                          </Tooltip>
                          <Tooltip title="Ticket">
                            <button
                              className={styles.iconWrapper}
                              onClick={() =>
                                navigate(
                                  routeConstant.singleTicket.path.replace(
                                    ":id",
                                    account?.id
                                  ),
                                  {
                                    state: {
                                      user: account,
                                    },
                                  }
                                )
                              }
                            >
                              <ChatBubbleIcon />
                            </button>
                          </Tooltip>
                          <Tooltip
                            arrow
                            title={
                              account?.is_blocked === 0
                                ? "Deactivate"
                                : "Activate"
                            }
                          >
                            <button
                              onClick={() => {
                                setSchool(account);
                                setOpenConfirmationModal(true);
                              }}
                              className={styles.iconWrapper}
                            >
                              {account?.is_blocked === 0 ? (
                                <Icons.SchoolDeactivate />
                              ) : (
                                <Icons.SchoolActivate />
                              )}
                            </button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          {totalItems &&
          activePage &&
          totalPage > 1 &&
          schoolAccounts?.length ? (
            <Pagination
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          ) : null}
        </Box>
        <Box>
          {totalItems &&
          activePage &&
          currentPage &&
          schoolAccounts?.length > 0 ? (
            <ItemsPerPage
              setActivePage={setActivePage}
              currentPage={currentPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage ? itemsPerPage : 10}
              totalItems={totalItems}
            />
          ) : null}
        </Box>
        <Box>{!loader && !(schoolAccounts?.length > 0) && <NoDataFound />}</Box>
      </Box>
      {/*  */}
      <EditSchoolModal
        viewModal={editModal}
        closeViewModal={setEditModal}
        confirm={confirmEditHandler}
        teachersCount={teachersCount}
        setTeachersCount={setTeachersCount}
        studentsCount={studentsCount}
        setStudentsCount={setStudentsCount}
        loader={loader}
      ></EditSchoolModal>
      {/* <CreateTicket
        open={openTicket}
        handleClose={() => setOpenTicket(false)}
        isSchool={true}
      /> */}
      <SchoolDetailsModal
        schoolDetails={school}
        isOpen={openSchoolDetailsModal}
        closeModal={() => setOpenSchoolDetailModal(false)}
      />
      <Confirmation
        open={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
        text={
          !school?.is_blocked
            ? "Are you sure you want to deactivate this school ?"
            : "Are you sure you want to activate this school ?"
        }
        handleClick={() => handleAccount(school)}
      />
    </section>
  );
};

export default Schools;
