import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from "@mui/material";
import { Icons } from "assets";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { rejectBookService } from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import Loader from "shared/components/loader/animation";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface RejectFeatureBookProps {
  open: boolean;
  handleClose: any;
  selectedItem: any;
  getAllFeatureBooks: any;
}

export default function RejectFeatureBook({
  open,
  handleClose,
  selectedItem,
  getAllFeatureBooks,
}: RejectFeatureBookProps) {
  const [loader, setLoader] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const initialValues = {
    reason: "",
  };

  React.useEffect(() => {
    formik.setFieldValue(
      "reason",
      String(selectedItem?.reason ? selectedItem?.reason : "")
    );
    // eslint-disable-next-line
  }, [open]);

  const textSchema = yup.object({
    reason: yup.string().required("Reason is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: textSchema,
    onSubmit: (values) => {
      setLoader(true);
      let resp = {
        feature_id: selectedItem?.id,
        reason: values?.reason,
      };

      rejectBookService(resp)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          onClose();
          setLoader(false);
          getAllFeatureBooks();
        })
        .catch((err) => {
          toastMessage("error", err?.response.data?.message);
          console.log("error", err?.response.data?.message);
          setLoader(false);
        });
    },
  });

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          />
          <Box display={"flex"} alignItems={"center"}>
            <Icons.RejectionIcon />
            <div className={styles.title}>Reason of rejection</div>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <textarea
              className={styles.textArea}
              id="reason"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              disabled={selectedItem?.reason ? true : false}
              style={
                selectedItem?.reason
                  ? { background: "#FFFBF3" }
                  : { background: "transparent" }
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.reason && formik.errors.reason ? (
              <div className={styles.errorText}>{formik.errors.reason}</div>
            ) : (
              !selectedItem?.reason && <div style={{ height: "30px" }}></div>
            )}
            {!selectedItem?.reason && (
              <CustomButton text="Submit" type={"submit"} />
            )}
          </form>
        </DialogContent>
        {loader && <Loader />}
      </Dialog>
    </React.Fragment>
  );
}
