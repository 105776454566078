import React, { useEffect, useState } from "react";
import CustomButton from "../customButton";
import styles from "./style.module.scss";
import {
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import classNames from "classnames";

interface ReviewDetailsProps {
  setEditBool: any;
  data: any;
  rejection: any;
  handleConfirmOpen: any;
  setOpenReject: any;
}
function ReviewDetails({
  setEditBool,
  data,
  rejection,
  handleConfirmOpen,
  setOpenReject,
}: ReviewDetailsProps) {
  const [dataArr, setDataArr] = useState<any>(
    Object.keys(data).map((key) => data[key])
  );
  const [rejectionReason, setRejectionReason] = useState<any>(null);

  useEffect(() => {
    setDataArr(Object.keys(data).map((key) => data[key]));
  }, [data]);

  useEffect(() => {
    if (rejection) {
      setRejectionReason(rejection?.find((item: any) => item?.marked));
    }
  }, [rejection]);

  const reject_books_status = {
    explicitContent: 1,
    ageAppropriate: 2,
    culturalInsensitivity: 3,
    others: 4,
  };

  return (
    <div className={styles.section}>
      <div className={styles.editContainer}>
        <div className={styles.sectionHeading} style={{ fontSize: "20px" }}>
          Review by Book Reviewer
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <TableContainer component={Paper} className={styles.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Parameters</TableCell>
                <TableCell>Response</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rejectionReason && (
                <>
                  <TableRow>
                    <TableCell>Rejection Reason</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      {rejectionReason?.value !== reject_books_status?.others
                        ? rejectionReason?.label
                        : rejectionReason?.label +
                          ": " +
                          rejectionReason?.reason}
                    </TableCell>
                  </TableRow>
                </>
              )}
              {dataArr?.map((item: any) => {
                return (
                  <TableRow>
                    <TableCell>{item?.label}</TableCell>
                    <TableCell>
                      {item?.type === "rating" ? (
                        <Rating
                          name="simple-controlled"
                          value={item?.value}
                          readOnly
                        />
                      ) : Number(item?.option) === 0 ? (
                        "No"
                      ) : (
                        "Yes"
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.type === "checklist" &&
                        item?.options?.map((itm: any) => {
                          return (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {itm?.value ? (
                                <>
                                  <CheckIcon style={{ fill: "#9A469B" }} />{" "}
                                  <span style={{ marginLeft: "10px" }}>
                                    {itm?.name}
                                  </span>
                                </>
                              ) : null}
                            </div>
                          );
                        })}

                      {item?.type === "dropdown" && item?.selected && (
                        <div>
                          Selected: {item?.selected?.name}{" "}
                          {item?.selected?.text}
                        </div>
                      )}

                      {item?.type === "rating" && <div>{item?.comment}</div>}
                      {item?.type === "text" && <div>{item?.comment}</div>}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className={styles.topBtnContainer}>
          <CustomButton
            text="Edit Review"
            buttonStyle={styles.btnCustom}
            handleClick={() => setEditBool(true)}
          />
          <CustomButton
            text="Approve"
            buttonStyle={classNames(styles.btnCustom, styles.approveBtn)}
            handleClick={() =>
              handleConfirmOpen("Are you sure, you want to approve?", "approve")
            }
          />
          {/* <CustomButton
            text="Publish"
            buttonStyle={styles.btnCustom}
            handleClick={() =>
              handleConfirmOpen(
                "Are you sure, you want to publish this book?",
                "publish"
              )
            }
          /> */}
          <CustomButton
            text="Reject"
            buttonStyle={classNames(styles.btnCustom, styles.rejectBtn)}
            handleClick={() => setOpenReject(true)}
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewDetails;
