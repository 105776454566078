import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const LoginUser = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.login, params);
};

const LogoutUser = () => {
  return HTTP_CLIENT.post(Endpoint.auth.logout);
};

const SendOTP = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.sendOTP, params);
};

const VerifyOTP = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.verifyOTP, params);
};

const ResetPassword = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.auth.resetPassword, params);
};

export { LoginUser, LogoutUser, SendOTP, VerifyOTP, ResetPassword };
