import React, { Fragment, useEffect, useState } from "react";
import styles from "./style.module.scss";
import { roles } from "shared/routes/constant";
import { roles as userRoles } from "shared/utils/constants";
import {
  getNewsletterListProductManager,
  deleteNewsLetterProductManager,
} from "shared/services/productManagerService";
import {
  getNewsletterListProjectHead,
  deleteNewsLetterProjectHead,
} from "shared/services/projectHeadService";
import { useSelector } from "react-redux";
import { searchHandlerHelper } from "shared/utils/helpers";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import { columnHeadings, actions, headings, skeletonInfo } from "./constants";

import { toastMessage } from "shared/components/toast";
import moment from "moment";
import Confirmation from "shared/modals/confirmation";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import TableSkeleton from "shared/components/TableSkeleton";
import ItemsPerPage from "shared/components/itemsPerPage";
import NoDataFound from "shared/components/noDataFound";
import Pagination from "shared/components/pagination";
import useDebounce from "shared/customHooks/useDebounce";
import useWindowSize from "shared/customHooks/windowSize";

const NewsLetterSubscriptions = () => {
  const screenWidth = useWindowSize();
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [newsLetters, setNewsLetters] = useState<any>([]);
  const [newsLetterId, setNewsLetterId] = useState<number | null>(null);
  const [openDeleteNewsletterModal, setOpenDeleteNewsletterModal] =
    useState<boolean>(false);

  const {
    user: { user },
  } = useSelector((state: any) => state.root);
  const currentUser = roles.find((role) => role.role_id === Number(user?.role));

  const handleView = (data: any) => {
    setNewsLetterId(data?.id);
    setOpenDeleteNewsletterModal(true);
  };

  const handleDelete = () => {
    let service =
      currentUser?.role_id === userRoles.productManager
        ? deleteNewsLetterProductManager
        : deleteNewsLetterProjectHead;
    service(newsLetterId)
      .then((data) => {
        toastMessage("success", data?.data?.message);
        setOpenDeleteNewsletterModal(false);
        getNewsletterList();
      })
      .catch((err) => {
        console.log("err----");
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handlers = [
    {
      id: "delete",
      function: handleView,
    },
  ];

  const getNewsletterList = () => {
    let service = null;
    service =
      currentUser?.role_id === userRoles.productManager
        ? getNewsletterListProductManager
        : getNewsletterListProjectHead;
    setLoader(true);
    service(search, activePage, itemsPerPage)
      .then(({ data, status }) => {
        if (status) {
          const {
            data: newsletterData,
            current_page,
            total,
            per_page,
            last_page,
          } = data?.data;
          setNewsLetters(newsletterData);
          setTotalPage(last_page);
          setTotalItems(total);
          setCurrentPage(current_page);
          setItemsPerPage(per_page);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        console.log("err--", err);
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getNewsletterList();
  }, [itemsPerPage, activePage, searchText]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs({
    title: "Newsletter Subscriptions",
    path: routeConstant.newsletterSubscription.path,
  });

  return (
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>{headings.mainHeading}</div>
            <div className={styles.tableSubHeading}>{headings.subHeading}</div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          className={styles.container}
          component={Paper}
          sx={
            screenWidth > 990
              ? {
                  width: `${screenWidth - 300}px`,
                }
              : {
                  boxShadow: "none",
                }
          }
        >
          <Table className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                {columnHeadings?.map((heading, index) => {
                  return <TableCell key={index}>{heading.title}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <TableSkeleton
                  rows={skeletonInfo.rows}
                  cellCount={skeletonInfo.cellCount}
                  actionCount={skeletonInfo.actions}
                  showPersonData={false}
                ></TableSkeleton>
              ) : (
                newsLetters?.map((newsletter: any, index: number) => {
                  return (
                    <>
                      <TableRow key={index} className={styles.tableRow}>
                        <TableCell>
                          {newsletter?.email
                            ? newsletter?.email
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {newsletter?.created_at
                            ? moment(newsletter?.created_at).format(
                                "DD MMM YYYY"
                              )
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          <Box>
                            {actions.map((action: any) => {
                              const handler = handlers.find(
                                (handler) => handler.id === action.id
                              )?.function;
                              return (
                                <button
                                  key={action.id}
                                  className={styles.btnStyles}
                                  onClick={
                                    handler
                                      ? () => handler(newsletter)
                                      : () => {
                                          console.log("Called");
                                        }
                                  }
                                >
                                  <Tooltip title={action.text}>
                                    <span>{action?.icon}</span>
                                  </Tooltip>
                                </button>
                              );
                            })}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          {totalItems &&
          activePage &&
          totalPage &&
          totalPage > 1 &&
          newsLetters.length ? (
            <Pagination
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          ) : null}
        </Box>
        <Box>
          {totalItems && activePage && currentPage && newsLetters.length ? (
            <ItemsPerPage
              setActivePage={setActivePage}
              currentPage={currentPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage ? itemsPerPage : 10}
              totalItems={totalItems}
            />
          ) : null}
        </Box>
        <Box>{!loader && !(newsLetters?.length > 0) && <NoDataFound />}</Box>
      </Box>

      {/* Confirm to Delete NewsLetter */}
      <Confirmation
        open={openDeleteNewsletterModal}
        handleClose={() => setOpenDeleteNewsletterModal(false)}
        loader={loader}
        text="Are you sure you want to delete this newsletter subscription?"
        showTextArea={false}
        handleClick={handleDelete}
      />
    </div>
  );
};

export default NewsLetterSubscriptions;
