import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons, Images } from "assets";
import styles from "./style.module.scss";
import { Box, Skeleton } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastMessage } from "shared/components/toast";
import {
  assignReviewerService,
  getHODBookReviewersService,
} from "shared/services/hodService";
import Loader from "shared/components/loader/animation";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Confirmation from "../confirmation";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AssignReviewerProps {
  open: boolean;
  handleClose: any;
  selectedBook?: any;
  initialDetail?: any;
  detailed_review?: any;
  reject_reason?: any;
  assginerId?: any;
  bookRating?: any;
}

export default function AssignReviewer({
  open,
  handleClose,
  selectedBook,
  initialDetail,
  detailed_review,
  reject_reason,
  assginerId,
  bookRating,
}: AssignReviewerProps) {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [allBookReviewers, setAllBookReviewers] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<any>(null);
  const [assignedLoader, setAssignedLoader] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [confirmCheck, setConfirmCheck] = useState<any>(null);
  let showTextArea = true;

  const getAllReviewers = () => {
    setLoader(true);
    getHODBookReviewersService(search)
      .then(({ data }) => {
        setAllBookReviewers(data?.data?.data);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      });
  };

  useEffect(() => {
    if (open) {
      getAllReviewers();
    }
    // eslint-disable-next-line
  }, [search, open]);

  const ExampleCustomInput = React.forwardRef(
    ({ value, onClick }: any, ref: any) => (
      <button className={styles.assignBtn} onClick={onClick} ref={ref}>
        Assign
      </button>
    )
  );

  const handleAssign = (item: any, date: any, textInput: any) => {
    let resp = {
      book_id: selectedBook?.id,
      reviewer_id: item?.id,
      feedback: {
        intial_review: initialDetail ? initialDetail : null,
        detailed_review:
          selectedBook?.book_review_assignment?.reviewer_id === item?.id
            ? detailed_review
              ? detailed_review
              : null
            : null,
        reject_reason: reject_reason ? reject_reason : null,
        rating: { value: bookRating ? bookRating : 0 },
      },
      comment: textInput ? textInput : "",
      due_date: moment(date).format("YYYY-MM-DD"),
    };

    setAssignedLoader(true);
    assignReviewerService(resp)
      .then(({ data }) => {
        handleClose();
        setAssignedLoader(false);
        if (data?.statusCode === 200) {
          toastMessage("success", data?.message);
        } else {
          toastMessage("error", data?.message);
        }
        navigate(routeConstant.dashboard.path);
      })
      .catch((err) => {
        setAssignedLoader(false);
        console.log(err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setConfirmCheck(null);
  };

  const handleActions = (inputText: string) => {
    handleAssign(confirmCheck?.item, confirmCheck?.date, inputText);
    handleConfirmClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogContent className={styles.dialog}>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          />
          <div className={styles.topleveContainer}>
            <div className={styles.bookAssignSection}>
              <div style={{ width: "100%" }}>
                <div className={styles.heading}>Assign to book reviewer</div>
                <div className={styles.subHeading}>
                  Frequently assign books to book reviewer
                </div>
              </div>

              <div className={styles.gridContainer} style={{ width: "100%" }}>
                <Icons.SearchIcon className={styles.searchIcon} />
                <input
                  placeholder="Search"
                  className={styles.input}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              {allBookReviewers?.map((item: any) => {
                return (
                  <Box className={styles.boxContainer}>
                    <Box className={styles.singleContainer}>
                      {loader ? (
                        <div style={{ display: "flex" }}>
                          <Skeleton variant="circular" width={40} height={40} />
                          <div>
                            <Skeleton
                              variant="rectangular"
                              width={100}
                              height={10}
                              sx={{ marginLeft: "10px", marginTop: "5px" }}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={250}
                              height={10}
                              sx={{ marginLeft: "10px", marginTop: "5px" }}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={120}
                              height={10}
                              sx={{ marginLeft: "10px", marginTop: "5px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            <img
                              alt="avatar"
                              src={
                                item?.profile_picture
                                  ? item?.profile_picture
                                  : Images.UserPlaceholderImage
                              }
                              className={styles.profilePic}
                            />
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <div
                              className={styles.subHeading}
                              style={{ color: "black" }}
                            >
                              {item?.full_name}
                            </div>
                            <Box sx={{ display: "flex", flexWrap:'wrap',marginBottom:'.3rem' }}>
                              {item?.genres?.map(
                                (itm: any, inx: any, arr: any) => {
                                  return (
                                    <div className={styles.genres}>
                                      {itm?.genre?.name}
                                      {arr?.length - 1 !== inx ? "," : "."}
                                    </div>
                                  );
                                }
                              )}
                            </Box>

                            <Box sx={{ display: "flex" }}>
                              <div className={styles.genres}>
                                Currently Assgined:{" "}
                                {item?.books_count?.assigned}
                              </div>
                            </Box>
                          </div>
                        </>
                      )}
                    </Box>
                    <Box>
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={145}
                          height={50}
                          sx={{
                            marginLeft: "10px",
                            marginTop: "5px",
                            borderRadius: "32px",
                          }}
                        />
                      ) : (
                        <DatePicker
                          selected={startDate}
                          onChange={(date: any) => {
                            setStartDate(date);
                            // handleAssign(item, date);
                            setOpenConfirm(true);
                            setConfirmCheck({
                              label: (
                                <div>
                                  Are you certain about assigning this book to
                                  <span> {item?.full_name}</span> till{" "}
                                  {moment(date).format("Do MMM YYYY")} ?
                                </div>
                              ),
                              item: item,
                              date: date,
                            });
                          }}
                          customInput={<ExampleCustomInput />}
                          minDate={new Date()}
                        />
                      )}
                    </Box>
                  </Box>
                );
              })}
            </div>
          </div>
          {assignedLoader && <Loader />}
        </DialogContent>

        <Confirmation
          open={openConfirm}
          handleClose={handleConfirmClose}
          text={showTextArea ? confirmCheck : confirmCheck?.label}
          showTextArea
          handleClick={handleActions}
        ></Confirmation>

        {/* <Confirmation
          open={openConfirm}
          handleClose={handleConfirmClose}
          text={confirmCheck?.label}
          handleClick={handleActions}
        /> */}
      </Dialog>
    </div>
  );
}
