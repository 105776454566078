import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Images } from "assets";
import Card from "@mui/material/Card";
import { Box, Checkbox } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Loader from "shared/components/loader/animation";
import ForgetPassword from "shared/modals/forgetPassword";
import Cookies from "universal-cookie";
import { setUser } from "shared/redux/reducers/userSlice";
import { LoginUser } from "shared/services/authService";
import { toastMessage } from "shared/components/toast";
import { roles } from "shared/routes/constant";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import { loginSchema } from "shared/utils/validationSchemas";

interface InitialValues {
  email: string;
  password: string;
}

export default function Auth() {
    // eslint-disable-next-line
  const cookies = new Cookies();
  const cookieValue = cookies.get("aliflaila_admin_credentials");
  const [checkBox, setCheckBox] = useState<boolean>(cookieValue ? true : false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openForgetPassword, setOpenForgetPassword] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();

  const initialValues: InitialValues = {
    email: cookieValue?.email ? cookieValue?.email : "",
    password: cookieValue?.password ? cookieValue?.password : "",
  };

  const handleLogin = useCallback((values: any) => {
    setLoader(true);
    LoginUser(values)
      .then(({ data }) => {
        if (data?.statusCode === 200) {
          let obj: any = {
            user: data?.data,
            isLoggedIn: true,
            token: data?.data?.token,
            role: roles.find(
              (role) => role.role_id === Number(data?.data?.role)
            ),
          };
          dispatch(setUser(obj));
          toastMessage("success", data?.message);
          setLoader(false);

          if (checkBox) {
            const cookieValue = cookies.get("aliflaila_admin_credentials");
            if (cookieValue?.email !== values?.email) {
              const expirationDate = new Date();
              expirationDate.setDate(expirationDate.getDate() + 30);
              cookies.set(
                "aliflaila_admin_credentials",
                JSON.stringify({
                  email: values.email,
                  password: values.password,
                }),
                { expires: expirationDate }
              );
            }
          }
        } else {
          toastMessage("error", data?.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  }, [checkBox, cookies, dispatch]);

  const handleCheckbox = () => {
    setCheckBox(!checkBox);
    if (cookieValue) {
      cookies.remove("aliflaila_admin_credentials");
      toastMessage("success", "Credentials removed.");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: handleLogin,
  });

  return (
    <Box className={styles.box}>
      <Card className={styles.cardContainer}>
        <Images.Logo className={styles.logo} />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <div className={styles.gridContainer}>
            <label className={styles.label}>Email address</label>
            <input
              id="email"
              name="email"
              type="email"
              className={styles.customInput}
              placeholder="johndoe@yopmail.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className={styles.errorText}>{formik.errors.email}</div>
            ) : (
              <div style={{ height: "20px" }}></div>
            )}
          </div>

          <div className={styles.gridContainer}>
            <label className={styles.label}>Password</label>
            <div style={{ position: "relative" }}>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                className={styles.customInput}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {showPassword ? (
                <VisibilityOffIcon
                  className={styles.icon}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <VisibilityIcon
                  className={styles.icon}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>

            {formik.touched.password && formik.errors.password ? (
              <div className={styles.errorText}>{formik.errors.password}</div>
            ) : (
              <div style={{ height: "20px" }}></div>
            )}
          </div>

          <div className={styles.customerContainer}>
            <label className={styles.labelHead}>
              {" "}
              <Checkbox
                sx={{ padding: "0px" }}
                className={checkBox ? styles.checkbox : ""}
                onChange={() => handleCheckbox()}
                defaultChecked={checkBox}
              />
              <span style={{ marginLeft: "5px" }}>Remember me?</span>
            </label>
            <label
              className={styles.forgetLabel}
              onClick={() => setOpenForgetPassword(true)}
            >
              Forgot password?
            </label>
          </div>

          <CustomButton
            text={"Login"}
            buttonStyle={styles.button}
            type={"submit"}
          />
        </form>
      </Card>
      {loader && <Loader />}

      <ForgetPassword
        open={openForgetPassword}
        handleClose={() => setOpenForgetPassword(false)}
      />
    </Box>
  );
}
