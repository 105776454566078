import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Card from "shared/components/card";
import Filter from "shared/components/filterButton";
import Loader from "shared/components/loader/animation";
import Pagination from "shared/components/pagination";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import useDebounce from "shared/customHooks/useDebounce";
import useWindowSize from "shared/customHooks/windowSize";
import Confirmation from "shared/modals/confirmation";
import ViewMember from "shared/modals/viewMember";
import { routeConstant } from "shared/routes/routesConstant";
import { getAllAgeRangesService } from "shared/services/general";
import {
  blockUserService,
  individualAccountService,
  statsIndividualKidsService,
  unblockUserService,
} from "shared/services/productManagerService";
import { searchHandlerHelper } from "shared/utils/helpers";
import SkeletonLoader from "./skeletonLoader";
import styles from "./style.module.scss";
import moment from "moment";

function Individuals() {
  const screenWidth = useWindowSize();
  const [activePage, setActivePage] = useState<number>(1);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [allAccounts, setAllAccounts] = useState<any>(null);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [allAgeRanges, setAllAgeRanges] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [openMember, setOpenMember] = useState<boolean>(false);
  const [stats, setStats] = useState<any>({});
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [selectedAgeRange, setSelectedAgeRange] = useState<any>(null);
  const [total, setTotal] = useState<any>(null);
  const [filterDates, setFilterDates] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedItem(null);
  };

  const handleOpenConfirm = (label: String, type: String, item: any) => {
    setOpenConfirm(true);
    setAction({
      label: label,
      type: type,
    });
    setSelectedItem(item);
  };

  const handleAction = (action: any) => {
    let service =
      action?.type === "deactivate"
        ? blockUserService
        : action?.type === "activate"
        ? unblockUserService
        : null;

    setActionLoader(true);
    service?.(selectedItem?.id)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        handleCloseConfirm();
        getAllAccounts(false);
        setActionLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response.data?.message);
        console.log("error", err?.response.data?.message);
        setActionLoader(false);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs({
    title: "Individuals",
    path: routeConstant.aliflailaIndividualUsers.path,
  });

  const getAllAccounts = (bool: boolean) => {
    if (bool) {
      setLoader(true);
    }
    individualAccountService(
      searchText,
      activePage,
      selectedAgeRange?.value,
      selectedFilter?.value,
      filterDates?.startDate,
      filterDates?.endDate
    )
      .then(({ data }) => {
        setTotal(data?.data?.total);
        setTotalPage(data?.data?.last_page);
        setAllAccounts(data?.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getStats = () => {
    statsIndividualKidsService()
      .then(({ data: { status, data } }) => {
        if (status) {
          setStats(data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    getAllAccounts(true);
    // eslint-disable-next-line
  }, [searchText, activePage, selectedAgeRange, selectedFilter, filterDates]);

  const cards = [
    {
      title: "Total User Accounts",
      stats: stats?.total,
      icon: null,
    },
    {
      title: "Free Users Accounts",
      stats: stats?.free,
      icon: null,
    },
    {
      title: "Subscribed User Accounts",
      stats: stats?.memberships,
      icon: null,
    },
  ];

  const filterOption = [
    {
      option: "Free",
      value: 1,
    },
    {
      option: "Subscribed",
      value: 2,
    },
  ];

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setFilterDates({
      startDate: start,
      //  moment(start).format("YYYY-MM-DD"),
      endDate: end,
      // moment(end).format("YYYY-MM-DD"),
    });
    setActivePage(1);
  };

  const resetFilters = () => {
    setFilterDates({
      startDate: null,
      endDate: null,
    });
    setSelectedFilter(null);
    setSelectedAgeRange(null);
    setSearch("");
    setSearchText("");
  };

  const getAllAgeRanges = () => {
    getAllAgeRangesService()
      .then(({ data }) => {
        // console.log("Data", data?.data);
        let obj: any = [];
        data?.data?.forEach((itm: any) => {
          obj.push({
            id: itm?.id,
            option: itm?.text,
            value: itm?.id,
          });
        });
        setAllAgeRanges(obj);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllAgeRanges();
  }, []);

  return (
    <div>
      <Grid container spacing={2} marginBottom={"20px"}>
        {cards?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={12} md={4}>
                <Card
                  Icon={item?.icon}
                  stats={item?.stats}
                  title={item?.title}
                  isLoading={false}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Individuals Accounts</div>
            <div className={styles.tableSubHeading}>
              Manage individuals accounts from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            {searchText?.length ||
            selectedAgeRange ||
            selectedFilter ||
            (filterDates?.startDate && filterDates?.startDate) ? (
              <div style={{ marginRight: "10px" }}>
                <button
                  className={styles.resetBtn}
                  onClick={() => resetFilters()}
                >
                  Reset Filters
                </button>
              </div>
            ) : null}
            <div className={styles.ageFilter}>
              <Filter
                label="Search by Age"
                filterOption={allAgeRanges}
                filterStyles={styles.filterStyles}
                selectedOption={selectedAgeRange}
                setSelectedOption={setSelectedAgeRange}
                setActivePage={setActivePage}
              />
            </div>
            <div className={styles.rangeInputWrapper}>
              <ReactDatePicker
                className={styles.range_input}
                dateFormat="d MMM"
                selected={filterDates?.startDate}
                onChange={(date: any) => onChangeDate(date)}
                startDate={filterDates?.startDate}
                endDate={filterDates?.endDate}
                selectsRange
                placeholderText="Search by Date"
              />
            </div>
            <div className={styles.userFilter}>
              <Filter
                label="Search by Membership Status"
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
                setActivePage={setActivePage}
              />
            </div>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <Box className={styles.counterContainer}>Total Results: {total}</Box>

        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Plan Status</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allAccounts?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.reader?.profile_picture}
                              alt="book cover"
                              className={styles.profile_picture}
                            />
                            <div className={styles.titleWrapper}>
                              <span style={{ marginLeft: "10px" }}>
                                {item?.reader?.full_name}
                              </span>
                            </div>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item?.email
                            ? item?.email
                            : item?.username
                            ? item?.username
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {moment(item?.created_at).format("MMM DD, YYYY")}
                        </TableCell>
                        <TableCell>
                          {item?.current_plan
                            ? `Yes (${
                                item?.current_plan?.duration === 12
                                  ? "Annual Plan"
                                  : item?.current_plan?.duration === 1
                                  ? "1 Month Plan"
                                  : item?.current_plan?.duration > 1
                                  ? `${item?.current_plan?.duration} Months Plan`
                                  : ""
                              })`
                            : "No"}
                        </TableCell>
                        <TableCell
                          className={
                            item?.is_blocked ? styles.deactive : styles.active
                          }
                        >
                          {item?.is_blocked ? "Deactive" : "Active"}
                        </TableCell>
                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip title={"View"} arrow>
                              <button
                                onClick={() => {
                                  setSelectedItem(item);
                                  setOpenMember(true);
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>
                            <Tooltip
                              title={
                                item?.is_blocked ? "Activate" : "Deactivate"
                              }
                              arrow
                            >
                              <button
                                onClick={() =>
                                  handleOpenConfirm(
                                    `Are you sure, you want to ${
                                      item?.is_blocked
                                        ? "activate"
                                        : "deactivate"
                                    } this user?`,
                                    `${
                                      item?.is_blocked
                                        ? "activate"
                                        : "deactivate"
                                    }`,
                                    item
                                  )
                                }
                              >
                                {item?.is_blocked ? (
                                  <Icons.SchoolActivate />
                                ) : (
                                  <Icons.SchoolDeactivate />
                                )}
                              </button>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {allAccounts?.length === 0 && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}

        <ViewMember
          open={openMember}
          handleClose={() => {
            setSelectedItem(null);
            setOpenMember(false);
          }}
          selectedItem={selectedItem}
        />

        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}

        <Confirmation
          text={action.label}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          handleClick={() => handleAction(action)}
        />

        {actionLoader && <Loader />}
      </Box>
    </div>
  );
}

export default Individuals;
