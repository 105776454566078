import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import classNames from "classnames";
import styles from "./style.module.scss";
import { Images } from "assets";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

const NotificationsTabs = ["All", "Unread"];
// const NotificationsTabsEnums = {
//   all: "All",
//   unRead: "Unread",
// };

function Notifications() {
  const navigate = useNavigate();
  const {
    user: {
      role: { role_id },
    },
  } = useSelector((state: any) => state.root);
  const [activeTab, setActiveTab] = useState<string>(NotificationsTabs[0]);
  


  return (
    <div className={styles.mainContainer}>
      <div className={styles.CTAContainer}>
        <div className={styles.headingContainer}>
          <KeyboardBackspaceIcon
            sx={{ cursor: "pointer" }}
            className={styles.backIcon}
            onClick={() => navigate(-1)}
          />
          <label className={styles.heading}>All Notifications</label>
        </div>
        <div className={classNames(styles.toggleContainer)}>
          {NotificationsTabs.map((itm, inx) => {
            return (
              <label
                className={classNames(
                  styles.toggleLabel,
                  activeTab === itm && styles.activeTabStyle
                )}
                role="button"
                onClick={() => {
                  setActiveTab(itm);
                }}
                key={inx}
              >
                {itm}
              </label>
            );
          })}
        </div>
      </div>
      <label className={styles.clearAll}>Clear All Notifications</label>

      <div className={styles.notificationsContainer}>
        <Grid container spacing={2} sx={{ marginTop: "10px", margin: "0" }}>
          <div style={{ display: "flex", width: "100%", cursor: "pointer" }}>
            <Grid item xs={3} md={2} className={styles.grid}>
              <div className={styles.imgContainer}>
                <img
                  src={Images.Sample}
                  alt="notification-img"
                  className={styles.roundImg}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={6} className={styles.grid}>
              <div className={styles.contentContainer}>
                <label className={styles.notificationHeading}>Book name</label>
                <label className={styles.description}>
                  New book Added by Ehya's Publishers
                </label>
                <div className={styles.assigneeContainer}>
                  <img
                    src={Images.AvatarPlaceholder}
                    alt="teacher-pp"
                    className={styles.assigneeImg}
                  />
                  <label className={styles.teacherName}>
                    by: <span>Teacher Name</span>
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} md={2} className={styles.grid}>
              <div className={styles.time}>
                {moment(
                  "Mon Aug 05 2024 15:27:28 GMT+0500 (Pakistan Standard Time)"
                )
                  .fromNow()
                  .replace("hours", "hrs")
                  .replace("an hour", "1 hr")
                  .replace("a few seconds", "seconds")
                  .replace("minutes", "mins")
                  .replace("minute", "min")
                  .replace("years", "yrs")
                  .replace("a year", "1 yr")}
              </div>
            </Grid>
          </div>
          <div style={{ display: "flex", width: "100%", cursor: "pointer" }}>
            <Grid item xs={3} md={2} className={styles.grid}>
              <div className={styles.imgContainer}>
                <img
                  src={Images.BookSample1}
                  alt="notification-img"
                  className={styles.bookImg}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={6} className={styles.grid}>
              <div className={styles.contentContainer}>
                <label className={styles.notificationHeading}>Book name</label>
                <label className={styles.description}>
                  New book Added by Ehya's Publishers
                </label>
                <div className={styles.assigneeContainer}>
                  <img
                    src={Images.AvatarPlaceholder}
                    alt="teacher-pp"
                    className={styles.assigneeImg}
                  />
                  <label className={styles.teacherName}>
                    by: <span>Teacher Name</span>
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={3} md={2} className={styles.grid}>
              <div className={styles.time}>
                {moment(
                  "Mon Aug 05 2024 15:27:28 GMT+0500 (Pakistan Standard Time)"
                )
                  .fromNow()
                  .replace("hours", "hrs")
                  .replace("an hour", "1 hr")
                  .replace("a few seconds", "seconds")
                  .replace("minutes", "mins")
                  .replace("minute", "min")
                  .replace("years", "yrs")
                  .replace("a year", "1 yr")}
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default Notifications;
