let roles = [
  {
    role_id: 1,
    title: "Project Head",
  },
  {
    role_id: 2,
    title: "HOD",
  },
  {
    role_id: 3,
    title: "Book Reviewer",
  },
  {
    role_id: 4,
    title: "Product Manager",
  },
  {
    role_id: 5,
    title: "Accountant",
  },
  {
    role_id: 6,
    title: "Admin",
  },
];

export { roles };
