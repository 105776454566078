import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "shared/components/sidebar";
import { routeConstant } from "./routesConstant";

interface LayoutProps {
  path: string;
  title: string;
  Component: any;
}

function Layout({ title, Component, path }: Partial<LayoutProps>) {
  const location = useLocation();
  useEffect(() => {
    document.title = title + " | Aliflaila";
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      {location?.pathname.includes(
        routeConstant.bookPreview.path.split("/")[1]
      ) ? (
        <Component />
      ) : (
        <Sidebar Component={Component} path={path} />
      )}
    </>
  );
}

export default Layout;
