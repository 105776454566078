import React, { useEffect } from "react";
import styles from "../style.module.scss";
import CustomButton from "shared/components/customButton";
import { useFormik } from "formik";
import { getEmailSchema } from "shared/utils/validationSchemas";


interface EmailProps {
  handleSendOTP: (email: string) => void;
  open: boolean;
  loader: boolean;
}

function GetEmail({ handleSendOTP, open, loader }: EmailProps) {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: getEmailSchema,
    onSubmit: (values) => {
      handleSendOTP(values?.email);
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
      // eslint-disable-next-line
  }, [open]);

  return (
    <div className={styles.insideContainer}>
      <h5 className={styles.title}>Reset Your Password</h5>
      <h3 className={styles.subTitle}>
        Enter your email below to receive a link to reset your password.
      </h3>

      <form onSubmit={formik.handleSubmit}>
        <h3>Email Address</h3>
        <input
          type="text"
          name="email"
          className={styles.input}
          placeholder="Enter your email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className={styles.errorText}>{formik.errors.email}</div>
        ) : (
          <div style={{ height: "30px" }}></div>
        )}
        <CustomButton
          text="Send Password Reset Email"
          buttonStyle={styles.btnCustom}
          type="submit"
          loader={loader}
        />
      </form>
    </div>
  );
}

export default GetEmail;
