import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import styles from "./style.module.scss";
import React, { useEffect, useState } from "react";
import CustomButton from "shared/components/customButton";
import FeatureBooks from "./featureBooks";
import Loader from "shared/components/loader/animation";
import {
  activateFeatureBookService,
  approveFeatureBookService,
  getFeaturedBooksService,
  inActivateFeatureBookService,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import PaginationComponent from "shared/components/pagination";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Icons } from "assets";
import moment from "moment";
import { routeConstant } from "shared/routes/routesConstant";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import useWindowSize from "shared/customHooks/windowSize";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "./skeletonLoader";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import Confirmation from "shared/modals/confirmation";
import InfoIcon from "@mui/icons-material/Info";
import RejectFeatureBook from "shared/modals/rejectFeatureBook";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import ViewCover from "./viewCover";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { getPublishedBy } from "shared/utils/helpers";
import { searchHandlerHelper } from "shared/utils/helpers";

import ItemsPerPage from "shared/components/itemsPerPage";

const tab_items = [
  {
    label: "Requested Books",
    value: 0,
  },
  {
    label: "Feature Books",
    value: 1,
  },
  {
    label: "Rejected Books",
    value: 2,
  },
  {
    label: "Expired Books",
    value: 3,
  },
];

function FeaturedBooks() {
  const screenWidth = useWindowSize();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>(tab_items[0].value);
  const [openFeature, setOpenFeature] = useState<boolean>(false);
  const [allFeatureBooks, setAllFeatureBooks] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirm, setOpenConfirmation] = useState<boolean>(false);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [isCoverRequired, setIsCoverRequired] = useState<boolean>(false);
  const [openCover, setOpenCover] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const handleCloseCover = () => {
    setOpenCover(false);
    setSelectedItem(null);
  };

  const handleOpenCover = (item: any) => {
    setOpenCover(true);
    setSelectedItem(item);
  };

  const getAllFeatureBooks = () => {
    setLoader(true);
    getFeaturedBooksService(activeTab, searchText, activePage, itemsPerPage)
      .then(({ data }) => {
        setAllFeatureBooks(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllFeatureBooks();
    // eslint-disable-next-line
  }, [activeTab, searchText, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAction({
      label: "",
      type: "",
    });
    setSelectedItem(null);
  };

  const handleOpenConfirmation = (label: String, type: String, item: any) => {
    setOpenConfirmation(true);
    setAction({
      label: label,
      type: type,
    });
    setSelectedItem(item);
  };

  const handleActions = (action: any) => {
    let service =
      action?.type === "approve"
        ? approveFeatureBookService
        : action?.type === "activate"
        ? activateFeatureBookService
        : action?.type === "deactivate"
        ? inActivateFeatureBookService
        : null;

    setActionLoader(true);
    service?.(selectedItem?.id)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        handleCloseConfirmation();
        getAllFeatureBooks();
        setActionLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response.data?.message);
        console.log("error", err?.response.data?.message);
        setActionLoader(false);
      });
  };

  const handleCloseRejection = () => {
    setOpenReject(false);
    setSelectedItem(null);
  };

  const handleOpenRejection = (item: any) => {
    setOpenReject(true);
    setSelectedItem(item);
  };

  const openRefeatured = (item: any, bool: boolean) => {
    setSelectedItem(item);
    setOpenFeature(true);
    setIsCoverRequired(bool);
  };

  const handleCloseFeature = () => {
    setOpenFeature(false);
    setSelectedItem(null);
    setIsCoverRequired(false);
  };

  useBreadcrumbs({
    title: "Feature Books",
    path: routeConstant.featuredBooks.path,
  });

  return (
    <Box>
      <div className={styles.topContainer}>
        <div className={styles.title}>Select books to feature them.</div>
        <div className={styles.btnContainer}>
          <CustomButton
            text="New Feature books"
            buttonStyle={styles.btnSave}
            handleClick={() => setOpenFeature(true)}
          />
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#9A469B",
              color: "red !important",
            },
          }}
          sx={{
            "& .MuiTab-root.Mui-selected": {
              color: "#9A469B",
            },
          }}
        >
          {tab_items?.map((item) => {
            return (
              <Tab
                label={item?.label}
                className={styles.tabs}
                onClick={() => {
                  setActiveTab(item?.value);
                  setSearch("");
                }}
              />
            );
          })}
        </Tabs>
      </Box>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>
              {tab_items.find((item: any) => item?.value === activeTab)?.label}{" "}
              here
            </div>
            <div className={styles.tableSubHeading}>
              All request books list showing here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />

              {search.length ? (
                <CloseIcon
                  sx={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSearch("")}
                />
              ) : null}
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book name</TableCell>
                <TableCell>Published by</TableCell>
                <TableCell>Duration</TableCell>
                {activeTab === 1 && <TableCell>Status</TableCell>}
                {activeTab === 0 && <TableCell>Cover Request</TableCell>}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader extra={activeTab === 0 || activeTab === 1} />
              ) : (
                allFeatureBooks?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.book?.thumbnail}
                              alt="book cover"
                              className={styles.bookCoverTable}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {item?.book?.title}
                              <div className={styles.name}>
                                @{item?.book?.book_author?.name}
                              </div>
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={getPublishedBy(item?.book?.partner)?.img}
                              alt="book cover"
                              className={styles.bookCoverTable}
                              style={{ borderRadius: "50%" }}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {getPublishedBy(item?.book?.partner)?.name}
                            </span>
                          </Box>{" "}
                        </TableCell>

                        <TableCell>
                          {moment(item?.start_date).format("D-MM-yyyy ") +
                            " to " +
                            moment(item?.end_date).format("D-MM-yyyy")}
                        </TableCell>

                        {activeTab === 1 && (
                          <TableCell>
                            {moment().isBefore(item?.start_date) ? (
                              <span className={styles.upcoming}>Scheduled</span>
                            ) : (
                              <span className={styles.active}>Active</span>
                            )}
                          </TableCell>
                        )}

                        {activeTab === 0 && (
                          <TableCell>
                            {item?.is_cover_requested ? "Yes" : "No"}
                          </TableCell>
                        )}

                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            {" "}
                            <Tooltip title="Book Detail" arrow>
                              <button
                                onClick={() =>
                                  navigate(
                                    routeConstant.bookDetail.path.replace(
                                      ":id",
                                      item?.book?.id
                                    )
                                  )
                                }
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>
                            {activeTab === 0 && (
                              <>
                                {" "}
                                <Tooltip title="Reject" arrow>
                                  <button
                                    onClick={() => handleOpenRejection(item)}
                                  >
                                    <ClearIcon />
                                  </button>
                                </Tooltip>
                                {item?.is_cover_requested &&
                                Number(item.status === 0) ? (
                                  <Tooltip title="Upload Cover" arrow>
                                    <button
                                      onClick={() => openRefeatured(item, true)}
                                    >
                                      <CloudUploadIcon />
                                    </button>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Approve" arrow>
                                    <button
                                      onClick={() =>
                                        handleOpenConfirmation(
                                          "Are you sure, you want to approve this?",
                                          "approve",
                                          item
                                        )
                                      }
                                    >
                                      <CheckIcon />
                                    </button>
                                  </Tooltip>
                                )}
                              </>
                            )}
                            {activeTab === 1 && (
                              <>
                                {" "}
                                <Tooltip
                                  title={
                                    item?.is_active ? "Deactivate" : "Activate"
                                  }
                                  arrow
                                >
                                  <button
                                    onClick={() =>
                                      handleOpenConfirmation(
                                        `Are you sure, you want to ${
                                          item?.is_active
                                            ? "deactivate"
                                            : "activate"
                                        } this? `,
                                        item?.is_active
                                          ? "deactivate"
                                          : "activate",
                                        item
                                      )
                                    }
                                  >
                                    {item?.is_active ? (
                                      <FlashOnIcon />
                                    ) : (
                                      <FlashOffIcon />
                                    )}
                                  </button>
                                </Tooltip>
                              </>
                            )}
                            {/* {activeTab === 3 && (
                              <Tooltip title="Refeature" arrow>
                                <button
                                  onClick={() => openRefeatured(item, false)}
                                >
                                  <AutorenewIcon />
                                </button>
                              </Tooltip>
                            )} */}
                            {activeTab === 2 && (
                              <Tooltip title="Reason" arrow>
                                <button
                                  onClick={() => handleOpenRejection(item)}
                                >
                                  <InfoIcon />
                                </button>
                              </Tooltip>
                            )}
                            {item?.cover_photo && (
                              <Tooltip arrow title={"View Cover"}>
                                <button onClick={() => handleOpenCover(item)}>
                                  <ImageIcon />
                                </button>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {allFeatureBooks?.length === 0 && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <PaginationComponent
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allFeatureBooks?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>

      <RejectFeatureBook
        open={openReject}
        handleClose={() => handleCloseRejection()}
        selectedItem={selectedItem}
        getAllFeatureBooks={getAllFeatureBooks}
      />

      <FeatureBooks
        open={openFeature}
        handleClose={() => handleCloseFeature()}
        getAllFeatureBooks={getAllFeatureBooks}
        selectedItem={selectedItem}
        isCoverRequired={isCoverRequired}
      />
      <Confirmation
        open={openConfirm}
        handleClose={() => handleCloseConfirmation()}
        handleClick={() => handleActions(action)}
        text={action.label}
      />

      <ViewCover
        open={openCover}
        handleClose={handleCloseCover}
        selectedItem={selectedItem}
      />

      {actionLoader && <Loader />}
    </Box>
  );
}

export default FeaturedBooks;
