import {
  Box,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import classNames from "classnames";
import { useEffect, useState } from "react";
import PaginationComponent from "shared/components/pagination";
import useWindowSize from "shared/customHooks/windowSize";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import Confirmation from "shared/modals/confirmation";
import NotificationDetail from "shared/modals/notificationDetail";
import { routeConstant } from "shared/routes/routesConstant";
import {
  cancelCustomNotification,
  getAllCustomNotifications,
} from "shared/services/projectHeadService";
import {
  getAllCustomNotifications as getAllCustomNotificationsManager,
  cancelCustomNotification as cancelCustomNotificationManager,
} from "shared/services/productManagerService";
import { directions, notification_status, roles, types } from "./constant";
import SkeletonLoader from "./skeletonLoader";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { roles as admin_roles } from "shared/utils/constants";

function NotificationListing() {
  const screenWidth = useWindowSize();
  const navigate = useNavigate();
  const {
    user: {
      user: { role },
    },
  } = useSelector((state: any) => state.root);
  const [activePage, setActivePage] = useState<number>(1);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [allNotification, setAllNotifications] = useState<[]>([]);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<boolean>(false);
  // const [search, setSearch] = useState<string>("");

  useBreadcrumbs({
    title: "Notification Listings",
    path: routeConstant.notificationListings.path,
  });

  let headers = [
    "Title",
    "Send to",
    "Type",
    "Direction",
    "Send Time",
    "Status",
    "Actions",
  ];

  const getNotifications = (loader: boolean) => {
    let service =
      Number(role) === admin_roles?.productManager
        ? getAllCustomNotificationsManager
        : Number(role) === admin_roles?.projectHead
        ? getAllCustomNotifications
        : null;
    if (loader) {
      setLoader(true);
    }
    service?.(activePage)
      .then(({ data: { data } }) => {
        setAllNotifications(data?.data);
        setLoader(false);
        setTotalPages(data?.last_page);
      })
      .catch((err) => {
        console.log("Data", err?.response?.data);
        setLoader(false);
      });
  };

  useEffect(() => {
    getNotifications(true);
    // eslint-disable-next-line
  }, [activePage]);

  const handleCancelNotification = () => {
    let service =
      Number(role) === admin_roles?.productManager
        ? cancelCustomNotificationManager
        : Number(role) === admin_roles?.projectHead
        ? cancelCustomNotification
        : null;

    service?.(selectedItem?.id)
      .then(({ data: { data, message, status } }) => {
        if (status) {
          toastMessage("success", message);
          setOpenConfirm(false);
          setSelectedItem(null);
          getNotifications(false);
        } else {
          toastMessage("error", message);
        }
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  return (
    <div>
      <div className={styles.topLevelContainer}>
        <div className={styles.headingContainer}>
          <label className={styles.heading}>Custom Notification History</label>
          <label className={styles.subHeading}>
            Here you can see custom generated notifications and cancel scheduled
            notification.
          </label>
        </div>
      </div>

      <div className={styles.mainContainer}>
        <Box className={styles.toptableContainer}>
          <div className={styles.headingContainer}>
            <label className={styles.tableHeading}>All Notifications</label>
          </div>
          {/* <div className={styles.gridContainer}>
            <Icons.SearchIcon className={styles.searchIcon} />
            <input
              placeholder="Search"
              className={styles.input}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div> */}
          <div className={styles.gridContainer}>
            <button
              className={styles.createBtn}
              onClick={() => {
                navigate(routeConstant.customNotifications.path);
              }}
            >
              Create New
            </button>
          </div>
        </Box>

        <TableContainer
          component={Paper}
          className={styles.tableContainer}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead className={styles.tableHead}>
              <TableRow>
                {headers?.map((item: any) => {
                  return (
                    <>
                      <TableCell>{item}</TableCell>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableBody}>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allNotification?.map((item: any, index: any, arr: any) => {
                  let Role: any = roles?.find(
                    (itm: any) => itm?.id === item?.roles[0]
                  );
                  return (
                    <TableRow
                      className={classNames(
                        index + 1 === arr.length && styles.lastCells
                      )}
                    >
                      <TableCell>{item?.title}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item?.roles?.length > 1 ? (
                            <MenuDropDown listing={item?.roles} isRole={true} />
                          ) : (
                            <>
                              {
                                //eslint-disable-next-line
                                <Role.icon className={styles.menuIcon} />
                              }
                              {Role?.label}
                            </>
                          )}
                        </div>
                      </TableCell>

                      <TableCell>
                        {item?.notification_methods?.length > 1 ? (
                          <MenuDropDown
                            listing={item?.notification_methods}
                            isMethod={true}
                          />
                        ) : (
                          types?.find(
                            (itm: any) =>
                              itm?.id === item?.notification_methods[0]
                          )?.label
                        )}
                      </TableCell>
                      <TableCell>
                        {
                          directions?.find(
                            (itm: any) => itm?.param === item?.direction_type
                          )?.label
                        }
                      </TableCell>
                      <TableCell>
                        {moment(
                          item?.schedule_date
                            ? item?.schedule_date
                            : item?.created_at
                        ).format("hh:mm A | DD MMM, YYYY")}
                      </TableCell>
                      <TableCell className={classNames(styles.statusCell)}>
                        <label
                          className={
                            item?.status === notification_status?.sent
                              ? styles.approved
                              : item?.status === notification_status?.scheduled
                              ? styles?.pending
                              : item?.status === notification_status?.cancelled
                              ? styles.rejected
                              : ""
                          }
                        >
                          {item?.status === notification_status?.sent
                            ? "Sent"
                            : item?.status === notification_status?.scheduled
                            ? "Scheduled"
                            : item?.status === notification_status?.cancelled
                            ? "Cancelled"
                            : ""}
                        </label>
                      </TableCell>
                      <TableCell>
                        <div className={styles.actionContainer}>
                          <Tooltip arrow title="View Detail">
                            <div
                              onClick={() => {
                                setOpenDetail(true);
                                setSelectedItem(item);
                              }}
                            >
                              <Icons.EyeIcon className={styles.icons} />
                            </div>
                          </Tooltip>
                          {item?.status === notification_status?.scheduled && (
                            <Tooltip arrow title="Cancel">
                              <div
                                onClick={() => {
                                  setOpenConfirm(true);
                                  setSelectedItem(item);
                                }}
                              >
                                <Icons.CircleCrossIcon
                                  className={styles.icons}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <PaginationComponent
          totalPages={totalPages}
          activePage={activePage}
          setActivePage={setActivePage}
        />

        <NotificationDetail
          open={openDetail}
          handleClose={() => {
            setOpenDetail(false);
            setSelectedItem(null);
          }}
          selectedItem={selectedItem}
          getNotifications={getNotifications}
        />

        <Confirmation
          open={openConfirm}
          handleClose={() => {
            setOpenConfirm(false);
            setSelectedItem(null);
          }}
          handleClick={() => handleCancelNotification()}
          text={"Are you sure, you want to cancel?"}
        />
      </div>
    </div>
  );
}

export default NotificationListing;

function MenuDropDown({ listing, isMethod, isRole }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  let Icon: any = roles?.find((itm: any) => itm?.id === listing[0])?.icon;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedItem(
      isRole
        ? roles?.find((itm: any) => itm?.id === listing[0])
        : isMethod
        ? types?.find((itm: any) => itm?.id === listing[0])
        : null
    );
  }, [listing, isMethod, isRole]);

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.menuBtn}
      >
        {isRole && <Icon className={styles.menuIcon} />}
        {selectedItem?.label}
        <span>+{listing?.length - 1}</span> <ExpandMoreIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            padding: "0px 10px",
          },
        }}
      >
        {listing?.map((item: any, inx: any, arr: any) => {
          let Icon: any = roles?.find((itm: any) => itm?.id === item)?.icon;
          let label = isRole
            ? roles?.find((itm: any) => itm?.id === item)?.label
            : isMethod
            ? types?.find((itm: any) => itm?.id === item)?.label
            : "";
          return (
            <>
              {inx !== 0 ? (
                <MenuItem
                  onClick={handleClose}
                  className={classNames(
                    styles.menuItems,
                    inx !== arr?.length - 1 && styles.borderBottom
                  )}
                >
                  {isRole && <Icon className={styles.menuIcon} />}
                  {label}
                </MenuItem>
              ) : null}
            </>
          );
        })}
      </Menu>
    </div>
  );
}
