import React, { useState } from "react";

import styles from "./style.module.scss";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import CounterButton from "shared/components/counterButton";
import CustomButton from "shared/components/customButton";

interface EditSchoolModalPropsType {
  viewModal: boolean;
  closeViewModal: any;
  confirm: any;
  teachersCount: number;
  setTeachersCount: any;
  studentsCount: number;
  setStudentsCount: any;
  loader: boolean;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditSchoolModal = ({
  loader,
  viewModal,
  closeViewModal,
  confirm,
  teachersCount,
  studentsCount,
  setTeachersCount,
  setStudentsCount,
}: EditSchoolModalPropsType) => {
  return (
    <React.Fragment>
      <Dialog
        className={styles.dialog}
        open={viewModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closeViewModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.closeIcon}>
          <span onClick={() => closeViewModal(false)}>
            <Icons.CrossIcon />
          </span>
        </div>
        <DialogTitle className={styles.heading}>
          <h3 className={styles.mainHeading}>
            Edit number of teachers and students
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={styles.details}
            id="alert-dialog-slide-description"
          >
            You can increase the number of students and teachers here. Perform
            the actions by clicking on the specified buttons.
          </DialogContentText>

          <div className={styles.teacherCountWrapper}>
            <span className={styles.iconWrapper}>
              <Icons.Teacher />
            </span>
            <p>Add teachers in school</p>
            <CounterButton
              counter={teachersCount}
              setCounter={setTeachersCount}
            />
          </div>
          <div className={styles.studentsCountWrapper}>
            <span className={styles.iconWrapper}>
              <Icons.Student />
            </span>
            <p>Add students in school</p>
            <CounterButton
              counter={studentsCount}
              setCounter={setStudentsCount}
            />
          </div>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <CustomButton
            buttonStyle={styles.cancelButton}
            text="Cancel"
            handleClick={() => closeViewModal(false)}
          />
          <CustomButton
            loader={loader}
            buttonStyle={styles.confirm}
            text="Confirm"
            handleClick={confirm}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EditSchoolModal;
