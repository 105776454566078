import { Endpoint, PublicURL } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const getAllLanguagesService = () => {
  return HTTP_CLIENT.get(PublicURL + Endpoint.general.getAllLanguages);
};

const getKeywordsService = () => {
  return HTTP_CLIENT.get(PublicURL + Endpoint.general.getKeywords);
};

const getAllAgeRangesService = () => {
  return HTTP_CLIENT.get(PublicURL + Endpoint.general.getAllAgeRanges);
};

const getGenresService = () => {
  return HTTP_CLIENT.get(PublicURL + Endpoint.general.getGenres);
};

const getBookDetailsService = (id: any) => {
  return HTTP_CLIENT.get(PublicURL + Endpoint.general.getBookDetail + id);
};

const getBookPagesService = (id: number) => {
  // return HTTP_CLIENT.get(Endpoint.general.getBookPages + id + "/pages");
  return HTTP_CLIENT.get(`/books/pages/${id}/code`);
};

export {
  getAllLanguagesService,
  getKeywordsService,
  getAllAgeRangesService,
  getGenresService,
  getBookDetailsService,
  getBookPagesService,
};
