import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
// import { Icons } from "assets";
import moment from "moment";
import CustomButton from "shared/components/customButton";
import {
  allMouService,
  deleteMOUService,
  getSingleMOUService,
  rejectMOUService,
  signatureMOUService,
  // assignToHeadService,
  // createMOUService,
  // deleteMOUService,
  // updateMOUService,
} from "shared/services/projectHeadService";
import { toastMessage } from "shared/components/toast";
import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import { MOUSchema } from "shared/utils/validationSchemas";
import Loader from "shared/components/loader/animation";
import Confirmation from "shared/modals/confirmation";
import { Icons } from "assets";
import SignatureCanvas from "react-signature-canvas";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";

const radioOptions = [
  {
    label: "Draw Signature",
    value: 0,
  },
  {
    label: "Upload Image",
    value: 1,
  },
];

function MOU() {
  const { id } = useParams<{ id: string }>() as { id: string };
  const location = useLocation();
  const signatureRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [allMous, setAllMous] = useState<any>(null);
  const [mouLoader, setMouLoader] = useState<boolean>(false);
  const [selectedMOU, setSelectedMOU] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [singleLoader, setSingleLoader] = useState<boolean>(false);
  const [openConfirm, setOpenConfirmation] = useState<boolean>(false);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [selectedOption, setSelectedOption] = useState<any>(radioOptions[0]);
  const [image, setImage] = useState<any>({
    preview: "",
    raw: null,
  });
  const [editAllowed, setEditAllowed] = useState<boolean>(false);

  const getAllMous = () => {
    setMouLoader(true);
    allMouService(id)
      .then(({ data }) => {
        setMouLoader(false);
        setAllMous(data?.data);
        setSelectedMOU(data?.data[0]);
      })
      .catch((err) => {
        setMouLoader(false);
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  let initialValues = {
    description: "",
    freeBooksLimit: "",
    premiumBooksLimit: "",
    platformFee: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: MOUSchema,
    onSubmit: (values) => {
      const signatureCanvas = signatureRef.current;
      const isSigned = signatureCanvas?.isEmpty();

      if (selectedOption.value === 0 && isSigned) {
        toastMessage("error", "Please sign to proceed.");
      } else if (selectedOption.value === 1 && !image?.raw) {
        toastMessage("error", "Please upload image to proceed.");
      } else {
        handleSubmit(values);
      }
    },
  });

  const handleSubmit = (values: any) => {
    let signatureData = signatureRef?.current?.toDataURL();

    let resp: any = {
      mou_id: selectedMOU?.id,
      agreement: values?.description,
      free_book_ratio: values?.freeBooksLimit,
      total_books: values?.premiumBooksLimit,
      platform_commission: values?.platformFee,
      signature_type: selectedOption?.value + 1,
    };

    if (selectedOption.value === 0) {
      resp["signature"] = signatureData;
    }

    if (selectedOption.value === 1) {
      resp["signature_file"] = image.raw;
    }

    let formBody = new FormData();
    Object.keys(resp).forEach((key) => {
      formBody.append(key, resp[key]);
    });

    signatureMOUService(formBody)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        setLoader(false);
        getAllMous();
        setSelectedMOU(null);
        formik.resetForm();
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllMous();
    // eslint-disable-next-line
  }, []);

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAction({
      label: "",
      type: "",
    });
  };

  const handleOpenConfirmation = (label: String, type: String) => {
    setOpenConfirmation(true);
    setAction({
      label: label,
      type: type,
    });
  };

  const handleActions = (action: any) => {
    if (action.type === "reject") {
      setLoader(true);
      // deleteMOUService(selectedMOU?.id)
      rejectMOUService(selectedMOU?.id)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          setLoader(false);
          getAllMous();
          handleCloseConfirmation();
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  const getMOU = () => {
    setSingleLoader(true);
    getSingleMOUService(selectedMOU.id)
      .then(({ data }) => {
        setSingleLoader(false);
        setEditAllowed(data?.data?.status === 1);
        formik.setFieldValue("freeBooksLimit", data?.data?.free_book_ratio);
        formik.setFieldValue("description", data?.data?.agreement);
        formik.setFieldValue("premiumBooksLimit", data?.data?.total_books);
        formik.setFieldValue("platformFee", data?.data?.platform_commission);
      })
      .catch((err) => {
        console.log("error", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setSingleLoader(false);
      });
  };

  useEffect(() => {
    if (selectedMOU) {
      getMOU();
    }
    // eslint-disable-next-line
  }, [selectedMOU]);

  const handleClear = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (event: any) => {
    event.preventDefault();
    setImage({ preview: "", raw: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const MOU_STATUS = [
    { label: "Draft", value: 0 },
    { label: "Review", value: 1 },
    { label: "Pending", value: 2 },
    { label: "Accepted", value: 3 },
    { label: "Declined", value: 4 },
    { label: "Rejected", value: 5 },
  ];

  useBreadcrumbs(
    {
      title: location?.state?.user?.full_name,
      path: routeConstant.mouHead.path.replace(":id", id),
    },
    true,
    location?.state
  );

  return (
    <div>
      {
        <Grid container spacing={2}>
          <Grid item xs={12} md={allMous?.length === 0 ? 12 : 3}>
            {mouLoader ? (
              Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"120px"}
                  sx={{ borderRadius: "10px", marginBottom: "20px" }}
                />
              ))
            ) : (
              <>
                <Box>
                  {allMous?.length ? (
                    allMous?.map((item: any, inx: any, array: any) => {
                      return (
                        <div
                          className={
                            item?.id === selectedMOU?.id
                              ? styles.activeMOU
                              : styles.singleMOU
                          }
                          key={inx}
                          onClick={() => setSelectedMOU(item)}
                        >
                          <div className={styles[statusClass(item.status)]}>
                            {
                              MOU_STATUS.find(
                                (itm) => item.status === itm.value
                              )?.label
                            }
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={styles.mouHeading}>
                              MOU {array.length - inx}
                            </div>
                            <div className={styles.mouDate}>
                              {moment(item?.created_at).format("DD-MM-YYYY")}
                            </div>
                          </div>
                          <div className={styles.agreement}>
                            {item?.agreement}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles.noUserCard}>
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </Box>
              </>
            )}
          </Grid>
          <Grid
            sx={allMous?.length === 0 ? { display: "none" } : {}}
            item
            xs={12}
            md={9}
          >
            {mouLoader ? (
              <Card>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"500px"}
                />
              </Card>
            ) : (
              <Card className={styles.mouEditorContainer}>
                <div className={styles.mouInnerContainer}>
                  <div className={styles.mouLabel}>Sign MOU Agreement</div>
                  <div className={styles.mouHeading}>
                    Date:{" "}
                    {moment(
                      new Date(
                        selectedMOU?.created_at
                          ? selectedMOU?.created_at
                          : new Date()
                      )
                    ).format("D/M/yyyy")}
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className={styles.mouHeading}>
                    MOU Description <span>*</span>
                  </div>
                  {!singleLoader ? (
                    <textarea
                      className={styles.textarea}
                      id="description"
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      disabled={!editAllowed}
                    />
                  ) : (
                    <Skeleton
                      variant={"rectangular"}
                      width={"100%"}
                      height={"250px"}
                      sx={{ borderRadius: "20px", marginTop: "10px" }}
                    />
                  )}
                  {formik.touched.description && formik.errors.description ? (
                    <div className={styles.errorText}>
                      {formik?.errors?.description}
                    </div>
                  ) : (
                    <div style={{ height: "20px" }}></div>
                  )}

                  <Grid container sx={{ marginTop: "20px" }}>
                    <Grid xs={4}>
                      <div className={styles.titleHead}>Free Books Limit</div>
                      {singleLoader ? (
                        <Skeleton
                          variant="rectangular"
                          width={"98%"}
                          height={"45px"}
                          sx={{ borderRadius: "32px" }}
                        />
                      ) : (
                        <input
                          className={styles.input}
                          id="freeBooksLimit"
                          name="freeBooksLimit"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.freeBooksLimit}
                          disabled={!editAllowed}
                        />
                      )}
                      {formik.touched.freeBooksLimit &&
                      formik.errors.freeBooksLimit ? (
                        <div className={styles.errorText}>
                          {formik.errors.freeBooksLimit}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>

                    <Grid xs={4}>
                      <div className={styles.titleHead}>
                        Premium Books Limit
                      </div>
                      {singleLoader ? (
                        <Skeleton
                          variant="rectangular"
                          width={"98%"}
                          height={"45px"}
                          sx={{ borderRadius: "32px" }}
                        />
                      ) : (
                        <input
                          className={styles.input}
                          id="premiumBooksLimit"
                          name="premiumBooksLimit"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.premiumBooksLimit}
                          disabled={!editAllowed}
                        />
                      )}
                      {formik.touched.premiumBooksLimit &&
                      formik.errors.premiumBooksLimit ? (
                        <div className={styles.errorText}>
                          {formik.errors.premiumBooksLimit}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>

                    <Grid xs={4}>
                      <div className={styles.titleHead}>Platform Fee</div>
                      {singleLoader ? (
                        <Skeleton
                          variant="rectangular"
                          width={"98%"}
                          height={"45px"}
                          sx={{ borderRadius: "32px" }}
                        />
                      ) : (
                        <input
                          className={styles.input}
                          id="platformFee"
                          name="platformFee"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.platformFee}
                          disabled={!editAllowed}
                        />
                      )}
                      {formik.touched.platformFee &&
                      formik.errors.platformFee ? (
                        <div className={styles.errorText}>
                          {formik.errors.platformFee}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>
                  </Grid>

                  {!editAllowed && (
                    <div className={styles.signatureContainer}>
                      {selectedMOU?.admin_signature && (
                        <div>
                          {singleLoader ? (
                            <Skeleton
                              variant="rectangular"
                              width="155px"
                              height="65px"
                            />
                          ) : (
                            <img
                              src={selectedMOU?.admin_signature}
                              className={styles.signatureImg}
                              alt="signature"
                            />
                          )}
                          <div className={styles.line}></div>
                          <div className={styles.titleHead}>
                            Admin Signature
                          </div>
                        </div>
                      )}
                      {selectedMOU?.user_signature && (
                        <div>
                          {" "}
                          {singleLoader ? (
                            <Skeleton
                              variant="rectangular"
                              width="155px"
                              height="65px"
                            />
                          ) : (
                            <img
                              src={selectedMOU?.user_signature}
                              className={styles.signatureImg}
                              alt="signature"
                            />
                          )}
                          <div className={styles.line}></div>
                          <div className={styles.titleHead}>User Signature</div>
                        </div>
                      )}
                    </div>
                  )}

                  {editAllowed && (
                    <>
                      <div className={styles.mouHeading}>
                        Signature <span>*</span>
                      </div>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={selectedOption.value}
                          onChange={(e) => {
                            setSelectedOption(
                              radioOptions?.find(
                                (item) => Number(e.target.value) === item?.value
                              )
                            );
                          }}
                          sx={{ flexDirection: "row" }}
                        >
                          {radioOptions?.map((option: any, index: number) => (
                            <div className={styles.radio} key={index}>
                              <FormControlLabel
                                value={option.value}
                                control={<Radio />}
                                label={""}
                                name={option.label}
                                checked={option.value === selectedOption.value}
                              />
                              <span className={styles.subHeading}>
                                {option?.label}
                              </span>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                      {selectedOption.value === 0 && (
                        <div style={{ position: "relative" }}>
                          <SignatureCanvas
                            ref={signatureRef}
                            penColor="black"
                            canvasProps={{
                              width: 400,
                              height: 200,
                              className: styles.signatureCanvas,
                            }}
                          />
                          <div>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              ref={fileInputRef}
                              style={{ display: "none" }}
                            />
                          </div>{" "}
                          <div
                            className={styles.clear}
                            onClick={() => handleClear()}
                          >
                            Clear
                          </div>
                        </div>
                      )}

                      {selectedOption.value === 1 ? (
                        !image?.preview ? (
                          <div
                            className={styles.nonImgContainer}
                            onClick={handleButtonClick}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              ref={fileInputRef}
                              style={{ display: "none" }}
                            />
                            <Icons.UploadImgIcon />
                          </div>
                        ) : (
                          <>
                            <div className={styles.nonImgContainer}>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                ref={fileInputRef}
                                style={{ display: "none" }}
                              />
                              <img
                                src={image.preview}
                                alt={"user"}
                                className={styles.uploadPicture}
                                onClick={handleButtonClick}
                              />
                              <CustomButton
                                text={"Clear"}
                                buttonStyle={styles.clearBtn}
                                handleClick={(e: any) => handleRemoveImage(e)}
                              />
                            </div>
                          </>
                        )
                      ) : null}
                    </>
                  )}

                  {editAllowed && (
                    <Box
                      display={"flex"}
                      justifyContent={"end"}
                      marginTop={"40px"}
                    >
                      <CustomButton
                        text={"Sign"}
                        buttonStyle={styles.btnInside}
                        type="Submit"
                      />
                      <CustomButton
                        text={"Reject"}
                        buttonStyle={styles.btn}
                        handleClick={(e: any) => {
                          e.preventDefault();
                          handleOpenConfirmation(
                            "Are you sure you want to reject this mou?",
                            "reject"
                          );
                        }}
                      />
                    </Box>
                  )}
                </form>{" "}
                {loader && <Loader />}
              </Card>
            )}
            <Confirmation
              open={openConfirm}
              handleClose={() => handleCloseConfirmation()}
              handleClick={() => handleActions(action)}
              text={action.label}
            />
          </Grid>
        </Grid>
      }
    </div>
  );
}

export default MOU;

function statusClass(status: number): string {
  const statusClasses: Record<number, string> = {
    3: "agreed",
    4: "declined",
  };
  return statusClasses[status] || "pending";
}
