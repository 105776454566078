import React, { useState, useEffect } from "react";
import styles from "../style.module.scss";
import CustomButton from "shared/components/customButton";
import OtpInput from "react-otp-input";
import { toastMessage } from "shared/components/toast";

interface OTPSectionProps {
  handleVerifyOTP: (otp: number) => void;
  open: boolean;
  loader: boolean;
  handleSendOTP: (email: string) => void;
  email: string;
}

const OTPSection = ({
  handleVerifyOTP,
  open,
  loader,
  handleSendOTP,
  email,
}: OTPSectionProps) => {
  const [value, setValue] = useState<string>("");
  const [resendLoader, setResendLoader] = useState<boolean>(false);
  const [confirmLoader, setConfirmLoader] = useState<boolean>(false);

  const handleOTP = () => {
    if (value.length === 4) {
      setConfirmLoader(true);
      handleVerifyOTP(Number(value));
    } else {
      toastMessage("error", "Type full OTP");
    }
  };

  const handleResendOTP = () => {
    setValue("");
    setResendLoader(true);
    handleSendOTP(email);
  };

  useEffect(() => {
    if (!open) {
      setValue("");
    }

    if (!loader) {
      setResendLoader(false);
      setConfirmLoader(false);
    }
  }, [open, loader]);

  return (
    <div className={styles.insideContainer}>
      <h5 className={styles.title}>OTP Verification Code</h5>
      <h3 className={styles.subTitle}>
        We have sent the code verification to your email.
      </h3>
      <div className={styles.otpContainer}>
        <OtpInput
          value={value}
          onChange={setValue}
          numInputs={4}
          // renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputType={"number"}
        />
      </div>

      <div className={styles.btnContainer}>
        <CustomButton
          text="Confirm"
          buttonStyle={styles.btnCustom}
          handleClick={() => handleOTP()}
          loader={confirmLoader}
        />
        <CustomButton
          text="Resend"
          buttonStyle={styles.btnInsideOut}
          handleClick={() => handleResendOTP()}
          loader={resendLoader}
        />
      </div>
    </div>
  );
};

export default OTPSection;
