import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./style.module.scss";
import { Icons } from "assets";
import moment from "moment";
import {
  Box,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { getBookHistoryService } from "shared/services/hodService";
import { toastMessage } from "shared/components/toast";
import { useSelector } from "react-redux";
import { getRecentHistoryBookReviewer } from "shared/services/bookReviewerService";
import PaginationComponent from "shared/components/pagination";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface RevisionsProps {
  open: boolean;
  handleClose: any;
  statsBool: boolean;
  selectedBook?: any;
}

export default function Revision({
  open,
  handleClose,
  statsBool,
  selectedBook,
}: RevisionsProps) {
  // const [tabValue, setTabValue] = useState<any>(statsBool ? 0 : 1);
  const [history, setHistory] = useState<any>(null);
  const { user } = useSelector((state: any) => state.root);
  // const [dataArr, setDataArr] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);

  // const handleTab = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabValue(newValue);
  // };

  const getBookHistory = () => {
    let service =
      user?.role?.role_id === 3
        ? getRecentHistoryBookReviewer
        : getBookHistoryService;

    service(selectedBook?.book ? selectedBook?.book?.id : selectedBook?.id)
      .then(({ data }) => {
        setHistory(data?.data?.data);
        setTotalPage(data?.data?.last_page);
      })
      .catch((error) => {
        toastMessage("error", error?.response?.data?.message);
        console.log("error", error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (open) {
      getBookHistory();
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(100),
              position: "relative",
            },
          },
        }}
      >
        <DialogContent>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          />
          <div className={styles.mainTitle}>Book Details</div>

          {history?.map((item: any, inx: any) => {
            return (
              <Box marginTop={"20px"}>
                <div className={styles.headingContainer}>
                  <div className={styles.title}>
                    <span className={styles.dot}>&#x2022;</span>
                    Book Revision {inx + 1}
                  </div>
                  <div className={styles.time}>
                    <span style={{ marginRight: "5px" }}>
                      {moment(item?.created_at).format("DD-MM-YYYY")}
                    </span>
                  </div>
                </div>

                <div className={styles.revisionDescription}>
                  <div>
                    {item?.response && (
                      <div className={styles.replySection}>
                        <div style={{ fontSize: "16px", fontWeight: "500" }}>
                          Publisher response:
                        </div>
                        <div className={styles.repliedText}>
                          {item?.response}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <TableContainer component={Paper} className={styles.table}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={styles.tableHead}>
                        <TableCell>Parameter</TableCell>
                        <TableCell>Response</TableCell>
                        <TableCell>Comments</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {item?.data?.intial_review &&
                        Object.keys(item?.data?.intial_review)
                          .map((key) => item?.data?.intial_review[key])
                          ?.map((itm: any) => {
                            return (
                              <TableRow>
                                <TableCell>{itm?.label}</TableCell>
                                <TableCell>
                                  {itm?.type === "rating" ? (
                                    <Rating
                                      name="simple-controlled"
                                      value={itm?.value}
                                      readOnly
                                    />
                                  ) : Number(itm?.option) === 0 ? (
                                    "No"
                                  ) : (
                                    "Yes"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {itm?.type === "checklist" &&
                                    itm?.options?.map((itms: any) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {itms?.value ? (
                                            <>
                                              <CheckIcon
                                                style={{ fill: "#9A469B" }}
                                              />{" "}
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {itms?.name}
                                              </span>
                                            </>
                                          ) : (
                                            <div>-</div>
                                          )}
                                        </div>
                                      );
                                    })}

                                  {itm?.type === "dropdown" && itm?.selected ? (
                                    <div>
                                      Selected: {itm?.selected?.name}{" "}
                                      {itm?.selected?.text}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}

                                  {itm?.type === "rating" ? (
                                    <div>{itm?.comment}</div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                  {itm?.type === "text" ? (
                                    <div>{itm?.comment}</div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                      {item?.data?.detailed_review &&
                        Object.keys(item?.data?.detailed_review)
                          .map((key) => item?.data?.detailed_review[key])
                          ?.map((itm: any) => {
                            return (
                              <TableRow>
                                <TableCell>{itm?.label}</TableCell>
                                <TableCell>
                                  {itm?.type === "rating" ? (
                                    <Rating
                                      name="simple-controlled"
                                      value={itm?.value}
                                      readOnly
                                    />
                                  ) : Number(itm?.option) === 0 ? (
                                    "No"
                                  ) : (
                                    "Yes"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {itm?.type === "checklist" &&
                                    itm?.options?.map((itms: any) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {itms?.value ? (
                                            <>
                                              <CheckIcon
                                                style={{ fill: "#9A469B" }}
                                              />{" "}
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {itms?.name}
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                      );
                                    })}

                                  {itm?.type === "dropdown" &&
                                    itm?.selected && (
                                      <div>
                                        Selected: {itm?.selected?.name}{" "}
                                        {itm?.selected?.text}
                                      </div>
                                    )}

                                  {itm?.type === "rating" && (
                                    <div>{itm?.comment}</div>
                                  )}
                                  {itm?.type === "text" && (
                                    <div>{itm?.comment}</div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            );
          })}
          {totalPage > 1 && (
            <PaginationComponent
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

// interface RevisionContainerProps {
//   item: any;
//   index: number;
// }

// const RevisionContainer = ({ item, index }: RevisionContainerProps) => {
//   const [replyText, setReplyText] = useState<boolean>(false);
//   const [initialForm, setInitialForm] = useState<any>(null);
//   const [detailForm, setDetailForm] = useState<any>(null);

//   // console.log("item", item?.data);
//   useEffect(() => {
//     if (item?.data?.detailed_review) {
//       setDetailForm(
//         Object.keys(item?.data?.detailed_review).map(
//           (key) => item?.data?.detailed_review[key]
//         )
//       );
//     }

//     if (item?.data?.intial_review) {
//       setInitialForm(
//         Object.keys(item?.data?.intial_review).map(
//           (key) => item?.data?.intial_review[key]
//         )
//       );
//     }
//   }, [item]);

//   return (
//     <div className={styles.topLevelContainer}>
//       <div className={styles.headingContainer}>
//         <div className={styles.title}>
//           <span className={styles.dot}>&#x2022;</span>
//           Book Revision {index + 1}
//         </div>
//         <div className={styles.time}>
//           <span style={{ marginRight: "5px" }}>
//             {moment(item?.created_at).format("DD-MM-YYYY")}
//           </span>
//         </div>
//       </div>

//       <div className={styles.revisionDescription}>
//         {initialForm?.length && (
//           <div>
//             {initialForm?.map((itm: any) => {
//               return (
//                 <div className={styles.revisionContainer}>
//                   {
//                     <div>
//                       <div>{itm?.label}</div>
//                       <div>Answer: {itm?.option ? "Yes" : "No"}</div>

//                       <div>
//                         {itm.type === "checklist" ? (
//                           <div className={styles.innerResponse}>
//                             <div>{itm?.comment}</div>
//                             <ul style={{ margin: "5px 0px" }}>
//                               {itm?.options?.map((data: any) => {
//                                 return data?.value ? (
//                                   <>
//                                     <li>
//                                       {data?.name
//                                         ? data?.name === "Others"
//                                           ? "Others : " + data?.comment
//                                           : data?.name
//                                         : data?.title
//                                         ? data?.title
//                                         : data?.text
//                                         ? data?.text
//                                         : null}
//                                     </li>
//                                   </>
//                                 ) : null;
//                               })}
//                             </ul>
//                           </div>
//                         ) : null}

//                         {itm.type === "dropdown" ? (
//                           <div className={styles.innerResponse}>
//                             <div>Suggested Option:</div>

//                             <div>
//                               {itm?.selected?.name
//                                 ? itm?.selected?.name
//                                 : itm?.selected?.text}
//                             </div>
//                           </div>
//                         ) : null}

//                         {itm.type === "text" && itm?.comment ? (
//                           <div className={styles.innerResponse}>
//                             <div>{itm?.comment ? itm?.comment : null}</div>
//                           </div>
//                         ) : null}
//                       </div>
//                     </div>
//                   }
//                 </div>
//               );
//             })}
//           </div>
//         )}

//         {detailForm?.length && (
//           <div>
//             {item?.response && (
//               <div className={styles.replySection}>
//                 <div style={{ fontSize: "16px", fontWeight: "500" }}>
//                   Publihser response:
//                 </div>
//                 <div className={styles.repliedText}>{item?.response}</div>
//               </div>
//             )}

//             {detailForm?.map((itm: any) => {
//               return (
//                 <div className={styles.revisionContainer}>
//                   {
//                     <div>
//                       <div>{itm?.label}</div>
//                       <div>
//                         {itm?.type === "rating" ? (
//                           <Rating
//                             name="simple-controlled"
//                             value={itm?.value}
//                             readOnly
//                             max={5}
//                           />
//                         ) : "Answer:" + itm?.option ? (
//                           "Yes"
//                         ) : (
//                           "No"
//                         )}
//                       </div>

//                       <div>
//                         {itm.type === "checklist" ? (
//                           <div className={styles.innerResponse}>
//                             <div>{itm?.comment}</div>
//                             <ul style={{ margin: "5px 0px" }}>
//                               {itm?.options?.map((data: any) => {
//                                 return data?.value ? (
//                                   <>
//                                     <li>
//                                       {data?.name
//                                         ? data?.name === "Others"
//                                           ? "Others : " + data?.comment
//                                           : data?.name
//                                         : data?.title
//                                         ? data?.title
//                                         : data?.text
//                                         ? data?.text
//                                         : null}
//                                     </li>
//                                   </>
//                                 ) : null;
//                               })}
//                             </ul>
//                           </div>
//                         ) : null}

//                         {itm.type === "dropdown" ? (
//                           <div className={styles.innerResponse}>
//                             <div>Suggested Option:</div>

//                             <div>
//                               {itm?.selected?.name
//                                 ? itm?.selected?.name
//                                 : itm?.selected?.text}
//                             </div>
//                           </div>
//                         ) : null}

//                         {(itm.type === "text" || itm?.type === "rating") &&
//                         itm?.comment ? (
//                           <div className={styles.innerResponse}>
//                             <div>{itm?.comment ? itm?.comment : null}</div>
//                           </div>
//                         ) : null}
//                       </div>
//                     </div>
//                   }
//                 </div>
//               );
//             })}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };
