import { Avatar, Box, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "shared/components/card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./style.module.scss";
import Loader from "shared/components/loader/animation";
import { Icons } from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "shared/components/pagination";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Confirmation from "shared/modals/confirmation";
import Revision from "shared/modals/revision";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EditOffIcon from "@mui/icons-material/EditOff";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import TaskIcon from "@mui/icons-material/Task";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RateReviewIcon from "@mui/icons-material/RateReview";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useWindowSize from "shared/customHooks/windowSize";
import moment from "moment";
import RejectBook from "shared/modals/rejectBook";
import AssignReviewer from "shared/modals/assignReviewer";
import PreviewIcon from "@mui/icons-material/Preview";

import {
  changeBookStatusService,
  getAllBooksService,
  getDashboardData,
  publishBookService,
  recentRevisionByHOD,
} from "shared/services/hodService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import { bookStatusEnum } from "shared/utils/constants";
import BugReportIcon from "@mui/icons-material/BugReport";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { getPublishedBy } from "shared/utils/helpers";
import { searchHandlerHelper } from "shared/utils/helpers";
import ItemsPerPage from "shared/components/itemsPerPage";

function Dashboard() {
  const navigate = useNavigate();
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [openRevision, setOpenRevision] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedBook, setSelectedBook] = useState<any>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const [openReview, setOpenReview] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(10);
  const screenWidth = useWindowSize();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [status, setStatus] = useState<any>(0);
  const [allBooks, setAllBooks] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [navigateLoader, setNavigateLoader] = useState<boolean>(false);
  const [rejectViewOnly, setRejectViewOnly] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const cardData = [
    {
      title: "Total book on platform",
      icon: MenuBookIcon,
      stats: dashboardData?.total,
    },
    {
      title: "Rejected book",
      icon: EditOffIcon,
      stats: dashboardData?.rejected,
    },
    {
      title: "Published books",
      icon: TaskIcon,
      stats: dashboardData?.published,
    },
    {
      title: "Books in internal review",
      icon: NoteAddIcon,
      stats: dashboardData?.admin_review,
    },
    {
      title: "Books in revision",
      icon: AutorenewIcon,
      stats: dashboardData?.revision,
    },
    {
      title: "Books in pending",
      icon: PendingActionsIcon,
      stats: dashboardData?.pending,
    },
  ];

  const handleChange = (event: any, newValue: number) => {
    setStatus(newValue);
  };

  const handleRejectBook = () => {
    setOpenReject(false);
    setSelectedBook(null);
    setRejectViewOnly(false);
  };

  const handleConfirmBook = (item: any) => {
    setOpenConfirm(true);
    setSelectedBook(item);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setSelectedBook(null);
  };

  const handleOpenRejectBook = (item: any) => {
    setOpenReject(true);
    setSelectedBook(item);
  };

  // const handleReviewOpen = (item: any) => {
  //   setOpenReview(true);
  //   setSelectedBook(item);
  // };

  const handleReviewClose = () => {
    setOpenReview(false);
    setSelectedBook(null);
  };

  // const handleRevisionOpen = (item: any) => {
  //   setOpenRevision(true);
  //   setSelectedBook(item);
  // };

  const handleCloseRevision = () => {
    setOpenRevision(false);
    setSelectedBook(null);
  };

  const getDashboardStats = () => {
    setIsLoading(true);
    getDashboardData()
      .then(({ data }) => {
        setIsLoading(false);
        setDashboardData(data?.data?.book);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getDashboardStats();
  }, []);

  const getBooks = () => {
    setLoader(true);
    getAllBooksService(search, status, activePage, itemsPerPage)
      .then(({ data: { data } }) => {
        setLoader(false);
        setAllBooks(data?.data);
        setTotalPage(data?.last_page);
        setTotalItems(data?.total);
        setCurrentPage(data?.current_page);
        setItemsPerPage(data?.per_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
        console.log("err", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getBooks();
    // eslint-disable-next-line
  }, [searchText, status, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const changeStatus = (item: any) => {
    changeBookStatusService({ book_id: item?.id, status: 1 })
      .then(({ data }) => {})
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  const handleRevisionNavigation = (item: any) => {
    setNavigateLoader(true);
    recentRevisionByHOD(item?.id)
      .then(({ data }) => {
        if (data?.data?.data?.detailed_review) {
          navigate(routeConstant.review.path.replace(":id", item?.id));
          changeStatus(item);
        } else {
          navigate(routeConstant.reviewByHOD.path.replace(":id", item?.id));
        }
        setNavigateLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setNavigateLoader(false);
      });
  };

  const handleAction = () => {
    let resp: any = {
      book_id: selectedBook?.id,
      feedback: selectedBook?.feedback,
      rating: selectedBook?.rating,
    };
    setNavigateLoader(true);
    publishBookService(resp)
      .then(({ data }) => {
        if (data?.statusCode === 200) {
          toastMessage("success", data?.message);
          getBooks();
        } else {
          toastMessage("error", data?.message);
        }
        setNavigateLoader(false);
        handleConfirmClose();
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setNavigateLoader(false);
      });
  };

  useBreadcrumbs({
    title: "Dashboard",
    path: routeConstant?.dashboard?.path,
  });

  return (
    <div>
      <Grid container spacing={2}>
        {cardData?.map((item) => {
          return (
            <Grid item xs={12} md={6} lg={2}>
              <Card
                Icon={item?.icon}
                stats={item?.stats}
                title={item?.title}
                style={styles.card}
                isLoading={isLoading}
              />
            </Grid>
          );
        })}
      </Grid>

      <Box sx={{ width: "100%", marginTop: "40px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={status}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#9A469B",
                color: "red !important",
              },
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#9A469B",
              },
            }}
          >
            <Tab label="Newly uploaded" className={styles.tabs} />
            <Tab label="In review" className={styles.tabs} />
            <Tab label="Revision Requested" className={styles.tabs} />
            <Tab label="Revision Submitted" className={styles.tabs} />
            <Tab label="Approved" className={styles.tabs} />
            <Tab label="Published" className={styles.tabs} />
            <Tab label="Rejected" className={styles.tabs} />
          </Tabs>
        </Box>
      </Box>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>
              {status === bookStatusEnum.pending
                ? "Newly Uploaded"
                : status === bookStatusEnum.admin_review
                ? "In Review"
                : status === bookStatusEnum.revision_requested
                ? "Revision Requested"
                : status === bookStatusEnum.revision_submitted
                ? "Revision Submitted"
                : status === bookStatusEnum.approved
                ? "Approved"
                : status === bookStatusEnum.published
                ? "Published"
                : status === bookStatusEnum.rejected
                ? "Rejected"
                : "Recently Added books"}
            </div>
            <div className={styles.tableSubHeading}>
              {status === bookStatusEnum.pending
                ? "Manage the newly uploaded books from Publishers here. You can perform actions accordingly.."
                : status === bookStatusEnum.admin_review
                ? "Manage the book in review state here. You can perform actions accordingly."
                : status === bookStatusEnum.revision_requested
                ? "Manage the books sent to publishers for correction here. You can perform actions accordingly."
                : status === bookStatusEnum.revision_submitted
                ? "Manage the books received from publishers, after correction here. You can perform tasks accordingly."
                : status === bookStatusEnum.approved
                ? "Manage the books after final approval from your side here."
                : status === bookStatusEnum.published
                ? "Manage the books published on the platform here."
                : status === bookStatusEnum.rejected
                ? "Here is the listing of all rejected books."
                : "Manage your recently added books from here."}
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book Name</TableCell>
                <TableCell>Published by</TableCell>
                {status === bookStatusEnum.rejected && (
                  <TableCell>Reviewer Name</TableCell>
                )}
                {Number(status) === bookStatusEnum.published && (
                  <>
                    <TableCell>Language</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Genre</TableCell>
                  </>
                )}

                {/* {status === 6 ? (
                  <TableCell>Reason of Rejection</TableCell>
                ) : null} */}
                {status !== bookStatusEnum.rejected ? (
                  <TableCell>Upload Date</TableCell>
                ) : null}
                {Number(status) === bookStatusEnum.published && (
                  <TableCell>Published Date</TableCell>
                )}

                {(status === bookStatusEnum.admin_review ||
                  status === bookStatusEnum.revision_requested ||
                  status === bookStatusEnum.revision_submitted) && (
                  <>
                    <TableCell>Reviewer</TableCell>
                    <TableCell>Review Submitted</TableCell>
                    <TableCell>Sent for Revision Date </TableCell>
                  </>
                )}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader
                  lines={
                    status === bookStatusEnum.pending ||
                    status === bookStatusEnum.approved ||
                    status === bookStatusEnum.rejected
                      ? 2
                      : status === bookStatusEnum.published
                      ? 6
                      : status === bookStatusEnum.admin_review ||
                        status === bookStatusEnum.revision_requested ||
                        status === bookStatusEnum.revision_submitted
                      ? 5
                      : 1
                  }
                  actions={
                    status === bookStatusEnum.pending ||
                    status === bookStatusEnum.admin_review ||
                    status === bookStatusEnum.revision_requested ||
                    status === bookStatusEnum.revision_submitted ||
                    status === bookStatusEnum.rejected
                      ? 3
                      : status === bookStatusEnum.approved
                      ? 4
                      : status === bookStatusEnum.published
                      ? 2
                      : 1
                  }
                />
              ) : (
                allBooks?.map((item: any) => {
                  const genreNames = item?.genres.map((genre: any) => {
                    return `${genre.name}, `;
                  });
                  const genreString = genreNames.join(" ").slice(0, -1);
                  const genreStringLength = genreString.length;
                  const genre = genreString.substring(0, genreStringLength - 1);

                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.thumbnail}
                              alt="book cover"
                              className={styles.bookCover}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {item?.title}
                              <div className={styles.name}>
                                @{item?.book_author?.name}
                              </div>
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={getPublishedBy(item?.partner)?.img}
                              alt="book cover"
                              style={{ borderRadius: "50%" }}
                              className={styles.bookCover}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {getPublishedBy(item?.partner)?.name}
                            </span>
                          </Box>
                        </TableCell>
                        {Number(item?.status) === bookStatusEnum.rejected && (
                          <TableCell>
                            {item?.book_review_assignment
                              ? item?.book_review_assignment?.reviewer
                                  ?.full_name
                              : "--"}
                          </TableCell>
                        )}
                        {Number(item?.status) === bookStatusEnum.published && (
                          <>
                            <TableCell>{item?.language?.name}</TableCell>
                            <TableCell>
                              {item?.age_range?.min} - {item?.age_range?.max}
                            </TableCell>
                            <TableCell id={styles.expandedTableCell}>
                              {genre}
                            </TableCell>
                          </>
                        )}

                        {item?.status !== bookStatusEnum.rejected && (
                          <TableCell id={styles.expandedTableCell}>
                            {moment(new Date(item?.created_at)).format(
                              // "D MMMM YYYY, HH:MM A"
                              "DD MMMM YYYY"
                            )}
                          </TableCell>
                        )}
                        {Number(status) === bookStatusEnum.published && (
                          <TableCell>
                            {moment(item?.publish_date).format("DD MMMM YYYY")}
                          </TableCell>
                        )}

                        {item?.status === bookStatusEnum.admin_review ||
                        item?.status === bookStatusEnum.revision_requested ||
                        item?.status === bookStatusEnum.revision_submitted ? (
                          <>
                            {item?.book_review_assignment ? (
                              <TableCell>
                                <Box display={"flex"} alignItems={"center"}>
                                  <Avatar
                                    src={
                                      item?.book_review_assignment?.reviewer
                                        ?.profile_picture
                                    }
                                  />
                                  <span style={{ marginLeft: "10px" }}>
                                    {
                                      item?.book_review_assignment?.reviewer
                                        ?.full_name
                                    }
                                  </span>
                                </Box>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <div>Not Assigned</div>
                              </TableCell>
                            )}

                            <TableCell>
                              {item?.revision_request_date
                                ? moment(
                                    new Date(item?.revision_request_date)
                                  ).format(
                                    // "D MMMM YYYY, HH:MM A"
                                    "DD MMMM YYYY"
                                  )
                                : "Not Submitted"}
                            </TableCell>
                            <TableCell>
                              {item?.revision_submit_date
                                ? moment(
                                    new Date(item?.revision_submit_date)
                                  ).format(
                                    // "D MMMM YYYY, HH:MM A"
                                    "DD MMMM YYYY"
                                  )
                                : "Not Submitted"}
                            </TableCell>
                          </>
                        ) : null}

                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip title="Book details" arrow>
                              <button
                                onClick={() =>
                                  navigate(
                                    routeConstant.bookDetail.path.replace(
                                      ":id",
                                      item?.id
                                    )
                                  )
                                }
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>

                            {Number(item?.status) !==
                              bookStatusEnum.admin_review &&
                              Number(item?.status) !==
                                bookStatusEnum.revision_requested &&
                              Number(item?.status) !==
                                bookStatusEnum.published &&
                              Number(item?.status) !==
                                bookStatusEnum.rejected &&
                              Number(item?.status) !==
                                bookStatusEnum.approved &&
                              Number(item?.status) !==
                                bookStatusEnum.revision_submitted &&
                              Number(item?.status) !==
                                bookStatusEnum.internal_review && (
                                <Tooltip title="Review" arrow>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        routeConstant.reviewByHOD.path.replace(
                                          ":id",
                                          item?.id
                                        )
                                      )
                                    }
                                  >
                                    <RateReviewIcon />
                                  </button>
                                </Tooltip>
                              )}

                            {(Number(item?.status) ===
                              bookStatusEnum.revision_submitted ||
                              Number(item?.status) ===
                                bookStatusEnum.admin_review) && (
                              <Tooltip title="Review" arrow>
                                <button
                                  onClick={() => handleRevisionNavigation(item)}
                                >
                                  <RateReviewIcon />
                                </button>
                              </Tooltip>
                            )}

                            {Number(item?.status) !==
                              bookStatusEnum.revision_requested &&
                              Number(item?.status) !==
                                bookStatusEnum.revision_submitted &&
                              Number(item?.status) !==
                                bookStatusEnum.rejected &&
                              Number(item?.status) !==
                                bookStatusEnum.published &&
                              Number(item?.status) !==
                                bookStatusEnum.approved && (
                                <>
                                  <Tooltip title="Reject book" arrow>
                                    <button
                                      onClick={() => handleOpenRejectBook(item)}
                                    >
                                      <CancelIcon />
                                    </button>
                                  </Tooltip>
                                </>
                              )}

                            {Number(item?.status) ===
                              bookStatusEnum.approved && (
                              <Tooltip arrow title="Publish">
                                <button
                                  onClick={() => {
                                    handleConfirmBook(item);
                                  }}
                                >
                                  <BeenhereIcon />
                                </button>
                              </Tooltip>
                            )}

                            {Number(item?.status) !== bookStatusEnum.pending &&
                              Number(item?.status) !==
                                bookStatusEnum.admin_review && (
                                <Tooltip arrow title="Book Revisions">
                                  <button
                                    onClick={() =>
                                      // handleRevisionOpen(item)
                                      navigate(
                                        routeConstant.bookRevisions.path.replace(
                                          ":id",
                                          item?.id
                                        )
                                      )
                                    }
                                  >
                                    <PreviewIcon />
                                  </button>
                                </Tooltip>
                              )}

                            {Number(item?.status) ===
                              bookStatusEnum.rejected && (
                              <Tooltip arrow title="Reason of Rejection">
                                <button
                                  onClick={() => {
                                    handleOpenRejectBook(item);
                                    setRejectViewOnly(true);
                                  }}
                                >
                                  <BugReportIcon />
                                </button>
                              </Tooltip>
                            )}

                            {/* <Tooltip title="Assign" arrow>
                              <button onClick={() => handleReviewOpen(item)}>
                                <AssignmentIndIcon />
                              </button>
                            </Tooltip> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allBooks.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allBooks.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
        {navigateLoader && <Loader />}
      </Box>
      <RejectBook
        open={openReject}
        handleClose={handleRejectBook}
        selectedBook={selectedBook}
        isRating
        rejectViewOnly={rejectViewOnly}
      />
      <Confirmation
        text="Are you sure, you want to publish this book ?"
        open={openConfirm}
        handleClose={handleConfirmClose}
        handleClick={handleAction}
      />
      <AssignReviewer
        open={openReview}
        handleClose={handleReviewClose}
        selectedBook={selectedBook}
      />
      <Revision
        open={openRevision}
        handleClose={handleCloseRevision}
        statsBool={false}
        selectedBook={selectedBook}
      />
    </div>
  );
}

export default Dashboard;
