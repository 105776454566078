import React from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { Icons, Images } from "assets";

import styles from "./style.module.scss";

interface SchoolDetailModalProps {
  schoolDetails: any;
  isOpen: boolean;
  closeModal: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SchoolDetailModal = ({
  schoolDetails,
  isOpen,
  closeModal,
}: SchoolDetailModalProps) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(75),
              position: "relative",
            },
          },
        }}
      >
        <div onClick={closeModal} className={styles.crossIcon}>
          <Icons.CrossIcon />
        </div>

        <DialogTitle>
          <div className={styles.imageWrapper}>
            <img
              alt={schoolDetails?.school?.school_name}
              src={
                schoolDetails?.school?.profile_picture ? (
                  schoolDetails?.school?.profile_picture
                ) : (
                  <Images.UserPlaceholderImage />
                )
              }
            />
          </div>
          <div className={styles.schoolName}>
            {schoolDetails?.school?.school_name}
          </div>
          <div>
            <div className={styles.username}>
              {schoolDetails?.username
                ? `@${schoolDetails?.username}`
                : "Not Provided"}
            </div>
          </div>
          <div className={styles.divider} />
        </DialogTitle>

        <DialogContent sx={{ paddingTop: 0 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <h4 className={styles.heading}>School Contact Info</h4>
            <div className={styles.schoolInfoWrapper}>
              <div>
                <div className={styles.label}>Contact Number PTCL:</div>
                <div className={styles.value}>
                  {schoolDetails?.school?.landline}
                </div>
              </div>
              <div>
                <div className={styles.label}>School Address:</div>
                <div className={styles.value}>
                  {schoolDetails?.school?.address}
                </div>
              </div>
              <div>
                <div className={styles.label}>No of Teachers:</div>
                <div className={styles.value}>
                  {schoolDetails?.school?.teachers_limit}
                </div>
              </div>
              <div>
                <div className={styles.label}>No of Students:</div>
                <div className={styles.value}>
                  {schoolDetails?.school?.students_limit}
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "1rem" }} className={styles.divider} />
            <h4 className={styles.heading}>Personal Info</h4>
            <div className={styles.schoolInfoWrapper}>
              <div>
                <div className={styles.label}>First Name:</div>
                <div className={styles.value}>
                  {schoolDetails?.school?.first_name
                    ? schoolDetails?.school?.first_name
                    : "Not Provided"}
                </div>
              </div>
              <div>
                <div className={styles.label}>Last Name:</div>
                <div className={styles.value}>
                  {schoolDetails?.school?.last_name
                    ? schoolDetails?.school?.last_name
                    : "Not Provided"}
                </div>
              </div>
              <div>
                <div className={styles.label}>Phone:</div>
                <div className={styles.value}>
                  {schoolDetails?.school?.phone
                    ? schoolDetails?.school?.phone
                    : "Not Provided"}
                </div>
              </div>
              <div>
                <div className={styles.label}>Email:</div>
                <div className={styles.value}>
                  {schoolDetails?.email ? schoolDetails?.email : "Not Provided"}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SchoolDetailModal;
