import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

function SkeletonLoader(props: any) {
  const { rectangularLoaderCount, actionLoaderCount } = props;
  return (
    <React.Fragment>
      {Array.from({ length: 8 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell sx={{ display: "flex" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton
              variant="rectangular"
              width={120}
              height={25}
              sx={{ marginLeft: "10px", marginTop: "5px" }}
            />
          </TableCell>
          {Array.from({ length: rectangularLoaderCount }).map((loader) => {
            return (
              <TableCell>
                <Skeleton variant="rectangular" width={80} height={25} />
              </TableCell>
            );
          })}
          {/* <TableCell>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell>{" "}
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell>{" "}
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell> */}
          <TableCell>
            <Box display={"flex"}>
              {Array.from({ length: actionLoaderCount }).map((loader) => {
                return (
                  <Skeleton
                    width={30}
                    height={40}
                    sx={{ marginRight: "10px" }}
                  />
                );
              })}
              {/* <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} /> */}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default SkeletonLoader;
