import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Icons } from "assets";
import useWindowSize from "shared/customHooks/windowSize";
import Loader from "shared/components/loader/animation";
import Pagination from "shared/components/pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Confirmation from "shared/modals/confirmation";
import ReportIcon from "@mui/icons-material/Report";
import {
  allReportedBooksLists,
  approvedBookService,
  declineBookService,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import SkeletonLoader from "./skeletonLoader";
import useDebounce from "shared/customHooks/useDebounce";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import PreviewIcon from "@mui/icons-material/Preview";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";
import { getPublishedBy } from "shared/utils/helpers";

function RequiredBooks() {
  const navigate = useNavigate();
  const screenWidth = useWindowSize();
  const [activePage, setActivePage] = useState<number>(1);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [allReportedBooks, setAllReportedBooks] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedItem(null);
  };

  const handleOpenConfirm = (label: String, type: String, item: any) => {
    setOpenConfirm(true);
    setAction({
      label: label,
      type: type,
    });
    setSelectedItem(item);
  };

  const getAllReportedBooks = () => {
    setLoader(true);
    allReportedBooksLists(searchText, activePage, itemsPerPage)
      .then(({ data }) => {
        setTotalPage(data?.data?.last_page);
        setAllReportedBooks(data?.data?.data);
        setLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllReportedBooks();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage]);

  const handleAction = (action: any) => {
    let service =
      action?.type === "approve"
        ? approvedBookService
        : action?.type === "reject"
        ? declineBookService
        : action?.type === "delete"
        ? null
        : null;

    setActionLoader(true);
    service?.(selectedItem?.id)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        handleCloseConfirm();
        getAllReportedBooks();
        setActionLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response.data?.message);
        console.log("error", err?.response.data?.message);
        setActionLoader(false);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs({
    title: "Reported Books",
    path: routeConstant.requiredBooks.path,
  });

  return (
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>
              Received reports concerning the book.
            </div>
            <div className={styles.tableSubHeading}>
              Manage reported complains from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book name</TableCell>
                <TableCell>Published By </TableCell>
                <TableCell>Total reports</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allReportedBooks?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.thumbnail}
                              alt="book cover"
                              className={styles.bookCover}
                            />
                            <div className={styles.titleWrapper}>
                              <span style={{ marginLeft: "10px" }}>
                                {item?.title}
                              </span>
                              <Tooltip title="Pending">
                                <span>
                                  {item?.reports_count?.pending > 0 ? (
                                    <Icons.NewIcon></Icons.NewIcon>
                                  ) : null}
                                </span>
                              </Tooltip>
                            </div>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {getPublishedBy(item?.partner)?.name}
                        </TableCell>
                        <TableCell>
                          {item?.reports_count ? item?.reports_count.total : 0}
                        </TableCell>
                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip arrow title={"Book detail"}>
                              <button
                                onClick={() =>
                                  navigate(
                                    routeConstant.bookDetail.path.replace(
                                      ":id",
                                      item?.book_id
                                    )
                                  )
                                }
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>

                            <Tooltip title="Report Details" arrow>
                              <button
                                onClick={() => {
                                  navigate(
                                    routeConstant.reportBookDetails.path.replace(
                                      ":id",
                                      item?.book_id
                                    ),
                                    {
                                      state: {
                                        item,
                                      },
                                    }
                                  );
                                }}
                              >
                                <ReportIcon />
                              </button>
                            </Tooltip>

                            <Tooltip title="Book history" arrow>
                              <button
                                onClick={() => {
                                  navigate(
                                    routeConstant.bookRevisions.path.replace(
                                      ":id",
                                      item?.book_id
                                    )
                                  );
                                }}
                              >
                                <PreviewIcon />
                              </button>
                            </Tooltip>

                            {/* <button
                              onClick={() =>
                                handleOpenConfirm(
                                  "Are you sure, you want to delete this book?",
                                  "delete",
                                  item
                                )
                              }
                            >
                              <DeleteIcon />
                            </button> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {allReportedBooks?.length === 0 && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}

        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allReportedBooks?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
        <Confirmation
          text={action.label}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          handleClick={() => handleAction(action)}
        />

        {actionLoader && <Loader />}
      </Box>
    </div>
  );
}

export default RequiredBooks;
