import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import PieChart from "shared/components/pieChart";
// import AreaChart from "shared/components/areaChart";
import styles from "./style.module.scss";
import { Icons } from "assets";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "shared/components/pagination";
import useWindowSize from "shared/customHooks/windowSize";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { routeConstant } from "shared/routes/routesConstant";
import {
  getReviewerBooksServiceProjectHead,
  getReviewerStats,
} from "shared/services/projectHeadService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import Cards from "shared/components/card";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";

function ReviewerPerformance() {
  const location = useLocation();
  const { id }: any = useParams();
  const screenWidth = useWindowSize();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(0);
  const [allBooks, setAllBooks] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [stats, setStats] = useState<any>(null);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const cardData = [
    {
      title: "Total Books in Review",
      icon: MenuBookIcon,
      stats: stats?.in_review,
    },
    {
      title: "Total Books reviewed",
      icon: AutoStoriesIcon,
      stats: stats?.total,
    },
  ];

  // const getProfile = () => {
  //   getReviewerProfileProjectHead(id)
  //     .then(({ data }) => {
  //       console.log("data", data);
  //     })
  //     .catch((err) => {
  //       console.log("err", err?.response?.data?.message);
  //       toastMessage("error", err?.response?.data?.message);
  //     });
  // };

  // useEffect(() => {
  //   getProfile();
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getReviewerBooks = () => {
    setLoader(true);
    getReviewerBooksServiceProjectHead(id, searchText, activePage, itemsPerPage)
      .then(({ data }) => {
        setLoader(false);
        setAllBooks(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getReviewerBooks();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const getStats = () => {
    getReviewerStats(id)
      .then(({ data }) => {
        setStats(data?.data);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line
  }, []);

  useBreadcrumbs(
    {
      title: location?.state?.item?.full_name,
      path: routeConstant.reviewerPerformance.path.replace(":id", id),
    },
    true
  );

  return (
    <div>
      {/* <Grid container>
        <Grid xs={12} md={6}>
          <Card className={`${styles.card} ${styles.cardRight}`}>
            <CardContent>
              <div className={styles.title}>Report recieved for books</div>
              <AreaChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card className={styles.card}>
            <CardContent>
              <div className={styles.title}>Total books reviewed</div>
              <PieChart stats={stats} />
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}

      <Grid container spacing={2}>
        {cardData?.map((item) => {
          return (
            <Grid item xs={12} sm={6}>
              <Cards
                Icon={item?.icon}
                stats={item?.stats}
                title={item?.title}
                style={styles.statsCard}
                isLoading={loader}
              />
            </Grid>
          );
        })}
      </Grid>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>All books here</div>
            <div className={styles.tableSubHeading}>
              Manage book reviwer's details from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book name</TableCell>
                <TableCell>Upload</TableCell>
                <TableCell>Assigned date</TableCell>
                <TableCell>Review submit date</TableCell>
                <TableCell>Revisions date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allBooks?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.book?.thumbnail}
                              alt="book cover"
                              className={styles.bookCover}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {item?.book?.title}
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {moment(item?.created_at).format("D MMMM YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(item?.schedule?.assign_date).format(
                            "D MMMM YYYY"
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.schedule?.submitted_date
                            ? moment(item?.schedule?.submitted_date).format(
                                "D MMMM YYYY"
                              )
                            : "--"}
                        </TableCell>
                        <TableCell>
                          {moment(item?.schedule?.review_date).format(
                            "D MMMM YYYY"
                          )}
                        </TableCell>

                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <button
                              onClick={() =>
                                navigate(
                                  routeConstant.bookDetail.path.replace(
                                    ":id",
                                    item?.book_id
                                  )
                                )
                              }
                            >
                              <RemoveRedEyeIcon />
                            </button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allBooks?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allBooks?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
    </div>
  );
}

export default ReviewerPerformance;
