import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";
import styles from "../style.module.scss";

interface SkeletonLoaderProps {
  extra: boolean;
}
function SkeletonLoader({ extra }: SkeletonLoaderProps) {
  return (
    <React.Fragment>
      {Array.from({ length: 8 }).map((_, index) => (
        <TableRow key={index} className={styles.tableRow}>
          <TableCell sx={{ display: "flex", alignItems: "center" }}>
            <Skeleton variant="rectangular" width={40} height={50} />
            <Skeleton
              variant="rectangular"
              width={120}
              height={25}
              sx={{ marginLeft: "10px" }}
            />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell>
        

          {extra && (
            <TableCell>
              <Skeleton variant="rectangular" width={140} height={25} />
            </TableCell>
          )}

          <TableCell>
            <Box display={"flex"}>
              <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
              <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
              <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
              {extra && (
                <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
              )}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default SkeletonLoader;
