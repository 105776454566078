import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import ProfileCard from "shared/components/profileCard";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Icons } from "assets";
import Pagination from "shared/components/pagination";
import {
  getAuthorBooksService,
  getAuthorDetails,
} from "shared/services/adminService";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate, useParams } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import { toastMessage } from "shared/components/toast";
import SkeletonLoader from "./skeletonLoader";
import useDebounce from "shared/customHooks/useDebounce";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import {
  getAuthorBooksByProjectHead,
  getAuthorProfileService,
} from "shared/services/projectHeadService";
import { roles } from "shared/utils/constants";
import { useSelector } from "react-redux";
import { searchHandlerHelper } from "shared/utils/helpers";
import ItemsPerPage from "shared/components/itemsPerPage";

function PublisherProfile() {
  const {
    user: { user },
  } = useSelector((state: any) => state.root);
  const { id } = useParams();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<number>(1);
  const [authorDetails, setAuthorDetails] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [allBooks, setAllBooks] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [bookLoader, setBookLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const getAuthor = () => {
    setLoader(true);

    let service: any =
      Number(user?.role) === roles?.projectHead
        ? getAuthorProfileService
        : Number(user?.role) === roles?.admin
        ? getAuthorDetails
        : "";

    service(id)
      .then(({ data }: any) => {
        setAuthorDetails(data?.data);
        setLoader(false);
      })
      .catch((err: any) => {
        toastMessage("error", err?.response?.data?.message);
        console.log(err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAuthor();
    // eslint-disable-next-line
  }, []);

  const getAuthors = () => {
    setBookLoader(true);
    let service: any =
      Number(user?.role) === roles?.projectHead
        ? getAuthorBooksByProjectHead
        : Number(user?.role) === roles?.admin
        ? getAuthorBooksService
        : "";

    service(id, searchText, activePage, itemsPerPage)
      .then(({ data }: any) => {
        setAllBooks(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setBookLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err: any) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("err", err?.response?.data?.message);
        setBookLoader(false);
      });
  };

  useEffect(() => {
    getAuthors();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs(
    {
      title: authorDetails?.author?.full_name,
      path: routeConstant.singleAuthor.path.replace(":id", authorDetails?.id),
    },
    true,
    authorDetails
  );

  const BOOK_STATUS = [
    { id: 0, label: "Pending" },
    { id: 1, label: "Admin Review" },
    { id: 2, label: "Revision Requested" },
    { id: 3, label: "Revision Submitted" },
    { id: 4, label: "Approved" },
    { id: 5, label: "Published" },
    { id: 6, label: "Rejected" },
    { id: 7, label: "Internal Review" },
    { id: 8, label: "Review Completed" },
  ];

  return (
    <div>
      <ProfileCard user={authorDetails} isAuthor={true} isLoader={loader} />
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>All books here</div>
            <div className={styles.tableSubHeading}>
              Manage user details from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer component={Paper} className={styles.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book name</TableCell>
                <TableCell>Author name</TableCell>
                <TableCell>Genre</TableCell>
                <TableCell>Age range</TableCell>
                <TableCell>Payment</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookLoader ? (
                <SkeletonLoader />
              ) : (
                allBooks?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.thumbnail}
                              alt="book cover"
                              className={styles.bookCover}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {item?.title}
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell>{item?.book_author?.name}</TableCell>
                        <TableCell>{item?.genres[0]?.name}</TableCell>
                        <TableCell>{item?.age_range?.text}</TableCell>
                        <TableCell>
                          {item?.price ? item?.price : "Not provided"}
                        </TableCell>
                        <TableCell>
                          <span
                            className={
                              item?.status === 4 || item?.status === 5
                                ? styles.statusApproved
                                : styles.statusRejected
                            }
                          >
                            {
                              BOOK_STATUS.find(
                                (itm: any) => itm?.id === Number(item?.status)
                              )?.label
                            }
                          </span>
                        </TableCell>
                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <button
                              onClick={() =>
                                navigate(
                                  routeConstant.bookDetail.path.replace(
                                    ":id",
                                    item?.id
                                  )
                                )
                              }
                            >
                              <RemoveRedEyeIcon />
                            </button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allBooks?.length > 0) && !bookLoader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}

        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allBooks?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage ? itemsPerPage : 10}
            currentPage={currentPage}
            totalItems={totalItems}
          />
        ) : null}
      </Box>
    </div>
  );
}

export default PublisherProfile;
