import { Icons } from "assets";
import React from "react";
import styles from "./style.module.scss";
import { Pagination, TablePagination } from "@mui/material";

interface PaginationProps {
  totalPages: any;
  activePage: number;
  setActivePage: any;
}

function PaginationComponent({
  totalPages,
  activePage,
  setActivePage,
}: PaginationProps) {
  const handleChangePage = (event: any, newPage: any) => {
    setActivePage(newPage);
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleNext = (currentActive: number) => {
    if (currentActive < totalPages) {
      setActivePage(currentActive + 1);
    }
  };

  const handlePrev = (currentActive: number) => {
    if (currentActive > 1) {
      setActivePage(currentActive - 1);
    }
  };

  return (
    <div className={styles.paginationContainer}>
      <button
        disabled={activePage === 1}
        className={styles.previousBtn}
        onClick={() => handlePrev(activePage)}
      >
        <Icons.PrevIcon />
        <span>Previous</span>
      </button>
      <Pagination
        page={activePage}
        onChange={handleChangePage}
        count={totalPages}
        variant="outlined"
        shape="rounded"
        hideNextButton
        hidePrevButton
        sx={{
          "& .MuiPaginationItem-root": {
            padding: "20px",
            fontSize: "1rem",
          },
          "& .Mui-selected": {
            backgroundColor: "#9A469B !important",
            color: "white",
            border: "none",
          },
          "@media (max-width: 768px)": {
            "& .MuiPaginationItem-root": {
              padding: "10px",
            },
          },
        }}
      />
      <button
        disabled={activePage === totalPages}
        className={styles.nextBtn}
        onClick={() => handleNext(activePage)}
      >
        <span>Next</span>
        <Icons.NextIcon />
      </button>
    </div>
  );
}

export default PaginationComponent;
