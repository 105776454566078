import { Skeleton, TableCell, TableRow, Box } from "@mui/material";
import React from "react";

function index(props: any) {
  const { actionsCount, rectangularCells } = props;
  return (
    <TableRow>
      <TableCell sx={{ display: "flex" }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton
          variant="rectangular"
          width={100}
          height={25}
          sx={{ marginLeft: "10px", marginTop: "5px" }}
        />
      </TableCell>
      {Array.from({ length: rectangularCells }).map((cell) => {
        return (
          <TableCell>
            <Skeleton variant="rectangular" width={60} height={25} />
          </TableCell>
        );
      })}
      {/* <TableCell>
        <Skeleton variant="rectangular" width={60} height={25} />
      </TableCell>
      <TableCell>
        <Skeleton width={70} height={40} />
      </TableCell>
      <TableCell>
        <Skeleton width={70} height={40} />
      </TableCell> */}
      {/* 
      <TableCell>
        <Skeleton width={30} height={40} />
      </TableCell> */}

      <TableCell>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {Array.from({ length: actionsCount }).map((actionLoader) => {
            return (
              <span>
                <Skeleton width={30} height={40} />
              </span>
            );
          })}
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default index;
