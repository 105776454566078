import React, { useEffect, useRef, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, CardContent } from "@mui/material";
import styles from "./style.module.scss";
import EditProfileForm from "shared/components/editProfileForm";
import ChangePasswordForm from "shared/components/changePasswordForm";
import { useLocation } from "react-router-dom";
import NotificationsCard from "shared/components/notificationsCard";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.box}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const targetedRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (location?.state?.scroll) {
      scrollToChangePassword();
    }
  }, [location]);

  const scrollToChangePassword = () => {
    if (targetedRef.current) {
      targetedRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useBreadcrumbs({
    title: "Settings",
    path: routeConstant?.settings?.path,
  });

  return (
    <Card className={styles.card}>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#9A469B",
                  color: "red !important",
                },
              }}
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "#9A469B",
                },
              }}
            >
              <Tab
                label="Edit Profile"
                {...a11yProps(0)}
                className={styles.tab}
              />
              <Tab
                label="Notifications"
                {...a11yProps(1)}
                className={styles.tab}
              />
            </Tabs>
          </Box> */}
          <CustomTabPanel value={value} index={0}>
            <div>
              <EditProfileForm />
            </div>
            <div ref={targetedRef}>
              <ChangePasswordForm />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <NotificationsCard />
          </CustomTabPanel>
        </Box>
      </CardContent>
    </Card>
  );
}
