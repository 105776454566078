import DeleteIcon from "@mui/icons-material/Delete";

const columnHeadings = [
  { title: "Email", id: "email" },
  { title: "Subscription Date", id: "subscription_date" },
  { title: "Actions", id: "actions" },
];

const actions = [
  {
    id: "delete",
    text: "Delete",
    icon: <DeleteIcon />,
  },
];

const headings = {
  mainHeading: "Newsletter Subscription",
  subHeading: "Manage your newsletter subscription from here",
};

const skeletonInfo = {
  rows: 8,
  cellCount: 3,
  actions: 1,
  showPerson: false,
};

export { columnHeadings, actions, headings, skeletonInfo };
