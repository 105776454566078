import React, { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tooltip,
} from "@mui/material";

import TableSkeleton from "shared/components/TableSkeleton";
import ItemsPerPage from "shared/components/itemsPerPage";
import NoDataFound from "shared/components/noDataFound";
import { searchHandlerHelper } from "shared/utils/helpers";

import {
  headings,
  skeletonInfo,
  columnHeadings,
  filterOptions,
} from "./constants";

import useWindowSize from "shared/customHooks/windowSize";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import styles from "./style.module.scss";
import { Icons } from "assets";
import Filter from "shared/components/filterButton";
import Pagination from "shared/components/pagination";
import { getAllSchoolAccounts } from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { routeConstant } from "shared/routes/routesConstant";
import { useNavigate } from "react-router-dom";
import useDebounce from "shared/customHooks/useDebounce";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import CustomButton from "shared/components/customButton";
import CreateTicket from "shared/modals/createTicket";

const SchoolTickets = () => {
  const navigate = useNavigate();
  const screenWidth = useWindowSize();
  const [search, setSearch] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [schoolTickets, setSchoolTickets] = useState<any>([]);

  const [totalItems, setTotalItems] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [schoolAccounts, setSchoolAccounts] = useState<any>([]);
  const [openTicket, setOpenTicket] = useState<boolean>(false);

  const getSchoolAccounts = () => {
    setLoader(true);
    getAllSchoolAccounts({
      selectedFilter: selectedFilter?.value,
      search: searchText,
      itemsPerPage,
      activePage,
    })
      .then(({ data, status }) => {
        if (status) {
          const { data: response } = data;
          const {
            current_page,
            last_page,
            per_page,
            total,
            data: accounts,
          } = response;
          setSchoolAccounts(accounts);
          setActivePage(current_page);
          setItemsPerPage(per_page);
          setTotalPage(last_page);
          setTotalItems(total);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error-----", err);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useBreadcrumbs({
    title: "School Tickets",
    path: routeConstant.schoolTickets.path,
  });

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    // Initial API call here
    getSchoolAccounts();
  }, [selectedFilter, searchText, itemsPerPage, activePage]);

  return (
    <section>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>{headings.mainHeading}</div>
            <div className={styles.tableSubHeading}>{headings.subHeading}</div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search By School Name"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <Box
              className={styles.filterBox}
              sx={{ display: "flex", gap: ".5rem" }}
            >
              <Filter
                filterOption={filterOptions}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
              <CustomButton
                text="Create Ticket"
                buttonStyle={styles.createBtn}
                handleClick={() => setOpenTicket(true)}
              />
            </Box>
          </Box>
        </Box>
        <TableContainer
          className={styles.container}
          component={Paper}
          sx={
            screenWidth > 990
              ? {
                  width: `${screenWidth - 300}px`,
                }
              : {
                  boxShadow: "none",
                }
          }
        >
          <Table className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                {columnHeadings?.map((heading, index) => {
                  return (
                    <TableCell key={heading.id}>{heading.title}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <TableSkeleton
                  rows={skeletonInfo.rows}
                  cellCount={skeletonInfo.cellCount}
                  actionCount={skeletonInfo.actions}
                  showPersonData={false}
                ></TableSkeleton>
              ) : (
                schoolAccounts?.map((account: any) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ minWidth: "188px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Avatar
                            alt={account?.username}
                            src={account?.school?.profile_picture}
                            sx={{ width: 40, height: 40 }}
                          />
                          <div className={styles.accountInfo}>
                            <span className={styles.fullName}>
                              {account?.school?.school_name
                                ? account?.school?.school_name
                                : "Not Provided"}
                            </span>
                            <span className={styles.email}>
                              {account?.email ? account?.email : "Not Provided"}
                            </span>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {account?.school?.phone
                          ? account?.school.phone
                          : "Not Provided"}
                      </TableCell>
                      <TableCell>
                        {account?.status || account?.status === 0 ? (
                          <span
                            className={
                              account?.status === 1
                                ? styles.active
                                : account?.status === 2
                                ? styles.rejected
                                : styles.pending
                            }
                          >
                            {
                              filterOptions?.find(
                                (filter) => filter.value === account?.status
                              )?.option
                            }
                          </span>
                        ) : (
                          "Not Provided"
                        )}
                      </TableCell>
                      {/* <TableCell sx={{ minWidth: "120px" }}>
                        {account?.school?.students_limit
                          ? account?.school?.students_limit
                          : "Not Provided"}
                      </TableCell>
                      <TableCell sx={{ minWidth: "120px" }}>
                        {account?.school?.teachers_limit
                          ? account?.school?.teachers_limit
                          : "Not Provided"}
                      </TableCell> */}
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          {/* <Tooltip title="View">
                            <span className={styles.iconWrapper}>
                              <RemoveRedEyeIcon />
                            </span>
                          </Tooltip> */}
                          <Tooltip arrow title="Ticket">
                            <button
                              className={styles.iconWrapper}
                              onClick={() =>
                                navigate(
                                  routeConstant.singleTicket.path.replace(
                                    ":id",
                                    account?.id
                                  ),
                                  {
                                    state: {
                                      user: account,
                                    },
                                  }
                                )
                              }
                            >
                              <ChatBubbleIcon />
                            </button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          {totalItems &&
          activePage &&
          totalPage > 1 &&
          schoolAccounts?.length ? (
            <Pagination
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          ) : null}
        </Box>
        <Box>
          {totalItems && activePage && currentPage && schoolAccounts?.length ? (
            <ItemsPerPage
              setActivePage={setActivePage}
              currentPage={currentPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage ? itemsPerPage : 10}
              totalItems={totalItems}
            />
          ) : null}
        </Box>
        <Box>{!loader && !(schoolAccounts?.length > 0) && <NoDataFound />}</Box>
      </Box>
      <CreateTicket
        open={openTicket}
        handleClose={() => setOpenTicket(false)}
        isSchool={true}
      />
    </section>
  );
};

export default SchoolTickets;
