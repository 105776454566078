import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import React, {
  BaseSyntheticEvent,
  Fragment,
  useEffect,
  useState,
} from "react";
import styles from "./style.module.scss";
import { Icons, Images } from "assets";
import useWindowSize from "shared/customHooks/windowSize";
import Pagination from "shared/components/pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Filter from "shared/components/filterButton";
import { ticketUserService } from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import SkeletonLoader from "./skeletonLoader";
import useDebounce from "shared/customHooks/useDebounce";
import { number } from "yup";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";

function CustomeSupport() {
  enum ticketStatus {
    "Open" = 0,
    "Closed" = 1,
  }
  const screenWidth = useWindowSize();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<number>(1);
  const [allTickets, setAllTickets] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [activeTabValue, setActiveTabValue] = useState<number>(
    ticketStatus.Open
  );
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const categories = {
    parent: "Parent",
    teacher: "Teacher",
    kid: "Kid",
    reader: "Reader",
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const getAllTickets = () => {
    setLoader(true);

    ticketUserService(searchText, activePage, itemsPerPage)
      .then(({ data }) => {
        setAllTickets(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        console.log("error", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllTickets();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage]);

  useBreadcrumbs({
    title: "Customer Support",
    path: routeConstant.customerSupport.path,
  });

  return (
    <div>
      <Box sx={{ marginBottom: "2rem" }}>
        <Box>
          <p className={styles.tabsHeading}>Select tab to view tickets</p>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTabValue}
            onChange={(event: BaseSyntheticEvent, newValue: number) =>
              setActiveTabValue(newValue)
            }
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#9A469B",
                color: "red !important",
              },
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#9A469B",
              },
            }}
          >
            <Tab
              className={styles.tabStyles}
              label="Open Tickets"
              value={ticketStatus.Open}
            ></Tab>
            <Tab
              className={styles.tabStyles}
              label="Closed Tickets"
              value={ticketStatus.Closed}
            ></Tab>
          </Tabs>
        </Box>
      </Box>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>
              All tickets are showing here.
            </div>
            <div className={styles.tableSubHeading}>
              All message tickets are showing here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Publisher name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell>Email</TableCell>
                <TableCell>Total tickets</TableCell>

                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader rectangularLoader={5} actionLoader={1} />
              ) : (
                allTickets
                  ?.filter((item: any) => item.status === activeTabValue)
                  ?.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow className={styles.tableRow}>
                          <TableCell>
                            {" "}
                            <Box display={"flex"} alignItems={"center"}>
                              <Avatar
                                alt={"profile"}
                                src={
                                  item?.reader
                                    ? item?.reader?.profile_picture
                                    : item?.parent
                                    ? item?.parent?.profile_picture
                                    : item?.kid
                                    ? item?.kid?.profile_picture
                                    : ""
                                }
                              />
                              <span
                                className={styles.tableItem}
                                style={{ marginLeft: "10px" }}
                              >
                                {item?.reader
                                  ? item?.reader?.full_name
                                  : item?.parent
                                  ? item?.parent?.full_name
                                  : item?.kid
                                  ? item?.kid?.full_name
                                  : ""}
                              </span>
                            </Box>
                          </TableCell>
                          <TableCell>
                            {item?.reader
                              ? categories.reader
                              : item?.parent
                              ? categories.parent
                              : item?.kid
                              ? categories.kid
                              : item?.teacher
                              ? categories.teacher
                              : "Not Provided"}
                          </TableCell>
                          {/* ADD Dynamic values here */}
                          <TableCell>
                            {item?.latest_ticket
                              ? item?.latest_ticket?.title
                              : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {ticketStatus?.Open === item?.status
                              ? "Open"
                              : ticketStatus.Closed === item?.status
                              ? "Closed"
                              : null}
                          </TableCell>
                          {/* Add Dynamic values above  */}
                          <TableCell>
                            {item?.email ? item?.email : "Not provided"}
                          </TableCell>

                          <TableCell>{item?.total_tickets}</TableCell>

                          <TableCell>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              className={styles.actionBtn}
                            >
                              <Tooltip title="View Conversation">
                                <button
                                  onClick={() => {
                                    navigate(
                                      routeConstant.singleTicket.path.replace(
                                        ":id",
                                        item?.id
                                      ),
                                      {
                                        state: {
                                          user: item?.parent
                                            ? item?.parent
                                            : item?.reader
                                            ? item?.reader
                                            : null,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  <ChatBubbleIcon />
                                </button>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(
          allTickets?.filter((item: any) => item.status === activeTabValue)
            .length > 0
        ) &&
          !loader && (
            <div className={styles.noUserCard}>
              <Icons.NoUsersData />
              <h5>No Data Found</h5>
            </div>
          )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allTickets?.filter((item: any) => item.status === activeTabValue)
          .length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
    </div>
  );
}

export default CustomeSupport;
