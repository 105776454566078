import { Avatar, Box, Grid, Tooltip, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "shared/components/card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GroupIcon from "@mui/icons-material/Group";
import CreateIcon from "@mui/icons-material/Create";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import styles from "./style.module.scss";
import ArticleIcon from "@mui/icons-material/Article";
import { Icons } from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "shared/components/pagination";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
// import DeleteIcon from "@mui/icons-material/Delete";
import Filter from "shared/components/filterButton";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import useWindowSize from "shared/customHooks/windowSize";
import {
  getPublisherStats,
  getUsersServices,
} from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";

function Publishers() {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<number>(1);
  const screenWidth = useWindowSize();
  // const [totalPage, setTotalPage] = useState<number>(10);
  const widthTablet = useMediaQuery("(max-width:767px)");
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [allPublishers, setAllPublishers] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const cardData = [
    {
      title: "Total Publishers",
      icon: GroupIcon,
      stats: dashboardData?.total,
    },
    {
      title: "Publishers Approved",
      icon: CreateIcon,
      stats: dashboardData?.approved,
    },
    {
      title: "Publishers Pending",
      icon: ImportContactsIcon,
      stats: dashboardData?.pending,
    },
  ];

  const filterOption = [
    {
      option: "All",
      value: null,
    },
    {
      option: "Pending",
      value: 0,
    },
    {
      option: "Approved",
      value: 1,
    },
    {
      option: "Rejected",
      value: 2,
    },
  ];

  const getStats = () => {
    setIsLoading(true);
    getPublisherStats()
      .then(({ data }) => {
        setIsLoading(false);
        setDashboardData(data?.data?.publisher);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  const getAllUsers = () => {
    setLoader(true);
    getUsersServices(
      searchText,
      selectedFilter?.value,
      6,
      activePage,
      itemsPerPage
    )
      .then(({ data }) => {
        setLoader(false);
        setTotalPage(data?.data?.last_page);
        setAllPublishers(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, [searchText, selectedFilter, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    getStats();
  }, []);

  const MOU_Status = [
    { label: "Draft", value: 0 },
    { label: "Review", value: 1 },
    { label: "Pending", value: 2 },
    { label: "Accepted", value: 3 },
    { label: "Declined", value: 4 },
  ];

  useBreadcrumbs({
    title: "Publishers",
    path: routeConstant.publishers.path,
  });

  return (
    <div>
      <Grid container spacing={2}>
        {cardData?.map((item) => {
          return (
            <Grid item xs={widthTablet ? 12 : 4}>
              <Card
                Icon={item?.icon}
                stats={item?.stats}
                title={item?.title}
                isLoading={isLoading}
              />
            </Grid>
          );
        })}
      </Grid>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Publishers</div>
            <div className={styles.tableSubHeading}>
              Effortlessly oversee publishers from here.{" "}
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <div>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Publisher name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>MOU Signed</TableCell>
                <TableCell>Publication House</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allPublishers?.map((item: any) => {
                  return (
                    <TableRow className={styles.tableRow}>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <Avatar src={item?.publisher?.profile_picture} />
                          <span
                            className={styles.tableItem}
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.publisher?.full_name}
                          </span>
                        </Box>
                      </TableCell>{" "}
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>{item?.publisher?.phone}</TableCell>
                      <TableCell>
                        {item?.latest_mou
                          ? MOU_Status.find(
                              (itm: any) =>
                                itm.value === Number(item?.latest_mou?.status)
                            )?.label
                          : "No agreement yet."}
                      </TableCell>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <Avatar
                            alt="Jackob Man"
                            src={item?.publisher?.publishing_logo}
                          />
                          <span
                            className={styles.tableItem}
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.publisher?.publishing_house}
                          </span>
                        </Box>
                      </TableCell>{" "}
                      <TableCell>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          className={styles.actionBtn}
                        >
                          <Tooltip arrow title="View details">
                            <button
                              onClick={() => {
                                navigate(
                                  routeConstant.publisherProfile.path.replace(
                                    ":id",
                                    item?.id
                                  )
                                );
                              }}
                            >
                              <RemoveRedEyeIcon />
                            </button>
                          </Tooltip>

                          <Tooltip arrow title="Ticket">
                            <button
                              onClick={() => {
                                navigate(
                                  routeConstant.singleTicket.path.replace(
                                    ":id",
                                    item?.id
                                  ),
                                  {
                                    state: {
                                      user: item,
                                    },
                                  }
                                );
                              }}
                            >
                              <ChatBubbleIcon />
                            </button>
                          </Tooltip>

                          <Tooltip arrow title="MOU">
                            <button
                              onClick={() =>
                                navigate(
                                  routeConstant.mou.path.replace(
                                    ":id",
                                    item?.id
                                  ),
                                  {
                                    state: { isAdmin: true },
                                  }
                                )
                              }
                            >
                              <ArticleIcon />
                            </button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allPublishers?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allPublishers.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
    </div>
  );
}

export default Publishers;
