import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ReportIcon from "@mui/icons-material/Report";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import moment from "moment";
import { TRANSACTION_STATUSES } from "pages/subscriptionRequest/constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Filter from "shared/components/filterButton";
import CircularLoader from "shared/components/loader/circular";
import Pagination from "shared/components/pagination";
import Popover from "shared/components/PopOver";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import useDebounce from "shared/customHooks/useDebounce";
import useWindowSize from "shared/customHooks/windowSize";
import { routeConstant } from "shared/routes/routesConstant";
import {
  checkTransactionInquiry,
  transactionHistoryList
} from "shared/services/productManagerService";
import { searchHandlerHelper } from "shared/utils/helpers";
import SkeletonLoader from "./skeletonLoader";
import styles from "./style.module.scss";

function RequiredBooks() {
  const navigate = useNavigate();
  const screenWidth = useWindowSize();
  const [activePage, setActivePage] = useState<number>(1);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [allTransactionsHistory, setAllTransactionHistory] =
    useState<any>(null);
  const [allReportedBooks, setAllReportedBooks] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [recheckLoader, setRecheckLoader] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [openViewReason, setOpenViewReason] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<any>(null);

  const getAllTransactionHistory = (loader?: boolean) => {
    if (!loader) {
      setLoader(true);
    }
    transactionHistoryList(search, selectedFilter?.value, activePage)
      .then(({ data: { data } }) => {
        setAllTransactionHistory(data?.data);
        setLoader(false);
        setTotalItems(data?.total);
        setItemsPerPage(data?.per_page);
        setCurrentPage(data?.current_page);
        setTotalPage(data?.last_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs({
    title: "Transaction History",
    path: routeConstant.transactionHistory.path,
  });

  useEffect(() => {
    getAllTransactionHistory();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage, selectedFilter, activePage]);

  const handleInquriy = (id: any) => {
    setRecheckLoader(true);
    checkTransactionInquiry(id)
      .then(({ data }) => {
        if (data?.status) {
          getAllTransactionHistory(true);
        }
        toastMessage("success", data?.message);
        setRecheckLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response.data?.message);
        console.log("error", err?.response.data?.message);
        setRecheckLoader(false);
      });
  };

  const filterOption = [
    {
      option: "All",
      value: "",
    },
    {
      option: "Pending",
      value: TRANSACTION_STATUSES?.pending.value,
    },
    {
      option: "Approved",
      value: TRANSACTION_STATUSES?.approved.value,
    },
    {
      option: "Failed",
      value: TRANSACTION_STATUSES?.failed.value,
    },
  ];

  console.log("allTransactionsHistory", allTransactionsHistory);

  return (
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>
              Payment Transaction History
            </div>
            <div className={styles.tableSubHeading}>
              Manage transaction from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <div>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell sx={{ paddingLeft: "40px !important" }}>
                  Transaction ID{" "}
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Amount (Rs)</TableCell>
                <TableCell>Payment Initiated Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allTransactionsHistory?.map((item: any) => {
                  const userFullName = item?.user?.parent
                    ? item?.user?.parent?.full_name
                    : item?.user?.reader
                    ? item?.user?.reader?.full_name
                    : item?.user?.school
                    ? item?.user?.school?.full_name
                    : "";

                  const transaction_status =
                    item?.status === TRANSACTION_STATUSES.pending.value
                      ? "Pending"
                      : item?.status === TRANSACTION_STATUSES.approved.value
                      ? "Approved"
                      : item?.status === TRANSACTION_STATUSES.rejected.value
                      ? "Rejected"
                      : item?.status === TRANSACTION_STATUSES.failed.value
                      ? "Failed"
                      : null;

                  const datetimeString = item?.created_at;
                  const dateTime = moment.utc(datetimeString).local();
                  const now = moment();

                  const diffInMinutes = now.diff(dateTime, "minutes");

                  console.log("item", item);
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell sx={{ paddingLeft: "40px" }}>
                          {item?.transaction_id}
                        </TableCell>
                        <TableCell>{userFullName}</TableCell>
                        <TableCell>{"Rs. " + item?.price}</TableCell>
                        <TableCell>
                          {moment(item?.created_at).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell
                          className={
                            item?.status === TRANSACTION_STATUSES.pending.value
                              ? styles.pending
                              : item?.status ===
                                TRANSACTION_STATUSES.approved.value
                              ? styles.approved
                              : item?.status ===
                                TRANSACTION_STATUSES.failed.value
                              ? styles.failed
                              : ""
                          }
                        >
                          {transaction_status
                            ? transaction_status
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            {item?.status ===
                            TRANSACTION_STATUSES?.pending.value ? (
                              <CheckInquiry
                                item={item}
                                getAllTransactionHistory={() =>
                                  getAllTransactionHistory(true)
                                }
                                diffInMinutes={diffInMinutes}
                              />
                            ) : null}
                            {item?.status ===
                            TRANSACTION_STATUSES?.failed.value ? (
                              <button
                                onClick={() => {
                                  setOpenViewReason(true);
                                  setSelectedReason(item?.reason);
                                }}
                              >
                                <Popover
                                  reason={item?.reason}
                                  Icon={ReportIcon}
                                />
                              </button>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {allTransactionsHistory?.length === 0 && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}

        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
      </Box>
    </div>
  );
}

export default RequiredBooks;

const CheckInquiry = ({
  item,
  getAllTransactionHistory,
  diffInMinutes,
}: any) => {
  const [recheckLoader, setRecheckLoader] = useState<boolean>(false);

  const handleInquriy = (id: any) => {
    setRecheckLoader(true);
    checkTransactionInquiry(id)
      .then(({ data }) => {
        if (data?.status) {
          getAllTransactionHistory(true);
        }
        toastMessage("success", data?.message);
        setRecheckLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response.data?.message);
        console.log("error", err?.response.data?.message);
        setRecheckLoader(false);
      });
  };

  return (
    <Tooltip arrow title="Inquiry">
      <button
        onClick={() => {
          if (diffInMinutes > 10) {
            handleInquriy(item?.transaction_id);
          }
        }}
        className={diffInMinutes < 10 ? styles.disabled : ""}
      >
        {recheckLoader ? <CircularLoader /> : <PublishedWithChangesIcon />}
      </button>
    </Tooltip>
  );
};
