import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import styles from "./style.module.scss";

interface CounterButtonPropsType {
  counter: number;
  setCounter: any;
}

const CounterButton = ({ counter, setCounter }: CounterButtonPropsType) => {
  const handleDecrement = () => {
    setCounter((prevValue: number) => {
      if (prevValue === 1) {
        return 1;
      }
      return prevValue - 1;
    });
  };

  const handleIncrement = () => {
    setCounter((prevValue: number) => {
      return prevValue + 1;
    });
  };

  return (
    <div className={styles.buttonWrapper}>
      <button onClick={() => handleDecrement()}>
        <RemoveIcon />
      </button>
      <input
        inputMode="numeric"
        value={counter}
        onChange={(e) => setCounter(e.target.value)}
        type="number"
        min={1}
      />
      <button onClick={() => handleIncrement()}>
        <AddIcon />
      </button>
    </div>
  );
};

export default CounterButton;
