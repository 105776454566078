import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./style.module.scss";
import { Icons } from "assets";
import { Card, CardContent, Grid } from "@mui/material";
import moment from "moment";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import { uploadReceiptSchema } from "shared/utils/validationSchemas";
import CustomButton from "shared/components/customButton";
import { uploadReceiptService } from "shared/services/accountantService";
import { toastMessage } from "shared/components/toast";
import Loader from "shared/components/loader/animation";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ProfileProps {
  open: boolean;
  handleClose: any;
  selectedTransaction: any;
  getEarnings: any;
}

export default function TransactionDetail({
  open,
  handleClose,
  selectedTransaction,
  getEarnings,
}: ProfileProps) {
  const [loader, setLoader] = useState<boolean>(false);
  const [image, setImage] = useState<any>({
    preview: "",
    raw: "",
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
        formik.setFieldValue("receipt_image", file);
      };

      reader.readAsDataURL(file);
    }
  };

  // const handleRemoveImage = () => {
  //   setImage({ preview: "", raw: null });
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = "";
  //   }
  // };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const initialValues = {
    transaction_id: "",
    payment_date: null,
    receipt_image: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: uploadReceiptSchema,
    onSubmit: (values) => {
      let resp: any = {
        earning_id: selectedTransaction?.earning_id,
        transaction_id: values?.transaction_id,
        receipt_image: values?.receipt_image,
        payment_date: moment(values?.payment_date).format("YYYY-MM-D"),
      };

      let formBody = new FormData();
      Object.keys(resp).forEach((key) => {
        formBody.append(key, resp[key]);
      });
      setLoader(true);

      uploadReceiptService(formBody)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          onClose();
          setLoader(false);
          getEarnings();
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data?.message);
          console.log("error", err?.response?.data?.message);
          setLoader(false);
        });
    },
  });

  const onClose = () => {
    handleClose();
    formik?.resetForm();
    setImage({
      preview: "",
      raw: "",
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(90),
              position: "relative",
            },
          },
        }}
      >
        <DialogContent>
          <div className={styles.crossIcon}>
            <Icons.CrossIcon onClick={() => onClose()} />
          </div>
          <Card className={styles.card}>
            <CardContent>
              {selectedTransaction?.status !== 0 ? (
                <div>
                  <div className={styles.title} style={{ fontSize: "16px" }}>
                    Receipt details
                  </div>

                  <Grid spacing={2} container className={styles.grid}>
                    <Grid xs={6}>
                      <div className={styles.title}>User name</div>
                      <div className={styles.subTitle}>
                        {selectedTransaction?.user?.publisher
                          ? selectedTransaction?.user?.publisher?.full_name
                          : selectedTransaction?.user?.author
                          ? selectedTransaction?.user?.author?.full_name
                          : ""}
                      </div>
                    </Grid>
                    <Grid xs={6}>
                      <div className={styles.title}>Transaction id.</div>
                      <div className={styles.subTitle}>
                        {selectedTransaction?.transaction_id}
                      </div>
                    </Grid>
                    <Grid xs={6} marginTop="20px">
                      <div className={styles.title}>Amount</div>
                      <div className={styles.subTitle}>
                        {"Rs. " + selectedTransaction?.amount}
                      </div>
                    </Grid>
                    <Grid xs={6} marginTop="20px">
                      <div className={styles.title}>Platform Amount</div>
                      <div className={styles.subTitle}>
                        {"Rs. " + selectedTransaction?.platform_amount}
                      </div>
                    </Grid>
                    <Grid xs={6} marginTop="20px">
                      <div className={styles.title}>Coins</div>
                      <div className={styles.subTitle}>
                        {selectedTransaction?.coins}
                      </div>
                    </Grid>
                    <Grid xs={6} marginTop="20px">
                      <div className={styles.title}>Platform Coins</div>
                      <div className={styles.subTitle}>
                        {selectedTransaction?.platform_coins}
                      </div>
                    </Grid>
                    <Grid xs={6} marginTop="20px">
                      <div className={styles.title}>Payment date</div>
                      <div className={styles.subTitle}>
                        {moment(selectedTransaction?.payment_date).format(
                          "Do MMMM, YYYY."
                        )}
                      </div>
                    </Grid>
                    <Grid
                      xs={6}
                      display="flex"
                      justifyContent="start"
                      flexDirection="column"
                      alignItems="start"
                      marginTop="20px"
                    >
                      <div className={styles.title}>Receipt image</div>
                      <div className={styles.subTitle}>
                        <img
                          src={selectedTransaction?.receipt_image}
                          alt="transaction"
                          className={styles.receipt}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <div className={styles.title} style={{ fontSize: "16px" }}>
                      Upload Receipt
                    </div>

                    <Grid spacing={2} container className={styles.grid}>
                      <Grid xs={6} marginBottom="20px">
                        <div className={styles.title}>Amount</div>
                        <div className={styles.subTitle}>
                          {"Rs. " + selectedTransaction?.amount}
                        </div>
                      </Grid>
                      <Grid xs={6} marginBottom="20px">
                        <div className={styles.title}>Platform Amount</div>
                        <div className={styles.subTitle}>
                          {"Rs. " + selectedTransaction?.platform_amount}
                        </div>
                      </Grid>
                      <Grid xs={6} marginBottom="20px">
                        <div className={styles.title}>Coins</div>
                        <div className={styles.subTitle}>
                          {selectedTransaction?.coins}
                        </div>
                      </Grid>
                      <Grid xs={6} marginBottom="20px">
                        <div className={styles.title}>Platform Coins</div>
                        <div className={styles.subTitle}>
                          {selectedTransaction?.platform_coins}
                        </div>
                      </Grid>
                      <Grid xs={6}>
                        <div>
                          <div
                            className={styles.title}
                            style={{ fontSize: "16px" }}
                          >
                            Transaction id
                          </div>
                          <input
                            id="transaction_id"
                            name="transaction_id"
                            type="transaction_id"
                            value={formik?.values?.transaction_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={styles.inputField}
                          />
                          {formik?.touched?.transaction_id &&
                          formik?.errors?.transaction_id ? (
                            <div className={styles.errorText}>
                              {formik?.errors?.transaction_id}
                            </div>
                          ) : (
                            <div style={{ height: "20px" }}></div>
                          )}
                        </div>
                      </Grid>
                      <Grid xs={6}>
                        <div>
                          <div className={styles.title}>Payment date</div>
                          <DatePicker
                            selected={formik.values.payment_date}
                            onChange={(date: Date) => {
                              formik.setFieldValue("payment_date", date);
                            }}
                            className={styles.inputField}
                          />
                          {formik.touched.payment_date &&
                          formik.errors.payment_date ? (
                            <div className={styles.errorText}>
                              {formik.errors.payment_date}
                            </div>
                          ) : (
                            <div style={{ height: "20px" }}></div>
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <div className={styles.imgContainer}>
                        {!image.preview ? (
                          <div
                            className={styles.uploadBtn}
                            onClick={handleButtonClick}
                          >
                            Upload Photo
                          </div>
                        ) : (
                          <img
                            src={image.preview ? image.preview : ""}
                            alt={"user"}
                            className={styles.uploadImg}
                            onClick={handleButtonClick}
                          />
                        )}
                      </div>
                      {formik?.touched?.receipt_image &&
                      formik?.errors?.receipt_image ? (
                        <div className={styles.errorText}>
                          {formik?.errors?.receipt_image}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </div>

                    <CustomButton text="Upload" type={"submit"} />
                  </div>
                </form>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        {loader && <Loader />}
      </Dialog>
    </div>
  );
}
