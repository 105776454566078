import { Animations } from "assets";
import React from "react";
import Lottie from "react-lottie";
import styles from "./style.module.scss"

function Animation() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animations.AnimationJSON,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={styles.loaderContainer}>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
        height={"300px"}
        width={"400px"}
      />
    </div>
  );
}

export default Animation;
