import React from "react";
import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  styled,
} from "@mui/material";

import styles from "./style.module.scss";

interface ItemsPerPage {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  setItemsPerPage: any;
  setActivePage: any;
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    "&:hover": {
      borderColor: "#9a469b",
    },
    "&:focusVisible": {
      borderRadius: 1,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const ItemsPerPage = ({
  setActivePage,
  currentPage,
  totalItems,
  itemsPerPage,
  setItemsPerPage,
}: ItemsPerPage) => {
  const startIndex =
    currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage + 1 : 0;
  const endIndex =
    currentPage && itemsPerPage && totalItems
      ? Math.min(currentPage * itemsPerPage, totalItems)
      : 0;
  const handleChange = (e: any) => {
    e.preventDefault();
    setActivePage(1);
    setItemsPerPage(e.target.value);
  };
  return (
    <Box className={styles.boxWrapper}>
      <div className={styles.selectWrapper}>
        <label>Rows per page: </label>
        <FormControl className={styles.formControl} sx={{ m: 1, minWidth: 50 }}>
          <Select
            value={itemsPerPage}
            input={<BootstrapInput />}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={40}>40</MenuItem>
          </Select>
        </FormControl>

        {/* <select
          className={styles.selectElement}
          value={itemsPerPage}
          onChange={handleChange}
        >
          <option value={5}>5</option>
          <option value={8}>8</option>
          <option value={10}>10</option>
        </select> */}
      </div>
      <span>
        {startIndex} - {endIndex}
      </span>
      <span>
        of <span className={styles.totalItems}>{totalItems}</span>
      </span>
    </Box>
  );
};

export default ItemsPerPage;
