import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
// import { useEffect, useState } from "react";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
// import { getSingleCustomNotification } from "shared/services/projectHeadService";
// import { toastMessage } from "shared/components/toast";
import { Skeleton } from "@mui/material";
import { Images } from "assets";
import moment from "moment";
import Loader from "shared/components/loader/circular";
import { toastMessage } from "shared/components/toast";
import { getBookDetailsService } from "shared/services/general";
import {
  cancelCustomNotification,
  getAuthorProfileService,
  getPublisherProfileService,
} from "shared/services/projectHeadService";
import {
  cancelCustomNotification as cancelCustomNotificationManager,
  getAuthorProfileService as getAuthorProfileServiceManager,
  getPublisherProfileService as getPublisherProfileServiceManager,
} from "shared/services/productManagerService";
import Confirmation from "../confirmation";
import { directionsEnums, notification_status } from "./constant";
import { useSelector } from "react-redux";
import { roles as admin_roles } from "shared/utils/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface NotificationDetailProps {
  open: boolean;
  handleClose: () => void;
  selectedItem: any;
  getNotifications: any;
}

export default function NotificationDetail({
  open,
  handleClose,
  selectedItem,
  getNotifications,
}: NotificationDetailProps) {
  const {
    user: {
      user: { role },
    },
  } = useSelector((state: any) => state.root);
  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [displayData, setDisplayData] = useState<any>(null);
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const onClose = () => {
    handleClose();
  };

  const handleCancelNotification = () => {
    let service =
      Number(role) === admin_roles?.productManager
        ? cancelCustomNotificationManager
        : Number(role) === admin_roles?.projectHead
        ? cancelCustomNotification
        : null;

    setOpenConfirm(false);
    setLoader(true);

    service?.(selectedItem?.id)
      .then(({ data: { data, message, status } }) => {
        if (status) {
          toastMessage("success", message);
          getNotifications(false);
          onClose();
        } else {
          toastMessage("error", message);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getData = async () => {
    setDataLoader(true);
    let service;
    switch (selectedItem?.direction_type) {
      case directionsEnums?.authors:
        service =
          Number(role) === admin_roles?.productManager
            ? getAuthorProfileServiceManager
            : getAuthorProfileService;
        break;
      case directionsEnums?.books:
        service = getBookDetailsService;
        break;
      case directionsEnums?.publishers:
        service =
          Number(role) === admin_roles?.productManager
            ? getPublisherProfileServiceManager
            : getPublisherProfileService;
        break;
      default:
        service = null;
    }
    if (service) {
      try {
        const response = await service(selectedItem?.direction_id);
        setDisplayData(response.data.data);
        setDataLoader(false);
      } catch (error: any) {
        console.error("Error fetching data:", error?.response?.data?.message);
        setDataLoader(false);
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(60),
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent className={styles.content}>
          <div className={styles.mainContainer}>
            <div className={styles.headingContainer}>
              <label className={styles.heading}>Notification Detail</label>
            </div>

            <div className={styles.detailContainer}>
              {directionsEnums.plans !== selectedItem?.direction_type ? (
                dataLoader &&
                directionsEnums.plans !== selectedItem?.direction_type ? (
                  <DataLoader
                    type={
                      selectedItem?.direction_type === directionsEnums.books
                        ? "Books"
                        : "User"
                    }
                  />
                ) : selectedItem?.direction_type === directionsEnums.books ? (
                  <>
                    {" "}
                    <img
                      src={displayData?.thumbnail}
                      alt="book"
                      className={styles.bookImg}
                    />
                    <label className={styles.userName}>
                      {displayData?.title ? displayData?.title : ""}
                    </label>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          displayData?.partner?.profile_picture
                            ? displayData?.partner?.profile_picture
                            : Images.UserPlaceholderImage
                        }
                        alt="author"
                        className={styles.writerImg}
                      />

                      <label className={styles.writerName}>
                        {displayData?.partner?.full_name}
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={
                        displayData?.publisher?.profile_picture
                          ? displayData?.publisher?.profile_picture
                          : displayData?.author?.profile_picture
                          ? displayData?.author?.profile_picture
                          : Images.UserPlaceholderImage
                      }
                      className={styles.userImg}
                      alt="writer"
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <label className={styles.userName}>
                        {displayData?.publisher?.full_name
                          ? displayData?.publisher?.full_name
                          : displayData?.author?.full_name
                          ? displayData?.author?.full_name
                          : ""}
                      </label>
                      {(displayData?.publisher?.publishing_house ||
                        displayData?.publisher?.publishing_house) && (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              displayData?.publisher?.publishing_logo
                                ? displayData?.publisher?.publishing_logo
                                : displayData?.author?.publishing_logo
                                ? displayData?.author?.publishing_logo
                                : Images.UserPlaceholderImage
                            }
                            alt="author"
                            className={styles.writerImg}
                          />
                          <label className={styles.publicationName}>
                            {displayData?.publisher?.publishing_house
                              ? displayData?.publisher?.publishing_house
                              : displayData?.author?.publishing_house
                              ? displayData?.author?.publishing_house
                              : ""}
                          </label>
                        </div>
                      )}
                    </div>
                  </>
                )
              ) : null}
            </div>

            <div className={styles.label}>
              Send time:
              <span>
                {"  "}
                {moment(
                  selectedItem?.schedule_date
                    ? selectedItem?.schedule_date
                    : selectedItem?.created_at
                ).format("hh:mm A | DD MMMM, YYYY")}
              </span>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <label className={styles.title}>{selectedItem?.title}</label>
              <label className={styles.description}>{selectedItem?.body}</label>
            </div>

            {selectedItem?.status === notification_status?.scheduled && (
              <div>
                <button
                  className={styles.cancelBtn}
                  onClick={() => setOpenConfirm(true)}
                >
                  {loader ? <Loader /> : "Cancel"}
                </button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Confirmation
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleClick={() => handleCancelNotification()}
        text={"Are you sure, you want to cancel?"}
      />
    </React.Fragment>
  );
}

const DataLoader = ({ type }: any) => {
  return (
    <div>
      {type !== "Books" ? (
        <>
          <Skeleton variant="circular" className={styles.userLoader} />
          <Skeleton variant="rectangular" className={styles.nameLoader} />
        </>
      ) : (
        <>
          <Skeleton variant="circular" className={styles.bookLoader} />
          <Skeleton variant="rectangular" className={styles.nameLoader} />
        </>
      )}
    </div>
  );
};
