import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
export const columnHeadings = [
  { title: "Name", id: "full_name" },
  { title: "Plan Type", id: "plan_" },
  {
    title: "Email",
    id: "email",
  },
  { title: "Amount (Rs)", id: "transaction_amount" },
  { title: "Coins", id: "coins" },
  {
    title: "Transaction Type",
    id: "transaction_type",
  },
  { title: "Payment Date", id: "date" },
  { title: "Status", id: "status" },
  {
    title: "Actions",
    id: "actions",
  },
];

export const headings = {
  mainHeading: "Approved Subscriptions History",
  subHeading: "Manage all approved subscriptions from here",
};

export const transactionTypes = [
  {
    id: 1,
    name: "Online",
  },
  {
    id: 2,
    name: "Manual",
  },
];

export const actions = [
  {
    id: "view",
    text: "View",
    icon: <RemoveRedEyeIcon />,
  },
];

export const skeletonInfo = {
  rows: 8,
  cellCount: 9,
  actions: 1,
  showPerson: false,
};
