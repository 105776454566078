import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import useWindowSize from "shared/customHooks/windowSize";
import moment from "moment";
import SkeletonLoader from "./skeletonLoader";
import "react-datepicker/dist/react-datepicker.css";
import DeleteIcon from "@mui/icons-material/Delete";
import classNames from "classnames";
import { planCategories, plan_category } from "./constant";
import { Icons } from "assets";
import Loader from "shared/components/loader/animation";
import {
  activatePlanService,
  deleteBalanceService,
  deletePlanService,
  getAllBalanceService,
  getAllPlansService,
  inActivatePlanService,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import EditIcon from "@mui/icons-material/Edit";
import Confirmation from "shared/modals/confirmation";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import CustomButton from "shared/components/customButton";
import AddPlan from "shared/modals/planForm";
import TopUpBalance from "shared/modals/topUpBalance";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import AddFreePlan from "shared/components/addFreePlan";

function Membership() {
  const screenWidth = useWindowSize();
  const [activeTab, setActiveTab] = useState<any>(plan_category[0].value);
  const [allPlans, setAllPlans] = useState<any>(null);
  const [allBalance, setAllBalance] = useState<any>(null);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [openConfirm, setOpenConfirmation] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const [selectedBalance, setSelectedBalance] = useState<any>(null);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openBalance, setOpenBalance] = useState<boolean>(false);
  const [viewOnly, setViewOnly] = useState<boolean>(false);
  const [editOnly, setEditOnly] = useState<boolean>(false);
  const [isSchoolTabSelected, setIsSchoolTabSelected] =
    useState<boolean>(false);

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedPlan(null);
    setEditOnly(false);
    setViewOnly(false);
  };

  const handleOpenForm = (item?: any) => {
    setOpenForm(true);
    if (item) {
      setViewOnly(true);
      setSelectedPlan(item);
    }
    setViewOnly(false);
  };

  const handleCloseBalance = () => {
    setOpenBalance(false);
    setSelectedPlan(null);
  };

  const handleOpenBalance = (item?: any) => {
    setOpenBalance(true);
    if (item) {
      setSelectedBalance(item);
    }
  };

  const getAllPlans = () => {
    setDataLoader(true);
    getAllPlansService({ category: activeTab })
      .then(({ data }) => {
        setAllPlans(data?.data?.data);
        setDataLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setDataLoader(false);
      });
  };

  const getAllBalance = () => {
    setDataLoader(true);
    getAllBalanceService()
      .then(({ data }) => {
        setAllBalance(data?.data);
        setDataLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setDataLoader(false);
      });
  };

  useEffect(() => {
    if (activeTab - 2 === 2) {
      getAllBalance();
    } else {
      getAllPlans();
    }
  }, [activeTab]);

  // const scrollToPlanForm = () => {
  //   if (targetedRef.current) {
  //     targetedRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAction({
      label: "",
      type: "",
    });
    setSelectedItem(null);
  };

  const handleOpenConfirmation = (label: String, type: String, item: any) => {
    setOpenConfirmation(true);
    setAction({
      label: label,
      type: type,
    });
    setSelectedItem(item);
  };
  const handleActions = (action: any) => {
    setLoader(true);
    let service =
      action.type === "delete" && activeTab - 2 !== 2
        ? deletePlanService
        : action.type === "delete" && activeTab - 2 === 2
        ? deleteBalanceService
        : action.type === "active"
        ? activatePlanService
        : action.type === "inactive"
        ? inActivatePlanService
        : null;
    service?.(selectedItem?.id)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        getAllPlans();
        getAllBalance();
        setLoader(false);
        handleCloseConfirmation();
      })
      .catch((err) => {
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useBreadcrumbs({
    title: "Membership",
    path: routeConstant.membership.path,
  });

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <div className={styles.title} style={{ marginBottom: "20px" }}>
          {/* <AddFreePlan></AddFreePlan> */}
        </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab - 1}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#9A469B",
                color: "red !important",
              },
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#9A469B",
              },
            }}
          >
            {plan_category?.map((item) => {
              return (
                <Tab
                  label={item?.label}
                  className={styles.tabs}
                  onClick={() => {
                    setSelectedItem(null);
                    setSelectedPlan(null);
                    setActiveTab(item?.value);
                    setSelectedBalance(null);
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        {/* <Card className={styles.card} ref={targetedRef}>
          <CardContent sx={{ paddingBottom: "16px !important" }}>
            {activeTab - 1 === 0 || activeTab - 1 === 1 ? (
              <div>
                <PlanForm
                  activeTab={activeTab}
                  selectedItem={selectedPlan}
                  setSelectedItem={setSelectedPlan}
                  getAllPlans={getAllPlans}
                />
              </div>
            ) : activeTab - 1 === 2 ? (
              <Balance
                getAllBalance={getAllBalance}
                selectedBalance={selectedBalance}
                setSelectedBalance={setSelectedBalance}
              />
            ) : null}
          </CardContent>
        </Card> */}
      </Box>

      {activeTab - 2 !== 2 && (
        <Box className={styles.topLevelContainer}>
          <Box className={styles.topHeadContainer}>
            <Box>
              <div className={styles.tableHeading}>
                Plans history for{" "}
                {activeTab === 1
                  ? "individual account"
                  : activeTab === 2
                  ? "family account"
                  : activeTab === 3
                  ? "school"
                  : null}
              </div>
              <div className={styles.tableSubHeading}>
                Activated and deactivated plans list showing below.
              </div>
            </Box>
            <Box className={styles.filterContainer}>
              <div className={styles.gridContainer}>
                <CustomButton
                  text={"Add New"}
                  buttonStyle={styles.addBtn}
                  handleClick={() => {
                    if (activeTab === 3) {
                      setIsSchoolTabSelected(true);
                    } else {
                      setIsSchoolTabSelected(false);
                    }
                    handleOpenForm();
                  }}
                />
              </div>
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            className={styles.container}
            sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
          >
            <Table className={styles.table} aria-label="simple table">
              <TableHead>
                <TableRow className={styles.tableHead}>
                  {activeTab === 3 ? <TableCell>School Name</TableCell> : null}
                  <TableCell>Plan Title</TableCell>
                  <TableCell>Amount (Rs)</TableCell>
                  <TableCell>Coins</TableCell>
                  <TableCell>Duration</TableCell>
                  {/* <TableCell>Benefits</TableCell>
                  <TableCell>Discount title</TableCell> */}
                  <TableCell>Discount</TableCell>
                  {/* <TableCell>Start date</TableCell>
                  <TableCell>End date</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLoader ? (
                  <SkeletonLoader activeTab={activeTab} />
                ) : (
                  allPlans
                    ?.filter((item: any) => item?.category === activeTab)
                    .map((item: any) => {
                      return (
                        <>
                          <TableRow className={styles.tableRow}>
                            {activeTab === 3 ? (
                              <TableCell>
                                {item?.school_name ? item?.school_name : "--"}
                              </TableCell>
                            ) : null}
                            <TableCell>
                              {item?.name ? item?.name : "--"}
                            </TableCell>
                            <TableCell>{item?.price}</TableCell>
                            <TableCell>{item?.coins}</TableCell>
                            <TableCell>{item?.duration + " Months"}</TableCell>
                            {/* <TableCell>{item?.description[0]}</TableCell>
                            <TableCell>
                              {item?.title ? item?.title : "--"}
                            </TableCell> */}
                            <TableCell>
                              {item?.is_discounted
                                ? "Available"
                                : "Not Available"}
                            </TableCell>
                            {/* <TableCell>
                              {item?.start_date
                                ? moment(item?.start_date).format("D MMMM YYYY")
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {item?.end_date
                                ? moment(item?.end_date).format("D MMMM YYYY")
                                : "--"}
                            </TableCell> */}
                            <TableCell>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                className={styles.actionBtn}
                              >
                                <Tooltip arrow title="View details">
                                  <button
                                    onClick={() => {
                                      handleOpenForm(item);
                                      setViewOnly(true);
                                      setEditOnly(false);
                                    }}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                </Tooltip>

                                <Tooltip title={"Edit"} arrow>
                                  <button
                                    onClick={() => {
                                      if (activeTab === 3) {
                                        setIsSchoolTabSelected(true);
                                      } else {
                                        setIsSchoolTabSelected(false);
                                      }
                                      // scrollToPlanForm();
                                      // setSelectedPlan(item);
                                      handleOpenForm(item);
                                      setEditOnly(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </button>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    item?.status === 1
                                      ? "Inactivate"
                                      : "Activate"
                                  }
                                  arrow
                                >
                                  <button
                                    onClick={() =>
                                      handleOpenConfirmation(
                                        `Are you sure you want to ${
                                          item?.status === 1
                                            ? "inactivate"
                                            : "activate"
                                        } this plan?`,
                                        `${
                                          item?.status === 0
                                            ? "active"
                                            : item?.status === 1
                                            ? "inactive"
                                            : ""
                                        }`,
                                        item
                                      )
                                    }
                                  >
                                    {item?.status === 1 ? (
                                      <FlashOnIcon />
                                    ) : item?.status === 0 ? (
                                      <FlashOffIcon />
                                    ) : null}
                                  </button>
                                </Tooltip>
                                <Tooltip title={"Delete"} arrow>
                                  <button
                                    onClick={() =>
                                      handleOpenConfirmation(
                                        "Are you sure, you want to delete this plan?",
                                        "delete",
                                        item
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </button>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            {allPlans?.filter(
              (plan: any) => plan.category === planCategories.school
            ).length === 0 && activeTab === planCategories.school ? (
              <NoDataCard />
            ) : allPlans?.filter(
                (plan: any) => plan.category === planCategories.individual
              ).length === 0 && activeTab === planCategories.individual ? (
              <NoDataCard />
            ) : allPlans?.filter(
                (plan: any) => plan.category === planCategories.family
              ).length === 0 && activeTab === planCategories.family ? (
              <NoDataCard />
            ) : null}
          </Box>
        </Box>
      )}

      {activeTab - 2 === 2 && (
        <Box className={styles.topLevelContainer}>
          <Box className={styles.topHeadContainer}>
            <Box>
              <div className={styles.tableHeading}>Balance records</div>
              <div className={styles.tableSubHeading}>
                Manage your balance records from here.
              </div>
            </Box>
            <Box className={styles.filterContainer}>
              <div className={styles.gridContainer}>
                <CustomButton
                  text={"Add New"}
                  buttonStyle={styles.addBtn}
                  handleClick={() => handleOpenBalance()}
                />
              </div>
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            className={styles.table}
            sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className={styles.tableHead}>
                  <TableCell>Price</TableCell>
                  <TableCell>Coins</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLoader ? (
                  <SkeletonLoader activeTab={activeTab} />
                ) : (
                  allBalance?.map((item: any) => {
                    return (
                      <>
                        <TableRow className={styles.tableRow}>
                          <TableCell>{item?.price}</TableCell>
                          <TableCell>{item?.coins}</TableCell>

                          <TableCell>
                            {moment(item?.created_at).format("D MMMM YYYY")}
                          </TableCell>
                          <TableCell>
                            {moment(item?.updated_at).format("D MMMM YYYY")}
                          </TableCell>

                          <TableCell>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              className={styles.actionBtn}
                            >
                              <Tooltip title="Edit" arrow>
                                <button
                                  onClick={() => {
                                    // scrollToPlanForm();
                                    // setSelectedBalance(item);
                                    handleOpenBalance(item);
                                  }}
                                >
                                  <EditIcon />
                                </button>
                              </Tooltip>
                              <Tooltip title="Delete" arrow>
                                <button
                                  onClick={() =>
                                    handleOpenConfirmation(
                                      "Are you sure, you want to delete this?",
                                      "delete",
                                      item
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </button>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            {allBalance?.length === 0 ? (
              <div className={classNames(styles.noDataCard, styles.noData)}>
                <Icons.NoDataIcon />
                <h5>No Data Found</h5>
              </div>
            ) : null}
          </Box>
        </Box>
      )}

      <AddPlan
        open={openForm}
        handleClose={handleCloseForm}
        activeTab={activeTab}
        selectedItem={selectedPlan}
        setSelectedItem={setSelectedPlan}
        getAllPlans={getAllPlans}
        viewOnly={viewOnly}
        editOnly={editOnly}
        isSchoolTabSelected={isSchoolTabSelected}
      />

      <TopUpBalance
        open={openBalance}
        handleClose={handleCloseBalance}
        getAllBalance={getAllBalance}
        selectedBalance={selectedBalance}
        setSelectedBalance={setSelectedBalance}
      />

      <Confirmation
        open={openConfirm}
        handleClose={() => handleCloseConfirmation()}
        handleClick={() => handleActions(action)}
        text={action.label}
      />
      {loader && <Loader />}
    </div>
  );
}

const NoDataCard = () => {
  return (
    <div className={classNames(styles.noDataCard, styles.noData)}>
      <Icons.NoDataIcon />
      <h5>No Data Found</h5>
    </div>
  );
};

export default Membership;
