import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";

import { Icons } from "assets";
import classNames from "classnames";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CardComp from "shared/components/card";
import Chart from "shared/components/chart";
import GenreCard from "shared/components/genreCard";
import ReadBooksCard from "shared/components/readBooksCard";
import styles from "./style.module.scss";

import TermsAndPolicyCard from "shared/components/termsAndPolicy";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import {
  getBooksStats,
  getCatalogStats,
  getGenreStats,
  getHardCopyStats,
  getSoftCopyStats,
} from "shared/services/productManagerService";

function DashboardManager() {
  const [dashboardManager, setDashboardManager] = useState<any>([
    { coinsSold: { title: "Coins Sold", stats: "11.5K" } },
    {
      coinsDistributionRegular: {
        title: "Coins Sold Coins Sold Coins Sold Coins Sold Coins Sold ",
        stats: "11.5K",
      },
    },
    { coinsDistributionFeatured: { title: "Coins Sold  ", stats: "11.5K" } },
    { coinsDistributionRegular: { title: "Coins Sold", stats: "11.5K" } },
    { coinsDistributionRegular: { title: "Coins Sold", stats: "11.5K" } },
    { coinsDistributionRegular: { title: "Coins Sold", stats: "11.5K" } },
  ]);
  const [statsLoader, setStatsLoader] = useState<boolean>(false);
  const [totalCatalogs, setTotalCatalogs] = useState<any>(null);
  const [catalogLoader, setCatalogLoader] = useState<boolean>(false);
  const [genreLists, setGenreList] = useState<any>(null);
  const [genreLoader, setGenreLoader] = useState<boolean>(false);
  const [booksLists, setBooksList] = useState<any>(null);
  const [booksFilter, setBooksFilter] = useState<string>("most_read");
  const [bookLoader, setBookLoader] = useState<boolean>(false);
  const [softCopyLabel, setSoftCopyLabels] = useState<any>(null);
  const [softCopySales, setSoftCopySales] = useState<any>(null);
  const [softCopyLoader, setSoftCopyLoader] = useState<any>(true);
  const [hardCopyLoader, setHardCopyLoader] = useState<boolean>(true);
  const [softCopyDates, setSoftCopyDates] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [hardCopyLabel, setHardCopyLabels] = useState<any>(null);
  const [hardCopySales, setHardCopySales] = useState<any>(null);
  const [hardCopyDates, setHardCopyDates] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const onChangeDate = (dates: any, action: string) => {
    const [start, end] = dates;
    if (action === "softCopy") {
      setSoftCopyDates({
        startDate: start,
        endDate: end,
      });
    } else if (action === "hardCopy") {
      setHardCopyDates({
        startDate: start,
        endDate: end,
      });
    }
  };

  const getCatalogs = () => {
    setCatalogLoader(true);
    getCatalogStats()
      .then(({ data }) => {
        setTotalCatalogs(data?.data?.total);
        setCatalogLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setCatalogLoader(false);
      });
  };

  const getMostReadGenres = () => {
    setGenreLoader(true);
    getGenreStats()
      .then(({ data }) => {
        setGenreList(data?.data);
        setGenreLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setGenreLoader(false);
      });
  };

  const getMostReadBooks = (filter: string) => {
    setBookLoader(true);
    getBooksStats(filter)
      .then(({ data }) => {
        setBooksList(data?.data);
        setBookLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setBookLoader(false);
      });
  };

  const getSoftCopySales = () => {
    setSoftCopyLoader(true);
    getSoftCopyStats(softCopyDates)
      .then(({ data }) => {
        setSoftCopyLabels(data?.data?.labels);
        setSoftCopySales(data?.data?.sales?.total_sales);
        setSoftCopyLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setSoftCopyLoader(false);
      });
  };

  const getHardCopySales = () => {
    setHardCopyLoader(true);
    getHardCopyStats(hardCopyDates)
      .then(({ data }) => {
        setHardCopyLabels(data?.data?.labels);
        setHardCopySales(data?.data?.sales);
        setHardCopyLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        setHardCopyLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getCatalogs();
    getMostReadGenres();
    getHardCopySales();
    getDashboardCoinsStats();
  }, []);

  useEffect(() => {
    getMostReadBooks(booksFilter);
  }, [booksFilter]);

  useEffect(() => {
    getSoftCopySales();
    // eslint-disable-next-line
  }, [softCopyDates?.endDate]);

  useEffect(() => {
    getHardCopySales();
    // eslint-disable-next-line
  }, [hardCopyDates?.endDate]);

  useBreadcrumbs({
    title: "Dashboard",
    path: routeConstant?.dashboard?.path,
  });

  const getDashboardCoinsStats = () => {
    // getDashboardData()
    //   .then(({ data }) => {
    //     setIsLoading(false);
    //     setDashboardData(data?.data);
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     toastMessage("error", err?.response?.data?.message);
    //     console.log("err", err?.response?.data?.message);
    //   });
  };

  const cardData = [
    {
      title: dashboardManager[0].coinsSold.title,
      stats: dashboardManager[0].coinsSold.stats,
    },
    {
      title: dashboardManager[1].coinsDistributionRegular.title,
      stats: dashboardManager[1].coinsDistributionRegular.stats,
    },
    {
      title: dashboardManager[1].coinsDistributionRegular.title,
      stats: dashboardManager[1].coinsDistributionRegular.stats,
    },
    {
      title: dashboardManager[1].coinsDistributionRegular.title,
      stats: dashboardManager[1].coinsDistributionRegular.stats,
    },
    {
      title: dashboardManager[1].coinsDistributionRegular.title,
      stats: dashboardManager[1].coinsDistributionRegular.stats,
    },
    {
      title: dashboardManager[1].coinsDistributionRegular.title,
      stats: dashboardManager[1].coinsDistributionRegular.stats,
    },
  ];

  return (
    <div>
      {/* DASHBOARD STATS SECTION */}

      {/* <Grid spacing={2} className={styles.dashboardStatsWrapper}>
        <Grid container spacing={2}>
          {cardData?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} md={3} lg={4}>
                  <CustomCard
                    stats={item?.stats}
                    title={item?.title}
                    isLoading={statsLoader}
                    style={styles.centerText}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid> */}

      <Grid spacing={2} container className={styles.topLevelGrid}>
        <Grid xs={12} md={8} className={styles.chartGrid}>
          <Card sx={{ marginBottom: "40px" }} className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Box className={styles.cardContentWrapper}>
                <div className={styles.title}>Soft Copy Sales figure</div>
                <div className={styles.rangeInputWrapper}>
                  <DatePicker
                   
                    className={styles.range_input}
                    dateFormat="d MMM"
                    selected={softCopyDates?.startDate}
                    onChange={(date: any) => onChangeDate(date, "softCopy")}
                    startDate={softCopyDates?.startDate}
                    endDate={softCopyDates?.endDate}
                    selectsRange
                    placeholderText="Select Range"
                  />
                </div>
              </Box>
              {softCopyLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <Skeleton
                    width={60}
                    height={60}
                    variant="circular"
                  ></Skeleton>
                  <Skeleton
                    width={200}
                    height={15}
                    variant="rectangular"
                  ></Skeleton>
                </div>
              ) : softCopySales?.length > 0 ? (
                <Chart labels={softCopyLabel} sales={softCopySales} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!softCopyLoader && (
                    <div
                      className={classNames(styles.noDataCard, styles.noData)}
                    >
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
          <Card className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Box className={styles.cardContentWrapper}>
                <div className={styles.title}>Hard Copy sales figure</div>
                <div className={styles.rangeInputWrapper}>
                  <DatePicker
                    // showIcon
                    // icon={<Icons.CalenderIcon></Icons.CalenderIcon>}
                    className={styles.range_input}
                    dateFormat="MMM d"
                    selected={hardCopyDates?.startDate}
                    onChange={(date: any) => onChangeDate(date, "hardCopy")}
                    startDate={hardCopyDates?.startDate}
                    endDate={hardCopyDates?.endDate}
                    selectsRange
                    placeholderText="Select Range"
                  />
                </div>
              </Box>
              {hardCopyLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <Skeleton
                    width={60}
                    height={60}
                    variant="circular"
                  ></Skeleton>
                  <Skeleton
                    width={200}
                    height={15}
                    variant="rectangular"
                  ></Skeleton>
                </div>
              ) : // <Skeleton width={20} height={"100%"}></Skeleton>
              hardCopySales?.length > 0 ? (
                <Chart labels={hardCopyLabel} sales={hardCopySales} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!hardCopyLoader && (
                    <div
                      className={classNames(styles.noDataCard, styles.noData)}
                    >
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={4}>
          <div style={{ marginBottom: "20px" }}>
            <CardComp
              // style={{
              //   fontSize: "16px",
              //   fontWeight: "700",
              // }}
              stats={totalCatalogs}
              title={"Total books catalog"}
              isLoading={catalogLoader}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <ReadBooksCard
              booksLists={booksLists}
              booksFilter={booksFilter}
              setBooksFilter={setBooksFilter}
              isLoading={bookLoader}
            />
          </div>
          <div>
            <GenreCard genreLists={genreLists} isLoading={genreLoader} />
          </div>
        </Grid>
      </Grid>
      {/* DASHBOARD Terms and Policy */}

      <div className={styles.gridContainer}>
        <div className={styles.termsAndPolicyWrapper}>
          <Box className={styles.cardWrapper}>
            <Card>
              <TermsAndPolicyCard type={"policy"} text={"Privacy Policy"} />
            </Card>
            <Card>
              <TermsAndPolicyCard type={"term"} text={"Terms And Condition"} />
            </Card>
          </Box>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default DashboardManager;
