import React, { useState } from "react";
import styles from "./style.module.scss";
import { Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { notificationSettingsService } from "shared/services/profileService";
import { toastMessage } from "../toast";
import { setUser } from "shared/redux/reducers/userSlice";

function NotificationsCard() {
  const {
    user: { user, token, role },
  } = useSelector((state: any) => state.root);
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<boolean>(
    user?.is_notification ? true : false
  );

  const handleNotifications = (notifications: boolean) => {
    setNotifications(!notifications);
    notificationSettingsService({ is_notification: notifications })
      .then(({ data }) => {
        toastMessage("success", data?.message);
        handleSetUser(notifications);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handleSetUser = (notifications: boolean) => {
    let temp = { ...user, is_notification: notifications };

    let obj: any = {
      user: temp,
      isLoggedIn: true,
      token: token,
      role: role,
    };
    dispatch(setUser(obj));
  };

  return (
    <div>
      <div className={styles.topMostContainer}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={styles.titleInput}>Turn notifications on/off</div>
          <div>
            <Switch
              defaultChecked={notifications}
              onChange={() => handleNotifications(!notifications)}
              sx={{
                "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  color: "#9A469B",
                },
                "&.MuiSwitch-root .Mui-checked": {
                  color: "#9A469B",
                },
                "&.MuiSwitch-root .MuiSwitch-track": {
                  backgroundColor: "#9A469B !important",
                },
                "&.MuiSwitch-root .Mui-checked .MuiSwitch-track": {
                  backgroundColor: "#9A469B !important",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsCard;
