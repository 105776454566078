import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

function SkeletonLoader({ lines, actions }: any) {
  return (
    <React.Fragment>
      {Array.from({ length: 8 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell sx={{ display: "flex" }}>
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton
              variant="rectangular"
              width={120}
              height={25}
              sx={{ marginLeft: "10px", marginTop: "5px" }}
            />
          </TableCell>
          {Array.from({ length: lines }).map((_, index) => {
            return (
              <TableCell>
                <Skeleton variant="rectangular" width={140} height={25} />
              </TableCell>
            );
          })}

          <TableCell>
            <Box display={"flex"}>
              {Array.from({ length: actions }).map((_, index) => {
                return (
                  <Skeleton
                    width={30}
                    height={40}
                    sx={{ marginRight: "10px" }}
                  />
                );
              })}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default SkeletonLoader;
