import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setBreadCrumb } from "shared/redux/reducers/breadCrumbs";

const useBreadcrumbs = (
  compObj: any,
  clonePreviousArr?: boolean,
  dependency?: any
) => {
  const { breadCrumbs } = useSelector((state: any) => state.root);
  const location = useLocation();
  const dispatch = useDispatch();


  useEffect(() => {
    let temp = [...breadCrumbs.crumbs];
    let index = temp?.findIndex(
      (item: any) => item?.path === location?.pathname
    );
    if (compObj?.title && compObj?.path) {
      if (index > -1) {
        temp.splice(index + 1);

        dispatch(
          setBreadCrumb({
            crumbs: temp,
          })
        );
      } else {
        if (clonePreviousArr) {
          dispatch(
            setBreadCrumb({
              crumbs: [...breadCrumbs.crumbs, compObj],
            })
          );
        } else {
          dispatch(
            setBreadCrumb({
              crumbs: [compObj],
            })
          );
        }
      }
    }

    // eslint-disable-next-line
  }, [dependency ? dependency : null]);
};

export default useBreadcrumbs;
