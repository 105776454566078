import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import SkeletonLoader from "./skeleton";
import { Icons } from "assets";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getUserTransactionsList } from "shared/services/productManagerService";
import { getUserTransactionsList as getUserTransactionListForProjectHead } from "shared/services/projectHeadService";
import { TransitionProps } from "@mui/material/transitions";
import { toastMessage } from "shared/components/toast";
import moment from "moment";
import NoDataFound from "shared/components/noDataFound";
import { useSelector } from "react-redux";
import { getRoutes } from "shared/components/sidebar/constant";
import ViewReceiptModal from "../viewReceiptModal";

interface ViewTransactionListModalProps {
  viewModal: boolean;
  handleViewClose: any;
  userId: any;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewTransactionListModal = ({
  viewModal,
  handleViewClose,
  userId,
}: ViewTransactionListModalProps) => {
  const { user } = useSelector((state: any) => state.root);
  const [userTransactions, setUserTransactions] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [viewReceiptModal, setViewReceiptModal] = useState<boolean>(false);
  const [receipt, setReceipt] = useState<any>(null);
  const routes = getRoutes();
  const activeRole = routes.find(
    (role: any) => role.role_id === Number(user?.user?.role)
  );

  const handleViewReceipt = (image: any) => {
    setReceipt(image);
    setViewReceiptModal(true);
  };

  const roles = {
    projectHead: 1,
    productManager: 4,
  };

  const getSingleUserTransactionDetails = () => {
    setLoader(true);
    const service =
      activeRole.role_id === roles.productManager
        ? getUserTransactionsList
        : getUserTransactionListForProjectHead;

    service(userId)
      .then((data) => {
        setLoader(false);
        setReceipt(null);
        setUserTransactions(data?.data?.data?.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("Err----", err);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (viewModal) {
      getSingleUserTransactionDetails();
    }
  }, [userId, viewModal]);

  const transactionTypes = {
    online: {
      text: "Online",
      value: 1,
    },
    manual: {
      text: "Manual",
      value: 2,
    },
  };
  return (
    <>
      <Dialog
        open={viewModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleViewClose()}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
      >
        <DialogTitle>
          <div className={styles.dialogTitle}>
            <h4>Transaction Details</h4>
            <span
              onClick={() => handleViewClose()}
              className={styles.crossIcon}
            >
              <Icons.CrossIcon />
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TableContainer className={styles.container} component={Paper}>
              <Table className={styles.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={styles.tableHead}>
                    <TableCell>Transaction Id</TableCell>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell>Coins</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Purchase Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader ? (
                    <SkeletonLoader></SkeletonLoader>
                  ) : (
                    userTransactions?.map((transaction: any) => {
                      return (
                        <TableRow className={styles.tableRow}>
                          <TableCell>{transaction?.transaction_id}</TableCell>
                          <TableCell>
                            {transaction?.transaction_type ===
                            transactionTypes.manual.value
                              ? transactionTypes.manual.text
                              : transactionTypes.online.text}
                          </TableCell>
                          <TableCell>{transaction?.coins}</TableCell>
                          <TableCell>{transaction?.price}</TableCell>
                          <TableCell>
                            {moment(transaction?.transaction_date).format(
                              "DD MMM YYYY"
                            )}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="View Receipt">
                              <span
                                className={styles.iconWrapper}
                                onClick={() =>
                                  handleViewReceipt(transaction?.receipt_image)
                                }
                              >
                                <RemoveRedEyeIcon></RemoveRedEyeIcon>
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!userTransactions?.length && !loader && (
              <NoDataFound></NoDataFound>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* View Receipt Modal */}
      <ViewReceiptModal
        loader={loader}
        viewModal={viewReceiptModal}
        image={receipt}
        setReceipt={setReceipt}
        handleViewClose={() => {
          setViewReceiptModal(false);
        }}
      />
    </>
  );
};

export default ViewTransactionListModal;
