import React, { useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./style.module.scss";
import {
  monthly_plans,
  plan_types,
  plan_status,
} from "pages/membership/constant";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createPlanService,
  updatePlanService,
} from "shared/services/productManagerService";
import moment from "moment";
import { toastMessage } from "shared/components/toast";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputBase,
  styled,
} from "@mui/material";
import DatePicker from "react-datepicker";
import Loader from "shared/components/loader/animation";
import CustomButton from "shared/components/customButton";
import { Icons } from "assets";
// import useWindowSize from "shared/customHooks/windowSize";
import classNames from "classnames";
import { getAllSchoolListService } from "shared/services/productManagerService";
import AutoCompleteUsers from "shared/components/autoCompleteUsers";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PlanFormProps {
  open: boolean;
  handleClose: any;
  activeTab: any;
  selectedItem: any;
  setSelectedItem: any;
  getAllPlans: any;
  viewOnly: boolean;
  editOnly: boolean;
  isSchoolTabSelected: boolean;
}

export default function PlanForm({
  open,
  handleClose,
  activeTab,
  selectedItem,
  setSelectedItem,
  getAllPlans,
  viewOnly,
  editOnly,
  isSchoolTabSelected,
}: PlanFormProps) {
  const plan = plan_status.find((plan) => plan.value === 1);
  const [selectedPlanDuration, setSelectedPlanDuration] = useState<any>(
    monthly_plans[0]
  );

  const [isDiscounted, setIsDiscounted] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(plan);
  const [status, setStatus] = useState<any>(plan_status[1]);
  const [loader, setLoader] = useState<boolean>(false);
  const [isItem, setIsItem] = useState<boolean>(false);
  const [schoolList, setSchoolList] = useState<any>([]);
  const [selectedSchool, setSelectedSchool] = useState<any>(null);
  const [searchSchool, setSearchSchool] = useState<string>("");
  const WebCardImageRef = useRef<HTMLInputElement | null>(null);
  const MobileCardImageRef = useRef<HTMLInputElement | null>(null);
  const [webCardImage, setWebCardImage] = useState<any>({
    raw: "",
    preview: "",
  });
  const [mobileCardImage, setMobileCardImage] = useState<any>({
    raw: "",
    preview: "",
  });
  // const screenWidth = useWindowSize();

  const initialValues = {
    coins: selectedItem ? Number(selectedItem?.coins) : "",
    amount: "",
    benefits: "",
    discountTitle: "",
    discountAmount: "",
    startDate: null,
    endDate: null,
    promotionalTitle: "",
    school: selectedSchool ? selectedSchool : "",
    mobileCard: "",
    webCard: "",
  };
  const getValidationSchema = () => {
    const commonSchema = {
      coins: yup
        .string()
        .required("Coins is required")
        .matches(/^\d+$/, "Coins must contain positive numbers"),
      amount: yup
        .string()
        .required("Amount is required")
        .matches(/^\d+$/, "Amount must contain positive numbers"),
      benefits: yup.string().required("Benefits is required"),
    };

    if (isSchoolTabSelected && !selectedItem) {
      return yup.object({
        ...commonSchema,
        school: yup.object().required("School is required"),
      });
    }

    if (selectedPlan?.value === 2) {
      return yup.object({
        ...commonSchema,
        promotionalTitle: yup.string().required("Title is required"),
        startDate: yup.string().required("Start date is required"),
        endDate: yup.string().required("End date is required"),
        mobileCard: yup.mixed().required("Mobile Card is required"),
        webCard: yup.mixed().required("Web Card is required."),
      });
    }

    return isDiscounted
      ? yup.object({
          ...commonSchema,
          discountTitle: yup.string().required("Title is required"),
          startDate: yup.string().required("Start date is required"),
          endDate: yup.string().required("End date is required"),
          discountAmount: yup
            .string()
            .required("Discount Amount is required")
            .matches(/^\d+$/, "Amount must contain positive numbers"),
        })
      : yup.object(commonSchema);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      setLoader(true);
      let resp: any = {
        category: activeTab,
        type: selectedPlan?.value,
        duration: selectedPlanDuration.value,
        price: Number(values?.amount),
        coins: Number(values?.coins),
        description: values?.benefits,
        status: status.value,
      };

      if (isSchoolTabSelected) {
        resp["user_id"] = selectedSchool?.id;
      }

      if (isDiscounted && selectedPlan.value !== 2) {
        resp["discounted_price"] = Number(values?.discountAmount);
        resp["is_discounted"] = isDiscounted ? 1 : 0;
        resp["title"] = values?.discountTitle;
      }

      if (isDiscounted || selectedPlan.value === 2) {
        resp["start_date"] = moment(values?.startDate).format("YYYY-MM-DD");
        resp["end_date"] = moment(values?.endDate).format("YYYY-MM-DD");
      }

      if (selectedPlan.value === 2) {
        resp["title"] = values?.promotionalTitle;
        if (!isItem) {
          resp["web_card"] = values?.webCard;
          resp["mobile_card"] = values?.mobileCard;
        } else {
          if (webCardImage?.raw) {
            resp["web_card"] = webCardImage?.raw;
          }
          if (mobileCardImage?.raw) {
            resp["mobile_card"] = mobileCardImage?.raw;
          }
        }
      }

      if (isItem) {
        resp["plan_id"] = selectedItem?.id;
      }

      let formBody = new FormData();

      Object.keys(resp).forEach((key) => {
        formBody.append(key, resp[key]);
      });

      let service = isItem ? updatePlanService : createPlanService;

      service(formBody)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          setLoader(false);
          resetPlanForm();
          getAllPlans();
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setLoader(false);
        });
    },
  });

  //handling Edit
  useEffect(() => {
    if (selectedItem) {
      setIsItem(true);
      setSelectedSchool(selectedItem);
      setSelectedPlanDuration(
        monthly_plans.find((item) => item?.value === selectedItem?.duration)
      );
      formik.setFieldValue("coins", Number(selectedItem?.coins));
      formik.setFieldValue("amount", Number(selectedItem?.price));
      formik.setFieldValue("benefits", selectedItem.description.join("\n"));
      if (selectedItem?.is_discounted) {
        formik.setFieldValue(
          "discountAmount",
          Number(selectedItem?.discounted_price)
        );
      }
      if (Number(selectedItem?.type) === 1 && selectedItem?.is_discounted) {
        formik.setFieldValue("discountTitle", selectedItem?.title);
      } else {
        formik.setFieldValue("promotionalTitle", selectedItem?.title);
      }
      formik.setFieldValue("benefits", selectedItem.description.join("\n"));
      formik.setFieldValue(
        "startDate",
        selectedItem?.start_date ? new Date(selectedItem?.start_date) : null
      );
      formik.setFieldValue(
        "endDate",
        selectedItem?.end_date ? new Date(selectedItem?.end_date) : null
      );
      setIsDiscounted(selectedItem?.is_discounted);
      setSelectedPlan(
        plan_types.find((item) => selectedItem?.type === item.value)
      );
      setStatus(
        plan_status.find((item) => selectedItem?.status === item.value)
      );
      if (Number(selectedItem?.type) === 2 && selectedItem?.web_card) {
        setWebCardImage({ preview: selectedItem?.web_card, raw: "" });
        formik.setFieldValue("webCard", selectedItem?.web_card);
      }
      if (Number(selectedItem?.type) === 2 && selectedItem?.mobile_card) {
        setMobileCardImage({ preview: selectedItem?.mobile_card, raw: "" });
        formik.setFieldValue("mobileCard", selectedItem?.mobile_card);
      }
    } else {
      resetPlanForm();
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  const resetPlanForm = () => {
    setSelectedItem(null);
    formik.resetForm();
    setSelectedPlanDuration(monthly_plans[0]);
    setIsDiscounted(false);
    setSelectedPlan(plan_types[0]);
    setStatus(plan_status[1]);
    setIsItem(false);
    handleClose();
    setSelectedSchool(null);
    setWebCardImage({
      raw: "",
      preview: "",
    });
    setMobileCardImage({
      raw: "",
      preview: "",
    });
  };

  const getAllSchoolsList = () => {
    getAllSchoolListService({ search: searchSchool })
      .then(({ status, data }) => {
        if (status) {
          setSchoolList(data?.data?.data);
        }
      })
      .catch((err: any) => {
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (isSchoolTabSelected) {
      getAllSchoolsList();
    }
  }, [isSchoolTabSelected, searchSchool]);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      border: "1px solid",
      borderColor: "#adb5bd",
      borderRadius: "32px !important",
      padding: "10px",
      outline: "none",
    },
  }));

  const handleImage = (type: string) => {
    if (type === "Web") {
      if (WebCardImageRef.current) {
        WebCardImageRef.current.click();
      }
    } else {
      if (MobileCardImageRef.current) {
        MobileCardImageRef.current.click();
      }
    }
  };

  const handleImageChange = (e: any, type: string) => {
    let url = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (type === "Web") {
          setWebCardImage({
            preview: e.target.result as string,
            raw: file,
          });
          formik.setFieldValue("webCard", file);
        } else {
          setMobileCardImage({
            preview: e.target.result as string,
            raw: file,
          });
          formik.setFieldValue("mobileCard", file);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={resetPlanForm}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(110),
              position: "relative",
            },
          },
        }}
      >
        <DialogContent>
          <div className={styles.crossIcon}>
            <Icons.CrossIcon onClick={() => resetPlanForm()} />
          </div>
          <div>
            <div
              className={classNames(
                styles.topLevel,
                styles.planFormModalWrapper
              )}
            >
              <div className={styles.title}>
                For{" "}
                {activeTab === 1
                  ? "Individual"
                  : activeTab === 2
                  ? "Family"
                  : "School"}{" "}
                Account
              </div>
              {!isSchoolTabSelected ? (
                <>
                  {!editOnly && viewOnly ? null : !editOnly ? (
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        sx={{ flexDirection: "row" }}
                      >
                        {plan_types?.map((item, inx) => {
                          return (
                            <div className={styles.radio} key={inx}>
                              <FormControlLabel
                                value={item.value}
                                control={<Radio />}
                                label=""
                                checked={item?.value === selectedPlan.value}
                                onChange={() => setSelectedPlan(item)}
                              />
                              <span>{item?.label}</span>
                            </div>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <div className={styles.radio}>
                      <Radio
                        className={styles.radio1}
                        disabled
                        defaultChecked
                      />

                      {
                        plan_types.find(
                          (item) => item?.value === Number(selectedItem?.type)
                        )?.label
                      }
                    </div>
                  )}
                </>
              ) : null}
            </div>
            {!viewOnly ? <div className={styles.title}>Add details</div> : null}

            {viewOnly ? (
              <div className={styles.tableWrapper}>
                {selectedItem?.type === 2 && (
                  <div className={styles.cardInfoContainer}>
                    {selectedItem?.web_card && (
                      <div
                        className={styles.gridCell}
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>Web Card</span>
                        <img
                          src={selectedItem?.web_card}
                          alt="web_card"
                          className={styles.cardView}
                        />
                      </div>
                    )}
                    {selectedItem?.mobile_card && (
                      <div
                        className={styles.gridCell}
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>Mobile Card</span>
                        <img
                          src={selectedItem?.mobile_card}
                          alt="web_card"
                          className={styles.cardView}
                        />
                      </div>
                    )}
                  </div>
                )}
                {/* <div className={styles.cardInfoContainer}>
                  <div style={{ width: "100%" }}>
                    <img
                      src={selectedItem?.web_card}
                      alt="web_card"
                      className={styles.cardView}
                    />
                  </div>
                  <div style={{ width: "100%" }} className={styles.cardView}>
                    <img
                      src={selectedItem?.mobile_card}
                      alt="web_card"
                      className={styles.cardView}
                    />
                  </div>
                </div> */}
                <div className={styles.twoColumnsGrid}>
                  <div className={styles.gridCell}>
                    <span>Plan Name</span>
                    <span>{selectedItem?.name}</span>
                  </div>
                  <div className={styles.gridCell}>
                    <span>Plan Type</span>
                    <span>
                      {selectedItem?.type === 1
                        ? "Regular"
                        : selectedItem?.type === 2
                        ? "Promotional"
                        : "Invalid"}
                    </span>
                  </div>
                  <div className={styles.gridCell}>
                    {" "}
                    <span>Plan Duration</span>
                    <span>{selectedItem?.duration + " Months"}</span>
                  </div>
                  <div className={styles.gridCell}>
                    <span>Coins</span>
                    <span>{selectedItem?.coins}</span>
                  </div>
                  <div className={styles.gridCell}>
                    <span>Amount (Rs)</span>
                    <span>{selectedItem?.price}</span>
                  </div>

                  <div className={styles.gridCell}>
                    <span>Status</span>
                    <span>
                      {
                        plan_status.find(
                          (plan) => plan.value === selectedItem?.status
                        )?.label
                      }
                    </span>
                  </div>
                  {selectedItem?.is_discounted ? (
                    <>
                      <div className={styles.gridCell}>
                        <span>Discount Title</span>
                        <span>{selectedItem?.title}</span>
                      </div>
                      <div className={styles.gridCell}>
                        <span>Discount Price</span>
                        <span>{selectedItem?.discounted_price}</span>
                      </div>
                      <div className={styles.gridCell}>
                        <span>Discount Start Date</span>
                        <span>{selectedItem?.start_date}</span>
                      </div>
                      <div className={styles.gridCell}>
                        <span>{selectedItem?.end_date}</span>
                      </div>
                    </>
                  ) : null}
                  <div className={classNames(styles.gridCell, styles.lastItem)}>
                    <span>Benefits</span>
                    <span>
                      <ul className={styles.list}>
                        {selectedItem?.description.map(
                          (item: string, idx: number) => {
                            return (
                              <li key={idx}>
                                {idx + 1}-{item}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </span>
                  </div>
                </div>

                {/* <TableContainer
                  component={Paper}
                  sx={
                    screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}
                  }
                  className={styles.table}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={styles.tableHead}>
                        <TableCell>Plan Name</TableCell>
                        <TableCell>Plan Type</TableCell>
                        <TableCell>Plan Duration</TableCell>
                        <TableCell>Coins</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Benefits</TableCell>
                        <TableCell>Status</TableCell>
                        {isDiscounted ? (
                          <>
                            <TableCell>Discount Title</TableCell>
                            <TableCell>Discount Amount</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell></TableCell>
                          </>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={styles.tableRow}>
                        <TableCell>{selectedItem?.name}</TableCell>
                        <TableCell>
                          {selectedItem?.type === 1
                            ? "Regular"
                            : selectedItem?.type === 2
                            ? "Promotional"
                            : "Invalid"}
                        </TableCell>
                        <TableCell>
                          {selectedItem?.duration + " Months"}
                        </TableCell>
                        <TableCell>{selectedItem?.coins}</TableCell>
                        <TableCell>{selectedItem?.price}</TableCell>
                        <TableCell>{selectedItem?.description}</TableCell>
                        <TableCell>status</TableCell>
                        {isDiscounted ? (
                          <>
                            <TableCell>{selectedItem?.title}</TableCell>
                            <TableCell>
                              {selectedItem?.discounted_price}
                            </TableCell>
                            <TableCell>{selectedItem?.start_date}</TableCell>
                            <TableCell>{selectedItem?.end_date}</TableCell>
                            <TableCell></TableCell>
                          </>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  className={styles.addNewMembershipForm}
                  container
                  spacing={2}
                  marginTop={"10px"}
                >
                  <Grid xs={12} md={6}>
                    <div className={styles.titleHead}>Plan duration</div>
                    <FormControl
                      sx={{
                        marginBottom: "1rem",
                        width: "100%",
                      }}
                    >
                      <Select
                        className={styles.selectElement}
                        input={<BootstrapInput />}
                        name="planDuration"
                        renderValue={(selected) => {
                          const plan = monthly_plans.find(
                            (plan) => plan.value === selected
                          );
                          if (selected === 1) {
                            return (
                              <div className={styles.label}>1 Month Plan</div>
                            );
                          }
                          return (
                            <div className={styles.label}>{plan?.label}</div>
                          );
                        }}
                        id="plan-status"
                        disabled={viewOnly}
                        value={selectedPlanDuration?.value || ""}
                        onChange={(e: any) => {
                          setSelectedPlanDuration(
                            monthly_plans.find(
                              (item) => item.value === Number(e.target.value)
                            ) as any
                          );
                        }}
                      >
                        {monthly_plans?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* 
                    <select
                      name="planDuration"
                      id="plan-duration-select"
                      className={styles.selection}
                      value={selectedPlanDuration?.value || ""}
                      onChange={(e: any) => {
                        setSelectedPlanDuration(
                          monthly_plans.find(
                            (item) => item.value === Number(e.target.value)
                          ) as any
                        );
                      }}
                      disabled={viewOnly}
                    >
                      {monthly_plans?.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item?.label}
                        </option>
                      ))}
                    </select> */}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className={styles.titleHead}>Coins</div>
                    <input
                      className={styles.inputField}
                      id="coins"
                      name="coins"
                      type="coins"
                      value={formik.values.coins}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={viewOnly}
                    />
                    {formik.touched.coins && formik.errors.coins ? (
                      <div className={styles.errorText}>
                        {formik.errors.coins}
                      </div>
                    ) : (
                      <div style={{ height: "20px" }}></div>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className={styles.titleHead}>Amount (Rs)</div>
                    <input
                      className={styles.inputField}
                      id="amount"
                      name="amount"
                      type="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={viewOnly}
                    />

                    {formik.touched.amount && formik.errors.amount ? (
                      <div className={styles.errorText}>
                        {formik.errors.amount}
                      </div>
                    ) : formik?.values?.amount && formik?.values?.coins ? (
                      <div className={styles.smallHeading}>
                        {(
                          Number(formik?.values?.amount) /
                          Number(formik?.values?.coins)
                        ).toFixed(2)}{" "}
                        per coin
                      </div>
                    ) : (
                      <div style={{ height: "20px" }}></div>
                    )}
                  </Grid>
                </Grid>

                <div style={{ position: "relative" }}>
                  <div className={styles.titleHead}>Add benefits</div>
                  <textarea
                    className={styles.textarea}
                    id="benefits"
                    name="benefits"
                    value={formik.values.benefits}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={viewOnly}
                  />
                  {formik.touched.benefits && formik.errors.benefits ? (
                    <div
                      className={styles.errorText}
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        right: "10px",
                      }}
                    >
                      {formik.errors.benefits}
                    </div>
                  ) : null}
                </div>
                <Grid className={styles.addNewMembershipForm} container>
                  <Grid item md={6} sm={12}>
                    <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                      <div className={styles.titleHead}>Status</div>
                      <FormControl
                        sx={{
                          marginBottom: "1rem",
                          minWidth: 120,
                          width: "100%",
                        }}
                      >
                        <Select
                          className={styles.selectElement}
                          input={<BootstrapInput />}
                          name="planStatus"
                          renderValue={(selected) => {
                            const label =
                              selected === 0
                                ? "Inactive"
                                : selected === 1
                                ? "Active"
                                : null;

                            return <div className={styles.label}>{label}</div>;
                          }}
                          id="plan-status"
                          disabled={viewOnly}
                          value={status?.value}
                          onChange={(e: any) => {
                            setStatus(
                              plan_status.find(
                                (item) => item.value === Number(e.target.value)
                              ) as any
                            );
                          }}
                        >
                          {plan_status?.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* <select
                        name="planDuration"
                        id="plan-duration-select"
                        className={styles.selection}
                        value={status?.value || ""}
                        onChange={(e: any) => {
                          setStatus(
                            plan_status.find(
                              (item) => item.value === Number(e.target.value)
                            ) as any
                          );
                        }}
                        disabled={viewOnly}
                      >
                        {plan_status?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item?.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </Grid>
                </Grid>
                {isSchoolTabSelected && !editOnly && (
                  <>
                    <div style={{ marginTop: "5px", marginBottom: "10px" }}>
                      <div className={styles.titleHead}>Select School</div>
                      <AutoCompleteUsers
                        user={selectedSchool ? selectedSchool : null}
                        userList={schoolList ? schoolList : []}
                        setSelectedUser={(school: any) => {
                          formik.setFieldValue("school", school);
                          setSelectedSchool(school);
                        }}
                        selectedUser={selectedSchool}
                        searchText={searchSchool}
                        setSearchText={setSearchSchool}
                      />
                    </div>
                    {formik?.touched?.school && formik?.errors?.school && (
                      <span className={styles.errorText}>
                        {String(formik?.errors?.school)}
                      </span>
                    )}
                  </>
                )}
                {!isSchoolTabSelected ? (
                  <>
                    {selectedPlan?.value === 1 && (
                      <div>
                        <Checkbox
                          checked={isDiscounted}
                          className={styles.checkbox}
                          onChange={() => setIsDiscounted(!isDiscounted)}
                          disabled={viewOnly}
                        />{" "}
                        <span className={styles.titleHead}>Add Discount</span>
                      </div>
                    )}
                  </>
                ) : null}

                {isDiscounted && selectedPlan?.value === 1 ? (
                  <Grid container marginTop={"10px"}>
                    <Grid xs={12} md={6}>
                      <div className={styles.titleHead}>Discount title</div>
                      <input
                        id="discountTitle"
                        name="discountTitle"
                        type="discountTitle"
                        value={formik?.values?.discountTitle}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={styles.inputField}
                        disabled={viewOnly}
                      />
                      {formik?.touched?.discountTitle &&
                      formik?.errors?.discountTitle ? (
                        <div className={styles.errorText}>
                          {formik?.errors?.discountTitle}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>
                    <Grid xs={12} md={6}>
                      <div className={styles.titleHead}>Discounted amount</div>
                      <input
                        id="discountAmount"
                        name="discountAmount"
                        type="discountAmount"
                        value={formik?.values?.discountAmount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={styles.inputField}
                        disabled={viewOnly}
                      />
                      {formik?.touched?.discountAmount &&
                      formik?.errors?.discountAmount ? (
                        <div className={styles.errorText}>
                          {formik?.errors?.discountAmount}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>
                    <Grid xs={12} md={6}>
                      <div className={styles.titleHead}>Start Date</div>
                      <DatePicker
                        selected={formik.values.startDate}
                        onChange={(date: Date) => {
                          formik.setFieldValue("startDate", date);
                          formik.setFieldValue("endDate", null);
                        }}
                        minDate={new Date()}
                        className={styles.inputField}
                        readOnly={viewOnly}
                      />
                      {formik.touched.startDate && formik.errors.startDate ? (
                        <div className={styles.errorText}>
                          {formik.errors.startDate}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>
                    <Grid xs={12} md={6}>
                      <div className={styles.titleHead}>End date</div>
                      <DatePicker
                        selected={formik.values.endDate}
                        onChange={(date: Date) => {
                          formik.setFieldValue("endDate", date);
                        }}
                        minDate={formik.values.startDate}
                        className={styles.inputField}
                        readOnly={viewOnly}
                      />
                      {formik.touched.endDate && formik.errors.endDate ? (
                        <div className={styles.errorText}>
                          {formik.errors.endDate}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>
                  </Grid>
                ) : selectedPlan?.value === 2 ? (
                  <>
                    <Grid container marginTop={"20px"}>
                      <Grid xs={12} md={6}>
                        <div className={styles.titleHead}>Promotion title</div>
                        <input
                          id="promotionalTitle"
                          name="promotionalTitle"
                          type="promotionalTitle"
                          value={formik?.values?.promotionalTitle}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={styles.inputField}
                          disabled={viewOnly}
                        />
                        {formik?.touched?.promotionalTitle &&
                        formik?.errors?.promotionalTitle ? (
                          <div className={styles.errorText}>
                            {formik?.errors?.promotionalTitle}
                          </div>
                        ) : (
                          <div style={{ height: "20px" }}></div>
                        )}
                      </Grid>

                      <Grid xs={12} md={6}>
                        <div className={styles.titleHead}>Start date</div>
                        <DatePicker
                          selected={formik.values.startDate}
                          onChange={(date: Date) => {
                            formik.setFieldValue("startDate", date);
                            formik.setFieldValue("endDate", null);
                          }}
                          minDate={new Date()}
                          className={styles.inputField}
                          readOnly={viewOnly}
                        />
                        {formik.touched.startDate && formik.errors.startDate ? (
                          <div className={styles.errorText}>
                            {formik.errors.startDate}
                          </div>
                        ) : (
                          <div style={{ height: "20px" }}></div>
                        )}
                      </Grid>
                      <Grid xs={12} md={6}>
                        <div className={styles.titleHead}>End date</div>
                        <DatePicker
                          selected={formik.values.endDate}
                          onChange={(date: Date) => {
                            formik.setFieldValue("endDate", date);
                          }}
                          minDate={formik.values.startDate}
                          className={styles.inputField}
                          readOnly={viewOnly}
                        />
                        {formik.touched.endDate && formik.errors.endDate ? (
                          <div className={styles.errorText}>
                            {formik.errors.endDate}
                          </div>
                        ) : (
                          <div style={{ height: "20px" }}></div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid xs={12} md={6} paddingRight={"8px"}>
                        <div className={styles.titleHead}>
                          Web {isItem && "(Preview)"}
                        </div>
                        <div className={styles.cardDescription}>
                          Card should adheres to the specified dimensions of
                          1280x375
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageChange(e, "Web")}
                          ref={WebCardImageRef}
                          style={{ display: "none" }}
                        />
                        <div
                          className={styles.uploadImageContainer}
                          onClick={() => handleImage("Web")}
                        >
                          {webCardImage?.preview ? (
                            <img
                              src={webCardImage?.preview}
                              alt="Upload Image"
                              className={styles.uploadedImage}
                            />
                          ) : (
                            <>
                              <Icons.UploadFileIcon />
                              <label>Click to upload Web Card</label>
                            </>
                          )}
                        </div>
                        {formik.touched.webCard && formik.errors.webCard ? (
                          <div className={styles.errorText}>
                            {formik.errors.webCard}
                          </div>
                        ) : (
                          <div style={{ height: "20px" }}></div>
                        )}
                      </Grid>
                      <Grid xs={12} md={6} paddingLeft={"8px"}>
                        <div className={styles.titleHead}>
                          Mobile {isItem && "(Preview)"}
                        </div>
                        <div className={styles.cardDescription}>
                          Card should adheres to the specified dimensions of
                          380x194
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageChange(e, "Mobile")}
                          ref={MobileCardImageRef}
                          style={{ display: "none" }}
                        />
                        <div
                          className={styles.uploadImageContainer}
                          onClick={() => handleImage("Mobile")}
                        >
                          {mobileCardImage?.preview ? (
                            <img
                              src={mobileCardImage?.preview}
                              alt="Upload Image"
                              className={styles.uploadedImage}
                            />
                          ) : (
                            <>
                              <Icons.UploadFileIcon />
                              <label>Click to upload Mobile Card</label>
                            </>
                          )}
                        </div>
                        {formik.touched.mobileCard &&
                        formik.errors.mobileCard ? (
                          <div className={styles.errorText}>
                            {formik.errors.mobileCard}
                          </div>
                        ) : (
                          <div style={{ height: "20px" }}></div>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                {!viewOnly && (
                  <div className={styles.btnContainer}>
                    <CustomButton
                      text="Save"
                      buttonStyle={styles.btnCustom}
                      type={"submit"}
                    />
                    <CustomButton
                      text={"Cancel"}
                      buttonStyle={styles.btnInsideOut}
                      handleClick={() => resetPlanForm()}
                    />
                  </div>
                )}
                {loader && <Loader />}
              </form>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
