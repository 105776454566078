import { Box, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Chart from "shared/components/chart";
import GenreCard from "shared/components/genreCard";
import Cards from "./../featuredBooks/card";
import {
  getGenreStats,
  getHardCopyStats,
  getTopSalesHardCopy,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import { useOnScroll } from "shared/customHooks/useOnScroll";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";

function HardCopy() {
  const [endReach, onScroll] = useOnScroll("topDiv");
  const [genreLists, setGenreList] = useState<any>(null);
  const [genreLoader, setGenreLoader] = useState<boolean>(false);
  const [allBooks, setAllBooks] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number | null>(null);
  const [hardCopyLabel, setHardCopyLabels] = useState<any>(null);
  const [hardCopySales, setHardCopySales] = useState<any>(null);
  const [hardcopyDates, setHardcopyDates] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setHardcopyDates({
      startDate: start,
      endDate: end,
    });
  };

  useEffect(() => {
    if (endReach && currentPage !== lastPage) {
      console.log("Reached end !!");
      getHardCopy(currentPage + 1);
    }
    // eslint-disable-next-line
  }, [endReach, currentPage, lastPage]);

  const getHardCopySales = () => {
    getHardCopyStats(hardcopyDates)
      .then(({ data }) => {
        setHardCopyLabels(data?.data?.labels);
        setHardCopySales(data?.data?.sales);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getMostReadGenres = () => {
    setGenreLoader(true);
    getGenreStats()
      .then(({ data }) => {
        setGenreList(data?.data);
        setGenreLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setGenreLoader(false);
      });
  };

  const getHardCopy = (page: number) => {
    getTopSalesHardCopy()
      .then(({ data }) => {
        setAllBooks((prevBooks: any) => [
          ...(prevBooks || []),
          ...(data?.data?.data || []),
        ]);
        setCurrentPage(page);
        setLastPage(data?.data?.last_page);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getMostReadGenres();
  }, []);

  useEffect(() => {
    getHardCopy(currentPage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getHardCopySales();
    // eslint-disable-next-line
  }, [hardcopyDates?.endDate]);

  useBreadcrumbs({
    title: "Hard Copy",
    path: routeConstant.hardCopy.path,
  });

  return (
    <div onScroll={onScroll} id="topDiv" className={styles.topDiv}>
      <Grid spacing={2} container className={styles.topLevelGrid}>
        <Grid xs={12} md={9} className={styles.chartGrid}>
          <Card sx={{ marginBottom: "40px" }} className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Box className={styles.cardContentWrapper}>
                <div className={styles.title}>Hard Copy Sales figure</div>
                <div className={styles.rangeInputWrapper}>
                  <DatePicker
                    showIcon
                    dateFormat="d MMM"
                    selected={hardcopyDates?.startDate}
                    onChange={(date: any) => onChangeDate(date)}
                    startDate={hardcopyDates?.startDate}
                    endDate={hardcopyDates?.endDate}
                    selectsRange
                    placeholderText="Select range"
                  />
                </div>
              </Box>
              <Chart sales={hardCopySales} labels={hardCopyLabel} />
            </CardContent>
          </Card>{" "}
        </Grid>
        <Grid xs={12} md={3}>
          <div>
            <GenreCard genreLists={genreLists} isLoading={genreLoader} />
          </div>
        </Grid>
      </Grid>

      <div className={styles.title} style={{ marginBottom: "20px" }}>
        Top sales of Book
      </div>
      <Grid container spacing={2}>
        {allBooks?.map((item: any) => {
          return <Cards item={item} />;
        })}
      </Grid>
    </div>
  );
}

export default HardCopy;
