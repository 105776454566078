import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styles from "./style.module.scss";
import { Skeleton } from "@mui/material";

interface CardCompProps {
  Icon?: any;
  stats: any;
  title: string;
  style: any;
  isLoading: boolean;
}

export default function CardComp({
  Icon,
  stats,
  title,
  style,
  isLoading,
}: Partial<CardCompProps>) {
  return (
    <Card className={styles.cardContainer}>
      <CardContent className={`${styles.cardContentContainer} ${style}`}>
        <div className={styles.iconContainer}>
          {Icon && <Icon className={styles.icon} />}
          <label className={styles.label}>{title}</label>
        </div>

        {isLoading ? (
          <Skeleton
            width={50}
            height={70}
            sx={{ position: "absolute", bottom: "0px" }}
          />
        ) : (
          <div className={styles.statsLabel}>{stats}</div>
        )}
      </CardContent>
    </Card>
  );
}
