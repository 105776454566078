import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "../style.module.scss";
import CustomButton from "shared/components/customButton";
import { Icons, Images } from "assets";
import { Box, Button, CardContent, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addBookFeatureService,
  getAllPublisherBooks,
  getAllPublishersService,
  uploadFeatureCoverService,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "../skeletonLoader/publisherLoader";
import moment from "moment";
import CropImage from "shared/modals/cropImage";
import { useFormik } from "formik";
import { dateSchema } from "shared/utils/validationSchemas";
import Loader from "shared/components/loader/animation";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FeatureBooksProps {
  open: boolean;
  handleClose: any;
  getAllFeatureBooks: any;
  selectedItem: any;
  isCoverRequired: boolean;
}

enum Steps {
  SelectPublisher,
  SelectBook,
  Reason,
  Feature,
}

export default function FeatureBooks({
  open,
  handleClose,
  getAllFeatureBooks,
  selectedItem,
  isCoverRequired,
}: FeatureBooksProps) {
  const [selectedBook, setSelectedBook] = useState<any>(null);
  const [resetStep, setResetStep] = useState<Steps>(Steps.SelectPublisher);
  // eslint-disable-next-line
  const [image, setImage] = useState<{ preview: any; raw: File | null }>({
    preview: null,
    raw: null,
  });
  const [imgUrl, setImgURL] = useState<string>("");
  // const [startDate, setStartDate] = useState<any>(null);
  // const [endDate, setEndDate] = useState<any>(null);
  const [allPublishers, setAllPublihsers] = useState<any>(null);
  const [allBooks, setAllBooks] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedPublisher, setSelectedPublisher] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [openCropImage, setOpenCropImage] = useState<boolean>(false);
  // const [croppedImg, setCroppedImg] = useState<any>(null);
  const [croppedURL, setCroppedURL] = useState<any>(null);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const onClose = () => {
    handleClose();
    setAllPublihsers(null);
    setSelectedBook(null);
    setAllBooks(null);
    // setStartDate(null);
    // setEndDate(null);
    setOpenCropImage(false);
    setSelectedPublisher(null);
    setResetStep(Steps.SelectPublisher);
    setSearch("");
    setImage({
      preview: null,
      raw: null,
    });
    // setCroppedImg(null);
    setCroppedURL(null);
    formik.resetForm();
  };

  const handlePrevious = () => {
    switch (resetStep) {
      case Steps.SelectBook:
        setResetStep(Steps.SelectPublisher);
        break;
      case Steps.Feature:
        setResetStep(Steps.SelectBook);
        break;
      default:
        break;
    }
  };

  const handleImageChange = (e: any) => {
    setOpenCropImage(true);

    let url = URL.createObjectURL(e.target.files[0]);
    setImgURL(url);

    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getAllPublishers = () => {
    setLoader(true);
    getAllPublishersService(searchText)
      .then(({ data }) => {
        setAllPublihsers(data?.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getBookByPublisher = () => {
    setLoader(true);
    getAllPublisherBooks(selectedPublisher?.id, searchText)
      .then(({ data }) => {
        setAllBooks(data?.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (resetStep === Steps.SelectPublisher && open) {
      getAllPublishers();
    } else if (resetStep === Steps.SelectBook && open) {
      getBookByPublisher();
    }

    // eslint-disable-next-line
  }, [open, searchText, resetStep]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const handleCloseCropImage = () => {
    setOpenCropImage(false);
    setImage({
      preview: null,
      raw: null,
    });
    setImgURL("");
  };

  const handleImage = (img: any) => {
    setCroppedURL(URL.createObjectURL(img));
    formik.setFieldValue("croppedImg", img);
  };

  const initialValues = {
    startDate: null,
    endDate: null,
    croppedImg: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: dateSchema,
    onSubmit: (values) => {
      if (isCoverRequired) {
        handleUpload(values);
      } else {
        handleSubmit(values);
      }
    },
  });

  const handleSubmit = (values: any) => {
    let resp: any = {
      user_id: selectedPublisher?.id,
      book_id: selectedBook?.id,
      cover_image: values?.croppedImg,
      start_date: moment(values?.startDate).format("YYYY-MM-DD"),
      end_date: moment(values?.endDate).format("YYYY-MM-DD"),
    };

    setSubmitLoader(true);

    let formBody = new FormData();
    Object.keys(resp).forEach((key) => {
      formBody.append(key, resp[key]);
    });

    addBookFeatureService(formBody)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        setSubmitLoader(false);
        onClose();
        getAllFeatureBooks();
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("error", err?.response?.data?.message);
        setSubmitLoader(false);
      });
  };

  const handleUpload = (values: any) => {
    let resp: any = {
      feature_id: selectedItem?.id,
      cover_photo: values?.croppedImg,
    };

    let formBody = new FormData();
    Object.keys(resp).forEach((key) => {
      formBody.append(key, resp[key]);
    });

    setSubmitLoader(true);

    uploadFeatureCoverService(formBody)
      .then(({ data }) => {
        if (data?.statusCode === 400) {
          toastMessage("error", data?.message);
        } else {
          toastMessage("success", data?.message);
        }
        setSubmitLoader(false);
        onClose();
        getAllFeatureBooks();
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("error", err?.response?.data?.message);
        setSubmitLoader(false);
      });
  };

  useEffect(() => {
    if (selectedItem && open) {
      setResetStep(Steps.Feature);
      setSelectedBook(selectedItem?.book);
      setSelectedPublisher(selectedItem?.book?.publisher);
    }

    if (isCoverRequired && selectedItem && open) {
      formik.setFieldValue(
        "startDate",
        selectedItem?.start_date ? selectedItem?.start_date : null
      );

      formik.setFieldValue(
        "endDate",
        selectedItem?.end_date ? selectedItem?.end_date : null
      );
    }

    // eslint-disable-next-line
  }, [open, selectedItem]);
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) =>
                resetStep === Steps.Feature
                  ? theme.spacing(95)
                  : theme.spacing(65),
              position: "relative",
            },
          },
        }}
      >
        <DialogContent className={styles.dialogContentWrapper}>
          {resetStep !== Steps.SelectPublisher && !selectedItem && (
            <Button
              className={styles.prevIcon}
              onClick={() => handlePrevious()}
            >
              <Icons.PrevIcon />
            </Button>
          )}

          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => onClose()}
          />

          {resetStep === Steps.SelectPublisher ? (
            <>
              <div
                className={styles.topTitle}
                style={{ textAlign: "center", margin: "20px 0px" }}
              >
                Select a publisher.
              </div>

              <div className={styles.gridContainerSearch}>
                <Icons.SearchIcon className={styles.searchIcon} />
                <input
                  placeholder="Search"
                  className={styles.input}
                  style={{ marginBottom: "20px", width: "100%" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className={styles.topPublisherContainer}>
                {loader ? (
                  <SkeletonLoader />
                ) : (
                  allPublishers?.map((item: any) => {
                    return (
                      <div
                        className={styles.publisherContainer}
                        onClick={() =>
                          setSelectedPublisher(
                            item.id === selectedPublisher?.id ? null : item
                          )
                        }
                      >
                        <div>
                          <img
                            src={item?.profile_picture}
                            alt="profile-pic"
                            className={styles.profilePic}
                            onError={(e: any) => {
                              e.target.src = Images.UserPlaceholderImage;
                            }}
                          />
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                          <div className={styles.containerHeadings}>
                            {item?.first_name + " " + item?.last_name}
                          </div>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.publishing_logo}
                              alt="logo"
                              className={styles.publisherHouseImg}
                            />
                            <div className={styles.containerSubHeadings}>
                              {item?.publishing_house}
                            </div>
                          </Box>

                          {selectedPublisher?.id === item?.id && (
                            <Icons.FilledCheckedIcon
                              className={styles.checkedIcon}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              {selectedPublisher && (
                <CustomButton
                  text="Next"
                  buttonStyle={styles.btnClose}
                  handleClick={() => setResetStep(Steps.SelectBook)}
                />
              )}
            </>
          ) : resetStep === Steps.SelectBook ? (
            <>
              <div
                className={styles.topTitle}
                style={{ textAlign: "center", margin: "20px 0px" }}
              >
                Select a book from {selectedPublisher?.first_name}.
              </div>
              <div className={styles.gridContainerSearch}>
                <Icons.SearchIcon className={styles.searchIcon} />
                <input
                  placeholder="Search"
                  className={styles.input}
                  style={{ marginBottom: "20px", width: "100%" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <div className={styles.topPublisherContainer}>
                {loader ? (
                  <SkeletonLoader />
                ) : allBooks?.length === 0 ? (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    margin={"30px"}
                  >
                    <Icons.NoDataIcon />
                    <Box className={styles.containerHeadings}>
                      No Books available
                    </Box>
                  </Box>
                ) : (
                  allBooks?.map((item: any) => {
                    return (
                      <div
                        className={styles.publisherContainer}
                        onClick={() =>
                          setSelectedBook(
                            item.id === selectedBook?.id ? null : item
                          )
                        }
                      >
                        <div>
                          <img
                            src={item?.thumbnail}
                            alt="profile-pic"
                            className={styles.profilePic}
                            onError={(e: any) => {
                              e.target.src = Images.UserPlaceholderImage;
                            }}
                          />
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                          <div className={styles.containerHeadings}>
                            {item?.title}
                          </div>
                          <Box display={"flex"} alignItems={"center"}>
                            <div className={styles.containerSubHeadings}>
                              Uploaded on{" "}
                              {moment(item?.created_at).format("D MMM, YYYY")}
                            </div>
                          </Box>

                          {selectedBook?.id === item?.id && (
                            <Icons.FilledCheckedIcon
                              className={styles.checkedIcon}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>

              {selectedBook && (
                <CustomButton
                  text="Next"
                  buttonStyle={styles.btnClose}
                  handleClick={() => setResetStep(Steps.Feature)}
                />
              )}
            </>
          ) : (
            <div>
              <div
                className={styles.topTitle}
                style={{ textAlign: "center", margin: "20px 0px" }}
              >
                Feature book.
              </div>
              <form onSubmit={formik.handleSubmit}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <div
                  className={styles.uploadImageContainer}
                  onClick={handleButtonClick}
                >
                  {!croppedURL ? (
                    <>
                      <div>
                        <CloudUploadIcon />
                      </div>
                      <div className={styles.containerSubHeadings}>
                        Upload Image
                      </div>
                    </>
                  ) : (
                    <img
                      src={croppedURL}
                      alt={"user"}
                      className={styles.uploadedImg}
                    />
                  )}
                </div>
                {formik.touched.croppedImg && formik.errors.croppedImg ? (
                  <div className={styles.errorText}>
                    {formik.errors.croppedImg}
                  </div>
                ) : (
                  <div style={{ height: "30px" }}></div>
                )}
                <CardContent className={styles.cardContent}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <img
                      src={selectedBook?.thumbnail}
                      alt="book-cover"
                      className={styles.bookCover}
                    />
                  </Box>

                  <div className={styles.featureContainer}>
                    <div className={styles.bookTitle}>
                      <div>{selectedBook?.title}</div>
                      <div className={styles.authorWrapper}>
                        <span>by</span>
                        <span> {selectedBook?.book_author?.name}</span>
                      </div>
                    </div>

                    <div>
                      {isCoverRequired ? (
                        <div className={styles.containerSubHeadings}>
                          Duration{" "}
                          {moment(selectedItem?.start_date).format(
                            "D MMMM YYYY "
                          ) +
                            " to " +
                            moment(selectedItem?.end_date).format(
                              "D MMMM YYYY"
                            )}
                        </div>
                      ) : (
                        <>
                          <div
                            className={styles.containerSubHeadings}
                            style={{ margin: "10px 0px" }}
                          >
                            Select start and end date to appear this book in
                            feature list
                          </div>
                          <Grid container>
                            <Grid xs={12} md={6}>
                              <div className={styles.dateTitle}>Start Date</div>
                              <DatePicker
                                placeholderText={"Select Start Date"}
                                dateFormat="dd/MM/yyyy"
                                selected={formik.values.startDate}
                                onChange={(date: Date) => {
                                  formik.setFieldValue("startDate", date);
                                  formik.setFieldValue("endDate", null);
                                }}
                                minDate={new Date()}
                                className={styles.inputField}
                              />
                              {formik.touched.startDate &&
                              formik.errors.startDate ? (
                                <div className={styles.errorText}>
                                  {formik.errors.startDate}
                                </div>
                              ) : (
                                <div style={{ height: "30px" }}></div>
                              )}
                            </Grid>
                            <Grid xs={12} md={6}>
                              <div className={styles.dateTitle}>End Date</div>
                              <DatePicker
                                placeholderText={"Select End Date"}
                                dateFormat="dd/MM/yyyy"
                                selected={formik.values.endDate}
                                onChange={(date: Date) => {
                                  formik.setFieldValue("endDate", date);
                                }}
                                minDate={formik.values.startDate}
                                className={styles.inputField}
                              />
                              {formik.touched.endDate &&
                              formik.errors.endDate ? (
                                <div className={styles.errorText}>
                                  {formik.errors.endDate}
                                </div>
                              ) : (
                                <div style={{ height: "30px" }}></div>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <CustomButton
                        text={isCoverRequired ? "Upload" : "Feature"}
                        buttonStyle={styles.featureButton}
                        type={"submit"}
                      />
                    </div>
                  </div>
                </CardContent>
              </form>
            </div>
          )}
        </DialogContent>
      </Dialog>

      {submitLoader && <Loader />}

      <CropImage
        open={openCropImage}
        handleClose={handleCloseCropImage}
        image={imgUrl}
        setImage={setImage}
        handleImage={handleImage}
      />
    </div>
  );
}
