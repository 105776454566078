import {
  CardContent,
  Card,
  Grid,
  // useMediaQuery,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { Images } from "assets";
import { useNavigate, useParams } from "react-router-dom";
import { getBookDetailsService } from "shared/services/general";
import { toastMessage } from "shared/components/toast";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";

function BookDetail() {
  const navigate = useNavigate();
  // const isMobile = useMediaQuery("(max-width:650px)");
  const { id }: any = useParams();

  const [bookDetail, setBookDetail] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const getBookDetails = () => {
    setLoader(true);
    getBookDetailsService(id)
      .then(({ data }) => {
        setBookDetail(data?.data);
        setLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getBookDetails();
    // eslint-disable-next-line
  }, [id]);

  const BOOK_TYPE = [
    { value: 1, text: "Free" },
    { value: 2, text: "Premium" },
  ];

  useBreadcrumbs(
    {
      title: bookDetail?.title,
      path: routeConstant.bookDetail.path.replace(":id", bookDetail?.id),
    },
    true,
    bookDetail
  );

  return (
    <div>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <div className={styles.upperGrid}>
            <div className={styles.bookTitle}>{bookDetail?.title}</div>
            <CustomButton
              text="Preview"
              buttonStyle={styles.btn}
              handleClick={() =>
                navigate(
                  routeConstant.bookPreview.path
                    .replace(":id", bookDetail?.code)
                    .replace(":slug", bookDetail?.slug)
                )
              }
            />
          </div>

          <div className={styles.mainContainer}>
            <Grid container spacing={2}>
              <Grid xs={12} md={4}>
                <div className={styles.imageContainer}>
                  {loader ? (
                    <Skeleton
                      variant="rectangular"
                      width={"280px"}
                      height={"360px"}
                    />
                  ) : (
                    <img
                      src={
                        bookDetail?.cover_photo
                          ? bookDetail?.cover_photo
                          : Images.BookSample2
                      }
                      alt="book"
                      className={styles.bookImage}
                    />
                  )}
                </div>
              </Grid>
              <Grid xs={12} md={8}>
                <div className={styles.bookInfo}>Book Information</div>

                <Grid
                  container
                  spacing={2}
                  className={styles.gridSingleContainer}
                >
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Age Range</div>
                    <div className={styles.subTitle}>
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : (
                        bookDetail?.age_range?.text
                      )}
                    </div>
                  </Grid>
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Language</div>
                    <div className={styles.subTitle}>
                      {" "}
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : (
                        bookDetail?.language?.name
                      )}
                    </div>
                  </Grid>
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Author Name</div>
                    <div className={styles.subTitle}>
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : (
                        bookDetail?.book_author?.name
                      )}
                    </div>
                  </Grid>
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Printed Copy</div>
                    <div className={styles.subTitle}>
                      {" "}
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : bookDetail?.is_hard_copy ? (
                        "Yes"
                      ) : (
                        "No"
                      )}
                    </div>
                  </Grid>
                  {bookDetail?.is_hard_copy ? (
                    <>
                      {" "}
                      <Grid xs={6} md={3} padding="10px">
                        <div className={styles.title}>Book Price</div>
                        <div className={styles.subTitle}>
                          {loader ? (
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={25}
                            />
                          ) : bookDetail?.price ? (
                            bookDetail?.price + " Rs."
                          ) : (
                            "Not Provided"
                          )}
                        </div>
                      </Grid>
                      <Grid xs={6} md={3} padding="10px">
                        <div className={styles.title}>Book Weight</div>
                        <div className={styles.subTitle}>
                          {loader ? (
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={25}
                            />
                          ) : bookDetail?.weight ? (
                            bookDetail?.weight + " Kg"
                          ) : (
                            "Not Provided"
                          )}
                        </div>
                      </Grid>{" "}
                    </>
                  ) : null}
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Book Type</div>
                    <div className={styles.subTitle}>
                      {" "}
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : (
                        BOOK_TYPE.find(
                          (item: any) => item?.value === bookDetail?.type
                        )?.text
                      )}
                    </div>
                  </Grid>
                  {bookDetail?.type === 2 ? (
                    <>
                      <Grid xs={6} md={3} padding="10px">
                        <div className={styles.title}>Borrow Coins</div>
                        <div className={styles.subTitle}>
                          {" "}
                          {loader ? (
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={25}
                            />
                          ) : bookDetail?.borrow_coins ? (
                            bookDetail?.borrow_coins
                          ) : (
                            "Not Provided"
                          )}
                        </div>
                      </Grid>
                      <Grid xs={6} md={3} padding="10px">
                        <div className={styles.title}>Buy Coins</div>
                        <div className={styles.subTitle}>
                          {loader ? (
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={25}
                            />
                          ) : bookDetail?.buy_coins ? (
                            bookDetail?.buy_coins
                          ) : (
                            "Not Provided"
                          )}
                        </div>
                      </Grid>
                    </>
                  ) : null}
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Quiz</div>
                    <div className={styles.subTitle}>
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : bookDetail?.is_quiz ? (
                        "Yes"
                      ) : (
                        "No"
                      )}
                    </div>
                  </Grid>
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Genres</div>
                    <div className={styles.subTitle}>
                      {" "}
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : bookDetail?.genres?.length ? (
                        bookDetail?.genres?.map(
                          (item: any, inx: any, arr: any) => {
                            return (
                              <div>
                                {item?.name +
                                  (inx !== arr.length - 1 ? "," : ".")}
                              </div>
                            );
                          }
                        )
                      ) : (
                        "Not Provided"
                      )}
                    </div>
                  </Grid>
                  <Grid xs={6} md={3} padding="10px">
                    <div className={styles.title}>Keywords</div>
                    <div className={styles.subTitle}>
                      {" "}
                      {loader ? (
                        <Skeleton
                          variant="rectangular"
                          width={80}
                          height={25}
                        />
                      ) : bookDetail?.tags?.length ? (
                        bookDetail?.tags?.map(
                          (item: any, inx: any, arr: any) => {
                            return (
                              <div>
                                {item?.name +
                                  (inx !== arr.length - 1 ? "," : ".")}
                              </div>
                            );
                          }
                        )
                      ) : (
                        "Not Provided"
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <div style={{ marginTop: "20px" }}>
                <div className={styles.bookInfo}>About this book</div>
                <div
                  className={
                    bookDetail?.language?.name === "English"
                      ? styles.description
                      : styles.descriptionUrdu
                  }
                  style={{ whiteSpace: "pre-line" }}
                >
                  {loader ? (
                    <Skeleton variant="rectangular" width={250} height={25} />
                  ) : bookDetail?.description ? (
                    bookDetail?.description
                  ) : (
                    "Not Provided"
                  )}
                </div>
              </div>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default BookDetail;
