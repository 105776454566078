import { Card, CardContent, Skeleton } from "@mui/material";
import React from "react";
import classNames from "classnames";

import { Icons } from "assets";
import styles from "./style.module.scss";

interface GenreCardProps {
  genreLists?: any;
  isLoading: boolean;
}

function GenreCard({ genreLists, isLoading }: GenreCardProps) {
  return (
    <Card className={styles.card}>
      <CardContent
        sx={{
          height: "auto",
        }}
        className={styles.cardContent}
      >
        <div className={styles.titleHeading}>Most read genre.</div>
        <div style={{ marginTop: "10px" }}>
          {isLoading ? (
            <>
              <Skeleton width={"100%"} height={20} variant="rectangular" />
              <Skeleton width={"100%"} height={20} variant="rectangular" />
            </>
          ) : (
            genreLists?.map((item: any, inx: any) => {
              return (
                <div className={styles.title}>
                  <span className={styles.genreCount}>
                    {inx + 1 < 10 ? `0${inx + 1}` : inx + 1}{" "}
                  </span>
                  <span className={styles.genreTitle}>{item?.name}</span>
                </div>
              );
            })
          )}
        </div>
        {genreLists?.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            className={classNames(styles.noDataCard, styles.noData)}
          >
            <Icons.NoDataAlternateSvg />
            <h5>No Data Found</h5>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default GenreCard;
