import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { TableCell, TableRow } from "@mui/material";

const SkeletonLoader = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((item, index) => {
        return (
          <TableRow key={index}>
            {Array.from({ length: 6 }).map((item, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton variant="rectangular" width={80} height={25} />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export default SkeletonLoader;
