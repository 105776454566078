import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

function SkeletonLoader() {
  return (
    <React.Fragment>
      {Array.from({ length: 8 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="rectangular" width={140} height={25} />
          </TableCell>

          <TableCell>
            <Skeleton variant="rectangular" width={140} height={25} />
          </TableCell>

          <TableCell>
            <Skeleton variant="rectangular" width={140} height={25} />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default SkeletonLoader;
