const planCategories = {
  individual: 1,
  family: 2,
  school: 3
}

const plan_category = [
  {
    label: "Individual",
    value: 1,
  },
  {
    label: "Family",
    value: 2,
  },

  {
    label: "School",
    value: 3,
  },
  {
    label: "Top up balance",
    value: 4,
  },
  // {
  //   label: "School",
  //   value: 4
  // },
];

const plan_types = [
  {
    label: "Regular",
    value: 1,
  },
  {
    label: "Promotional",
    value: 2,
  },
];

const plan_status = [
  {
    label: "Inactive",
    value: 0,
  },
  {
    label: "Active",
    value: 1,
  },
];

const monthly_plans = [
  {
    label: "Monthly plan",
    value: 1,
  },
  {
    label: "2-Months plan",
    value: 2,
  },
  {
    label: "3-Months plan",
    value: 3,
  },
  {
    label: "4-Months plan",
    value: 4,
  },
  {
    label: "5-Months plan",
    value: 5,
  },
  {
    label: "6-Months plan",
    value: 6,
  },
  {
    label: "7-Months plan",
    value: 7,
  },
  {
    label: "8-Months plan",
    value: 8,
  },
  {
    label: "9-Months plan",
    value: 9,
  },
  {
    label: "10-Months plan",
    value: 10,
  },
  {
    label: "11-Months plan",
    value: 11,
  },
  {
    label: "Annual Plan",
    value: 12,
  },
];

export { plan_category, plan_types, plan_status, monthly_plans, planCategories };
