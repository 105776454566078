import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chart.js/auto";
import styles from "./style.module.scss";

interface BarGraphProps {
  labels?: any;
  sales?: any;
}

const BarGraph = ({ labels, sales }: BarGraphProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  const barChartOptions = {
    // responsive: true,
    maintainAspectRatio: false,
    // // aspectRatio: windowWidth > 500 ? 2 : 1,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: "",
      },

      tooltip: {
        displayColors: false,
        borderWidth: 1,
        borderColor: "#9A469B",
        backgroundColor: "white",
        padding: 14,
        footerFont: {
          weight: "normal",
        },

        footerColor: "#696974",
        titleFont: {
          padding: 20,
          size: 15,
          weight: "bold",
        },
        bodyFont: {
          padding: 20,
          size: 15,
          weight: "bold",
        },
        callbacks: {
          title: () => {
            return "";
          },
          labelTextColor: (context: any) => {
            return "black";
          },
          label: (context: any) => {
            return context.formattedValue;
          },
          footer: (context: any) => {
            return context[0].label;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: true,
        },
        grid: {
          display: true,
        },
      },
    },
  };

  const chartLabels = labels ? labels : [];

  const barChartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Price",
        data: sales,
        backgroundColor: "#f6c4f7",
        borderColor: "#9A469B",
        borderWidth: 1,
        // borderRadius: 6,
      },
    ],
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowWidth);
    return () => {
      window.removeEventListener("remove", handleWindowWidth);
    };
  }, [windowWidth]);

  return (
    <div>
      <Bar
        height={windowWidth > 500 ? 400 : 300}
        options={barChartOptions}
        data={barChartData}
        // className={styles.chart}
      />
    </div>
  );
};

export default BarGraph;
