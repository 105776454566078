import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "shared/components/customButton";
import Filter from "shared/components/filterButton";
import ItemsPerPage from "shared/components/itemsPerPage";
import Pagination from "shared/components/pagination";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import useDebounce from "shared/customHooks/useDebounce";
import useWindowSize from "shared/customHooks/windowSize";
import CreateTicket from "shared/modals/createTicket";
import { routeConstant } from "shared/routes/routesConstant";
import { allTicketsService } from "shared/services/adminService";
import { ticketStatus } from "shared/utils/constants";
import { searchHandlerHelper } from "shared/utils/helpers";
import SkeletonLoader from "./skeletonLoader";
import styles from "./style.module.scss";

function Tickets() {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.root);
  const screenWidth = useWindowSize();
  const [allTickets, setAllTickets] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [openTicket, setOpenTicket] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const filterOption = [
    {
      option: "Open",
      value: 1,
    },
    {
      option: "Close",
      value: 2,
    },
  ];

  const getAllTickets = () => {
    setLoader(true);
    allTicketsService(
      searchText,
      selectedFilter?.value,
      activePage,
      itemsPerPage
    )
      .then(({ data }) => {
        setAllTickets(data?.data?.data);
        setLoader(false);
        setTotalPage(data?.data?.last_page);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllTickets();
    // eslint-disable-next-line
  }, [searchText, selectedFilter, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs({
    title: "Tickets",
    path: routeConstant?.tickets?.path,
  });

  const MOU_Status = [
    { label: "Draft", value: 0 },
    { label: "Review", value: 1 },
    { label: "Pending", value: 2 },
    { label: "Accepted", value: 3 },
    { label: "Declined", value: 4 },
  ];

  return (
    // <div style={screenWidth > 990 ? { width: `${screenWidth - 316}px` } : {}}>
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>
              All ticket are showing here.
            </div>
            <div className={styles.tableSubHeading}>
              All messages ticket manage through here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <div>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div>
            <CustomButton
              text="Create Ticket"
              buttonStyle={styles.createBtn}
              handleClick={() => setOpenTicket(true)}
            />
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          style={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Participant Name</TableCell>
                {user?.role?.title === "Admin" ? (
                  <>
                    <TableCell>Category</TableCell>
                    <TableCell>MOU</TableCell>
                  </>
                ) : null}
                {user?.role?.title === "Admin" ? (
                  <TableCell>Title</TableCell>
                ) : null}
                <TableCell>Status</TableCell>
                {/* <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell> */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allTickets?.map((item: any, index: any) => {
                  return (
                    <TableRow className={styles.tableRow} key={index}>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <Avatar
                            src={
                              item?.publisher
                                ? item?.publisher?.profile_picture
                                : item?.author?.profile_picture
                            }
                          />
                          <span
                            className={styles.tableItem}
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.publisher
                              ? item?.publisher?.full_name
                              : item?.author?.full_name}
                          </span>
                        </Box>
                      </TableCell>
                      {user?.role?.title === "Admin" ? (
                        <>
                          <TableCell>
                            {item?.hasOwnProperty("publisher")
                              ? item?.publisher?.publishing_house
                              : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {item?.latest_mou
                              ? MOU_Status.find(
                                  (itm: any) =>
                                    itm.value ===
                                    Number(item?.latest_mou?.status)
                                )?.label
                              : "No agreement yet."}
                          </TableCell>
                        </>
                      ) : null}
                      {user?.role?.title === "Admin" ? (
                        <TableCell>{item?.latest_ticket?.title}</TableCell>
                      ) : null}

                      {user?.role?.title === "Admin" ? (
                        <TableCell>
                          {ticketStatus?.Open === item?.latest_ticket?.status
                            ? "Open"
                            : ticketStatus.Closed ===
                              item?.latest_ticket?.status
                            ? "Closed"
                            : null}
                        </TableCell>
                      ) : null}
                      {/* <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        {item?.publisher
                          ? item?.publisher?.phone
                          : item?.author?.phone}
                      </TableCell> */}
                      <TableCell>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          className={styles.actionBtn}
                        >
                          <Tooltip arrow title="View Detail">
                            <button
                              onClick={() => {
                                navigate(
                                  item?.publisher
                                    ? routeConstant.publisherProfile.path.replace(
                                        ":id",
                                        item?.id
                                      )
                                    : item?.author
                                    ? routeConstant.singleAuthor.path.replace(
                                        ":id",
                                        item?.id
                                      )
                                    : ""
                                );
                              }}
                            >
                              <RemoveRedEyeIcon />
                            </button>
                          </Tooltip>

                          <Tooltip arrow title="Ticket">
                            <button
                              onClick={() => {
                                navigate(
                                  routeConstant.singleTicket.path.replace(
                                    ":id",
                                    item?.id
                                  ),
                                  {
                                    state: {
                                      user: item,
                                    },
                                  }
                                );
                              }}
                            >
                              <ChatBubbleIcon />
                            </button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!allTickets?.length && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {allTickets.length > 0 && totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allTickets?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
      <CreateTicket
        getAllTickets={getAllTickets}
        open={openTicket}
        handleClose={() => setOpenTicket(false)}
      />
    </div>
  );
}

export default Tickets;
