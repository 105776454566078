import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Editor from "../../shared/components/Editor";

import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import styles from "./style.module.scss";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";

import {
  getPolicyDataAPI,
  updatePolicyDataAPI,
} from "shared/services/productManagerService";

const TermsAndPolicy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [textContent, setTextContent] = useState<string>("");
  const [current, setCurrent] = useState<any>(null);

  const enums = [
    {
      value: 1,
      path: routeConstant?.privacyPolicy?.path,
      title: "Privacy Policy",
      type: 1,
    },
    {
      value: 2,
      path: routeConstant?.termsAndCondition?.path,
      title: "Terms and Conditons",
      type: 2,
    },
  ];

  useEffect(() => {
    const current = enums.filter(
      (item: any) => item?.path === location?.pathname
    )[0];
    setCurrent(current);
  }, []);

  useBreadcrumbs(
    {
      title: current?.title,
      path: current?.path,
    },
    true,
    current?.path
  );

  return (
    <Box>
      <div className={styles.btnWrapper}></div>
      <div className={styles.heading}>
        <h2 style={{ marginTop: "0px" }}>{current?.title}</h2>
      </div>
      <div>
        <Editor
          text={textContent}
          setText={setTextContent}
          getAPI={getPolicyDataAPI}
          updateAPI={updatePolicyDataAPI}
          current={current}
        />
      </div>
    </Box>
  );
};

export default TermsAndPolicy;
