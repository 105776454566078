import { Box, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Chart from "shared/components/chart";
import GenreCard from "shared/components/genreCard";
import Cards from "./../featuredBooks/card";
import {
  getAllGraphsData,
  getGenreStats,
  getSoftCopyStats,
  getTopSalesSoftCopy,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import { useOnScroll } from "shared/customHooks/useOnScroll";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import { Line } from "react-chartjs-2";
import classNames from "classnames";

const lineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "",
    },

    tooltip: {
      displayColors: false,
      backgroundColor: "rgb(15, 17, 6,0.8)",
      padding: 14,
      footerFont: {
        weight: "normal",
      },
      footerColor: "white",
      titleFont: {
        padding: 20,
        size: 15,
        weight: "bold",
      },
      bodyFont: {
        padding: 20,
        size: 15,
        weight: "bold",
      },
      callbacks: {
        title: () => {
          return "";
        },
        labelTextColor: (context: any) => {
          return "white";
        },
        label: (context: any) => {
          return context.formattedValue + " Sales";
        },
        footer: (context: any) => {
          return context[0].label;
        },
      },
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
      },
    },
    y: {
      ticks: {
        display: true,
      },
      grid: {
        display: false,
      },
    },
  },
};

function SoftCopy() {
  const [chart, setChart] = useState<any>(null);
  const [endReach, onScroll] = useOnScroll("topDiv");
  const [softCopyLabel, setSoftCopyLabels] = useState<any>(null);
  const [softCopySales, setSoftCopySales] = useState<any>(null);
  const [genreLists, setGenreList] = useState<any>(null);
  const [genreLoader, setGenreLoader] = useState<boolean>(false);
  const [allBooks, setAllBooks] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number | null>(null);
  const [softCopyDates, setSoftCopyDates] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setSoftCopyDates({
      startDate: start,
      endDate: end,
    });
  };

  useEffect(() => {
    if (endReach && currentPage !== lastPage) {
      console.log("Reached end !!");
      getSoftCopy(currentPage + 1);
    }
    // eslint-disable-next-line
  }, [endReach, currentPage, lastPage]);

  const getSoftCopySales = () => {
    getSoftCopyStats(softCopyDates)
      .then(({ data }: any) => {
        setSoftCopyLabels(data?.data?.labels);
        setSoftCopySales(data?.data?.sales?.total_sales);
      })
      .catch((err: any) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getMostReadGenres = () => {
    setGenreLoader(true);
    getGenreStats()
      .then(({ data }) => {
        setGenreList(data?.data);
        setGenreLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setGenreLoader(false);
      });
  };

  const getSoftCopy = (page: number) => {
    getTopSalesSoftCopy(1)
      .then(({ data }) => {
        setAllBooks((prevBooks: any) => [
          ...(prevBooks || []),
          ...(data?.data?.data || []),
        ]);
        setCurrentPage(page);
        setLastPage(data?.data?.last_page);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getMostReadGenres();
  }, []);

  useEffect(() => {
    getSoftCopy(currentPage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSoftCopySales();
    // eslint-disable-next-line
  }, [softCopyDates?.endDate]);

  useBreadcrumbs({
    title: "Soft Copy",
    path: routeConstant.softCopy.path,
  });

  // const getAllGraphs = () => {
  //   getAllGraphsData(softCopyDates)
  //     .then(({ data }) => {
  //       setChart(data?.data);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  // useEffect(() => {
  //   getAllGraphs();
  //   // eslint-disable-next-line
  // }, [softCopyDates]);

  console.log("chart", chart);

  return (
    <div onScroll={onScroll} id="topDiv" className={styles.topDiv}>
      <Grid spacing={2} container className={styles.topLevelGrid}>
        <Grid xs={12} md={9} className={styles.chartGrid}>
          <Card sx={{ marginBottom: "40px" }} className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Box className={styles.cardContentWrapper}>
                <div className={styles.title}>Sales figure</div>
                <div className={styles.rangeInputWrapper}>
                  <DatePicker
                    // showIcon
                    selected={softCopyDates?.startDate}
                    onChange={(date: any) => onChangeDate(date)}
                    startDate={softCopyDates?.startDate}
                    endDate={softCopyDates?.endDate}
                    selectsRange
                    dateFormat="d MMM"
                    placeholderText="Select range"
                  />
                </div>
              </Box>
              {/* <Chart sales={softCopySales} labels={softCopyLabel} /> */}

              <Line
                options={lineChartOptions}
                data={{
                  labels: chart?.labels,
                  datasets: [
                    {
                      label: "Dataset 2",
                      data: chart?.sales?.soft_copy_sales?.total_sales,
                      borderColor: "#EF437B",
                      borderWidth: 2,
                      backgroundColor: "white",
                      pointStyle: "circle",
                      pointRadius: 5.5,
                      pointHoverRadius: 8,
                    },
                    {
                      label: "Dataset 3",
                      data: chart?.sales?.hard_copy_sales,
                      borderColor: "#1897A6",
                      borderWidth: 2,
                      backgroundColor: "white",
                      pointStyle: "circle",
                      pointRadius: 5.5,
                      pointHoverRadius: 8,
                    },
                  ],
                }}
              />
            </CardContent>
          </Card>{" "}
        </Grid>
        <Grid xs={12} md={3}>
          <div>
            <GenreCard genreLists={genreLists} isLoading={genreLoader} />
          </div>
        </Grid>
      </Grid>

      <div className={styles.title} style={{ marginBottom: "20px" }}>
        Top sales of Book
      </div>
      <Grid container spacing={2}>
        {allBooks?.map((item: any) => {
          return <Cards item={item} />;
        })}
      </Grid>
    </div>
  );
}

export default SoftCopy;
