import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "../style.module.scss";
// import { Avatar } from "@mui/material";
import { Icons } from "assets";
import SkeletonLoader from "../skeletonLoader";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import { toastMessage } from "shared/components/toast";
import { addCommentService } from "shared/services/adminService";
// import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { addMessageByProductManager } from "shared/services/productManagerService";

interface ConversationProps {
  activeTicket: any;
  loader: boolean;
  activeConversation: any;
  setActiveConversation: any;
  handleCloseTicket: () => void;
}

function Conversation({
  activeTicket,
  loader,
  activeConversation,
  setActiveConversation,
  handleCloseTicket,
}: ConversationProps) {
  const [files, setFiles] = useState<any>([]);
  const messageContainerRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [typedMsg, setTypedMsg] = useState<any>("");
  const {
    user: { user },
  } = useSelector((state: any) => state.root);

  // const PROXY_URL = "https://proxy.codingpixel.org/";
  const PROXY_URL = "https://cors-proxy-production-761e.up.railway.app/";
  const handleSendMsg = () => {
    if (!typedMsg.trim() && files.length === 0) {
      toastMessage("error", "Please type in something to send");
      setTypedMsg("");
      return;
    }

    if (typedMsg.trim()) {
      handleAddComment({
        ticket_id: activeTicket?.id,
        comment: typedMsg,
        type: 1,
      });
    }

    files.forEach((item: any) => {
      handleAddComment({
        ticket_id: activeTicket?.id,
        attachment: item.file,
        type: item.file.type.includes("image") ? 2 : 3,
      });
    });

    setTypedMsg("");
    setFiles([]);
  };

  const handleAddComment = (resp: any) => {
    // 4 for Product manager and 1 for Admin
    let service =
      user.role === "4" ? addMessageByProductManager : addCommentService;
    service(resp)
      .then(({ data }) => {
        setActiveConversation((prevConv: any) => [...prevConv, data?.data]);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  const handleFileInputChange = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      const selectedFiles = fileInputRef.current.files;
      let temp = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const id = Math.floor(Math.random() * (10000 - 1)) + 1;
        temp.push({ id: id, file });
      }
      let selectedfiles = [...files, ...temp];
      setFiles(selectedfiles);

      fileInputRef.current.value = "";
    }
  };

  const handleDeletePicture = (item: any) => {
    let filterArr = [...files]?.filter((i) => {
      return i?.id !== item?.id;
    });
    setFiles(filterArr);
  };

  const handleKeyDown = (e: any) => {
    if (e?.key === "Enter") {
      handleSendMsg();
      e.preventDefault();
    }
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [activeConversation]);

  const handleDownload = async (fileUrl: any) => {
    fetch(PROXY_URL + fileUrl, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "attachment";
        link.click();
      })
      .catch(console.error);
    // const link = document.createElement("a");
    // link.download = "downloaded_file_name";
    // link.href = fileUrl;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };
  return (
    <div className={styles.chatContainer}>
      <div className={styles.chatHead}>
        <div className={`${styles.gridContain} ${styles.chatTextContainer}`}>
          <div style={{ marginLeft: "15px" }}>
            <div className={styles.chatHeading}>{activeTicket?.title}</div>
            <div className={styles.chatSubHeading}>
              {activeTicket?.status === 1 ? "Active" : "Closed"}
            </div>
          </div>
        </div>

        {activeTicket?.status === 1 && (
          <button className={styles.completeBtn} onClick={handleCloseTicket}>
            <Icons.CheckedIcon2 />
            <span>Mark as complete</span>
          </button>
        )}
      </div>
      <div
        style={{ height: activeTicket?.status === 2 ? "auto" : "35vh" }}
        className={styles.msgContainer}
        ref={messageContainerRef}
      >
        {loader ? (
          <SkeletonLoader />
        ) : (
          activeConversation?.map((item: any) => {
            return (
              <>
                {item?.comment ? (
                  <div className={item?.admin ? styles.endGrid : ""}>
                    <div
                      className={item?.admin ? styles.sent : styles.received}
                    >
                      {item?.comment
                        .split(/\r\n|\r|\n/)
                        .map((item: any, index: any) => {
                          return (
                            <Fragment key={index}>
                              {item}
                              <br />
                            </Fragment>
                          );
                        })}
                      <div className={styles.dateLabel}>
                        {moment(item?.created_at).format("D MMMM YY, h:mm a")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={item?.admin ? styles.endGrid : ""}>
                    <div className={styles.fileMsg}>
                      {(() => {
                        const fileExtension = item?.attachment
                          ?.split(".")
                          .pop()
                          ?.toLowerCase();

                        const isJPG = ["jpeg", "jpg"].includes(fileExtension);

                        const isPDF = fileExtension === "pdf";

                        const iconComponent = isJPG ? (
                          <Icons.JPGIcon />
                        ) : isPDF ? (
                          <Icons.PDFIcon />
                        ) : (
                          <Icons.PNGIcon />
                        );

                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignContent: "center",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDownload(item?.attachment)}
                            >
                              <span style={{ marginRight: "10px" }}>
                                {iconComponent}
                              </span>
                              <span>{"Attachment"}</span>
                              <Icons.DownloadIcon
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                }}
                              />
                            </div>
                            <div className={styles.dateLabel}>
                              {moment(item?.created_at).format(
                                "D MMMM YY, h:mm a"
                              )}
                            </div>
                          </>
                        );
                      })()}
                    </div>
                  </div>
                )}
              </>
            );
          })
        )}
      </div>
      {activeTicket?.status === 1 && (
        <div className={styles.chatActionContainer}>
          <div className={styles.filesContainer}>
            {files?.map((item: any, inx: any) => {
              return (
                <div className={styles.fileContainer}>
                  <div>
                    {item?.file?.type?.split("/")[1] === "png" ? (
                      <Icons.PNGIcon />
                    ) : item?.file?.type?.split("/")[1] === "jpeg" ? (
                      <Icons.JPGIcon />
                    ) : (
                      <Icons.PDFIcon />
                    )}
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    {/* <div className={styles.fileName}>{item?.name}</div> */}
                    <div className={styles.fileName}>
                      {item?.file?.name?.substring(0, 15)}
                    </div>
                    <div className={styles.fileDetail}>
                      {item?.file?.size > 1048576
                        ? `${(item?.file?.size / 1048576).toFixed(0)} Mbs`
                        : `${(item?.file?.size / 1024).toFixed(0)} Kbs`}
                    </div>
                  </div>
                  <CancelIcon
                    className={styles.cancelIcon}
                    onClick={() => handleDeletePicture(item)}
                  />
                </div>
              );
            })}
          </div>
          <textarea
            className={styles.textArea}
            value={typedMsg}
            onKeyDown={handleKeyDown}
            onChange={(e: any) => {
              setTypedMsg(e.target.value);
            }}
          />
          <div className={styles.attachmentContainer}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .pdf"
              multiple
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
            <Icons.AttachmentIcon
              className={styles.attachmentIcon}
              onClick={() => fileInputRef.current?.click()}
            />
            <Icons.SendIcon
              className={styles.sendIcon}
              onClick={() => handleSendMsg()}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Conversation;
