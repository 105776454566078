import React from "react";
import styles from "./style.module.scss";

const SkeletonLoader = () => {
  return (
    <div className={styles.border}>
      <div className={styles.wrapper}>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.rectangle}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
        <div className={styles.box}></div>
      </div>
      <div className={styles.contentWappper}>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
        <div className={styles.content}></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
