import { Icons } from "assets";

const user_roles = {
  school: 1,
  teacher: 2,
  parent: 3,
  individual_reader: 4,
  author: 5,
  publisher: 6,
  family_kid: 7,
  student: 8,
};

const roles = [
  {
    id: 3,
    label: "Parent",
    selected: false,
    icon: Icons.RoleIconParents,
  },
  {
    id: 4,
    label: "Individual",
    selected: false,
    icon: Icons.RoleIconKid,
  },
  {
    id: 2,
    label: "Teacher",
    selected: false,
    icon: Icons.RoleIconTeacher,
  },
  {
    id: 8,
    label: "Students",
    selected: false,
    icon: Icons.RoleIconStudents,
  },
  {
    id: 7,
    label: "Family Kid",
    selected: false,
    icon: Icons.RoleIconFamilyKid,
  },
];

const types = [
  {
    label: "Email Notification",
    selected: false,
    id: 3,
  },
  {
    label: "In App Notification",
    selected: false,
    id: 1,
  },
  {
    label: "Push Notification",
    selected: false,
    id: 2,
  },
];

const typesEnums = {
  inApp: 1,
  push: 2,
  email: 3,
};

const directions = [
  {
    label: "All Plans",
    selected: true,
    id: 1,
    param: "plans",
  },
  {
    label: "Author",
    selected: false,
    id: 2,
    param: "authors",
  },
  {
    label: "Publisher",
    selected: false,
    id: 3,
    param: "publishers",
  },
  {
    label: "Book",
    selected: false,
    id: 4,
    param: "books",
  },
];

const directionsEnums = {
  plans: 1,
  authors: 2,
  publishers: 3,
  books: 4,
};

export { user_roles, roles, types, directions, directionsEnums, typesEnums };
