import React from "react";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import classNames from "classnames";

function BreadCrumbs() {
  const { breadCrumbs } = useSelector((state: any) => state.root);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.topLevelContainer}>
      <div className={styles.center}>
        {breadCrumbs.crumbs.map((item: any, inx: any, arr: any) => {
          return (
            <React.Fragment key={inx}>
              {
                <div
                  className={classNames(
                    styles.center,
                    location?.pathname === item?.path
                      ? styles.ActiveContainer
                      : styles.container
                  )}
                  onClick={() => navigate(item?.path)}
                >
                  <label className={styles.label}>{item?.title}</label>
                  {arr?.length - 1 !== inx && (
                    <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                  )}
                </div>
              }
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default BreadCrumbs;
