import { Avatar, Box } from "@mui/material";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./style.module.scss";
import { Icons, Images } from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "shared/components/pagination";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Filter from "shared/components/filterButton";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Confirmation from "shared/modals/confirmation";

function Dashboard() {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  // const [totalPage, setTotalPage] = useState<number>(10);

  const filterOption = [
    {
      option: "Most Books",
    },
  ];

  const handleClick = () => {
    setOpenDelete(false);
  };

  return (
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Recent chats</div>
            <div className={styles.tableSubHeading}>
              Manage your recently added tickets from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input placeholder="Search" className={styles.input} />
            </div>
            <div>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
              />
            </div>
          </Box>
        </Box>
        <TableContainer component={Paper} className={styles.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Ticket title</TableCell>
                <TableCell>Participant name</TableCell>
                <TableCell>MOU</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell>Penguin Random House</TableCell>
              <TableCell>
                <Box display={"flex"} alignItems={"center"}>
                  <Avatar alt="Jackob Man" src={Images.Sample} />
                  <span
                    className={styles.tableItem}
                    style={{ marginLeft: "10px" }}
                  >
                    Aaliyah Omar
                  </span>
                </Box>
              </TableCell>
              <TableCell>Signed</TableCell>
              <TableCell>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  className={styles.actionBtn}
                >
                  <button
                    onClick={() =>
                      navigate(
                        routeConstant.publisherProfile.path.replace(":id", "1")
                      )
                    }
                  >
                    <RemoveRedEyeIcon />
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        routeConstant.singleTicket.path.replace(":id", "1")
                      )
                    }
                  >
                    <ChatBubbleIcon />
                  </button>
                </Box>
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          totalPages={10}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </Box>
      <Confirmation
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleClick={handleClick}
        text="Are you sure, you want to delete this publisher?"
      />
    </div>
  );
}

export default Dashboard;
