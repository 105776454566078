import { Card, CardContent, Skeleton, Box } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

import classNames from "classnames";

import { Icons } from "assets";

import styles from "./style.module.scss";

interface ReadBooksCardProps {
  booksLists: any;
  isLoading: boolean;
  setBooksFilter: Dispatch<SetStateAction<string>>;
  booksFilter: string;
}

function ReadBooksCard({
  booksLists,
  isLoading,
  setBooksFilter,
  booksFilter,
}: ReadBooksCardProps) {
  return (
    <Card className={styles.card}>
      <CardContent>
        <div className={styles.headingWrapper}>
          <p className={styles.titleHeading}>
            Most{" "}
            {booksFilter === "most_read"
              ? "read books"
              : booksFilter === "most_popular"
              ? "popular books"
              : booksFilter === "highly_rated"
              ? "highly rated books"
              : ""}
          </p>
          <select
            className={styles.selectFilter}
            onChange={(e) => {
              setBooksFilter(e.target.value);
            }}
          >
            <option defaultValue={"most_read"} value={"most_read"}>
              Most Read
            </option>

            <option value="most_popular">Most Popular</option>
            <option value="highly_rated">Highly Rated</option>
          </select>
        </div>
        {isLoading ? (
          <>
            {Array.from({ length: 4 }).map((skeleton) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <div>
                    <Skeleton
                      variant="rounded"
                      width={70}
                      height={70}
                      // sx={{ marginBottom: "5px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={120}
                      height={15}
                      sx={{ marginBottom: "10px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={80}
                      height={10}
                      // sx={{ marginBottom: "5px" }}
                    />
                  </div>
                </Box>
              );
            })}
          </>
        ) : (
          booksLists?.map((item: any) => {
            return (
              <div className={styles.singleContainer}>
                <div className={styles.thumbnailWrapper}>
                  <img
                    src={item?.thumbnail}
                    alt="book-cover"
                    className={styles.bookCover}
                  />
                </div>
                <div className={styles.details}>
                  <div className={`${styles.title} ${styles.titleLabel}`}>
                    {item?.title}
                  </div>
                  <div className={`${styles.title} ${styles.count}`}>
                    {booksFilter === "most_read"
                      ? `Read ${item?.count} times`
                      : booksFilter === "most_popular"
                      ? `Viewed ${item?.count} times `
                      : booksFilter === "highly_rated"
                      ? `${item?.count} star ratings`
                      : ""}
                  </div>
                </div>
              </div>
            );
          })
        )}
        {booksLists?.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className={classNames(styles.noDataCard, styles.noData)}
          >
            <Icons.NoDataAlternateSvg />
            <h5>No Data Found</h5>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default ReadBooksCard;
