import React from "react";
import styles from "../style.module.scss";
import {
  CardContent,
  Grid,
  Card,
  Checkbox,
  checkboxClasses,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";

interface CardProps {
  item: any;
  handleFeatureBook?: (name: any) => void;
  setChangesMade?: (value: boolean) => void;
  handleDelete?: any;
  bestSellers?: boolean;
}

function SingleCard({
  item,
  handleFeatureBook,
  setChangesMade,
  handleDelete,
  bestSellers,
}: Partial<CardProps>) {
  const navigate = useNavigate();
  const handleCheckboxChange = () => {
    handleFeatureBook && handleFeatureBook(item?.name);
    setChangesMade && setChangesMade(true);
  };

  return (
    <Grid
      md={12}
      lg={6}
      xl={6}
      xs={12}
      padding={"10px"}
      sx={{ boxSizing: "border-box" }}
    >
      <Card className={bestSellers ? styles.cardWidth : styles.card}>
        {handleDelete && (
          <div
            className={styles.icon}
            onClick={() =>
              handleDelete(item, "are you sure, you want to delete this book?")
            }
          >
            <DeleteOutlineIcon sx={{ color: "red" }} />
          </div>
        )}
        <CardContent
          className={bestSellers ? styles.bestSellersCard : styles.cardContent}
        >
          <div className={bestSellers ? styles.imageWrapper : ""}>
            <img
              src={item?.thumbnail}
              alt="book-cover"
              className={styles.bookCover}
            />
          </div>

          <div className={styles.headings}>
            <div className={styles.titleWrapper}>
              <span className={styles.titleSpan}>Name: </span>
              {item?.title}
            </div>
            <div className={styles.copiesContainer}>
              {Number(item?.sales?.soft_copy.total) > 0 ? (
                <div>
                  <div className={styles.copyTitle}>Soft Copy(s) Sold</div>
                  <div style={{ color: "#ef437b", fontSize: "14px" }}>
                    {Number(item.sales?.soft_copy.total) > 1000
                      ? (item?.softCopy / 1000).toFixed(2) + "K"
                      : Number(item.sales?.soft_copy.total)}
                  </div>
                </div>
              ) : null}
              {Number(item?.sales?.hard_copy) > 0 ? (
                <div>
                  <div className={styles.copyTitle}>Hard Copy(s) Sold</div>
                  <div style={{ color: "#ef437b", fontSize: "14px" }}>
                    {Number(item?.sales?.hard_copy) > 1000
                      ? (item?.hardCopy / 1000).toFixed(2) + "K"
                      : Number(item?.sales?.hard_copy)}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.categoryWrapper}>
              <span className={styles.categoryTitle}>Catagory:</span>
              <span className={styles.categoryContainer}>
                {item?.genre?.map((itm: any, inx: number, arr: any) => {
                  return (
                    <span
                      className={styles.category}
                      style={{ marginLeft: "4px" }}
                    >
                      {" "}
                      {itm?.name}{" "}
                    </span>
                  );
                })}
              </span>
            </div>
            {bestSellers ? (
              <div
                className={styles.viewDeatilsBtn}
                onClick={() =>
                  navigate(
                    routeConstant.bookDetail.path.replace(":id", item?.id)
                  )
                }
              >
                <span>View Details</span>
                <span>
                  &#8594;
                  {/* <ArrowForwardIcon></ArrowForwardIcon> */}
                  {/* + (inx !== arr.length - 1 ? "," : ".") */}
                </span>
              </div>
            ) : null}
            {handleFeatureBook && (
              <div>
                <Checkbox
                  className={styles.checkBox}
                  sx={{
                    [`&, &.${checkboxClasses.checked}`]: {
                      color: "#9A469B",
                      padding: "0px",
                    },
                  }}
                  checked={item.isFeatured}
                  onChange={handleCheckboxChange}
                />
                <span style={{ marginLeft: "5px" }}>
                  {item?.isFeatured
                    ? "Marked as featured"
                    : "Marked as unfeatured"}
                </span>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default SingleCard;
