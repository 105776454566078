import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./style.module.scss";
import { Icons } from "assets";
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PreviewIcon from "@mui/icons-material/Preview";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CancelIcon from "@mui/icons-material/Cancel";
import Pagination from "shared/components/pagination";
// import Filter from "shared/components/filterButton";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import useWindowSize from "shared/customHooks/windowSize";
import moment from "moment";
import RejectBook from "shared/modals/rejectBook";
import Confirmation from "shared/modals/confirmation";
// import RateReviewIcon from "@mui/icons-material/RateReview";
// import GradingIcon from "@mui/icons-material/Grading";
import Revision from "shared/modals/revision";
import AssignReviewer from "shared/modals/assignReviewer";
import { useSelector } from "react-redux";
import { getBookReviewersBooksService } from "shared/services/bookReviewerService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import Filter from "shared/components/filterButton";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";
const filterOption = [
  {
    option: "Pending",
    value: 7,
  },
  {
    option: "Completed",
    value: 8,
  },
];

function BooksListing() {
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openReview, setOpenReview] = useState<boolean>(false);
  const [openRevision, setOpenRevision] = useState<boolean>(false);
  const [selectedBook, setSelectedBook] = useState<any>(null);
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<number>(1);
  const screenWidth = useWindowSize();
  const { user } = useSelector((state: any) => state.root);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [allBooks, setAllBooks] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<any>(filterOption[1]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const handleRejectBook = () => {
    setOpenReject(false);
    setSelectedBook(null);
  };

  const BOOK_STATUS = [
    { id: 0, label: "Pending" },
    { id: 1, label: "Admin Review" },
    { id: 2, label: "Revision Requested" },
    { id: 3, label: "Revision Submitted" },
    { id: 4, label: "Approved" },
    { id: 5, label: "Published" },
    { id: 6, label: "Rejected" },
    { id: 7, label: "Internal Review" },
    { id: 8, label: "Review Completed" },
  ];

  // const handleConfirmBook = (item: any) => {
  //   setOpenConfirm(true);
  //   setSelectedBook(item);
  // };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setSelectedBook(null);
  };

  // const handleOpenRejectBook = (item: any) => {
  //   setOpenReject(true);
  //   setSelectedBook(item);
  // };

  // const handleReviewOpen = (item: any) => {
  //   setOpenReview(true);
  //   setSelectedBook(item);
  // };

  const handleReviewClose = () => {
    setOpenReview(false);
    setSelectedBook(null);
  };

  // const handleRevisionOpen = (item: any) => {
  //   setOpenRevision(true);
  //   setSelectedBook(item);
  // };

  const handleCloseRevision = () => {
    setOpenRevision(false);
    setSelectedBook(null);
  };

  const getAllBooks = () => {
    setLoader(true);
    getBookReviewersBooksService(
      searchText,
      selectedFilter?.value,
      activePage,
      itemsPerPage
    )
      .then(({ data }) => {
        setLoader(false);
        setTotalPage(data?.data?.last_page);
        setAllBooks(data?.data?.data);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllBooks();
    // eslint-disable-next-line
  }, [searchText, selectedFilter, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs({
    title: "Books",
    path: routeConstant.books.path,
  });

  return (
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Books</div>
            <div className={styles.tableSubHeading}>
              List of all books that need approval.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <div>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book name</TableCell>
                {user?.role.title === "Book Reviewer" ? (
                  <>
                    <TableCell>Author Name</TableCell>
                    <TableCell>Genre</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Language</TableCell>
                  </>
                ) : null}
                {/* <TableCell>Reviewed By</TableCell> */}

                {/* 
               
                <TableCell>Age range</TableCell>
                <TableCell>Publisher name</TableCell> */}
                <TableCell>Assigned Date</TableCell>
                <TableCell>Book Review Start Time</TableCell>
                <TableCell>Book Review End Time</TableCell>
                {/* <TableCell>Payment</TableCell> */}
                {/* <TableCell>Reviewed by</TableCell> */}
                {user?.role.title === "Book Reviewer" ? (
                  <>
                    <TableCell>Status</TableCell>
                  </>
                ) : null}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader rectangularLoader={8} actionLoader={2} />
              ) : (
                allBooks?.map((item: any) => {
                  const genreNames = item?.book?.genres.map((genre: any) => {
                    return `${genre.name}, `;
                  });
                  const genreString = genreNames.join(" ").slice(0, -1);
                  const genreStringLength = genreString.length;
                  const genre = genreString.substring(0, genreStringLength - 1);

                  const bookStatus = BOOK_STATUS?.find((status: any) => {
                    return status.id === item?.book?.status;
                  });
                  return (
                    <TableRow className={styles.tableRow}>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <img
                            src={item?.book?.thumbnail}
                            alt="book cover"
                            className={styles.bookCover}
                          />
                          <span style={{ marginLeft: "10px" }}>
                            {item?.book?.title}
                          </span>
                        </Box>
                      </TableCell>
                      {user?.role?.title === "Book Reviewer" ? (
                        <>
                          <TableCell>{item?.book?.book_author?.name}</TableCell>
                          <TableCell>
                            {item?.book?.genres ? genre : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {item?.book?.age_range
                              ? item?.book?.age_range.text.split(" ")[1]
                              : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            {item?.book?.language
                              ? item?.book?.language.name
                              : "Not Provided"}
                          </TableCell>
                        </>
                      ) : null}
                      {/* <TableCell>
                        {item?.assignment?.reviewer?.fullname
                          ? item?.assignment?.reviewer?.fullname
                          : "Not Provided"}
                      </TableCell> */}
                      {/* 
                      
                      <TableCell>{item?.ageRange}</TableCell>
                      <TableCell>{item?.publisherName}</TableCell> */}
                      <TableCell>
                        {moment(item?.schedule?.assign_date).format(
                          "D-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(item?.schedule?.review_date).format(
                          "D-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        {item?.schedule?.submitted_date
                          ? moment(item?.schedule?.submitted_date).format(
                              "D-MM-YYYY"
                            )
                          : "Not submitted yet."}
                      </TableCell>
                      {/* <TableCell>{"Rs." + item?.payment}</TableCell> */}
                      {user?.role?.title === "Book Reviewer" ? (
                        <TableCell>
                          {item?.book?.status &&
                          item?.book?.status === bookStatus?.id
                            ? bookStatus?.label
                            : "Not Provided"}
                        </TableCell>
                      ) : null}
                      <TableCell>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          className={styles.actionBtn}
                        >
                          <Tooltip title="Book Details" arrow>
                            <button
                              onClick={() =>
                                navigate(
                                  routeConstant.bookDetail.path.replace(
                                    ":id",
                                    item?.book_id
                                  )
                                )
                              }
                            >
                              <RemoveRedEyeIcon />
                            </button>
                          </Tooltip>

                          {/* <Tooltip title="Approve" arrow>
                            <button onClick={() => handleConfirmBook(item)}>
                              <CheckCircleIcon />
                            </button>
                          </Tooltip> */}

                          {/* <Tooltip title="Review" arrow>
                            <button
                              onClick={() =>
                                navigate(
                                  routeConstant.review.path.replace(
                                    ":id",
                                    item?.book_id
                                  )
                                )
                              }
                            >
                              <RateReviewIcon />
                            </button>
                          </Tooltip> */}

                          <Tooltip title="Book Revisions" arrow>
                            <button
                              onClick={() => {
                                navigate(
                                  routeConstant.bookRevisions.path.replace(
                                    ":id",
                                    item?.book_id
                                  )
                                );
                                // handleRevisionOpen(item);
                              }}
                            >
                              <PreviewIcon />
                            </button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allBooks?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allBooks?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
      <RejectBook
        open={openReject}
        handleClose={handleRejectBook}
        selectedBook={selectedBook}
      />
      <Confirmation
        text="Are you sure, you want to approve this book ?"
        open={openConfirm}
        handleClose={handleConfirmClose}
      />
      <AssignReviewer open={openReview} handleClose={handleReviewClose} />

      <Revision
        open={openRevision}
        handleClose={handleCloseRevision}
        statsBool={user?.user?.email === "bookreviewer@yopmail.com"}
        selectedBook={selectedBook}
      />
    </div>
  );
}

export default BooksListing;
