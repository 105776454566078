import React, { useState } from "react";
import styles from "./style.module.scss";
import { useFormik } from "formik";
import { editProfileSchema } from "shared/utils/validationSchemas";
import ImageUploadButton from "../imageUploadButton";
import CustomButton from "../customButton";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileService } from "shared/services/profileService";
import { toastMessage } from "../toast";
import { setUser } from "shared/redux/reducers/userSlice";
import { roles } from "shared/routes/constant";
import Loader from "shared/components/loader/animation";
import classNames from "classnames";

interface InitialValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

function EditProfileForm() {
  const dispatch = useDispatch();
  const {
    user: { user, token },
  } = useSelector((state: any) => state.root);
  const [image, setImage] = useState<{ preview: string; raw: File | null }>({
    preview: user?.profile_picture,
    raw: null,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const initialValues: InitialValues = {
    last_name: user?.last_name ? user?.last_name : "",
    first_name: user?.first_name ? user?.first_name : "",
    email: user?.email ? user?.email : "",
    phone: user?.phone ? user?.phone : "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editProfileSchema,
    onSubmit: (values) => {
      handleEditProfile(values);
    },
  });

  const handleEditProfile = (values: any) => {
    let resp = { ...values };
    if (image?.raw) {
      resp["profile_picture"] = image?.raw;
    }
    let formBody = new FormData();
    Object.keys(resp).forEach((key) => {
      formBody.append(key, resp[key]);
    });
    setLoader(true);
    updateProfileService(formBody)
      .then(({ data }) => {
        setLoader(false);
        handleSetUser(data?.data);
        toastMessage("success", data?.message);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const handleSetUser = (data: any) => {
    let obj: any = {
      user: data,
      isLoggedIn: true,
      token: token,
      role: roles.find((role) => role.role_id === Number(data?.data?.role)),
    };
    dispatch(setUser(obj));
  };

  return (
    <div className={styles.topMostContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.container}>
          <div className={styles.heading}>Edit information</div>
        </div>
        <ImageUploadButton image={image} setImage={setImage} />
        <div className={styles.inputsWrapper}>
          <div className={styles.inputContainer} style={{ marginTop: "20px" }}>
            <div className={styles.inputSingleContainer}>
              <div className={styles.titleInput}>First Name</div>
              <input
                className={styles.input}
                name="first_name"
                placeholder="John"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className={styles.errorText}>
                  {formik.errors.first_name}
                </div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
            </div>
            <div className={styles.inputSingleContainer}>
              <div className={styles.titleInput}>Last Name</div>

              <input
                className={styles.input}
                name="last_name"
                placeholder="Doe"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className={styles.errorText}>
                  {formik.errors.last_name}
                </div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputSingleContainer}>
              <div className={styles.titleInput}>Email</div>
              <input
                className={styles.input}
                name="email"
                placeholder="johndoe@gmail.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={styles.errorText}>{formik.errors.email}</div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
            </div>
            <div className={styles.inputSingleContainer}>
              <div className={styles.titleInput}>Contact</div>
              <input
                className={styles.input}
                name="phone"
                placeholder="0300000000"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className={styles.errorText}>{formik.errors.phone}</div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
            </div>
          </div>
          <div
            className={classNames(styles.inputContainer, styles.inputContainerButton)}
            style={{ marginTop: "10px" }}
          >
            <CustomButton text="Save Changes" buttonStyle={styles.btnCustom} />
          </div>
        </div>
      </form>
      {loader && <Loader />}
    </div>
  );
}

export default EditProfileForm;
