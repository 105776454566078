import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import styles from "../style.module.scss";
import CustomButton from "shared/components/customButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ViewCoverProps {
  open: boolean;
  handleClose: any;
  selectedItem: any;
}

export default function ViewCover({
  open,
  handleClose,
  selectedItem,
}: ViewCoverProps) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {/* <div className={styles.crossIconViewCover}>
            <Icons.CrossIcon onClick={() => handleClose()} />
          </div> */}
          <img
            src={selectedItem?.cover_photo}
            alt="cover"
            className={styles.coverImg}
          />

          <CustomButton
            text="Close"
            buttonStyle={styles.customBtn}
            handleClick={handleClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
