import { Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import SkeletonLoader from "./SkeletonLoader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toastMessage } from "shared/components/toast";
import styles from "./style.module.scss";

interface MyEditor {
  text: string;
  setText: any;
  getAPI: any;
  updateAPI: any;
  current: any;
}

export default function MyEditor({
  text,
  setText,
  getAPI,
  updateAPI,
  current,
}: MyEditor) {
  const [loader, setLoader] = useState(false);
  const [editorLoader, setEditorLoader] = useState(true);

  function handleTextChange(value: any) {
    setText(value);
  }

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
      ["link"],
      ["code-block"],
    ],
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "image",
    "video",
    "link",
    "code-block",
  ];

  const getText = () => {
    if (current) {
      setEditorLoader(true);
      getAPI(current?.value)
        .then((data: any) => {
          setEditorLoader(false);
          setText(data?.data?.data?.content);
          // toastMessage("success", "Content Loaded Successfully");
        })
        .catch((err: any) => {
          setEditorLoader(false);
          console.log("err", err);
          toastMessage("error", err?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    getText();
  }, [current]);

  const handleUpdate = () => {
    let obj: any = {
      content: text,
      page_type: current?.type,
      title: current?.title,
    };
    let formBody = new FormData();

    Object.keys(obj).forEach((key) => {
      formBody.append(key, obj[key]);
    });

    setEditorLoader(true);
    updateAPI(formBody)
      .then((data: any) => {
        setEditorLoader(false);
        setText(data?.data?.data?.content);
        toastMessage("success", data?.data?.message);
      })
      .catch((err: any) => {
        setEditorLoader(false);
        console.log(err);
        toastMessage(err?.message, "error");
      });
  };
  return (
    <div className={styles.container}>
      {editorLoader ? (
        <SkeletonLoader />
      ) : (
        <ReactQuill
          value={text}
          onChange={handleTextChange}
          modules={modules}
          formats={formats}
        />
      )}
      <div className={styles.btnWrapper}>
        {/* <button className={styles.cancelBtn}>Cancel</button> */}
        <button className={styles.saveBtn} onClick={handleUpdate}>
          Save
        </button>
      </div>
    </div>
  );
}
