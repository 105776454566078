import React, { useState, useEffect } from "react";
import styles from "../style.module.scss";
import CustomButton from "shared/components/customButton";
import { useFormik } from "formik";
import * as yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface ChangePasswordSectionProps {
  open: boolean;
  handleResetPassword: (password: string) => void;
  loader: boolean;
}

function Index({ open, handleResetPassword,loader }: ChangePasswordSectionProps) {
  const [showIcon, setShowIcon] = useState<any>({
    password: false,
    confirmPassword: false,
  });
  const validationSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is Required")
      .oneOf([yup.ref("password"), ""], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleResetPassword(values.password);
    },
  });

  const handleShowPassword = (key: any) => {
    setShowIcon({
      ...showIcon,
      [key]: !showIcon[`${key}`],
    });
  };

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <div className={styles.insideContainer}>
      <h5 className={styles.title}>Create New Password</h5>
      <h3 className={styles.subTitle}>
        Please enter your new password credentials.
      </h3>

      <form onSubmit={formik.handleSubmit}>
        <h3 className={styles.heading}>
          Password <span>*</span>
        </h3>
        <div style={{ position: "relative" }}>
          <input
            type={showIcon?.password ? "text" : "password"}
            name="password"
            className={styles.input}
            placeholder="Enter your password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <div
            className={styles.iconContainer}
            onClick={() => handleShowPassword("password")}
          >
            {showIcon.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </div>{" "}
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className={styles.errorText}>{formik.errors.password}</div>
        ) : (
          <div style={{ height: "30px" }}></div>
        )}

        <h3 className={styles.heading}>
          Change Password <span>*</span>
        </h3>
        <div style={{ position: "relative" }}>
          <input
            type={showIcon?.confirmPassword ? "text" : "password"}
            name="confirmPassword"
            className={styles.input}
            placeholder="Enter your confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          <div
            className={styles.iconContainer}
            onClick={() => handleShowPassword("confirmPassword")}
          >
            {showIcon.confirmPassword ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </div>
        </div>

        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <div className={styles.errorText}>
            {formik.errors.confirmPassword}
          </div>
        ) : (
          <div style={{ height: "30px" }}></div>
        )}

        <CustomButton
          text="Change Password"
          buttonStyle={styles.btnCustom}
          type="submit"
          loader={loader}
        />
      </form>
    </div>
  );
}

export default Index;
