import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "shared/components/loader/animation";
import useDebounce from "shared/customHooks/useDebounce";
import Confirmation from "shared/modals/confirmation";
import { setBreadCrumb } from "shared/redux/reducers/breadCrumbs";
import { resetUser } from "shared/redux/reducers/userSlice";
import { routeConstant } from "shared/routes/routesConstant";
import { LogoutUser } from "shared/services/authService";
import BreadCrumbs from "../breadCrumbs";
import { toastMessage } from "../toast";
import styles from "./style.module.scss";
import { Icons } from "assets";
import NotificationDropDown from "../notificationDropdown";

interface HeaderProps {
  setToggle: any;
  toggle: boolean;
  setCollapse: any;
  collapse: boolean;
}

function Header({
  toggle,
  setToggle,
  setCollapse,
  collapse,
}: Partial<HeaderProps>) {
  const { user } = useSelector((state: any) => state.root);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenLg = useMediaQuery("(max-width:992px)");
  const [loader, setLoader] = useState<boolean>(false);
  const [searchBook, setSearchBook] = useState<string>("");
  const [searchBookText, setSearchBookText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [searchBookLoader, setSearchBookLoader] = useState<boolean>(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const resultsContainerRef = useRef<HTMLDivElement | null>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [openLogout, setOpenLogout] = useState<boolean>(false);
  const handleLogout = () => {
    setLoader(true);
    LogoutUser()
      .then(({ data }) => {
        if (data?.statusCode === 200) {
          toastMessage("success", data?.message);
          dispatch(resetUser());
          dispatch(setBreadCrumb({ crumbs: [] }));
          setLoader(false);
        }
      })
      .catch((err) => {
        toastMessage("error", err?.resp?.data?.message);
        setLoader(false);
      });
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      label: "Settings",
      action: () => {
        handleCloseUserMenu();
        navigate(routeConstant.settings.path);
      },
    },
    {
      label: "Change Password",
      action: () => {
        handleCloseUserMenu();
        navigate(routeConstant.settings.path, {
          state: { scroll: true },
        });
      },
    },
    {
      label: "Logout",
      action: () => {
        setOpenLogout(true);
        handleCloseUserMenu();
      },
    },
  ];

  useDebounce(
    () => {
      setSearchBookText(searchBook);
    },
    [searchBook],
    800
  );

  const handleBookSearch = (e: any) => {
    setIsDropdownVisible(true);
    setSearchBook(e.target.value);
    setSearchResults([1]);
  };

  const handleOutsideClick = (event: any) => {
    if (
      resultsContainerRef?.current &&
      !resultsContainerRef?.current?.contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);


  return (
    <AppBar position="static" className={styles.headerMainContainer}>
      <Container
        maxWidth="xl"
        className={classNames(
          !screenLg ? styles.headerItems : "",
          styles.topLevelContainer
        )}
      >
        <Toolbar
          disableGutters
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <BreadCrumbs />

          <Box
            sx={{
              flexGrow: 1,
              display: { xl: "flex", justifyContent: "space-between" },
            }}
            className={styles.displayNone}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              //   onClick={handleOpenNavMenu}
              color="inherit"
              //   onClick={() => setCollapse(!collapse)}
              onClick={() => setToggle(!toggle)}
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {/* <Box>
              <div className={styles.booksSearch}>
                <SearchOutlinedIcon />
                <input
                  type="text"
                  placeholder="Search Book Name Here"
                  value={searchBook}
                  onChange={(e) => handleBookSearch(e)}
                  onBlur={() => {
                    setSearchBook("");
                  }}
                />

                {searchBookText && isDropdownVisible && (
                  <div
                    ref={resultsContainerRef}
                    className={styles.seacrhResultsContainer}
                  >
                    {searchBookLoader ? (
                      <div style={{ color: "black" }}>Loading</div>
                    ) : searchResults.length > 0 ? (
                      Array.from({ length: 8 }).map((item) => {
                        return (
                          <SearchBookCard
                            setIsDropdownVisible={setIsDropdownVisible}
                          />
                        );
                      })
                    ) : (
                      <div className={styles.noBookFound}>
                        <NoDataFound />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Box> */}
            {/* <NotificationDropDown /> */}
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tooltip title="Account">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  className={styles.buttonInfo}
                >
                  <Avatar
                    alt={user?.user?.full_name}
                    src={user?.user?.profile_picture}
                    sx={{ marginRight: "10px" }}
                  />
                  <div className={styles.flexGrid}>
                    <div>
                      <div className={styles.nameLabel}>
                        {user?.user?.full_name}
                      </div>
                      <div className={styles.emailLabel}>
                        {user?.user?.email}
                      </div>
                    </div>
                    <Icons.DropDownIcon style={{ marginLeft: "10px" }} />
                  </div>
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting: any, index: number) => (
                  <MenuItem key={index} onClick={setting.action}>
                    <Typography textAlign="center">{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Confirmation
        open={openLogout}
        handleClose={() => setOpenLogout(false)}
        text="Are you sure, you want to logout?"
        handleClick={handleLogout}
      />
      {loader && <Loader />}
    </AppBar>
  );
}
export default Header;
