import React, { useEffect, useState } from "react";
import { Images } from "assets";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import styles from "./style.module.scss";
import Header from "../header";
// import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import useWindowSize from "shared/customHooks/windowSize";
import Confirmation from "shared/modals/confirmation";
import { resetUser } from "shared/redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { getRoutes } from "./constant";
import { LogoutUser } from "shared/services/authService";
import { toastMessage } from "../toast";
import Loader from "shared/components/loader/animation";

interface SidebarProps {
  Component: any;
  path: any;
}

const SidebarComp = ({ Component, path }: SidebarProps) => {
  const width = useWindowSize();
  const dispatch = useDispatch();
  const [openLogout, setOpenLogout] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { user } = useSelector((state: any) => state.root);
  const navigate = useNavigate();
  const routes = getRoutes();

  const activeRoutes = routes.find(
    (role: any) => role.role_id === Number(user?.user?.role)
  )?.routes;
  const handleNavigation = (item: any) => {
    navigate(item?.path);
    if (width < 992) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (width > 992) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [width]);

  const handleLogout = () => {
    setLoader(true);
    LogoutUser()
      .then(({ data }) => {
        if (data?.statusCode === 200) {
          toastMessage("success", data?.message);
          dispatch(resetUser());
          setLoader(false);
        }
      })
      .catch((err) => {
        toastMessage("error", err?.resp?.data?.message);
        setLoader(false);
      });
  };
  return (
    <div style={{ display: "flex" }}>
      <Sidebar
        className={styles.sidebar}
        onBackdropClick={() => setToggle(false)}
        toggled={toggle}
        // collapsed={collapse}
        breakPoint={"lg"}
      >
        <Menu className={styles.menu}>
          <div className={styles.logoContainer}>
            <Images.Logo className={styles.logo} />
          </div>
          {activeRoutes.map((item: any, inx: any) => {
            return (
              <React.Fragment key={item?.title}>
                {item?.path ? (
                  <MenuItem
                    onClick={() => handleNavigation(item)}
                    icon={item?.icon}
                    className={path === item?.path ? styles.active : ""}
                  >
                    {item?.title}
                  </MenuItem>
                ) : (
                  <SubMenu
                    key={item?.title}
                    label={item?.title}
                    icon={item?.icon}
                    className={styles.subMenuContainer}
                  >
                    {item?.subMenu?.map(
                      (subItem: any, inx: any, array: any) => {
                        return (
                          <MenuItem
                            key={subItem?.path}
                            onClick={() => handleNavigation(subItem)}
                            className={
                              path === subItem?.path && array.length !== inx + 1
                                ? `${styles.subMenuActive} ${styles.borderSubMenu}`
                                : array.length !== inx + 1
                                ? styles.borderSubMenu
                                : path === subItem?.path
                                ? styles.subMenuActive
                                : ""
                            }
                          >
                            {subItem?.title}
                          </MenuItem>
                        );
                      }
                    )}
                  </SubMenu>
                )}
              </React.Fragment>
            );
          })}

          {/* <MenuItem icon={<LogoutIcon />} onClick={() => setOpenLogout(true)}>
            Logout
          </MenuItem> */}
        </Menu>
      </Sidebar>
      <main style={{ width: "100%" }}>
        <div className={styles.mainContainer}>
          <Header
            toggle={toggle}
            setToggle={setToggle}
            setCollapse={setCollapse}
            collapse={collapse}
          />
          <div className={styles.componentContainer}>
            <Component />
          </div>
        </div>
      </main>
      <Confirmation
        open={openLogout}
        handleClose={() => setOpenLogout(false)}
        text="Are you sure, you want to logout?"
        handleClick={handleLogout}
      />
      {loader && <Loader />}
    </div>
  );
};

export default SidebarComp;
