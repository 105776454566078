export const headings = {
    mainHeading: 'All schools requested listing',
    subHeading: 'Manage all requests from here'
}

export const columnHeadings = [
    {
        id: "school_name",
        title: "School Name",
    },
    {
        id: "phone",
        title: "Phone",
    },
    // {
    //     id: "email",
    //     title: "Email",
    // },

    {
        id: "no_of_student",
        title: "No Of Students",
    },
    {
        id: "no_of_teachers",
        title: "No Of Teachers",
    },
    {
        id: 'status',
        title: 'Status'
    },
    {
        id: "actions",
        title: "Actions",
    },
];

export const skeletonInfo = {
    rows: 10,
    cellCount: 6,
    actions: 5,
};

export const filterOptions = [
    {
        option: "All",
        value: null,
    },
];
