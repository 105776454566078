import { Icons, Images } from "assets";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
// import { roundNum } from "shared/utils/helper";
import styles from "./style.module.scss";
import { Grid } from "@mui/material";
import { languageEnum } from "shared/utils/constants";
import { getPublishedBy } from "shared/utils/helpers";

interface StartPage {
  bookDetail: any;
}

const StartPage = ({ bookDetail }: StartPage) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={classNames(styles.bookDetailWrapper)}>
        <div
          className={classNames(styles.bookDetailContainer, "pe-2")}
          style={{ paddingRight: "0.5rem" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div
              className={classNames(
                "d-flex flex-column align-items-start justify-content-start gap-1"
              )}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                gap: "0.25rem",
              }}
            >
              <label className={classNames(styles.bookTitle)}>
                {bookDetail?.title}
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                }}
              >
                <label className={classNames(styles.authorName)}>
                  {bookDetail?.author?.name}
                </label>
              </div>
            </div>
          </div>

          <label
            className={classNames(
              bookDetail?.language?.name === languageEnum?.urdu
                ? styles.descriptionLabelUrdu
                : styles.descriptionLabel
            )}
          >
            {bookDetail?.description}
          </label>
        </div>
      </div>
      <div className={classNames(styles.bookStatsInfoContainer)}>
        <div
          style={{
            height: "100%",
            flexDirection: "column",
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "0.25rem",
            }}
          >
            <label className={classNames(styles.stateTitle)}>Age Range</label>
            <label className={classNames(styles.descriptionLabel)}>
              {bookDetail?.age_range?.min}-{bookDetail?.age_range?.max}
            </label>
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "0.25rem",
            }}
          >
            <label className={classNames(styles.stateTitle, styles.green)}>
              Language
            </label>
            <label className={classNames(styles.descriptionLabel)}>
              {bookDetail?.language?.name}
            </label>
          </div>
        </div>
        <div
          className={classNames(
            "d-flex flex-column align-items-start justify-content-between"
          )}
          style={{
            height: "100%",
            flexDirection: "column",
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "0.25rem",
            }}
          >
            <label className={classNames(styles.stateTitle, styles.secondary)}>
              No. of Pages
            </label>
            <label className={classNames(styles.descriptionLabel)}>
              {bookDetail?.total_pages}
            </label>
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "0.25rem",
            }}
          >
            <label className={classNames(styles.stateTitle)}>Genre</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "0.25rem",
                flexWrap: "wrap",
              }}
            >
              {bookDetail?.genres?.map((itm: any, inx: any) => {
                return (
                  <label
                    className={classNames(styles.descriptionLabel)}
                    key={inx}
                  >
                    {itm?.name}
                    {inx !== bookDetail?.genres?.length - 1 ? `,` : ""}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-between",
            gap: "0.25rem",
          }}
        >
          <label className={classNames(styles.stateTitle, styles.yellow)}>
            Quiz
          </label>
          <label className={classNames(styles.descriptionLabel)}>
            {bookDetail?.is_quiz ? "Yes" : "No"}
          </label>
        </div>
      </div>
      <div className={classNames(styles.publisherInfoContainer)}>
        <img
          src={
            getPublishedBy(bookDetail?.partner)?.img
            // bookDetail?.publisher?.publishing_logo
            //   ? bookDetail?.publisher?.publishing_logo
            //   : Images.AvatarPlaceholder
          }
          alt="publishing-house-pic"
          className={classNames(styles.avatar)}
          height={40}
          width={40}
          role="button"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <label className={classNames(styles.publisherName)} role="button">
            {getPublishedBy(bookDetail?.partner).name}
          </label>
          {/* <label className={classNames(styles.publisherTag)}>
            Publisher House
          </label> */}
        </div>
      </div>
    </>
  );
};

export default StartPage;
