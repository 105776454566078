import { Icons } from "assets";
import classNames from "classnames";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import HTMLFlipBook from "react-pageflip";
import { useNavigate, useParams } from "react-router-dom";
import { toastMessage } from "shared/components/toast";
import styles from "./style.module.scss";
import { languageEnum } from "shared/utils/constants";
import StartPage from "shared/components/startPage";
import Slider from "shared/components/slider";
import { getBookPagesService } from "shared/services/general";

const BookView = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const handle = useFullScreenHandle();
  const bookRef = useRef<any>(null);
  const pageNumber = useRef<number>(1);
  const totalPagesRef = useRef<number>(0);
  const pagesRef = useRef<any>([]);
  const languageRef = useRef<any>(languageEnum.english);
  const [renderCount, setRenderCount] = useState<number>(1);
  const [bookHeight, setHeight] = useState<number>(0);
  const [bookWidth, setWidth] = useState<number>(1);
  const [slider, setSlider] = useState<number>(0);
  const [bookArr, setBookArr] = useState<any>([]);
  const [bookDetail, setBookDetail] = useState<any>(null);
  const [selectedLang, setSelectLang] = useState<string>("");
  const [showActions, setShowActions] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  /* -------------------------------------Rotation Functions------------------------------------- */

  const handlePreviousPage = () => {
    if (pageNumber.current > 1) {
      let x = 0;
      x = pageNumber.current - 2;
      if (x !== 1) {
        pageNumber.current = x;
        setSlider(x);
      } else {
        setSlider(0);
        pageNumber.current = x;
      }
    }
  };

  const handleNextPage = () => {
    if (pageNumber.current <= totalPagesRef.current) {
      let x = 0;
      x = pageNumber.current + 2;
      if (x < totalPagesRef.current) {
        pageNumber.current = x;
        setSlider(x);
      } else {
        setSlider(totalPagesRef.current);
        pageNumber.current = totalPagesRef.current;
      }
    }
  };

  function handleLeftRotate() {
    bookRef?.current?.pageFlip()?.flipPrev();
    if (selectedLang === languageEnum.urdu) {
      handleNextPage();
    } else {
      handlePreviousPage();
    }
  }

  function handleRightRotate() {
    bookRef?.current?.pageFlip()?.flipNext();
    if (selectedLang === languageEnum.urdu) {
      handlePreviousPage();
    } else {
      handleNextPage();
    }
  }

  function handleLastPage() {
    if (handle.active) {
      setLoading(true);
      handle.exit();
    } else {
      navigate(-1);
    }
  }

  const handleClick = (page_no: number) => {
    if (bookArr?.length % 2 === 0) {
      if (page_no % 2 === 0) {
        setSlider(page_no - 2);
        pageNumber.current = page_no - 2;
      } else {
        setSlider(page_no + 1);
        pageNumber.current = page_no + 1;
      }
    } else {
      if (page_no % 2 === 0) {
        setSlider(page_no - 2);
        pageNumber.current = page_no - 1;
      } else {
        setSlider(page_no + 2);
        pageNumber.current = page_no + 2;
      }
    }
  };

  const rotateToPage = (page: number) => {
    let tempTotalPages = pagesRef.current?.length;
    let tempPage = page + 1;
    if (page % 2 === 0) {
      pageNumber.current = page + 1;
    } else {
      pageNumber.current = page;
    }

    if (Number(tempTotalPages) % 2 === 0) {
      tempTotalPages = tempTotalPages + 2;
    } else {
      tempTotalPages = tempTotalPages + 1;
    }
    if (languageRef.current === languageEnum.urdu) {
      let exactPage = tempTotalPages - tempPage;
      bookRef?.current?.pageFlip()?.turnToPage(Number(exactPage));
    } else {
      bookRef?.current?.pageFlip()?.turnToPage(Number(page));
    }
  };

  /* -------------------------------------API Calls------------------------------------- */

  function handleGetBook() {
    getBookPagesService(id)
      .then((response: any) => {
        handleBookPreviewResponse(response?.data);
        // handleInitialPageSize();
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        navigate(-1);
      });
  }

  /* -------------------------------------Event Handler Functions------------------------------------- */

  function handleResizeObserver() {
    let topElem: any = document.getElementById("book-preview-top-level");
    let leftBookPage: any = document.getElementById("left-book-page");
    let rightBookPage: any = document.getElementById("right-book-page");
    let height = topElem?.offsetHeight - 20;
    let width = Number(2 * Math.trunc(topElem?.offsetHeight * 0.688));
    if (width > topElem?.offsetWidth) {
      width = Math.trunc(topElem?.offsetWidth - 40);
      height = Number(Math.trunc(width / 2));
      height = Math.trunc(height / 0.688);
    }
    setHeight(height);
    setWidth(width / 2);
    setRenderCount(width / 2);

    setTimeout(() => {
      setLoading(false);
      rotateToPage(pageNumber.current);
    }, 300);

    if (leftBookPage) {
      //@ts-ignore
      leftBookPage.style.height = `${height}px`;
    }
    if (rightBookPage) {
      //@ts-ignore
      rightBookPage.style.height = `${height}px`;
    }
  }

  const registerKeyPress = useCallback((e: any) => {
    if (e.key == "ArrowLeft") {
      handleLeftRotate();
    } else if (e.key == "ArrowRight") {
      handleRightRotate();
    }
  }, []);

  /* -------------------------------------Initial Render Functions------------------------------------- */

  function handleBookPreviewResponse(bookResponse: any) {
    let { data, status, message } = bookResponse;
    if (status) {
      setBookDetail(data?.book);
      languageRef.current = data?.book?.language?.name;
      setSelectLang(data?.book?.language?.name);
      let tempArr = [];
      if (data?.book?.language?.name === languageEnum.urdu) {
        tempArr = data?.pages.reverse();
        setBookArr(tempArr);
      } else {
        tempArr = data?.pages;
        setBookArr(data?.pages);
      }

      pagesRef.current = tempArr;

      let tempTotalPages = 0;

      if (Number(data?.book?.total_pages) % 2 === 0) {
        tempTotalPages = Number(data?.book?.total_pages) + 2;
      } else {
        tempTotalPages = Number(data?.book?.total_pages) + 1;
      }

      handleResizeObserver();
      if (data?.book?.language?.name === languageEnum.urdu) {
        setTimeout(() => {
          //@ts-ignore
          bookRef?.current?.pageFlip()?.turnToPage(tempTotalPages - 1);
        }, 100);
      }
    } else {
      toastMessage("error", message);
      navigate(-1);
    }
  }

  /* -------------------------------------Helper Functions------------------------------------- */

  function throttle(f: () => void, delay: number) {
    let timer: any = 0;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => f(), delay);
    };
  }

  /* -------------------------------------UseEffect Hooks------------------------------------- */

  useEffect(() => {
    handleGetBook();
  }, []);

  useEffect(() => {
    let topElem: any = document.getElementById("book-preview-top-level");
    const observer: any = new ResizeObserver(
      throttle(() => {
        setLoading(true);
        handleResizeObserver();
      }, 600)
    ).observe(topElem);
    return () => {
      observer?.unobserve(topElem);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", registerKeyPress);
    return () => {
      window.removeEventListener("keydown", registerKeyPress);
    };
  }, [registerKeyPress]);

  return (
    <FullScreen handle={handle}>
      {loading ? <div className={classNames(styles.whiteContainer)} /> : null}

      <div
        className={classNames(styles.topContainer)}
        onClick={() => {
          setShowActions(!showActions);
        }}
      >
        {/* ---------------------------------------Header Section--------------------------------------- */}
        <div
          className={classNames(
            styles.headerContainer,
            showActions ? styles.titleShown : styles.titleHidden
          )}
        >
          <div className={classNames(styles.titleContainer)}>
            <div
              className={classNames(
                // " d-flex align-items-center justify-content-between w-100 px-3",
                styles.h100
              )}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                // paddingLeft: "1.5rem",
                // paddingRight: "1.5rem",
              }}
            >
              <div />
              <label className={classNames(styles.titleLabel)}>
                {bookDetail?.title}
              </label>
              <Icons.BookCloseIcon
                className={classNames(styles.closeIcon2)}
                role="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (handle.active) {
                    setShowActions(false);
                    handle.exit();
                  } else {
                    navigate(-1);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {!showActions && (
          <div
            className={classNames(styles.actionContainer)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className={classNames(styles.closeContainer)}
              onClick={(e) => {
                e.stopPropagation();

                if (handle.active) {
                  setShowActions(false);
                  handle.exit();
                } else {
                  navigate(-1);
                }
              }}
            >
              <Icons.BookCloseIcon className={classNames(styles.closeIcon)} />
            </div>
          </div>
        )}

        {/* ---------------------------------------Pages Section--------------------------------------- */}
        <div
          className={classNames(styles.scrollHide)}
          id="book-preview-top-level"
          style={{ height: "100%" }}
        >
          <div
            className={classNames(styles.bookWrapper)}
            style={{
              position: "relative",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowActions(false);
            }}
          >
            <div
              className={classNames(styles.leftbookPages)}
              onClick={handleLeftRotate}
              id="left-book-page"
            />
            {((selectedLang == languageEnum.english && slider !== 0) ||
              (selectedLang == languageEnum.urdu &&
                slider !== bookArr?.length)) && (
              <div
                className={classNames(styles.leftArrowContainer)}
                onClick={handleLeftRotate}
              />
            )}
            <HTMLFlipBook
              key={renderCount}
              ref={bookRef}
              width={bookWidth}
              height={bookHeight}
              className={classNames(styles.bookPages)}
              style={{ zIndex: 1 }}
              startPage={0}
              size={"fixed"}
              minWidth={bookWidth}
              maxWidth={bookWidth}
              minHeight={bookHeight}
              maxHeight={bookHeight}
              drawShadow={true}
              flippingTime={600}
              usePortrait={false}
              startZIndex={0}
              autoSize={false}
              maxShadowOpacity={1}
              showCover={false}
              mobileScrollSupport={false}
              clickEventForward={true}
              useMouseEvents={true}
              swipeDistance={30}
              showPageCorners={false}
              disableFlipByClick={false}
            >
              {selectedLang === languageEnum.english ||
              (bookArr?.length % 2 === 0 &&
                selectedLang === languageEnum.urdu) ? (
                <Page>
                  {selectedLang == languageEnum.english ? (
                    <div className={classNames(styles.startPage)}>
                      <StartPage bookDetail={bookDetail} />
                    </div>
                  ) : (
                    <div
                      className={classNames(styles.endPage)}
                      role="button"
                      onClick={() => {
                        handleLastPage();
                      }}
                    >
                      <label className={classNames(styles.endText)}>
                        Almost Done
                      </label>
                    </div>
                  )}
                </Page>
              ) : (
                <></>
              )}
              {bookArr?.map((itm: any, inx: number) => {
                return (
                  <Page key={inx + 1}>
                    <img
                      src={itm?.page_path}
                      className={classNames(styles.imgStyle)}
                      onClick={(e) => {
                        if (bookArr?.length % 2 !== 0) {
                          if (selectedLang === languageEnum.urdu && inx === 0) {
                            handleLastPage();
                          } else if (
                            selectedLang === languageEnum.english &&
                            inx === bookArr?.length - 1
                          ) {
                            handleLastPage();
                          } else {
                            handleClick(itm?.page_no);
                          }
                        } else {
                          handleClick(itm?.page_no);
                        }
                      }}
                    />
                  </Page>
                );
              })}
              {selectedLang === languageEnum.urdu ||
              (bookArr?.length % 2 === 0 &&
                selectedLang === languageEnum.english) ? (
                <Page>
                  {selectedLang == languageEnum.urdu ? (
                    <div className={classNames(styles.startPage)}>
                      <StartPage bookDetail={bookDetail} />
                    </div>
                  ) : (
                    <div
                      className={classNames(styles.endPage)}
                      role="button"
                      onClick={() => {
                        handleLastPage();
                      }}
                    >
                      <label className={classNames(styles.endText)}>
                        Almost Done
                      </label>
                    </div>
                  )}
                </Page>
              ) : (
                <></>
              )}
            </HTMLFlipBook>

            {((selectedLang === languageEnum.urdu && slider !== 0) ||
              (selectedLang === languageEnum.english &&
                slider !== bookArr?.length)) && (
              <div
                className={classNames(styles.rightArrowContainer)}
                onClick={handleRightRotate}
              />
            )}

            <div
              className={classNames(styles.rightbookPages)}
              id="right-book-page"
              onClick={handleRightRotate}
            />
          </div>
        </div>

        {/* ---------------------------------------Bottom Section--------------------------------------- */}
        <div
          className={classNames(
            styles.bottomContainer,
            showActions ? styles.sliderShown : styles.sliderHidden
          )}
        >
          <div
            className={classNames(
              styles.arrowContainer,
              showActions ? styles.sliderUpSlide : styles.sliderDownSlide
            )}
          >
            <Icons.ChevUpIcon
              className={classNames(
                styles.arrowIcon,
                showActions && styles.rotateVertical
              )}
            />
          </div>
          <div className={classNames(styles.sliderContainer)}>
            <div
              className={classNames(styles.sliderSubContainer, styles.h100)}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1.25rem",
                paddingRight: "1.25rem",
                gap: "2rem",
              }}
            >
              {selectedLang == languageEnum.urdu ? (
                handle?.active ? (
                  <div className={classNames(styles.tooltip)}>
                    <Icons.MinimizeIcon
                      className={classNames(styles.fullScreenIcon)}
                      role="button"
                      onClick={() => {
                        setLoading(true);
                        handle.exit();
                      }}
                    />
                    <span className={classNames(styles.tooltiptext)}>
                      Minimize
                    </span>
                  </div>
                ) : (
                  <>
                    <div className={classNames(styles.tooltip)}>
                      <Icons.MaximizeIcon
                        className={classNames(
                          styles.fullScreenIcon,
                          styles.tooltip
                        )}
                        role="button"
                        onClick={() => {
                          setLoading(true);
                          handle.enter();
                        }}
                      />
                      <span className={classNames(styles.tooltiptext)}>
                        Full Screen
                      </span>
                    </div>
                  </>
                )
              ) : null}
              <Slider
                progress={slider}
                onChange={(e) => {
                  document.getElementById("myinput")?.blur();
                  setSlider(Number(e.currentTarget.value));
                  rotateToPage(Number(e.currentTarget.value));
                }}
                onInput={(e) => {
                  setSlider(Number(e.currentTarget.value));
                }}
                max={bookDetail?.total_pages}
                isRightOriented={selectedLang == languageEnum.urdu}
              />
              {selectedLang == languageEnum.english ? (
                handle?.active ? (
                  <div className={classNames(styles.tooltip)}>
                    <Icons.MinimizeIcon
                      className={classNames(styles.fullScreenIcon)}
                      role="button"
                      onClick={() => {
                        setLoading(true);
                        handle.exit();
                      }}
                    />
                    <span className={classNames(styles.tooltiptext)}>
                      Minimize
                    </span>
                  </div>
                ) : (
                  <>
                    <div className={classNames(styles.tooltip)}>
                      <Icons.MaximizeIcon
                        className={classNames(
                          styles.fullScreenIcon,
                          styles.tooltip
                        )}
                        role="button"
                        onClick={() => {
                          setLoading(true);
                          handle.enter();
                        }}
                      />
                      <span className={classNames(styles.tooltiptext)}>
                        Full Screen
                      </span>
                    </div>
                  </>
                )
              ) : null}
            </div>
            <label className={classNames(styles.pageLabel)}>
              PAGE {slider ? slider : "1"} of {bookDetail?.total_pages}
            </label>
          </div>
        </div>
      </div>
    </FullScreen>
  );
};

const Page = forwardRef<any, any>((props, ref) => {
  return (
    <div className={classNames(styles.demoPage)} ref={ref}>
      {props.children}
    </div>
  );
});

export default BookView;
