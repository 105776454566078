import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";
import styles from "../style.module.scss";

function SkeletonLoader({ activeTab }: any) {
  return (
    <React.Fragment>
      {Array.from({ length: 8 }).map((_, index) => (
        <TableRow key={index} className={styles.tableRow}>
          {activeTab === 3 ? (
            <TableCell>
              <Skeleton variant="rectangular" width={120} height={25} />
            </TableCell>
          ) : null}
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>{" "}
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>{" "}
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>{" "}
          {/* <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={120} height={25} />
          </TableCell> */}
          <TableCell>
            <Box display={"flex"}>
              <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
              <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
              <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
              <Skeleton width={30} height={40} sx={{ marginRight: "10px" }} />
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

export default SkeletonLoader;
