import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

interface TableSkeletonProps {
  cellCount: number;
  actionCount: number;
  showPersonData?: boolean;
  rows: number;
}

const TableSkeleton = ({
  rows,
  cellCount,
  actionCount,
  showPersonData,
}: TableSkeletonProps) => {
  return (
    <React.Fragment>
      {Array.from({ length: rows }).map((_, index) => (
        <TableRow key={index}>
          {/* {showPersonData ? (
            <TableCell sx={{ display: "flex" }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton
                variant="rectangular"
                width={120}
                height={25}
                sx={{ marginLeft: "10px", marginTop: "5px" }}
              />
            </TableCell>
          ) : null} */}
          {Array.from({ length: cellCount - 1 }).map((item, index) => {
            return (
              <TableCell key={index}>
                <Skeleton variant="rectangular" width={150} height={25} />
              </TableCell>
            );
          })}
          <TableCell>
            <Box display={"flex"}>
              {Array.from({ length: actionCount }).map((item: any, index) => {
                return (
                  <Skeleton
                    key={index}
                    width={30}
                    height={40}
                    sx={{ marginRight: "10px" }}
                  />
                );
              })}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
};

export default TableSkeleton;
