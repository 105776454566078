import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import styles from "./style.module.scss";

interface ComboBoxProps {
  genres: { name: string }[];
  handleSelectGenre: any;
}

export default function ComboBox({
  genres,

  handleSelectGenre,
}: ComboBoxProps) {
  const [text, setText] = useState<string>("");

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setText("");
      e.currentTarget.blur();
    }
  };

  const handleInputChange = (e: any, value: any) => {
    handleSelectGenre(value);
    setText("");
  };

  return (
    <Autocomplete
      id="free-solo-demo"
      getOptionLabel={(option: any) => option?.name}
      onChange={(_, newValue: any) => {
        handleInputChange(_, newValue);
      }}
      value={genres.find((option) => option.name === text) || null}
      options={genres}
      clearOnBlur={false}
      inputValue={text}
      onInputChange={(_, newInputValue) => {
        setText(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={text}
          onKeyDown={handleKeyDown}
          InputProps={{
            ...params.InputProps,
          }}
          className={styles.input}
        />
      )}
    />
  );
}
