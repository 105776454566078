import { user_roles } from "./constants";

function searchHandlerHelper(
  e: any,
  searchHandler: any,
  activePageHandler: any
) {
  searchHandler(e.target.value);
  activePageHandler(1);
}

function roundNum(number: string, decPlaces: number) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= Number(number)) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = String(
        Math.round((Number(number) * decPlaces) / size) / decPlaces
      );

      // Handle special case where we round up to the next abbreviation
      if (Number(number) === 1000 && i < abbrev.length - 1) {
        number = String(1);
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
}

function percentage(partialValue: number, totalValue: number) {
  return (100 * partialValue) / totalValue;
}

function getPublishedBy(user: any) {
  const name =
    user?.role === user_roles?.publisher
      ? user?.publisher
        ? user?.publisher?.publishing_house
        : user?.publishing_house
      : user?.role === user_roles?.author
        ? user?.author
          ? user?.author?.full_name
          : user?.full_name
        : null;
  const img =
    user?.role === user_roles?.publisher
      ? user?.publisher
        ? user?.publisher?.publishing_logo
        : user?.publishing_logo
      : user?.author
        ? user?.author?.profile_picture
        : user?.profile_picture;

  return {
    name: name ?? "",
    img: img ?? "",
  };
}

export { roundNum, percentage, getPublishedBy, searchHandlerHelper };
