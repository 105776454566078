import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as yup from "yup";
import { useFormik } from "formik";
import styles from "./style.module.scss";
import {
  addBalanceService,
  updateBalanceService,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import CustomButton from "shared/components/customButton";
import { Grid } from "@mui/material";
import Loader from "shared/components/loader/animation";
import { Icons } from "assets";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TopUpBalanceProps {
  open: boolean;
  handleClose: any;
  getAllBalance: any;
  selectedBalance: any;
  setSelectedBalance: any;
}

export default function TopUpBalance({
  open,
  handleClose,
  getAllBalance,
  selectedBalance,
  setSelectedBalance,
}: TopUpBalanceProps) {
  const [loader, setLoader] = useState<boolean>(false);
  const initialValues = {
    price: "",
    coins: "",
  };
  const [isItem, setIsItem] = useState<boolean>(false);

  const balanceSchema = yup.object().shape({
    price: yup
      .string()
      .required("Price is required")
      .matches(/^\d+$/, "Amount must contain positive numbers"),
    coins: yup
      .string()
      .required("Coins is required")
      .matches(/^\d+$/, "Coins must contain positive numbers"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: balanceSchema,
    onSubmit: (values) => {
      setLoader(true);
      let resp: any = { ...values };
      let service = isItem ? updateBalanceService : addBalanceService;

      if (isItem) {
        resp["id"] = selectedBalance?.id;
      }

      service(resp)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          setLoader(false);
          resetPlanForm();
          getAllBalance();
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setLoader(false);
        });
    },
  });

  useEffect(() => {
    if (selectedBalance) {
      setIsItem(true);
      formik.setFieldValue("price", Number(selectedBalance?.price));
      formik.setFieldValue("coins", Number(selectedBalance?.coins));
    } else {
      resetPlanForm();
    }
    // eslint-disable-next-line
  }, [selectedBalance]);

  const resetPlanForm = () => {
    setSelectedBalance(null);
    formik.resetForm();
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={resetPlanForm}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(60),
              position: "relative",
            },
          },
        }}
      >
        <DialogContent>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => resetPlanForm()}
          />
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div
                className={styles.tableHeading}
                style={{ marginBottom: "10px", marginTop: "40px" }}
              >
                Added top up balance.
              </div>
              <Grid container className={styles.topBalanceWrapper} spacing={2}>
                <Grid classes={styles.topBalanceInput} xs={6}>
                  <div className={styles.titleHead}>Price</div>
                  <input
                    className={styles.inputField}
                    id="price"
                    name="price"
                    type="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.price && formik.errors.price ? (
                    <div className={styles.errorText}>
                      {formik.errors.price}
                    </div>
                  ) : (
                    <div style={{ height: "20px" }}></div>
                  )}
                </Grid>

                <Grid classes={styles.topBalanceInput} xs={6}>
                  <div className={styles.titleHead}>Estimated Coins</div>
                  <input
                    className={styles.inputField}
                    id="coins"
                    name="coins"
                    type="coins"
                    value={formik.values.coins}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.coins && formik.errors.coins ? (
                    <div className={styles.errorText}>
                      {formik.errors.coins}
                    </div>
                  ) : (
                    <div style={{ height: "20px" }}></div>
                  )}
                </Grid>
                {/* <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <DeleteIcon sx={{ color: "#868E96", marginRight: "10px" }} />
          <CreateIcon sx={{ color: "#868E96" }} />
        </Grid> */}
              </Grid>

              <div
                className={`${styles.btnContainer} ${styles.topUpBalanceBtnWrapper}`}
              >
                <CustomButton
                  text="Save"
                  buttonStyle={styles.btnCustom}
                  type={"submit"}
                />
                <CustomButton
                  text={"Cancel"}
                  buttonStyle={styles.btnInsideOut}
                  handleClick={() => resetPlanForm()}
                />
              </div>
            </form>
            {loader && <Loader />}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
