import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./style.module.scss";
import { Icons } from "assets";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CustomButton from "shared/components/customButton";
import { toastMessage } from "shared/components/toast";
import { rejectBookService } from "shared/services/hodService";
import { useSelector } from "react-redux";
import { assignHODSerivce } from "shared/services/bookReviewerService";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Loader from "shared/components/loader/animation";
import { Rating } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const enums = [
  { label: "Explicit content", value: 1, marked: false },
  { label: "Age-inappropriate", value: 2, marked: false },
  { label: "Cultural insensitivity", value: 3, marked: false },
  { label: "Other", value: 4, reason: "", marked: false },
];

interface RejectBookProps {
  open: boolean;
  handleClose: any;
  selectedBook: any;
  feedback?: any;
  initialData?: any;
  rejection_data?: any;
  isRating?: boolean;
  bookReviewRating?: any;
  isFromReview?: boolean;
  rejectViewOnly?: boolean;
}

export default function RejectBook({
  open,
  handleClose,
  selectedBook,
  feedback,
  initialData,
  rejection_data,
  bookReviewRating,
  isRating,
  isFromReview,
  rejectViewOnly,
}: RejectBookProps) {
  const [active, setActive] = useState<any>(enums[0].value);
  const [reason, setReason] = useState<any>("");
  const { user } = useSelector((state: any) => state.root);
  const [rejectData, setRejectData] = useState(enums);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [bookRating, setBookRating] = useState<any>(0);

  const handleSubmit = () => {
    if (bookRating === 0 && user?.role?.role_id === 2 && !isFromReview) {
      toastMessage("error", "Please put ratings.");
    } else {
      const isLastOption = Number(active) === enums[enums.length - 1].value;
      const rejectReason = isLastOption
        ? reason
        : enums.find((item: any) => item.value === Number(active))?.label;

      const service =
        user?.role?.role_id === 3 ? assignHODSerivce : rejectBookService;

      const data =
        user?.role?.role_id === 2
          ? {
              book_id: selectedBook?.id,
              feedback: {
                rating: isFromReview
                  ? { value: bookReviewRating }
                  : { value: bookRating },
                intial_review: initialData || null,
                detailed_review: feedback || null,
                reject_reason: rejectData,
              },
              reason: rejectReason,
              rating: isFromReview ? bookReviewRating : bookRating,
              comment: "",
            }
          : {
              book_id: selectedBook?.id,
              feedback: {
                rating: bookReviewRating ? { value: bookReviewRating } : null,
                intial_review: initialData || null,
                detailed_review: feedback || null,
                reject_reason: rejectData,
              },
              comment: "",
            };

      setLoader(true);
      service(data)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          onClose();
          navigate(routeConstant.dashboard.path);
          setLoader(false);
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data?.message);
          console.log("err", err?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  const onClose = () => {
    handleClose();
    setActive(enums[0].value);
    setReason("");
    setBookRating(0);
    setRejectData(enums);
  };

  const handleChange = (e: any) => {
    setActive(e.target.value);
    setRejectData((prevEnums) =>
      prevEnums.map((item) => ({
        ...item,
        marked: item.value === Number(e.target.value),
      }))
    );

    if (Number(e.target.value) !== enums[enums.length - 1].value) {
      setReason("");
    }
  };

  useEffect(() => {
    if (rejection_data && open) {
      const markedItem = rejection_data.find((item: any) => item?.marked);

      if (markedItem) {
        setActive(markedItem.value);
      }
    }

    const lastEnum = enums[enums.length - 1];
    if (rejection_data && rejection_data.length > 0) {
      const markedItem = rejection_data.find((item: any) => item?.marked);

      if (markedItem) {
        setRejectData((prevEnums) => [
          ...prevEnums.slice(0, -1),
          {
            ...prevEnums[prevEnums.length - 1],
            marked: true,
          },
        ]);
      }

      if (lastEnum && lastEnum.value) {
        setReason(markedItem?.reason);
        setRejectData((prevEnums) => [
          ...prevEnums.slice(0, -1),
          {
            ...prevEnums[prevEnums.length - 1],
            reason: markedItem?.reason,
          },
        ]);
      }
    }
  }, [rejection_data, open]);

  useEffect(() => {
    if (rejectViewOnly && open) {
      const markedItem = selectedBook?.feedback?.reject_reason?.find(
        (item: any) => item?.marked
      );

      setBookRating(selectedBook?.rating);

      if (markedItem) {
        setActive(markedItem.value);
      }

      const lastEnum = enums[enums.length - 1];
      if (
        selectedBook?.feedback?.reject_reason &&
        selectedBook?.feedback?.reject_reason.length > 0
      ) {
        const markedItem = selectedBook?.feedback?.reject_reason.find(
          (item: any) => item?.marked
        );

        if (markedItem) {
          setRejectData((prevEnums) => [
            ...prevEnums.slice(0, -1),
            {
              ...prevEnums[prevEnums.length - 1],
              marked: true,
            },
          ]);
        }

        if (lastEnum && lastEnum.value) {
          setReason(markedItem?.reason);
          setRejectData((prevEnums) => [
            ...prevEnums.slice(0, -1),
            {
              ...prevEnums[prevEnums.length - 1],
              reason: markedItem?.reason,
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line
  }, [rejectViewOnly]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent sx={{ padding: "50px" }}>
          <Icons.CrossIcon className={styles.crossIcon} onClick={handleClose} />

          <div className={styles.title}>
            {rejectViewOnly
              ? "Reason of Rejection"
              : "Select the reason for rejection"}
          </div>

          <div style={{ marginTop: "20px" }}>
            <FormControl disabled={rejectViewOnly}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {rejectData?.map((option) => (
                  <div className={styles.radio} key={option.value}>
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={""}
                      onChange={handleChange}
                      checked={option.value === Number(active)}
                    />
                    <span>{option.label}</span>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </div>

          {Number(active) === enums[enums.length - 1].value && (
            <textarea
              className={styles.textarea}
              value={reason}
              disabled={rejectViewOnly}
              placeholder="Add reason"
              onChange={(e) => {
                setReason(e.target.value);
                setRejectData((prevEnums) => [
                  ...prevEnums.slice(0, -1),
                  {
                    ...prevEnums[prevEnums.length - 1],
                    reason: e.target.value,
                  },
                ]);
              }}
            />
          )}

          {isRating && (
            <div className={styles.ratingContainer}>
              <div className={styles.title}>Rating</div>

              <Rating
                name="simple-controlled"
                value={bookRating}
                readOnly={rejectViewOnly}
                onChange={(event, newValue) => {
                  setBookRating(newValue);
                }}
                max={10}
              />
            </div>
          )}
          {rejectViewOnly ? null : (
            <CustomButton
              text="Submit"
              buttonStyle={styles.btn}
              handleClick={handleSubmit}
            />
          )}

          {loader && <Loader />}
        </DialogContent>
      </Dialog>
    </div>
  );
}
