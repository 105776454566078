import React, { Fragment, useState } from "react";
import styles from "./style.module.scss";
import { Skeleton } from "@mui/material";

interface DetailsCardProps {
  details: { id: string; value: string }[];
  loader: boolean;
  info: any;
  account?: any;
}

const DetailsCard = ({ details, loader, info, account }: DetailsCardProps) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.userProfilewrapper}>
        <div className={styles.userInfoWrapper}>
          {account && account.image && (
            <figure className={styles.userProfileImageWrapper}>
              <img
                className={styles.profileImage}
                alt="Parent Profile"
                src="https://images.pexels.com/photos/10964535/pexels-photo-10964535.jpeg"
              ></img>
            </figure>
          )}
          <div className={styles.userInfo}>
            <div className={styles.name}>
              {account && (
                <>
                  <span className={styles.bankHeading}>
                    {account && account.title ? account.title : null}:{" "}
                  </span>
                  <span className={styles.bankName}>
                    {account && account.value ? account.value : null}
                  </span>
                </>
              )}
            </div>
            <div className={styles.type}>
              {account && account.address ? account?.address : null}
            </div>
          </div>
        </div>
        {/* <button onClick={deactivateHandler} className={styles.deactiveBtn}>
          Deactivate
        </button> */}
      </div>
      <div className={styles.line}></div>
      <div className={styles.detailsWrapper}>
        {loader ? (
          <div className={styles.outerFlex}>
            {details.map((heading: any) => {
              return (
                <div key={heading.id} className={styles.innerFlex}>
                  <span className={styles.heading}>{heading.value}:</span>
                  <span>
                    <Skeleton
                      variant="rectangular"
                      width={70}
                      height={15}
                    ></Skeleton>
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.outerFlex}>
            {Object.entries(info[0]).map(([key, value]) => (
              <div className={styles.innerFlex} key={key}>
                <div className={styles.heading}>
                  {(key.charAt(0).toUpperCase() + key.slice(1)).replaceAll(
                    "_",
                    " "
                  )}
                </div>
                :
                <span className={styles.value}>
                  {typeof value === "number" ? value : `${value}`}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsCard;
