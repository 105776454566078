import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { Icons } from "assets";
import TableSkeleton from "shared/components/TableSkeleton";
import ItemsPerPage from "shared/components/itemsPerPage";
import NoDataFound from "shared/components/noDataFound";
import Pagination from "shared/components/pagination";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

import { searchHandlerHelper } from "shared/utils/helpers";
import {
  getAllSchoolAccounts,
  rejectSchool,
  approveSchool,
  schoolStats,
  blockSchool,
  unBlockSchool,
} from "shared/services/adminService";

import { headings, columnHeadings, skeletonInfo } from "./constants";
import styles from "./style.module.scss";
import useWindowSize from "shared/customHooks/windowSize";
import useDebounce from "shared/customHooks/useDebounce";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import Confirmation from "shared/modals/confirmation";
import SchoolApprovedModal from "shared/modals/schoolApprovedModal";
import { toastMessage } from "shared/components/toast";
import { useNavigate } from "react-router-dom";
import CardComp from "shared/components/card";

const SchoolRequests = () => {
  const navigate = useNavigate();
  const screenWidth = useWindowSize();
  const [search, setSearch] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [schoolAccounts, setSchoolAccounts] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [openSuccessfullyApprovedModal, setOpenSuccessfullyApprovedModal] =
    useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<number | null>(null);
  const [schoolName, setSchoolName] = useState<string>("");
  const [modalType, setModalType] = useState<string>("");
  const [statsLoader, setStatsLoader] = useState<boolean>(false);
  const [schoolStatsData, setSchoolStatsData] = useState<any>([]);

  const cardData = [
    {
      title: "Total Requests Received",
      stats: schoolStatsData?.total ? schoolStatsData?.total : 0,
    },
    {
      title: "Total Accounts Approved",
      stats: schoolStatsData?.approved ? schoolStatsData?.approved : 0,
    },
    {
      title: " Total Accounts Rejected",
      stats: schoolStatsData?.rejected ? schoolStatsData?.rejected : 0,
    },
  ];

  useBreadcrumbs({
    title: "School Requests",
    path: routeConstant.schoolRequests.path,
  });

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );
  const handleOpenApproveModal = (school: any) => {
    setModalType("Approve");
    setSchoolId(school.id);
    setSchoolName(school.full_name);
    setOpenApproveModal(true);
  };

  const handleOpenRejectModal = (school: any) => {
    setModalType("Reject");
    setSchoolId(school.id);
    setSchoolName(school.full_name);
    setOpenRejectModal(true);
  };
  const handleApproveConfirmation = () => {
    setLoader(true);
    approveSchool(schoolId)
      .then(({ data, status }) => {
        if (status) {
          setOpenApproveModal(false);
          getPendingSchools();
          toastMessage("success", data?.message);
          setOpenSuccessfullyApprovedModal(true);
          setLoader(false);
          setSchoolName(data?.school?.full_name);
        }
      })
      .catch((err) => {
        console.log("Error----", err);
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handleRejectConfirmation = () => {
    setLoader(true);
    rejectSchool(schoolId)
      .then(({ data, status }) => {
        if (status) {
          setOpenRejectModal(false);
          getPendingSchools();
          setLoader(false);
          toastMessage("success", data?.message);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Error---", err);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getPendingSchools = () => {
    setLoader(true);
    getAllSchoolAccounts({
      selectedFilter: 0,
      itemsPerPage,
      activePage,
      search: searchText,
    })
      .then(({ data, status }) => {
        if (status) {
          const { data: response } = data;
          const {
            current_page,
            last_page,
            per_page,
            total,
            data: accounts,
          } = response;

          setActivePage(current_page);
          setItemsPerPage(per_page);
          setTotalPage(last_page);
          setTotalItems(
            accounts?.filter((account: any) => account?.status === 0).length
          );
          setLoader(false);
          setSchoolAccounts(
            accounts?.filter((account: any) => account?.status === 0)
          );
        }
      })
      .catch((err) => {
        console.log("Error-----", err);
        setLoader(false);
      });
  };

  const getSchoolStats = () => {
    setStatsLoader(true);
    schoolStats()
      .then(({ data, status }) => {
        if (status) {
          const { data: stats } = data;
          setSchoolStatsData(stats);
          setStatsLoader(false);
        }
      })
      .catch((err: any) => {
        setStatsLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    // Call Api here
    getPendingSchools();
  }, [selectedFilter, activePage, itemsPerPage, searchText]);

  useEffect(() => {
    getSchoolStats();
  }, []);

  return (
    <section>
      {/* Stats */}
      <Box>
        <Grid container spacing={2}>
          {cardData?.map((card: any, index) => {
            return (
              <Grid item xs={12} sm={4} key={index}>
                <CardComp
                  isLoading={statsLoader}
                  stats={card.stats}
                  title={card.title}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {/* Table */}
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>{headings.mainHeading}</div>
            <div className={styles.tableSubHeading}>{headings.subHeading}</div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search By School Name"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          className={styles.container}
          component={Paper}
          sx={
            screenWidth > 990
              ? {
                  width: `${screenWidth - 300}px`,
                }
              : {
                  boxShadow: "none",
                }
          }
        >
          <Table className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                {columnHeadings?.map((heading, index) => {
                  return (
                    <TableCell key={heading.id}>{heading.title}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <TableSkeleton
                  rows={skeletonInfo.rows}
                  cellCount={skeletonInfo.cellCount}
                  actionCount={skeletonInfo.actions}
                  showPersonData={false}
                ></TableSkeleton>
              ) : (
                schoolAccounts?.map((account: any) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ minWidth: "188px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Avatar
                            alt={account?.username}
                            src={account?.school?.profile_picture}
                            sx={{ width: 40, height: 40 }}
                          />
                          <div className={styles.accountInfo}>
                            <span className={styles.fullName}>
                              {account?.school?.school_name
                                ? account?.school?.school_name
                                : "Not Provided"}
                            </span>
                            <span className={styles.email}>
                              {account?.email ? account?.email : "Not Provided"}
                            </span>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {account?.school?.phone
                          ? account?.school.phone
                          : "Not Provided"}
                      </TableCell>
                      {/* <TableCell>
                        {account?.email ? account?.email : "NOt Provided"}
                      </TableCell> */}

                      <TableCell sx={{ minWidth: "120px" }}>
                        {account?.school?.students_limit
                          ? account?.school?.students_limit
                          : "Not Provided"}
                      </TableCell>
                      <TableCell sx={{ minWidth: "120px" }}>
                        {account?.school?.teachers_limit
                          ? account?.school?.teachers_limit
                          : "Not Provided"}
                      </TableCell>
                      <TableCell>
                        <span className={styles.pending}>
                          {account?.status === 0 ? "Pending" : "Not Provided"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <Tooltip title="Ticket">
                            <button
                              className={styles.iconWrapper}
                              onClick={() =>
                                navigate(
                                  routeConstant.singleTicket.path.replace(
                                    ":id",
                                    account?.id
                                  ),
                                  {
                                    state: {
                                      user: account,
                                    },
                                  }
                                )
                              }
                            >
                              <ChatBubbleIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Approve">
                            <span
                              onClick={() => handleOpenApproveModal(account)}
                              className={styles.iconWrapper}
                            >
                              <DoneIcon />
                            </span>
                          </Tooltip>
                          <Tooltip title="Reject">
                            <span
                              onClick={() => handleOpenRejectModal(account)}
                              className={styles.iconWrapper}
                            >
                              <CloseIcon />
                            </span>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          {totalItems &&
          activePage &&
          totalPage > 1 &&
          schoolAccounts?.length ? (
            <Pagination
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          ) : null}
        </Box>
        <Box>
          {totalItems && activePage && currentPage && schoolAccounts?.length ? (
            <ItemsPerPage
              setActivePage={setActivePage}
              currentPage={currentPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage ? itemsPerPage : 10}
              totalItems={totalItems}
            />
          ) : null}
        </Box>
        <Box>{!loader && !(schoolAccounts?.length > 0) && <NoDataFound />}</Box>
      </Box>

      {/* Open Approve/Reject Modal */}
      <Confirmation
        loader={loader}
        open={modalType === "Approve" ? openApproveModal : openRejectModal}
        handleClose={
          modalType === "Approve"
            ? () => setOpenApproveModal(false)
            : () => setOpenRejectModal(false)
        }
        text={
          modalType === "Approve"
            ? "Are you sure you want to approve this request ?"
            : "Are you sure you want to reject this request ?"
        }
        handleClick={
          modalType === "Approve"
            ? handleApproveConfirmation
            : handleRejectConfirmation
        }
        showTextArea={false}
      />
      {/* Successfully Approved Modal */}
      <SchoolApprovedModal
        content={`School has been approved and and now it can add teachers and students`}
        open={openSuccessfullyApprovedModal}
        closeModal={() => setOpenSuccessfullyApprovedModal(false)}
      />
    </section>
  );
};

export default SchoolRequests;
