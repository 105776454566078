import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Cards from "shared/components/card";
import GroupIcon from "@mui/icons-material/Group";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Chart from "shared/components/chart";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCoinsGraph,
  getDashboardStatsProjectHead,
  getMembershipGraphs,
  getSoftCopyGraph,
  getUserGraph,
} from "shared/services/projectHeadService";
import { Icons } from "assets";
import { toastMessage } from "shared/components/toast";
import moment from "moment";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";

function DashboardHead() {
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [usersLabels, setUserLabels] = useState<any>(null);
  const [userStats, setUserStats] = useState<any>(null);
  const [userGraphDates, setUserGraphDates] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [membershipStats, setMembershipStats] = useState<any>(null);
  const [membershipStatsLoader, setMembershipStatsLoader] =
    useState<boolean>(true);
  const [membershipLabels, setMembershipLabels] = useState<any>(null);
  const [membershipDates, setMembershipDates] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [coinsStats, setCoinsStats] = useState<any>(null);
  const [coinsStatsLoader, setCoinsStatsLoader] = useState<boolean>(true);
  const [coinsLabels, setCoinsLabels] = useState<any>(null);
  const [coinsDates, setCoinsDates] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [softCopy, setSoftCopy] = useState<any>(null);
  const [softCopyLoader, setSoftCopyLoader] = useState<boolean>(true);
  const [userStatsLoader, setUserStatsLoader] = useState<boolean>(true);
  const [softCopyLables, setSoftCopyLables] = useState<any>(null);
  const [softCopyDates, setSoftCopyDates] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const onChangeDate = (dates: any, action: string) => {
    const [start, end] = dates;
    if (action === "users") {
      setUserGraphDates({
        startDate: start,
        endDate: end,
      });
    } else if (action === "coins") {
      setCoinsDates({
        startDate: start,
        endDate: end,
      });
    } else if (action === "sales") {
      setSoftCopyDates({
        startDate: start,
        endDate: end,
      });
    } else if (action === "membership") {
      setMembershipDates({
        startDate: start,
        endDate: end,
      });
    }
    // setStartDate(start);
    // setEndDate(end);
  };

  const cardData = [
    {
      title: "Total users of AlifLaila",
      icon: GroupIcon,
      stats: dashboardData?.users?.total,
    },
    {
      title: "Total coins earned by partners",
      icon: MonetizationOnIcon,
      stats: dashboardData?.earnings,
    },
    {
      title: "Sales of books",
      icon: ImportContactsIcon,
      stats: dashboardData?.sales?.total,
    },
    {
      title: "Membership users",
      icon: CreditCardIcon,
      stats: dashboardData?.memberships?.memberships?.total,
    },
  ];

  const getDashboardStats = () => {
    setLoader(true);
    getDashboardStatsProjectHead()
      .then(({ data }) => {
        setDashboardData(data?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getUsersStats = () => {
    setUserStatsLoader(true);
    getUserGraph(userGraphDates)
      .then(({ data }) => {
        setUserLabels(data?.data?.labels);
        setUserStats(data?.data?.users_joined);
        setUserStatsLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        setUserStatsLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getMembershipStats = () => {
    setMembershipStatsLoader(true);
    getMembershipGraphs(membershipDates)
      .then(({ data }) => {
        setMembershipLabels(data?.data?.labels);
        setMembershipStats(data?.data?.memberships);
        setMembershipStatsLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setMembershipStatsLoader(false);
      });
  };

  const getCoinsStats = () => {
    setCoinsStatsLoader(true);
    getCoinsGraph(coinsDates)
      .then(({ data }) => {
        setCoinsStats(data?.data?.coins_earned);
        setCoinsLabels(data?.data?.labels);
        setCoinsStatsLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setCoinsStatsLoader(false);
      });
  };

  const getSoftCopyStats = () => {
    setSoftCopyLoader(true);
    getSoftCopyGraph(softCopyDates)
      .then(({ data }) => {
        setSoftCopyLables(data?.data?.labels);
        setSoftCopy(data?.data?.sales?.total_sales);
        setSoftCopyLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setSoftCopyLoader(false);
      });
  };

  useEffect(() => {
    getDashboardStats();
  }, []);

  useEffect(() => {
    getUsersStats();
    // eslint-disable-next-line
  }, [userGraphDates?.endDate]);

  useEffect(() => {
    getCoinsStats();
    // eslint-disable-next-line
  }, [coinsDates?.endDate]);

  useEffect(() => {
    getSoftCopyStats();
    // eslint-disable-next-line
  }, [softCopyDates?.endDate]);

  useEffect(() => {
    getMembershipStats();
    // eslint-disable-next-line
  }, [membershipDates?.endDate]);

  useBreadcrumbs({
    title: "Dashboard",
    path: routeConstant?.dashboard?.path,
  });

  return (
    <div>
      <Grid container spacing={2}>
        {cardData?.map((item) => {
          return (
            <Grid item xs={12} sm={3}>
              <Cards
                Icon={item?.icon}
                stats={item?.stats}
                title={item?.title}
                style={styles.card}
                isLoading={loader}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={2} className={styles.gridContainer}>
        <Grid xs={12} md={6} className={styles.leftGrid}>
          <Card
            sx={{ marginBottom: "40px", minHeight: "375px" }}
            className={styles.card}
          >
            <CardContent className={styles.cardContent}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className={styles.title}>Users joing Figures</div>
                <DatePicker
                  selected={userGraphDates?.startDate}
                  onChange={(date: any) => onChangeDate(date, "users")}
                  startDate={userGraphDates?.startDate}
                  endDate={userGraphDates?.endDate}
                  selectsRange
                  placeholderText="Select range"
                />
              </Box>

              {userStatsLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <Skeleton
                    width={60}
                    height={60}
                    variant="circular"
                  ></Skeleton>
                  <Skeleton
                    width={200}
                    height={15}
                    variant="rectangular"
                  ></Skeleton>
                </div>
              ) : userStats?.some((item: any) => item > 0) ? (
                <Chart labels={usersLabels} sales={userStats} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "15%",
                  }}
                >
                  {!userStatsLoader && (
                    <div
                      className={classNames(styles.noDataCard, styles.noData)}
                    >
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} className={styles.rightGrid}>
          <Card
            sx={{ marginBottom: "40px", minHeight: "375px" }}
            className={styles.card}
          >
            <CardContent className={styles.cardContent}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className={styles.title}>Coins earned by partners</div>
                <DatePicker
                  selected={coinsDates?.startDate}
                  onChange={(date: any) => onChangeDate(date, "coins")}
                  startDate={coinsDates?.startDate}
                  endDate={coinsDates?.endDate}
                  selectsRange
                  placeholderText="Select range"
                />
              </Box>

              {coinsStatsLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <Skeleton
                    width={60}
                    height={60}
                    variant="circular"
                  ></Skeleton>
                  <Skeleton
                    width={200}
                    height={15}
                    variant="rectangular"
                  ></Skeleton>
                </div>
              ) : coinsStats?.some((item: any) => item > 0) ? (
                <Chart labels={coinsLabels} sales={coinsStats} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "15%",
                  }}
                >
                  {!coinsStatsLoader && (
                    <div
                      className={classNames(styles.noDataCard, styles.noData)}
                    >
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} className={styles.leftGrid}>
          <Card
            sx={{ marginBottom: "40px", minHeight: "375px" }}
            className={styles.card}
          >
            <CardContent className={styles.cardContent}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className={styles.title}>Sales figure from soft copy</div>
                <DatePicker
                  selected={softCopyDates?.startDate}
                  onChange={(date: any) => onChangeDate(date, "sales")}
                  startDate={softCopyDates?.startDate}
                  endDate={softCopyDates?.endDate}
                  selectsRange
                  placeholderText="Select range"
                />
              </Box>
              {softCopyLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <Skeleton
                    width={60}
                    height={60}
                    variant="circular"
                  ></Skeleton>
                  <Skeleton
                    width={200}
                    height={15}
                    variant="rectangular"
                  ></Skeleton>
                </div>
              ) : softCopy?.some((item: any) => item > 0) ? (
                <Chart labels={softCopyLables} sales={softCopy} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "15%",
                  }}
                >
                  {!softCopyLoader && (
                    <div
                      className={classNames(styles.noDataCard, styles.noData)}
                    >
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={6} className={styles.rightGrid}>
          <Card
            sx={{ marginBottom: "40px", minHeight: "375px" }}
            className={styles.card}
          >
            <CardContent className={styles.cardContent}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className={styles.title}>Memberships graph</div>
                <DatePicker
                  selected={membershipDates?.startDate}
                  onChange={(date: any) => onChangeDate(date, "membership")}
                  startDate={membershipDates?.startDate}
                  endDate={membershipDates?.endDate}
                  selectsRange
                  placeholderText="Select range"
                />
              </Box>
              {membershipStatsLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <Skeleton
                    width={60}
                    height={60}
                    variant="circular"
                  ></Skeleton>
                  <Skeleton
                    width={200}
                    height={15}
                    variant="rectangular"
                  ></Skeleton>
                </div>
              ) : membershipStats?.some((item: any) => item > 0) ? (
                <Chart labels={membershipLabels} sales={membershipStats} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "15%",
                  }}
                >
                  {!membershipStatsLoader && (
                    <div
                      className={classNames(styles.noDataCard, styles.noData)}
                    >
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardHead;
