import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import { useEffect, useState } from "react";
import Loader from "shared/components/loader/animation";
import Pagination from "shared/components/pagination";
import useWindowSize from "shared/customHooks/windowSize";
import { routeConstant } from "shared/routes/routesConstant";
import styles from "./style.module.scss";

import EditIcon from "@mui/icons-material/Edit";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import CustomButton from "shared/components/customButton";
import ItemsPerPage from "shared/components/itemsPerPage";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import useDebounce from "shared/customHooks/useDebounce";
import Confirmation from "shared/modals/confirmation";
import {
  activateGenreService,
  allGenreService,
  deleteGenresService,
  inActivateGenreService,
} from "shared/services/productManagerService";
import { searchHandlerHelper } from "shared/utils/helpers";
import AddCategory from "./AddCategory";
import SkeletonLoader from "./skeletonLoader";

function CustomeSupport() {
  const screenWidth = useWindowSize();
  const [activePage, setActivePage] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [allGenres, setAllGenres] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirm, setOpenConfirmation] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const getAllGenres = () => {
    setLoader(true);
    allGenreService(searchText, activePage, null, itemsPerPage)
      .then(({ data }) => {
        setAllGenres(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setLoader(false);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
        setTotalItems(data?.data?.total);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllGenres();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage, currentPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const handleOpenConfirmation = (label: String, type: String, item: any) => {
    setOpenConfirmation(true);
    setAction({
      label: label,
      type: type,
    });
    setSelectedItem(item);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAction({
      label: "",
      type: "",
    });
    setSelectedItem(null);
  };

  const handleActions = (action: any) => {
    setActionLoader(true);
    let service =
      action.type === "delete"
        ? deleteGenresService
        : action.type === "active"
        ? activateGenreService
        : action.type === "inactive"
        ? inActivateGenreService
        : null;

    service?.(selectedItem?.id)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        setActionLoader(false);
        getAllGenres();
        setSelectedItem(null);
        handleCloseConfirmation();
        setSelectedItem(null);
      })
      .catch((err) => {
        setActionLoader(false);
        toastMessage("error", err?.response?.data?.message);
        setSelectedItem(null);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const handleOpen = (item?: any) => {
    setOpen(true);
    if (item) {
      setSelectedItem(item);
    }
  };

  useBreadcrumbs({
    title: "Books catalog",
    path: routeConstant.booksCatalog.path,
  });


  return (
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Books Catalog.</div>
            <div className={styles.tableSubHeading}>
              All genre are showing here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <CustomButton
                text={"Add New"}
                buttonStyle={styles.addBtn}
                handleClick={() => handleOpen()}
              />
            </div>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e: any) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total Books</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allGenres?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>
                          <span
                            className={`${
                              item?.is_active
                                ? styles.status_active
                                : styles.status_inactive
                            }`}
                          >
                            {item?.is_active ? "Active" : "Inactive"}
                          </span>
                        </TableCell>
                        <TableCell>
                          {item?.books_count
                            ? item?.books_count?.total
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip
                              arrow
                              title={
                                item?.is_active ? "Activate" : "Deactivate"
                              }
                            >
                              <button
                                onClick={() =>
                                  handleOpenConfirmation(
                                    `Are you sure you want to ${
                                      item?.is_active === 1
                                        ? "deactivate"
                                        : "activate"
                                    } this catalog?`,
                                    `${
                                      item?.is_active === 1
                                        ? "inactive"
                                        : item?.is_active === 0
                                        ? "active"
                                        : ""
                                    }`,
                                    item
                                  )
                                }
                              >
                                {item?.is_active ? (
                                  <FlashOnIcon />
                                ) : (
                                  <FlashOffIcon />
                                )}
                              </button>
                            </Tooltip>
                            <Tooltip arrow title={"Edit"}>
                              <button onClick={() => handleOpen(item)}>
                                <EditIcon />
                              </button>
                            </Tooltip>
                            {/* <Tooltip arrow title={"Delete"}>
                              <button
                                onClick={() =>
                                  handleOpenConfirmation(
                                    "Are you sure, you want to delete this Genre?",
                                    "delete",
                                    item
                                  )
                                }
                              >
                                <DeleteIcon />
                              </button>
                            </Tooltip> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allGenres?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && allGenres.length > 0 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        <Box>
          {allGenres?.length > 1 ? (
            <ItemsPerPage
              setActivePage={setActivePage}
              currentPage={currentPage}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage ? itemsPerPage : 10}
              totalItems={totalItems}
            />
          ) : null}
        </Box>
        <Confirmation
          open={openConfirm}
          handleClose={() => handleCloseConfirmation()}
          handleClick={() => handleActions(action)}
          text={action.label}
        />

        {open && <AddCategory
          open={open}
          handleClose={handleClose}
          selectedCategory={selectedItem}
          getAllGenres={getAllGenres}
        />}

        {actionLoader && <Loader />}
      </Box>
    </div>
  );
}

export default CustomeSupport;
