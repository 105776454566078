import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";

import { Icons } from "assets";

interface TermsAndPolicyCardProps {
  text: string;
  type: string;
}
const TermsAndPolicyCard = ({ text, type }: TermsAndPolicyCardProps) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className={styles.content}>
        <i>
          {type === "policy" ? (
            <span className={styles.icon}>
              <Icons.PrivacyPolicy />
            </span>
          ) : (
            <span className={styles.icon}>
              <Icons.TermsAndCondition />
            </span>
          )}
        </i>
        <p>{text}</p>
      </div>
      <div className={styles.btnWrapper}>
        <button
          onClick={() => {
            navigate(
              type === "policy" ? "/privacy-policy" : "/terms-and-conditions",
              { state: { type, text } }
            );
          }}
        >
          View/Edit Now
        </button>
      </div>
    </div>
  );
};

export default TermsAndPolicyCard;
