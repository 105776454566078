import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./style.module.scss";
import { Icons } from "assets";
import { Checkbox } from "@mui/material";
import CustomButton from "shared/components/customButton";
import { toastMessage } from "shared/components/toast";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SendToRevisionProps {
  open: boolean;
  handleClose: any;
  formData: any;
  handleSendRevision: any;
}

export default function SendToRevision({
  open,
  handleClose,
  formData,
  handleSendRevision,
}: SendToRevisionProps) {
  const [form, setForm] = useState<any>(null);

  useEffect(() => {
    if (open) {
      const formInside = Object.keys(formData).map((key) => {
        const item = { ...formData[key], key: key };

        if (item.option === 0) {
          return { ...item, checked: !item.checked };
        }

        return item;
      });

      setForm(formInside);
    }
    // eslint-disable-next-line
  }, [open, formData]);

  const handleChecked = (item: any) => {
    setForm((prevForm: any) => {
      const updatedForm = [...prevForm];
      const index = updatedForm.findIndex((itm) => item.key === itm.key);
      if (index > -1) {
        updatedForm[index] = { ...item, checked: !item.checked };
      }
      return updatedForm;
    });
  };

  function convertArrayToObject(inputArray: any) {
    return inputArray.reduce((acc: any, item: any) => {
      const key = item.key;
      acc[key] = { ...item };
      delete acc[key].key;
      return acc;
    }, {});
  }

  const handleSubmit = () => {
    const error = form.some((itm: any) => itm.checked === true);

    if (!error) {
      toastMessage("error", "Please select any point to send publisher.");
    } else {
      handleSendRevision(convertArrayToObject(form));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(80),
              position: "relative",
            },
          },
        }}
      >
        <DialogContent>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          />
          <div className={styles.title} style={{ marginTop: "10px" }}>
            Select points to send for revision.
          </div>
          {form?.map((item: any) => {
            return (
              <div className={styles.subTitle}>
                <Checkbox
                  className={styles.checkbox}
                  checked={item.checked}
                  onChange={() => handleChecked(item)}
                />
                <div>{item?.label}</div>
              </div>
            );
          })}

          <CustomButton
            text="Send"
            handleClick={handleSubmit}
            buttonStyle={styles.btnCustom}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
