import { Avatar, Box, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DatePicker from "react-datepicker";
import styles from "./style.module.scss";
import Card from "shared/components/card";
import { Icons } from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "shared/components/pagination";
import Filter from "shared/components/filterButton";
import useWindowSize from "shared/customHooks/windowSize";
import TransactionDetail from "shared/modals/transactionDetail";
import { getEarningsService } from "shared/services/accountantService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import { rolesRoutes } from "shared/routes/allRoutes";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaidIcon from "@mui/icons-material/Paid";
import {
  getTransactionService,
  getTransactionsStatsService,
} from "shared/services/projectHeadService";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";

function DashboardAccountant() {
  const {
    user: { user },
  } = useSelector((state: any) => state.root);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const screenWidth = useWindowSize();
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [allTransactions, setAllTransactions] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const widthTablet = useMediaQuery("(max-width:767px)");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const current_role = rolesRoutes.find(
    (role) => role.role_id === Number(user?.role)
  );
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [dashboardLoader, setDashboardLoader] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const cardData = [
    {
      title: "Total amount",
      icon: AccountBalanceIcon,
      stats: dashboardData?.total_amount,
    },
    {
      title: "Total Cash-in",
      icon: LocalAtmIcon,
      stats: dashboardData?.total_cash_in,
    },
    {
      title: "Total Cash-out",
      icon: AttachMoneyIcon,
      stats: dashboardData?.total_cash_out,
    },
    {
      title: "Total no of transactions",
      icon: PaidIcon,
      stats: dashboardData?.transactions?.completed,
    },
  ];

  const getStats = () => {
    setDashboardLoader(true);
    getTransactionsStatsService()
      .then(({ data }) => {
        setDashboardLoader(false);
        setDashboardData(data?.data);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setDashboardLoader(false);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  const filterOption = [
    {
      option: "Completed",
      value: 2,
    },
    {
      option: "Pending",
      value: 0,
    },
  ];

  const onChange = (dates: any) => {
    console.log(dates);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getEarnings = () => {
    let formatedStartDate;
    let formatedEndDate;
    if (startDate && endDate) {
      formatedStartDate = moment(startDate).format("YYYY-MM-DD");
      formatedEndDate = moment(endDate).format("YYYY-MM-DD");
    }
    let service =
      current_role?.title === "Accountant"
        ? getEarningsService
        : getTransactionService;
    setLoader(true);
    service(
      searchText,
      activePage,
      selectedFilter?.value,
      formatedStartDate,
      formatedEndDate,
      itemsPerPage
    )
      .then(({ data }) => {
        setAllTransactions(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        setStartDate(null);
        setEndDate(null);
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getEarnings();
    // eslint-disable-next-line
  }, [searchText, activePage, selectedFilter, endDate, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const PAYMENT_STATUS = [
    {
      status: "Pending",
      value: 0,
    },
    {
      status: "Completed",
      value: 2,
    },
  ];

  const handleopenDetail = (item: any) => {
    setOpenDetail(true);
    setSelectedTransaction(item);
  };

  const handleCloseTransaction = () => {
    setSelectedTransaction(null);
    setOpenDetail(false);
  };

  const admin_roles = [
    { id: 1, label: "School" },
    { id: 2, label: "Teacher" },
    { id: 3, label: "Parent" },
    { id: 4, label: "Reader" },
    { id: 5, label: "Author" },
    { id: 6, label: "Publisher" },
  ];

  useBreadcrumbs({
    title: "Transactions",
    path: routeConstant.transactions.path,
  });

  return (
    <div>
      {current_role?.title !== "Accountant" && (
        <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
          {cardData?.map((item) => {
            return (
              <Grid item xs={widthTablet ? 12 : 3}>
                <Card
                  Icon={item?.icon}
                  stats={item?.stats}
                  title={item?.title}
                  isLoading={dashboardLoader}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Transaction history</div>
            <div className={styles.tableSubHeading}>
              All members transactions history.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <div className={styles.datesContainer}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Select Range"
                dateFormat="d MMM"
                maxDate={new Date()}
              />
            </div>
            <div className={styles.filterButtonWrapper}>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Receiver</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Payment Date</TableCell>
                {/* <TableCell>Email</TableCell> */}
                <TableCell>Amount (Rs)</TableCell>
                <TableCell>Payment Type</TableCell>
                {/* <TableCell>Coins</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell> */}
                {current_role?.title === "Accountant" && (
                  <TableCell>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allTransactions?.map((item: any) => {
                  let payment_type = "";
                  if (item?.payment_type === 1) {
                    payment_type = "Soft Copy";
                  } else if (item?.payment_type) {
                    payment_type = "Hard Copy";
                  }
                  return (
                    <TableRow className={styles.tableRow}>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <Avatar
                            src={
                              item?.user?.publisher
                                ? item?.user?.publisher?.profile_picture
                                : item?.user?.author
                                ? item?.user?.author?.profile_picture
                                : ""
                            }
                          />
                          <span
                            className={styles.tableItem}
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.user?.publisher
                              ? item?.user?.publisher?.full_name
                              : item?.user?.author
                              ? item?.user?.author?.full_name
                              : ""}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {item?.transaction_id
                          ? item?.transaction_id
                          : "Transaction Pending"}
                      </TableCell>
                      <TableCell>
                        {item?.payment_date ? item?.payment_date : "--/--/--"}
                      </TableCell>
                      {/* <TableCell>{item?.user?.email}</TableCell> */}
                      <TableCell>{"Rs. " + item?.amount}</TableCell>
                      <TableCell>
                        {item?.payment_type ? payment_type : "Not Provided"}
                      </TableCell>
                      {/* <TableCell>{item?.coins}</TableCell>
                      <TableCell>
                        {
                          admin_roles.find(
                            (role) => role.id === Number(item?.user?.role)
                          )?.label
                        }
                      </TableCell>
                      <TableCell>
                        {
                          PAYMENT_STATUS.find(
                            (itm) => itm?.value === item?.status
                          )?.status
                        }
                      </TableCell> */}
                      {current_role?.title === "Accountant" && (
                        <TableCell>
                          {/*Status pending then upload receipt less view receipt*/}
                          {
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              className={styles.actionBtn}
                            >
                              <button onClick={() => handleopenDetail(item)}>
                                {item?.status !== 0 ? (
                                  <RemoveRedEyeIcon />
                                ) : (
                                  <CloudUploadIcon />
                                )}
                              </button>
                            </Box>
                          }
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allTransactions?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allTransactions?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
      <TransactionDetail
        open={openDetail}
        handleClose={handleCloseTransaction}
        selectedTransaction={selectedTransaction}
        getEarnings={getEarnings}
      />
    </div>
  );
}

export default DashboardAccountant;
