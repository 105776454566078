import { Box, Skeleton } from "@mui/material";
import React from "react";
import styles from "../style.module.scss";

function SkeletonLoader() {
  return (
    <>
      <Box display={"flex"} justifyContent={"end"}>
        <Skeleton variant={"rectangular"} className={styles.skeleton1} />
      </Box>
      <Box display={"flex"} justifyContent={"start"}>
        <Skeleton variant={"rectangular"} className={styles.skeleton2} />
      </Box>
    </>
  );
}

export default SkeletonLoader;
