export const headings = {
  mainHeading: "All school accounts listing ",
  subHeading: "Manage all school accounts here",
};

export const columnHeadings = [
  {
    id: "school_name",
    title: "School Name",
  },
  {
    id: "phone",
    title: "Phone",
  },
  // {
  //   id: "email",
  //   title: "Email",
  // },

  // {
  //   id: "membership_plan",
  //   title: "Membership Plan",
  // },
  {
    id: "no_of_student",
    title: "No Of Students",
  },
  {
    id: "no_of_teachers",
    title: "No Of Teachers",
  },
  {
    id: "account_status",
    title: "Status",
  },
  {
    id: "actions",
    title: "Actions",
  },
];

export const skeletonInfo = {
  rows: 10,
  cellCount: 6,
  actions: 4,
};

export const filterOptions = [
  {
    option: "All",
    value: ''
  },
  {
    option: "Pending",
    value: 0,
  },
  {
    option: "Approved",
    value: 1,
  },
  {
    option: "Rejected",
    value: 2
  }
];

