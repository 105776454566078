import React, { useState } from "react";
import styles from "./style.module.scss";
import { changePasswordSchema } from "shared/utils/validationSchemas";
import { useFormik } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomButton from "../customButton";
import { changePasswordService } from "shared/services/profileService";
import { toastMessage } from "../toast";
import Loader from "shared/components/loader/animation";
import classNames from "classnames";

function ChangePassordForm() {
  const [loader, setLoader] = useState<boolean>(false);
  const [showPasswords, setShowPasswords] = useState<{
    password: boolean;
    current_password: boolean;
    password_confirmation: boolean;
  }>({
    password: false,
    current_password: false,
    password_confirmation: false,
  });

  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      handleChangePassword(values);
    },
  });

  const handleChangePassword = (values: any) => {
    setLoader(true);
    changePasswordService(values)
      .then(({ data }) => {
        if (data?.statusCode === 400) {
          toastMessage("error", data?.message);
        } else {
          toastMessage("success", data?.message);
        }
        setLoader(false);
        formik.resetForm();
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  return (
    <div className={styles.topMostContainer}>
      <div className={styles.container}>
        <div className={styles.heading}>Change Password</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputsWrapper} style={{ marginTop: "20px" }}>
          <div className={styles.inputSingleContainer}>
            <div className={styles.titleInput}>Current password</div>
            <div style={{ position: "relative" }}>
              <input
                className={styles.input}
                name="current_password"
                value={formik.values.current_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={showPasswords?.current_password ? "text" : "password"}
              />
              <div
                className={styles.iconContainer}
                onClick={() =>
                  setShowPasswords({
                    ...showPasswords,
                    current_password: !showPasswords.current_password,
                  })
                }
              >
                {showPasswords.current_password ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </div>
            </div>
            {formik.touched.current_password &&
            formik.errors.current_password ? (
              <div className={styles.errorText}>
                {formik.errors.current_password}
              </div>
            ) : (
              <div style={{ height: "20px" }}></div>
            )}
          </div>
          <div className={styles.inputSingleContainer}>
            <div className={styles.titleInput}>New password</div>
            <div style={{ position: "relative" }}>
              <input
                className={styles.input}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={showPasswords?.password ? "text" : "password"}
              />
              <div
                className={styles.iconContainer}
                onClick={() =>
                  setShowPasswords({
                    ...showPasswords,
                    password: !showPasswords.password,
                  })
                }
              >
                {showPasswords.password ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className={styles.errorText}>{formik.errors.password}</div>
            ) : (
              <div style={{ height: "20px" }}></div>
            )}
          </div>
          <div className={styles.inputSingleContainer}>
            <div className={styles.titleInput}>Confirm new password</div>
            <div style={{ position: "relative" }}>
              <input
                className={styles.input}
                name="password_confirmation"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={
                  showPasswords?.password_confirmation ? "text" : "password"
                }
              />
              <div
                className={styles.iconContainer}
                onClick={() =>
                  setShowPasswords({
                    ...showPasswords,
                    password_confirmation: !showPasswords.password_confirmation,
                  })
                }
              >
                {showPasswords.password_confirmation ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </div>
            </div>
            {formik.touched.password_confirmation &&
            formik.errors.password_confirmation ? (
              <div className={styles.errorText}>
                {formik.errors.password_confirmation}
              </div>
            ) : (
              <div style={{ height: "20px" }}></div>
            )}
          </div>
          <div
            className={classNames(
              styles.inputContainer,
              styles.inputContainerButton
            )}
            style={{ marginTop: "30px" }}
          >
            <CustomButton
              text="Change Password"
              buttonStyle={styles.btnCustom}
            />
          </div>
        </div>
      </form>
      {loader && <Loader />}
    </div>
  );
}

export default ChangePassordForm;
