import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Slide,
  Skeleton,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import styles from "./style.module.scss";

interface ViewReceiptModalProps {
  viewModal: boolean;
  handleViewClose: () => void;
  image: any;
  setReceipt: any;
  loader: boolean;
  // subscribeId: number | any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewReceiptModal: React.FC<ViewReceiptModalProps> = ({
  viewModal,
  loader,
  image,
  handleViewClose,
  setReceipt,
}: ViewReceiptModalProps) => {
  const handleClose = () => {
    handleViewClose();
    setReceipt(null);
  };
  return (
    <Dialog
      open={viewModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Receipt Image</DialogTitle>
      {loader ? (
        <Skeleton variant="rounded" width={210} height={60} />
      ) : (
        <div className={styles.container}>
          {image && (
            <figure className={styles.imageWrapper}>
              <img
                src={image}
                alt="Receipt"
                loading="lazy"
                style={{ maxWidth: "100%" }}
              />
            </figure>
          )}
        </div>
      )}
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={styles.closeButton} onClick={handleViewClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewReceiptModal;
