import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icons } from "assets";
import React, { useState } from "react";
import styles from "./style.module.scss";

interface FilterProps {
  label?: any;
  filterOption: any;
  filterStyles: any;
  selectedOption: any;
  setSelectedOption: any;
  setActivePage?: any;
}

export default function Filter({
  label,
  filterOption,
  filterStyles,
  selectedOption,
  setSelectedOption,
  setActivePage,
}: Partial<FilterProps>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: "relative" }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e)}
        className={styles.button}
      >
        <span>{label ? label : "Filter"}</span>{" "}
        <Icons.FilterIcon style={{ marginLeft: "10px" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {filterOption?.map((item: any, index: any) => {
          return (
            <MenuItem
              key={index}
              onClick={(e) => {
                handleClose();
                setSelectedOption(item);
                if (setActivePage) {
                  setActivePage?.(1);
                }
              }}
            >
              {item?.option}
            </MenuItem>
          );
        })}
      </Menu>

      {selectedOption?.option ? (
        <div className={styles.selectedOption}>
          {selectedOption?.option}{" "}
          <CloseIcon
            className={styles.selectionOptionIcon}
            onClick={() => setSelectedOption(null)}
          />
        </div>
      ) : null}
    </div>
  );
}
