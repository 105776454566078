import React, { useRef } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import styles from "./style.module.scss";
import { Icons, Images } from "assets";

interface ImageUploadButtonProps {
  image: any;
  setImage: any;
}

const ImageUploadButton = ({ image, setImage }: ImageUploadButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  // const handleRemoveImage = () => {
  //   setImage({ preview: "", raw: null });
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = "";
  //   }
  // };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <div className={styles.imgContainer}>
        <img
          src={image.preview ? image.preview : Images.User_Upload}
          alt={"user"}
          className={styles.uploadPicture}
          onClick={handleButtonClick}
        />
        {/* <UploadIcon sx={{ color: "white" }} className={styles.uploadIcon} /> */}
        <Icons.UploadIcon className={styles.uploadIcon} />
      </div>
    </div>
  );
};

export default ImageUploadButton;
