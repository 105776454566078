export const headings = {
    subHeading: "Manage all school accounts tickets from here",
    mainHeading: "All Schools Tickets"
}


export const columnHeadings = [
    {
        id: "school_name",
        title: "School Name",
    },
    {
        id: "phone",
        title: "Phone",
    },
    // {
    //     id: "email",
    //     title: "Email",
    // },
    {
        id: "status",
        title: 'Status'
    },
    // {
    //     id: "no_of_student",
    //     title: "No Of Students",
    // },
    // {
    //     id: "no_of_teachers",
    //     title: "No Of Teachers",
    // },
    {
        id: "actions",
        title: "Actions",
    },
]

export const skeletonInfo = {
    rows: 8,
    cellCount: 4,
    actions: 1,
}

export const filterOptions = [
    {
        option: "All",
        value: ''
    },
    {
        option: "Pending",
        value: 0,
    },
    {
        option: "Approved",
        value: 1,
    },
    {
        option: "Rejected",
        value: 2
    }
];