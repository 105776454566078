import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./style.module.scss";
import { Icons } from "assets";
import { Card, CardContent, Grid } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ProfileProps {
  open: boolean;
  handleClose: any;
  selectedAccount: any;
}

export default function Profile({
  open,
  handleClose,
  selectedAccount,
}: ProfileProps) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          />

          {/* <div style={{ marginTop: "50px" }}>
            <ProfileCard isMOU={true} />
          </div> */}
          <Card className={styles.card}>
            <CardContent>
              <div className={styles.title} style={{ fontSize: "16px" }}>
                Bank account details
              </div>

              {selectedAccount?.accounts?.map((item: any) => {
                return (
                  <Grid spacing={2} container className={styles.grid}>
                    <Grid xs={4}>
                      <div className={styles.title}>Account no.</div>
                      <div className={styles.subTitle}>
                        {item?.account_number}
                      </div>
                    </Grid>
                    <Grid xs={4}>
                      <div className={styles.title}>Bank Name</div>
                      <div className={styles.subTitle}>{item?.bank_name}</div>
                    </Grid>
                    <Grid xs={4}>
                      <div className={styles.title}>Bank title</div>
                      <div className={styles.subTitle}>
                        {item?.account_title}
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
