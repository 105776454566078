import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Grid,
  ListItemText,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import BestSellers from "pages/bestSeller";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GenreCard from "shared/components/genreCard";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import {
  getAllGraphsData,
  getGenreStats,
} from "shared/services/productManagerService";
import styles from "./style.module.scss";

import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

import {
  getAllAgeRangesService,
  getAllLanguagesService,
  getGenresService,
} from "shared/services/general";

const lineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
    tooltip: {
      displayColors: false,
      backgroundColor: "rgb(15, 17, 6,0.8)",
      padding: 14,
      footerFont: {
        weight: "normal",
      },
      footerColor: "white",
      titleFont: {
        padding: 20,
        size: 15,
        weight: "bold",
      },
      bodyFont: {
        padding: 20,
        size: 15,
        weight: "bold",
      },
      callbacks: {
        title: () => {
          return "";
        },
        labelTextColor: (context: any) => {
          return "white";
        },
        label: (context: any) => {
          return context.formattedValue + " Sales";
        },
        footer: (context: any) => {
          return context[0].label;
        },
      },
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
      },
      min: 0, // Prevent x-axis from going negative
    },
    y: {
      ticks: {
        display: true,
      },
      grid: {
        display: false,
      },
      min: 0, // Prevent y-axis from going negative
    },
  },
};

// MATERILA UI FILTERS STYLES

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    "&:hover": {
      borderColor: "#9a469b",
    },
    "&:focusVisible": {
      borderRadius: 1,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function Sales() {
  const [chart, setChart] = useState<any>(null);
  const [genreLists, setGenreList] = useState<any>(null);
  const [genreLoader, setGenreLoader] = useState<boolean>(false);
  const [allGenres, setAllGenres] = useState<any>([]);
  const [allAgeRange, setAllAgeRange] = useState<any>([]);
  const [allLanguage, setAllLanguage] = useState<any>([]);
  const [ageRange, setAgeRange] = useState<any>("");
  const [languages, setLanguages] = useState<any>([]);
  const [genres, setGenres] = useState<any>([]);
  const [allGenresLoader, setAllGenresLoader] = useState<boolean>(false);
  const [allAgeRangeLoader, setAllAgeRangeLoader] = useState<boolean>(false);
  const [languageLoader, setLanguageLoader] = useState<boolean>(false);
  const [selectedSaleType, setSelectedSaleType] = useState<string>("");
  const [softCopyDates, setSoftCopyDates] = useState<any>({
    startDate: new Date().setDate(new Date().getDate() - 7),
    endDate: new Date(),
  });

  const getBookCopyType = (type: string) => {
    if (type === "softcopy") return "Soft Copy";
    if (type === "hardcopy") return "Hard Copy";
  };
  // const classes = useStyles();

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setSoftCopyDates({
      startDate: start,
      endDate: end,
    });
  };

  const getMostReadGenres = () => {
    setGenreLoader(true);
    getGenreStats()
      .then(({ data }) => {
        setGenreList(data?.data);
        setGenreLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setGenreLoader(false);
      });
  };

  useEffect(() => {
    getMostReadGenres();
  }, []);

  useBreadcrumbs({
    title: "Sales",
    path: routeConstant.sales.path,
  });

  const getAllGraphs = () => {
    getAllGraphsData(softCopyDates, selectedSaleType)
      .then(({ data }) => {
        setChart(data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getAllGraphs();
    // eslint-disable-next-line
  }, [softCopyDates?.endDate, selectedSaleType]);

  const getAllGenres = () => {
    setAllGenresLoader(true);
    getGenresService()
      .then(({ data }) => {
        setAllGenres(
          data?.data?.map((item: any) => ({ ...item, checked: false }))
        );
        setAllGenresLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        setAllGenresLoader(false);
        console.log("err", err?.response?.data?.message);
      });
  };

  const getAllAgeRanges = () => {
    setAllAgeRangeLoader(true);
    getAllAgeRangesService()
      .then(({ data }) => {
        setAllAgeRange(
          data?.data?.map((item: any) => ({ ...item, checked: false }))
        );
        setAllAgeRangeLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setAllAgeRangeLoader(false);
      });
  };

  const getAllLanguages = () => {
    setLanguageLoader(true);
    getAllLanguagesService()
      .then(({ data }) => {
        setAllLanguage(
          data?.data?.map((item: any) => ({ ...item, checked: false }))
        );
        setLanguageLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLanguageLoader(false);
      });
  };

  useEffect(() => {
    getAllGenres();
    getAllAgeRanges();
    getAllLanguages();
  }, []);

  // Filters

  const handleAgeSelector = (event: any) => {
    setAgeRange(event.target.value);
  };

  const handleLanguageSelector = (event: any) => {
    setLanguages(event.target.value);
  };

  const handleGenreSelector = (event: any) => {
    setGenres(event.target.value);
  };
  return (
    <div>
      <Grid spacing={4} container className={styles.topLevelGrid}>
        <Grid xs={12} sm={12} md={8} lg={8} className={styles.chartGrid}>
          <Card sx={{ marginBottom: "40px" }} className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Box className={styles.topGraphContainer}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <div className={styles.title}>Sales figure</div>
                  <label htmlFor="datePicker" className={styles.dateLabel}>
                    <Box display={"flex"} alignItems={"center"}>
                      {moment(softCopyDates?.startDate).format("DD MMM")} -{" "}
                      {softCopyDates?.endDate
                        ? moment(softCopyDates?.endDate).format("DD MMM")
                        : ""}
                      <KeyboardArrowDownIcon />
                    </Box>
                    <DatePicker
                      placeholderText="Select range"
                      selected={softCopyDates?.startDate}
                      onChange={(date: any) => onChangeDate(date)}
                      startDate={softCopyDates?.startDate}
                      endDate={softCopyDates?.endDate}
                      peekNextMonth
                      selectsRange
                      id="datePicker"
                      className={styles.datePickerSales}
                    />
                  </label>
                </Box>
                <Box display={"flex"}>
                  <Box marginRight={"10px"}>
                    <select
                      value={selectedSaleType}
                      className={styles.selectFilter}
                      onChange={(e) => {
                        setSelectedSaleType(e.target.value);
                      }}
                    >
                      <option value="">Select sales type</option>

                      <option value={"softcopy"}>Soft Copy</option>
                      <option value="hardcopy">Hard Copy</option>
                    </select>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <div className={styles.secondary}></div>
                    <div className={styles.graphLabel}>Soft Copy</div>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <div className={styles.primary}></div>
                    <div className={styles.graphLabel}>Hard Copy</div>
                  </Box>
                </Box>
              </Box>

              <Line
                options={lineChartOptions}
                data={{
                  labels: chart?.labels,
                  datasets: [
                    {
                      label: "Dataset 2",
                      data: chart?.sales?.soft_copy_sales?.total_sales,
                      borderColor: "#EF437B",
                      borderWidth: 2,
                      backgroundColor: "white",
                      pointStyle: "circle",
                      pointRadius: 5.5,
                      pointHoverRadius: 8,
                    },
                    {
                      label: "Dataset 3",
                      data: chart?.sales?.hard_copy_sales,
                      borderColor: "#1897A6",
                      borderWidth: 2,
                      backgroundColor: "white",
                      pointStyle: "circle",
                      pointRadius: 5.5,
                      pointHoverRadius: 8,
                    },
                  ],
                }}
              />
            </CardContent>
          </Card>{" "}
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={4}>
          <div>
            <GenreCard genreLists={genreLists} isLoading={genreLoader} />
          </div>
        </Grid>
      </Grid>

      <Box className={styles.wrapper}>
        <Box className={styles.title}>
          Top sales of{" "}
          {selectedSaleType === "softcopy"
            ? "Soft Copy"
            : selectedSaleType === "hardcopy"
            ? "Hard Copy"
            : "Books"}
        </Box>
        <Box className={styles.filtersContainer}>
          <FormControl className={styles.filterWrapper}>
            <Select
              className={styles.selectElement}
              input={<BootstrapInput />}
              renderValue={(selected) => {
                if (selected) {
                  return (
                    <p className={styles.message}>
                      {getBookCopyType(selected)}
                    </p>
                  );
                }
                return <p className={styles.message}>Select Sales Type</p>;
              }}
              value={selectedSaleType}
              onChange={(event) => setSelectedSaleType(event.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem key="show soft copies" value="">
                <ListItemText>Select Sales Type</ListItemText>
              </MenuItem>
              <MenuItem key="softcopy" value="softcopy">
                <ListItemText>Soft Copy</ListItemText>
              </MenuItem>
              <MenuItem key="hardcopy" value="hardcopy">
                <ListItemText>Hard Copy</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className={styles.filterWrapper}>
            <Select
              input={<BootstrapInput />}
              className={styles.selectElement}
              value={ageRange}
              onChange={handleAgeSelector}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                const genre = allAgeRange.find(
                  (age: any) => age.id === selected
                );
                if (selected) {
                  return <p className={styles.message}>{genre.text}</p>;
                }
                return <p className={styles.message}>Select Age Range</p>;
              }}
            >
              <MenuItem value={""}>
                <ListItemText>{"None"}</ListItemText>
              </MenuItem>
              {allAgeRange.map((item: any) => {
                return (
                  <MenuItem key={item?.id} value={item?.id}>
                    <ListItemText>{item?.text}</ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={styles.filterWrapper}>
            <Select
              input={<BootstrapInput />}
              multiple
              className={styles.selectElement}
              displayEmpty
              renderValue={(selected) => {
                const message =
                  selected?.length > 1
                    ? `${selected.length} Languages Selected`
                    : `${selected.length} Language Selected`;
                if (selected.length === 0) {
                  return <p className={styles.message}>Select Language(s)</p>;
                }
                return <p className={styles.message}>{message}</p>;
              }}
              value={languages}
              onChange={handleLanguageSelector}
            >
              {allLanguage.map((language: any) => (
                <MenuItem key={language?.id} value={language?.id}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={languages.includes(language?.id)}
                  ></Checkbox>
                  <ListItemText primary={language?.name}></ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles.filterWrapper}>
            <Select
              input={<BootstrapInput />}
              className={styles.selectElement}
              multiple
              displayEmpty
              renderValue={(selected) => {
                const message =
                  selected.length > 1
                    ? `${selected.length} Genres Selected`
                    : `${selected.length} Genre Selected`;
                if (selected.length === 0) {
                  return <p className={styles.message}>Select Genre(s)</p>;
                }
                return <p className={styles.message}>{message}</p>;
              }}
              value={genres}
              onChange={handleGenreSelector}
            >
              {allGenres.map((genre: any) => (
                <MenuItem key={genre?.id} value={genre?.id}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={genres.includes(genre?.id)}
                  ></Checkbox>
                  <ListItemText primary={genre?.name}></ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <BestSellers
        genres={genres}
        ageRange={ageRange}
        languages={languages}
        sales_type={selectedSaleType}
      />
    </div>
  );
}

export default Sales;
