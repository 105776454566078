import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { useFormik } from "formik";
import { createBookReviewerSchema } from "shared/utils/validationSchemas";
import ImageUploadButton from "shared/components/imageUploadButton";
import AutoComplete from "shared/components/autoComplete";
import CloseIcon from "@mui/icons-material/Close";
import { toastMessage } from "shared/components/toast";
import { createBookReviewerService } from "shared/services/adminService";
import { getGenresService } from "shared/services/general";
import Loader from "shared/components/loader/animation";
import { Box, Skeleton } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface BookReviwerProps {
  open: boolean;
  handleClose: any;
  setIsBookReviewerAdded: any;
}

export default function BookReviwer({
  open,
  handleClose,
  setIsBookReviewerAdded,
}: BookReviwerProps) {
  const [image, setImage] = useState<{ preview: string; raw: File | null }>({
    preview: "",
    raw: null,
  });
  const [selectedGenres, setSelectedGenres] = useState<
    { label: string; value: number }[]
  >([]);
  const [allGenres, setAllGenres] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [genreLoader, setGenreLoader] = useState<boolean>(false);

  const onClose = () => {
    setImage({
      preview: "",
      raw: null,
    });
    handleClose();
    setSelectedGenres([]);
    setAllGenres([]);
    formik?.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      genres: [],
    },
    validationSchema: createBookReviewerSchema,
    onSubmit: (values: any) => {
      // Handle form submission logic here
      handleSubmit(values);
    },
  });

  const handleSelectGenre = (obj: any) => {
    setAllGenres((prevGenres: any) =>
      prevGenres?.filter((item: any) => item?.id !== obj.id)
    );
    setSelectedGenres((prevGenres: any) => [...prevGenres, obj]);
    formik.setFieldValue("genres", [...formik.values.genres, obj]);
  };

  const handleRemoveGenres = (item: any) => {
    setSelectedGenres((prevGenres: any) =>
      prevGenres?.filter((itm: any) => itm?.id !== item.id)
    );
    setAllGenres((prevGenres: any) => [...prevGenres, item]);
    formik.setFieldValue(
      "genres",
      formik.values.genres?.filter((genre: any) => genre.id !== item.id)
    );
  };

  const handleSubmit = (values: any) => {
    setLoader(true);
    let resp: any = {
      first_name: values.firstName,
      last_name: values?.lastName,
      email: values?.email,
      phone: values?.contact,
      genre: values?.genres?.map((item: any) => item?.id),
    };

    if (image.raw) {
      resp["profile_picture"] = image.raw;
    }

    let formBody = new FormData();
    Object.keys(resp).forEach((key) => {
      if (Array.isArray(resp[key])) {
        resp[key].forEach((value: any, index: any) => {
          formBody.append(`${key}[]`, value);
        });
      } else {
        formBody.append(key, resp[key]);
      }
    });

    createBookReviewerService(formBody)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        setLoader(false);
        setIsBookReviewerAdded(true);
        onClose();
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getGenres = () => {
    setGenreLoader(true);
    getGenresService()
      .then(({ data }) => {
        setAllGenres(data?.data);
        setGenreLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setGenreLoader(false);
      });
  };

  useEffect(() => {
    if (open) {
      getGenres();
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent
          sx={{ padding: "50px" }}
          className={styles.dialogContent}
        >
          {/* <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          /> */}

          <div className={styles.title}>Add new book reviewer</div>

          <ImageUploadButton image={image} setImage={setImage} />
          <form style={{ marginTop: "20px" }} onSubmit={formik.handleSubmit}>
            <div className={styles.inputContainer}>
              <div>
                <div className={styles.heading}>
                  First name <span>*</span>
                </div>
                <input
                  className={styles.input}
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className={styles.error}>
                    {formik?.errors?.firstName}
                  </div>
                ) : (
                  <div style={{ height: "20px" }}></div>
                )}
              </div>
              <div className={styles.inputRight}>
                <div style={{ width: "97%" }} className={styles.heading}>
                  Last name <span>*</span>
                </div>
                <input
                  className={styles.input}
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className={styles.error}>{formik?.errors?.lastName}</div>
                ) : (
                  <div style={{ height: "20px" }}></div>
                )}
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div>
                <div className={styles.heading}>
                  Email <span>*</span>
                </div>
                <input
                  className={styles.input}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className={styles.error}>{formik?.errors?.email}</div>
                ) : (
                  <div style={{ height: "20px" }}></div>
                )}
              </div>
              <div className={styles.inputRight}>
                <div style={{ width: "97%" }} className={styles.heading}>
                  Contact <span>*</span>
                </div>
                <input
                  className={styles.input}
                  name="contact"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact}
                />
                {formik.touched.contact && formik.errors.contact ? (
                  <div className={styles.error}>{formik?.errors?.contact}</div>
                ) : (
                  <div style={{ height: "20px" }}></div>
                )}
              </div>
            </div>
            <div>
              <div className={styles.heading}>
                Specialities <span>*</span>
              </div>
            </div>

            {genreLoader ? (
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={45}
                sx={{ borderRadius: "32px" }}
              />
            ) : (
              <AutoComplete
                genres={allGenres}
                handleSelectGenre={handleSelectGenre}
              />
            )}
            {selectedGenres && selectedGenres?.length > 0 && (
              <div className={styles.tagContainer}>
                {selectedGenres?.map((item: any) => (
                  <div key={item.name} className={styles.genres}>
                    {item?.name}{" "}
                    <CloseIcon
                      className={styles.closeIcon}
                      onClick={() => handleRemoveGenres(item)}
                    />
                  </div>
                ))}
              </div>
            )}
            {formik.touched.genres && formik.errors.genres ? (
              <div className={styles.error}>{formik?.errors?.genres}</div>
            ) : (
              <div style={{ height: "20px" }}></div>
            )}
            <Box display={"flex"}>
              <CustomButton text="Add" buttonStyle={styles.addBtn} />
              <CustomButton
                text="Cancel"
                buttonStyle={styles.cancelBtn}
                handleClick={(e: any) => {
                  e.preventDefault();
                  onClose();
                }}
              />
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {loader && <Loader />}
    </div>
  );
}
