import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "pages/reviewHOD/style.module.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useSelector } from "react-redux";

interface SectionProps {
  options: any;
  label: string;
  state: Record<string, any>;
  setState: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  name: string;
  loader?: boolean;
}

const ReviewSection = ({
  options,
  label,
  state,
  setState,
  name,
  loader,
}: SectionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const [initialOptions, setInitialOptions] = useState<any>(
    state[name]?.options
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { user } = useSelector((state: any) => state.root);

  useEffect(() => {
    setInitialOptions(state[name]?.options);
    // eslint-disable-next-line
  }, [loader]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (selectedValue: any) => {
    let updatedState: Record<string, any> = {
      ...state,
      [name]: {
        ...state[name],
        option: Number(selectedValue),
      },
    };

    if (state[name].type === "checklist") {
      updatedState[name].options =
        Number(selectedValue) === 1
          ? initialOptions.map((item: any) => ({
              ...item,
              value: false,
            }))
          : [...state[name].options];
    } else if (state[name].type === "text") {
      updatedState[name].comment =
        Number(selectedValue) === 1 ? "" : state[name].comment;
    } else if (state[name].type === "dropdown") {
      updatedState[name].selected =
        Number(selectedValue) === 1 ? null : state[name].selected;
    }

    setState(updatedState);
  };

  // const handleChecked = () => {
  //   setState({
  //     ...state,
  //     [name]: {
  //       ...state[name],
  //       checked: !state[name].checked,
  //     },
  //   });
  // };

  const handleCount = (section: any) => {
    let count = 0;
    section?.options?.map((item: any) => {
      if (item?.value) {
        count = count + 1;
      }
    });

    return count + 1;
  };

  const handleCheckList = (item: any) => {
    let temp = state[name].options;
    let index = temp.findIndex((itm: any) => itm?.id === item?.id);

    if (index !== -1) {
      temp[index] = {
        ...temp[index],
        value: !temp[index].value,
      };
    }

    setState({
      ...state,
      [name]: {
        ...state[name],
        options: [...temp],
      },
    });
  };

  const selection_restriction: any = {
    Genre: 3,
    Keywords: 5,
  };

  return (
    <div>
      <Box display={"flex"} alignItems={"center"}>
        <div className={styles.title}>{label}</div>
        {/* {user?.role !== "3" && (
          <Checkbox
            className={styles.checkbox}
            checked={state[name].checked}
            onChange={() => handleChecked()}
          />
        )} */}
      </Box>

      {state[name].type !== "rating" && (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={state[name].option}
            onChange={(e) => handleSelection(e.target.value)}
            sx={{ flexDirection: "row" }}
          >
            {options.map((option: any, index: number) => (
              <div className={styles.radio} key={index}>
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={""}
                  name={name}
                  checked={option.value === state[name].option}
                />
                <span>{option?.label}</span>
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      )}

      <div className={styles.checkBoxContainer}>
        {state[name].type === "checklist" &&
          state[name].option === options[1].value &&
          state[name].options?.map((item: any) => {
            return (
              <>
                <div
                  className={
                    !(
                      handleCount(state[name]) >
                        selection_restriction[state[name]?.label] &&
                      !item?.value
                    )
                      ? styles.review
                      : styles.reviewDisbaled
                  }
                >
                  <Checkbox
                    edge="start"
                    className={
                      !(
                        handleCount(state[name]) >
                          selection_restriction[state[name]?.label] &&
                        !item?.value
                      )
                        ? styles.checkbox
                        : styles.checkboxDisabled
                    }
                    checked={item?.value}
                    onChange={() => handleCheckList(item)}
                    disabled={
                      handleCount(state[name]) >
                        selection_restriction[state[name]?.label] &&
                      !item?.value
                    }
                  />
                  {item?.name}
                </div>

                {item?.id === 3 && item.value && item.name === "Others" && (
                  <div>
                    <textarea
                      className={styles.textarea}
                      value={item?.comment}
                      placeholder={item?.placeholder}
                      onChange={(e) => {
                        const updatedOptions = state[name].options.map(
                          (optionItem: any) =>
                            optionItem.id === 3
                              ? { ...optionItem, comment: e.target.value }
                              : optionItem
                        );

                        setState({
                          ...state,
                          [name]: {
                            ...state[name],
                            options: updatedOptions,
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </>
            );
          })}
      </div>

      {state[name].type === "text" &&
        state[name].option === options[1].value && (
          <div style={{ marginTop: "10px" }}>
            <textarea
              className={styles.textarea}
              placeholder={state[name]?.placeholder}
              value={state[name].comment}
              onChange={(e) =>
                setState({
                  ...state,
                  [name]: {
                    ...state[name],
                    comment: e.target.value,
                  },
                })
              }
            />
          </div>
        )}

      {state[name].type === "dropdown" &&
        state[name].option === options[1].value && (
          <div>
            <button
              className={styles.btn}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <span>
                {state[name].selected
                  ? state[name].selected.name
                    ? state[name].selected.name
                    : state[name].selected.text
                    ? state[name].selected.text
                    : ""
                  : state[name].placeholder}
              </span>
              {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {state[name]?.options?.map((option: any) => (
                <MenuItem
                  key={option?.id}
                  onClick={() => {
                    handleClose();
                    setState({
                      ...state,
                      [name]: {
                        ...state[name],
                        selected: option,
                      },
                    });
                  }}
                >
                  {option?.name ? option?.name : option.text}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}

      {state[name].type === "rating" && (
        <div>
          <Rating
            sx={{ marginBottom: "9px" }}
            value={state[name].value}
            onChange={(event, newValue) => {
              setState({
                ...state,
                [name]: {
                  ...state[name],
                  value: newValue,
                },
              });
            }}
          />

          <div>
            <textarea
              className={styles.textarea}
              value={state[name].comment}
              placeholder={state[name]?.placeholder}
              onChange={(e) => {
                setState({
                  ...state,
                  [name]: {
                    ...state[name],
                    comment: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewSection;
