import { Box, Skeleton } from "@mui/material";
import React from "react";
import styles from "../style.module.scss";
import useWindowSize from "shared/customHooks/windowSize";

function PublisherLoader() {
  const screenWidth = useWindowSize();

  return (
    <React.Fragment>
      {Array.from({ length: 8 }).map((_, index) => (
        <div className={styles.publisherContainer}>
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <Skeleton variant="rectangular" width={"94px"} height={"100px"} />
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Skeleton
                variant="rectangular"
                width={screenWidth < 550 ? "120px" : "180px"}
                height={"25px"}
                sx={{ marginBottom: "10px" }}
              />
              <Box display={"flex"}>
                <Skeleton
                  variant="circular"
                  width={"25px"}
                  height={"25px"}
                  sx={{ marginRight: "10px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={screenWidth < 550 ? "90px" : "150px"}
                  height={"25px"}
                />
              </Box>
            </Box>
          </Box>
        </div>
      ))}
    </React.Fragment>
  );
}

export default PublisherLoader;
