import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import Cards from "shared/components/card";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Icons } from "assets";
import useWindowSize from "shared/customHooks/windowSize";
import Pagination from "shared/components/pagination";
import ArticleIcon from "@mui/icons-material/Article";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { routeConstant } from "shared/routes/routesConstant";
import { useNavigate } from "react-router-dom";
import {
  getAllUsersProjectHead,
  getStatsProjectHead,
  blockPartnerUser,
  unBlockPartnerUser,
} from "shared/services/projectHeadService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import Filter from "shared/components/filterButton";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { searchHandlerHelper } from "shared/utils/helpers";
import ItemsPerPage from "shared/components/itemsPerPage";
import Confirmation from "shared/modals/confirmation";
import { user_roles } from "shared/utils/constants";

function PartnerAccount() {
  const screenWidth = useWindowSize();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [users, setAllUsers] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [partner, setPartner] = useState<any>(null);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  const getAllUsers = () => {
    setLoader(true);
    getAllUsersProjectHead(
      searchText,
      activePage,
      selectedFilter?.value,
      itemsPerPage
    )
      .then(({ data }) => {
        setLoader(false);
        setAllUsers(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getDashboardStats = () => {
    setIsLoading(true);
    getStatsProjectHead()
      .then(({ data }) => {
        setIsLoading(false);
        setDashboardData(data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  const handlePartner = (partner: any) => {
    setLoader(true);
    const service =
      partner?.is_blocked === 0 ? blockPartnerUser : unBlockPartnerUser;
    service(partner?.id)
      .then(({ data, status }) => {
        if (status) {
          setLoader(false);
          getAllUsers();
          setOpenConfirmationModal(false);
          toastMessage("success", data?.message);
        }
      })
      .catch((err: any) => {
        toastMessage("err", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, [searchText, selectedFilter, activePage, itemsPerPage]);

  useEffect(() => {
    getDashboardStats();
  }, []);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const admin_roles = [
    { id: 1, label: "School" },
    { id: 2, label: "Teacher" },
    { id: 3, label: "Parent" },
    { id: 4, label: "Reader" },
    { id: 5, label: "Author" },
    { id: 6, label: "Publisher" },
  ];

  const filterOption = [
    {
      option: "All",
      value: "all",
    },
    {
      option: "Author",
      value: 5,
    },
    {
      option: "Publisher",
      value: 6,
    },
  ];

  const cardData = [
    {
      title: "Total Publishers",
      icon: BeenhereIcon,
      stats: dashboardData?.publisher?.total,
    },
    {
      title: "Total Authors",
      icon: DrawIcon,
      stats: dashboardData?.author?.total,
    },
    {
      title: "Total Book Reviewer",
      icon: ReviewsIcon,
      stats: dashboardData?.book_reviewer?.total,
    },
  ];

  useBreadcrumbs({
    title: "Partners Account",
    path: routeConstant.partnerAccount.path,
  });

  return (
    <div>
      <Grid container spacing={2}>
        {cardData?.map((item) => {
          return (
            <Grid item xs={12} sm={4}>
              <Cards
                Icon={item?.icon}
                stats={item?.stats}
                title={item?.title}
                style={styles.card}
                isLoading={isLoading}
              />
            </Grid>
          );
        })}
      </Grid>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Partners account</div>
            <div className={styles.tableSubHeading}>
              Manage your partner accounts from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            <div>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Participent name</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                users?.map((item: any, index: any) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <Avatar
                            src={
                              item?.publisher
                                ? item?.publisher?.profile_picture
                                : item?.author?.profile_picture
                            }
                          />
                          <span
                            className={styles.tableItem}
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.publisher
                              ? item?.publisher?.full_name
                              : item?.author?.full_name}
                          </span>
                        </Box>
                      </TableCell>{" "}
                      <TableCell>
                        {
                          admin_roles.find(
                            (role) => role.id === Number(item?.role)
                          )?.label
                        }
                      </TableCell>
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          className={styles.actionBtn}
                        >
                          <Tooltip title="View details" arrow>
                            <button
                              onClick={() =>
                                navigate(
                                  Number(item?.role) === 6
                                    ? routeConstant.publisherProfile.path.replace(
                                        ":id",
                                        item?.id
                                      )
                                    : routeConstant.singleAuthor.path.replace(
                                        ":id",
                                        item?.id
                                      )
                                )
                              }
                            >
                              <RemoveRedEyeIcon />
                            </button>
                          </Tooltip>

                          <Tooltip title="MOU" arrow>
                            <button
                              onClick={() => {
                                navigate(
                                  routeConstant.mouHead.path.replace(
                                    ":id",
                                    item?.id
                                  ),
                                  {
                                    state: {
                                      user: item?.publisher
                                        ? item?.publisher
                                        : item?.author,
                                    },
                                  }
                                );
                              }}
                            >
                              <ArticleIcon />
                            </button>
                          </Tooltip>
                          <Tooltip
                            title={item?.is_blocked ? "Activate" : "Deactivate"}
                            arrow
                          >
                            <button
                              onClick={() => {
                                setPartner(item);
                                setOpenConfirmationModal(true);
                              }}
                            >
                              {item?.is_blocked ? (
                                <Icons.SchoolActivate />
                              ) : (
                                <Icons.SchoolDeactivate />
                              )}
                            </button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!(users.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}

        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {/* ROWS PER PAGE */}
        {users.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>

      {/* Confirmation Modal For Activatng and Deactivating Partner */}
      <Confirmation
        loader={loader}
        open={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
        text={
          !partner?.is_blocked
            ? `Are you sure you want to deactivate this ${
                partner?.role === user_roles.publisher ? "Publisher" : "Author"
              } ?`
            : `Are you sure you want to activate this ${
                partner?.role === user_roles.publisher ? "Publisher" : "Author"
              } ? `
        }
        handleClick={() => handlePartner(partner)}
      />
    </div>
  );
}

export default PartnerAccount;
