import React from "react";
import { Icons } from "assets";

import styles from "./style.module.scss";

const NoDataFound = () => {
  return (
    <div className={styles.noUserCard}>
      <Icons.NoUsersData />
      <h5>No Data Found</h5>
    </div>
  );
};

export default NoDataFound;
