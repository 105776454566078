import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const getEarningsService = (
  searchText: any,
  activeTab: any,
  status?: any,
  from?: any,
  to?: any,
  itemsPerPage?: number
) => {
  const params = new URLSearchParams();
  if (status || status === 0) params.append("status", status);
  if (searchText) params.append("search", searchText);
  if (activeTab) params.append("page", activeTab.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  if (from && to) {
    params.append("from", from);
    params.append("to", to);
  }
  const url = `${Endpoint.accountant.getEarnings}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const uploadReceiptService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.accountant.uploadReceipt, resp);
};

const getStatsService = () => {
  return HTTP_CLIENT.get(Endpoint.accountant.getStats);
};

const getBankAccountsService = (
  searchText: any,
  activeTab: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (searchText) params.append("search", searchText);
  if (activeTab) params.append("page", activeTab.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.accountant.getBankAccounts}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

export {
  getEarningsService,
  uploadReceiptService,
  getBankAccountsService,
  getStatsService,
};
