import Loader from "shared/components/loader/circular"
import styles from "./style.module.scss";


interface BtnProps extends React.HTMLProps<HTMLButtonElement> {
  text: string;
  buttonStyle: any;
  type: any;
  handleClick: any;
  loader: boolean;
}

const CustomButton = ({
  text,
  buttonStyle,
  type,
  handleClick,
  loader,
}: Partial<BtnProps>) => {
  return (
    <button
      className={
        buttonStyle ? `${buttonStyle} ${styles.button}` : styles.button
      }
      type={type}
      onClick={handleClick}
    >
      {loader ? (
        <Loader/>
      ) : (
        text
      )}
    </button>
  );
};

export default CustomButton;
