import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const getProfileService = () => {
  return HTTP_CLIENT.get(Endpoint.profile.getProfile);
};

const updateProfileService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.profile.updateProfile, params);
};

const changePasswordService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.profile.changePassword, params);
};

const notificationSettingsService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.profile.notificationSettings, params);
};

export {
  getProfileService,
  updateProfileService,
  changePasswordService,
  notificationSettingsService,
};
