import { Avatar, Box, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./style.module.scss";
import { Icons } from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "shared/components/pagination";
import useWindowSize from "shared/customHooks/windowSize";
import {
  getBankAccountsService,
  getEarningsService,
} from "shared/services/accountantService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ProfileModal from "shared/modals/profile";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import { searchHandlerHelper } from "shared/utils/helpers";
import ItemsPerPage from "shared/components/itemsPerPage";

function DashboardAccountant() {
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const screenWidth = useWindowSize();
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [allAccounts, setAllAccounts] = useState<any>(null);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const filterOption = [
    {
      option: "Completed",
      value: 2,
    },
    {
      option: "Pending",
      value: 0,
    },
  ];

  const getEarnings = () => {
    setLoader(true);
    getEarningsService(
      searchText,
      activePage,
      selectedFilter?.value,
      itemsPerPage
    )
      .then(({ data }) => {
        // setAllTransactions(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setTotalItems(data?.data?.data?.total);
        setCurrentPage(data?.data?.data?.current_page);
        setItemsPerPage(data?.data?.data?.per_page);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getEarnings();
    // eslint-disable-next-line
  }, [searchText, activePage, selectedFilter, itemsPerPage]);

  const getAccounts = () => {
    getBankAccountsService(searchText, activePage, itemsPerPage)
      .then(({ data }) => {
        setAllAccounts(data?.data);
        // setAllTransactions(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const handleopenDetail = (item: any) => {
    setOpenDetail(true);
    setSelectedAccount(item);
  };

  const handleCloseTransaction = () => {
    setSelectedAccount(null);
    setOpenDetail(false);
  };

  const admin_roles = [
    { id: 1, label: "School" },
    { id: 2, label: "Teacher" },
    { id: 3, label: "Parent" },
    { id: 4, label: "Reader" },
    { id: 5, label: "Author" },
    { id: 6, label: "Publisher" },
  ];

  useBreadcrumbs({
    title: "Accounts",
    path: routeConstant.accounts.path,
  });

  return (
    <div>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>
              All User account information
            </div>
            <div className={styles.tableSubHeading}>
              View all user account's information from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            {/* <div>
              <Filter
                filterOption={filterOption}
                filterStyles={styles.filterStyles}
                selectedOption={selectedFilter}
                setSelectedOption={setSelectedFilter}
              />
            </div> */}
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Publisher name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allAccounts?.map((item: any) => {
                  return (
                    <TableRow className={styles.tableRow}>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <Avatar
                            src={
                              item?.publisher
                                ? item?.publisher?.profile_picture
                                : item?.author
                                ? item?.author?.profile_picture
                                : ""
                            }
                          />
                          <span
                            className={styles.tableItem}
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.publisher
                              ? item?.publisher?.full_name
                              : item?.author
                              ? item?.author?.full_name
                              : ""}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        {item?.publisher
                          ? item?.publisher?.phone
                          : item?.author
                          ? item?.author?.phone
                          : ""}
                      </TableCell>
                      <TableCell>
                        {
                          admin_roles.find(
                            (role) => role.id === Number(item?.role)
                          )?.label
                        }
                      </TableCell>{" "}
                      <TableCell>
                        {/*Status pending then upload receipt less view receipt*/}
                        {
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip arrow title="View Detail">
                              <button onClick={() => handleopenDetail(item)}>
                                {item?.status !== 0 ? (
                                  <RemoveRedEyeIcon />
                                ) : (
                                  <CloudUploadIcon />
                                )}
                              </button>
                            </Tooltip>
                          </Box>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allAccounts?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
      </Box>
      {allAccounts?.length > 0 ? (
        <ItemsPerPage
          setActivePage={setActivePage}
          currentPage={currentPage}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
        />
      ) : null}
      <ProfileModal
        open={openDetail}
        handleClose={handleCloseTransaction}
        selectedAccount={selectedAccount}
        // getEarnings={getEarnings}
      />
    </div>
  );
}

export default DashboardAccountant;
