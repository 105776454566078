import {
  Avatar,
  Box,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import DoneIcon from "@mui/icons-material/Done";
import GroupIcon from "@mui/icons-material/Group";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Icons } from "assets";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/components/card";
import ItemsPerPage from "shared/components/itemsPerPage";
import NoDataFound from "shared/components/noDataFound";
import Pagination from "shared/components/pagination";
import TableSkeleton from "shared/components/TableSkeleton";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import useDebounce from "shared/customHooks/useDebounce";
import useWindowSize from "shared/customHooks/windowSize";
import Confirmation from "shared/modals/confirmation";
import SchoolApprovedModal from "shared/modals/schoolApprovedModal";
import SchoolDetailsModal from "shared/modals/schoolDetailsModal";
import { routeConstant } from "shared/routes/routesConstant";
import {
  approveSchool,
  blockSchool,
  getAllSchoolAccounts,
  getDashboardData,
  getUsersServices,
  rejectSchool,
  schoolStats,
  unBlockSchool,
} from "shared/services/adminService";
import { searchHandlerHelper } from "shared/utils/helpers";
import {
  columnHeadings,
  headings,
  skeletonInfo
} from "./constants";
import SkeletonLoader from "./skeletonLoader";
import styles from "./style.module.scss";

const tab_items = [
  {
    label: "Partner Accounts",
    value: 0,
  },
  {
    label: "School Accounts",
    value: 1,
  },
];

function Dashboard() {
  const navigate = useNavigate();
  const screenWidth = useWindowSize();
  const [schoolAccounts, setSchoolAccounts] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(tab_items[0].value);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [users, setAllUsers] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  // eslint-disable-next-line
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [openSuccessfullyApprovedModal, setOpenSuccessfullyApprovedModal] =
    useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<number | null>(null);
  const [schoolName, setSchoolName] = useState<string>("");
  const [modalType, setModalType] = useState<string>("");
  const [schoolStatsData, setSchoolStatsData] = useState<any>([]);
  const [statsLoader, setStatsLoader] = useState<boolean>(false);
  const [school, setSchool] = useState<any>({});
  const [openSchoolDetailsModal, setOpenSchoolDetailModal] =
    useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const partnersCardData = [
    {
      title: "Total Publishers",
      icon: GroupIcon,
      stats: dashboardData?.publisher?.total,
    },
    {
      title: "Total Authors",
      icon: CreateIcon,
      stats: dashboardData?.author?.total,
    },
    {
      title: "Total Book Reviewers",
      icon: ImportContactsIcon,
      stats: dashboardData?.book_reviewer?.total,
    },
  ];

  const schoolCardsData = [
    {
      title: "Total Requests Received",
      stats: schoolStatsData?.total ? schoolStatsData?.total : 0,
      icon: null,
    },
    {
      title: "Total Accounts Approved",
      stats: schoolStatsData?.approved ? schoolStatsData?.approved : 0,
      icon: null,
    },
    {
      title: " Total Accounts Rejected",
      stats: schoolStatsData?.rejected ? schoolStatsData?.rejected : 0,
      icon: null,
    },
  ];

  const handleOpenApproveModal = (school: any) => {
    setModalType("Approve");
    setSchoolId(school.id);
    setSchoolName(school.full_name);
    setOpenApproveModal(true);
  };

  const handleOpenRejectModal = (school: any) => {
    setModalType("Reject");
    setSchoolId(school.id);
    setSchoolName(school.full_name);
    setOpenRejectModal(true);
  };

  const handleClick = () => {
    setOpenDelete(false);
  };
  // GET PARTNER ACCOUNTS USERS
  const getAllUsers = () => {
    setLoader(true);
    getUsersServices(searchText, 0, "", activePage, itemsPerPage)
      .then(({ data }) => {
        setLoader(false);
        setTotalPage(data?.data?.last_page);
        setAllUsers(data?.data?.data);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  // GET APPROVAL PENDING SCHOOLS

  const getPendingSchools = () => {
    setLoader(true);
    getAllSchoolAccounts({
      selectedFilter: 0,
      itemsPerPage,
      activePage,
      search: searchText,
    })
      .then(({ data, status }) => {
        if (status) {
          const { data: response } = data;
          const {
            current_page,
            last_page,
            per_page,
            total,
            data: accounts,
          } = response;

          setActivePage(current_page);
          setItemsPerPage(per_page);
          setTotalPage(last_page);
          setTotalItems(
            accounts?.filter((account: any) => account?.status === 0).length
          );
          setLoader(false);
          setSchoolAccounts(
            accounts?.filter((account: any) => account?.status === 0)
          );
        }
      })
      .catch((err) => {
        console.log("Error-----", err);
        setLoader(false);
      });
  };
  const handleApproveConfirmation = () => {
    setLoader(true);
    approveSchool(schoolId)
      .then(({ data, status }) => {
        if (status) {
          setOpenApproveModal(false);
          getPendingSchools();
          toastMessage("success", data?.message);
          setOpenSuccessfullyApprovedModal(true);
          setLoader(false);
          setSchoolName(data?.school?.full_name);
        }
      })
      .catch((err) => {
        console.log("Error----", err);
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handleRejectConfirmation = () => {
    setLoader(true);
    rejectSchool(schoolId)
      .then(({ data, status }) => {
        if (status) {
          setOpenRejectModal(false);
          getPendingSchools();
          setLoader(false);
          toastMessage("success", data?.message);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Error---", err);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getDashboardStats = () => {
    setIsLoading(true);
    getDashboardData()
      .then(({ data }) => {
        setIsLoading(false);
        setDashboardData(data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  const getSchoolStats = () => {
    setStatsLoader(true);
    schoolStats()
      .then(({ data, status }) => {
        if (status) {
          const { data: stats } = data;
          setSchoolStatsData(stats);
          setStatsLoader(false);
        }
      })
      .catch((err: any) => {
        setStatsLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handleAccount = (school: any) => {
    const service = school?.is_blocked === 0 ? blockSchool : unBlockSchool;
    service(school?.id)
      .then(({ data, status }) => {
        if (status) {
          getPendingSchools();
          setOpenConfirmationModal(false);
          toastMessage("success", data?.message);
        }
      })
      .catch((err: any) => {
        console.log("Error----", err);
        toastMessage("err", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (activeTab === 0) {
      getAllUsers();
    } else if (activeTab === 1) {
      getPendingSchools();
    }

    // eslint-disable-next-line
  }, [searchText, selectedFilter, activePage, itemsPerPage, activeTab]);

  useEffect(() => {
    if (activeTab === 0) {
      getDashboardStats();
    } else {
      getSchoolStats();
    }
  }, [activeTab]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const admin_roles = [
    { id: 1, label: "School" },
    { id: 2, label: "Teacher" },
    { id: 3, label: "Parent" },
    { id: 4, label: "Reader" },
    { id: 5, label: "Author" },
    { id: 6, label: "Publisher" },
  ];

  const MOU_Status = [
    { label: "Draft", value: 0 },
    { label: "Review", value: 1 },
    { label: "Pending", value: 2 },
    { label: "Accepted", value: 3 },
    { label: "Declined", value: 4 },
  ];

  useBreadcrumbs({
    title: "Dashboard",
    path: routeConstant?.dashboard?.path,
  });
  const cards = activeTab === 0 ? partnersCardData : schoolCardsData;
  return (
    <div>
      <Grid container spacing={2}>
        {cards?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={12} md={4}>
                <Card
                  Icon={item?.icon}
                  stats={item?.stats}
                  title={item?.title}
                  isLoading={activeTab === 0 ? isLoading : statsLoader}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "1rem" }}>
        <Tabs
          value={activeTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#9A469B",
              color: "red !important",
            },
          }}
          sx={{
            "& .MuiTab-root.Mui-selected": {
              color: "#9A469B",
            },
          }}
        >
          {tab_items?.map((item) => {
            return (
              <Tab
                key={item?.value}
                label={item?.label}
                className={styles.tabs}
                onClick={() => {
                  setActiveTab(item?.value);
                  setSearch("");
                }}
              />
            );
          })}
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <Box className={styles.topLevelContainer}>
          <Box className={styles.topHeadContainer}>
            <Box>
              <div className={styles.tableHeading}>Newly registered users.</div>
              <div className={styles.tableSubHeading}>
                Manage your recently added users from here.
              </div>
            </Box>
            <Box className={styles.filterContainer}>
              <div className={styles.gridContainer}>
                <Icons.SearchIcon className={styles.searchIcon} />
                <input
                  placeholder="Search"
                  className={styles.input}
                  value={search}
                  onChange={(e) =>
                    searchHandlerHelper(e, setSearch, setActivePage)
                  }
                />
              </div>
            </Box>
          </Box>
          <TableContainer component={Paper} className={styles.table}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className={styles.tableHead}>
                  <TableCell>Participant name</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>MOU Signed</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loader ? (
                  <SkeletonLoader />
                ) : (
                  users?.map((item: any, index: any) => {
                    return (
                      <TableRow key={item?.id}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <Avatar
                              src={
                                item?.publisher
                                  ? item?.publisher?.profile_picture
                                  : item?.author?.profile_picture
                              }
                            />
                            <span
                              className={styles.tableItem}
                              style={{ marginLeft: "10px" }}
                            >
                              {item?.publisher
                                ? item?.publisher?.full_name
                                : item?.author?.full_name}{" "}
                            </span>
                          </Box>
                        </TableCell>{" "}
                        <TableCell>
                          {
                            admin_roles.find(
                              (role) => role.id === Number(item?.role)
                            )?.label
                          }
                        </TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell>
                          {item?.latest_mou
                            ? MOU_Status.find(
                                (itm: any) =>
                                  itm.value === Number(item?.latest_mou?.status)
                              )?.label
                            : "No agreement yet."}
                        </TableCell>
                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip arrow title="View Detail">
                              <button
                                onClick={() =>
                                  navigate(
                                    Number(item?.role) === 6
                                      ? routeConstant.publisherProfile.path.replace(
                                          ":id",
                                          item?.id
                                        )
                                      : routeConstant.singleAuthor.path.replace(
                                          ":id",
                                          item?.id
                                        )
                                  )
                                }
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>
                            <Tooltip arrow title="Ticket">
                              <button
                                onClick={() =>
                                  navigate(
                                    routeConstant.singleTicket.path.replace(
                                      ":id",
                                      item?.id
                                    )
                                  )
                                }
                              >
                                <ChatBubbleIcon />
                              </button>
                            </Tooltip>
                            <Tooltip arrow title="MOU">
                              <button
                                onClick={() =>
                                  navigate(
                                    routeConstant.mou.path.replace(
                                      ":id",
                                      item?.id
                                    ),
                                    {
                                      state: { isAdmin: true },
                                    }
                                  )
                                }
                              >
                                <ArticleIcon />
                              </button>
                            </Tooltip>
                            {/* <Tooltip arrow title="Delete">
                              <button onClick={() => handleOpenDelete()}>
                                <DeleteIcon />
                              </button>
                            </Tooltip> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!(users.length > 0) && !loader && (
            <div className={styles.noUserCard}>
              <Icons.NoUsersData />
              <h5>No Data Found</h5>
            </div>
          )}
          {totalPage > 1 ? (
            <Pagination
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          ) : null}
          {users?.length > 0 ? (
            <ItemsPerPage
              setActivePage={setActivePage}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              totalItems={totalItems}
            ></ItemsPerPage>
          ) : null}
        </Box>
      )}

      {activeTab === 1 && (
        <section>
          {/* Stats */}
          {/* <Box>
        <Grid container spacing={2}>
          {cardData?.map((card: any, index) => {
            return (
              <Grid item xs={12} sm={4} key={index}>
                <CardComp
                  isLoading={statsLoader}
                  stats={card.stats}
                  title={card.title}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box> */}
          {/* Table */}
          <Box className={styles.topLevelContainer}>
            <Box className={styles.topHeadContainer}>
              <Box>
                <div className={styles.tableHeading}>
                  {headings.mainHeading}
                </div>
                <div className={styles.tableSubHeading}>
                  {headings.subHeading}
                </div>
              </Box>
              <Box className={styles.filterContainer}>
                <div className={styles.gridContainer}>
                  <Icons.SearchIcon className={styles.searchIcon} />
                  <input
                    placeholder="Search By School Name"
                    className={styles.input}
                    value={search}
                    onChange={(e) =>
                      searchHandlerHelper(e, setSearch, setActivePage)
                    }
                  />
                </div>
              </Box>
            </Box>
            <TableContainer
              className={styles.container}
              component={Paper}
              sx={
                screenWidth > 990
                  ? {
                      width: `${screenWidth - 300}px`,
                    }
                  : {
                      boxShadow: "none",
                    }
              }
            >
              <Table className={styles.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={styles.tableHead}>
                    {columnHeadings?.map((heading, index) => {
                      return (
                        <TableCell key={heading.id}>{heading.title}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader ? (
                    <TableSkeleton
                      rows={skeletonInfo.rows}
                      cellCount={skeletonInfo.cellCount}
                      actionCount={skeletonInfo.actions}
                      showPersonData={false}
                    ></TableSkeleton>
                  ) : (
                    schoolAccounts?.map((account: any) => {
                      return (
                        <TableRow>
                          <TableCell sx={{ minWidth: "188px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <Avatar
                                alt={account?.username}
                                src={account?.school?.profile_picture}
                                sx={{ width: 40, height: 40 }}
                              />
                              <div className={styles.accountInfo}>
                                <span className={styles.fullName}>
                                  {account?.school?.school_name
                                    ? account?.school?.school_name
                                    : "Not Provided"}
                                </span>
                                <span className={styles.email}>
                                  {account?.email
                                    ? account?.email
                                    : "Not Provided"}
                                </span>
                              </div>
                            </Box>
                          </TableCell>
                          <TableCell>
                            {account?.school?.phone
                              ? account?.school.phone
                              : "Not Provided"}
                          </TableCell>
                          {/* <TableCell>
                        {account?.email ? account?.email : "NOt Provided"}
                      </TableCell> */}

                          <TableCell sx={{ minWidth: "120px" }}>
                            {account?.school?.students_limit
                              ? account?.school?.students_limit
                              : "Not Provided"}
                          </TableCell>
                          <TableCell sx={{ minWidth: "120px" }}>
                            {account?.school?.teachers_limit
                              ? account?.school?.teachers_limit
                              : "Not Provided"}
                          </TableCell>
                          <TableCell>
                            <span className={styles.pending}>
                              {account?.status === 0
                                ? "Pending"
                                : "Not Provided"}
                            </span>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <Tooltip title="View">
                                <span
                                  onClick={() => {
                                    setSchool(account);
                                    setOpenSchoolDetailModal(true);
                                  }}
                                  className={styles.iconWrapper}
                                >
                                  <RemoveRedEyeIcon />
                                </span>
                              </Tooltip>
                              <Tooltip title="Ticket">
                                <button
                                  className={styles.iconWrapper}
                                  onClick={() =>
                                    navigate(
                                      routeConstant.singleTicket.path.replace(
                                        ":id",
                                        account?.id
                                      ),
                                      {
                                        state: {
                                          user: account,
                                        },
                                      }
                                    )
                                  }
                                >
                                  <ChatBubbleIcon />
                                </button>
                              </Tooltip>

                              <Tooltip
                                arrow
                                title={
                                  account?.is_blocked === 0
                                    ? "Deactivate"
                                    : "Activate"
                                }
                              >
                                <button
                                  onClick={() => {
                                    setSchool(account);
                                    setOpenConfirmationModal(true);
                                  }}
                                  className={styles.iconWrapper}
                                >
                                  {account?.is_blocked === 0 ? (
                                    <Icons.SchoolDeactivate />
                                  ) : (
                                    <Icons.SchoolActivate />
                                  )}
                                </button>
                              </Tooltip>

                              <Tooltip title="Reject">
                                <span
                                  onClick={() => handleOpenRejectModal(account)}
                                  className={styles.iconWrapper}
                                >
                                  <CloseIcon />
                                </span>
                              </Tooltip>
                              <Tooltip title="Approve">
                                <span
                                  onClick={() =>
                                    handleOpenApproveModal(account)
                                  }
                                  className={styles.iconWrapper}
                                >
                                  <DoneIcon />
                                </span>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box>
              {totalItems &&
              activePage &&
              totalPage > 1 &&
              schoolAccounts?.length ? (
                <Pagination
                  totalPages={totalPage}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              ) : null}
            </Box>
            <Box>
              {totalItems &&
              activePage &&
              currentPage &&
              schoolAccounts?.length ? (
                <ItemsPerPage
                  setActivePage={setActivePage}
                  currentPage={currentPage}
                  setItemsPerPage={setItemsPerPage}
                  itemsPerPage={itemsPerPage ? itemsPerPage : 10}
                  totalItems={totalItems}
                />
              ) : null}
            </Box>
            <Box>
              {!loader && !(schoolAccounts?.length > 0) && <NoDataFound />}
            </Box>
          </Box>

          {/* Open Approve/Reject Modal */}
          <Confirmation
            loader={loader}
            open={modalType === "Approve" ? openApproveModal : openRejectModal}
            handleClose={
              modalType === "Approve"
                ? () => setOpenApproveModal(false)
                : () => setOpenRejectModal(false)
            }
            text={
              modalType === "Approve"
                ? "Are you sure you want to approve this request ?"
                : "Are you sure you want to reject this request ?"
            }
            handleClick={
              modalType === "Approve"
                ? handleApproveConfirmation
                : handleRejectConfirmation
            }
            showTextArea={false}
          />
          {/* Approve Reject School */}
          <Confirmation
            open={openConfirmationModal}
            handleClose={() => setOpenConfirmationModal(false)}
            text={
              !school?.is_blocked
                ? "Are you sure you want to deactivate this school ?"
                : "Are you sure you want to activate this school ?"
            }
            handleClick={() => handleAccount(school)}
          />
          {/* Successfully Approved Modal */}
          <SchoolDetailsModal
            schoolDetails={school}
            isOpen={openSchoolDetailsModal}
            closeModal={() => setOpenSchoolDetailModal(false)}
          />
          <SchoolApprovedModal
            content={`School has been approved and and now it can add teachers and students`}
            open={openSuccessfullyApprovedModal}
            closeModal={() => setOpenSuccessfullyApprovedModal(false)}
          />
        </section>
      )}

      <Confirmation
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleClick={handleClick}
        text="Are you sure, you want to delete this publisher?"
      />
    </div>
  );
}

export default Dashboard;
