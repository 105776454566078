import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import ProfileCard from "shared/components/profileCard";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import Pagination from "shared/components/pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate, useParams } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import useWindowSize from "shared/customHooks/windowSize";
import moment from "moment";
import Confirmation from "shared/modals/confirmation";
import {
  bookReviewerDetails,
  getBookReviewerBooksService,
} from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import {
  getBookReviewerBooksHOD,
  getReviewerProfileByHOD,
} from "shared/services/hodService";
import { useSelector } from "react-redux";
import { rolesRoutes } from "shared/routes/allRoutes";
import Filter from "shared/components/filterButton";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";

function BookReviewer() {
  const { id } = useParams();
  const { user } = useSelector((state: any) => state.root);
  const navigate = useNavigate();
  const screenWidth = useWindowSize();
  const [activePage, setActivePage] = useState<number>(1);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  // const [totalPage, setTotalPage] = useState<number>(10);
  const [bookReviewer, setBookReviewer] = useState<any>({});
  const [allBooks, setAllBooks] = useState<any>([]);
  // const [loader, setLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [bookLoader, setBookLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const current_role = rolesRoutes.find(
    (role) => role.role_id === Number(user?.user?.role)
  );

  // const handleOpenDelete = () => {
  //   setOpenDelete(true);
  // };

  const handleClick = () => {
    setOpenDelete(false);
  };

  const getBookReviewerDetails = () => {
    let activeRole = rolesRoutes.find(
      (role) => role.role_id === Number(user?.user?.role)
    );

    let service =
      activeRole?.role_id === 2 ? getReviewerProfileByHOD : bookReviewerDetails;

    service(id)
      .then(({ data }) => {
        setBookReviewer(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log(err?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBookReviewerDetails();
    // eslint-disable-next-line
  }, []);

  const getBooksReviewersBooks = () => {
    setBookLoader(true);

    let activeRole = rolesRoutes.find(
      (role) => role.role_id === Number(user?.user?.role)
    );

    let service =
      activeRole?.role_id === 2
        ? getBookReviewerBooksHOD
        : getBookReviewerBooksService;

    service(id, search, activePage, selectedFilter?.value, itemsPerPage)
      .then(({ data }) => {
        setAllBooks(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setBookLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("err", err?.response?.data?.message);
        setBookLoader(false);
      });
  };

  useEffect(() => {
    getBooksReviewersBooks();
    // eslint-disable-next-line
  }, [searchText, activePage, selectedFilter, itemsPerPage]);

  const BOOK_STATUS = [
    { id: 0, label: "Pending" },
    { id: 1, label: "Admin Review" },
    { id: 2, label: "Revision Requested" },
    { id: 3, label: "Revision Submitted" },
    { id: 4, label: "Approved" },
    { id: 5, label: "Published" },
    { id: 6, label: "Rejected" },
    { id: 7, label: "Internal Review" },
    { id: 8, label: "Review Completed" },
  ];

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  const filterOption = [
    {
      option: "Internal review",
      value: 7,
    },
    {
      option: "Review completed",
      value: 8,
    },
  ];

  useBreadcrumbs(
    {
      title: bookReviewer?.full_name,
      path: routeConstant.bookReview.path.replace(":id", bookReviewer?.id),
    },
    true,
    bookReviewer
  );

  return (
    <div>
      <ProfileCard
        user={bookReviewer}
        isBookReviewer={true}
        isLoader={loading}
      />
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Assigned Books</div>
            <div className={styles.tableSubHeading}>
              Here is the listing of all assigned books to{" "}
              <span style={{ color: "#9A469B", fontWeight: "bold" }}>
                {bookReviewer?.full_name}.
              </span>
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                style={{ margin: "20px 0px" }}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            {current_role?.title !== "Admin" ? (
              <div>
                <Filter
                  filterOption={filterOption}
                  filterStyles={styles.filterStyles}
                  selectedOption={selectedFilter}
                  setSelectedOption={setSelectedFilter}
                />
              </div>
            ) : null}
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book name</TableCell>
                <TableCell>Author name</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Genre</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Total Pages</TableCell>
                <TableCell>Assigned Date</TableCell>
                <TableCell>Book Review Start Time</TableCell>
                <TableCell>Book Review End Time</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookLoader ? (
                <SkeletonLoader
                  rectangularLoaderCount={8}
                  actionLoaderCount={1}
                />
              ) : (
                allBooks?.map((item: any) => {
                  const genreNames = item?.book?.genres.map((genre: any) => {
                    return `${genre.name}, `;
                  });
                  const genreString = genreNames.join(" ").slice(0, -1);
                  const genreStringLength = genreString.length;
                  const genre = genreString.substring(0, genreStringLength - 1);
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={item?.book?.thumbnail}
                              alt="book cover"
                              className={styles.bookCover}
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {item?.book?.title}
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item?.book?.book_author?.name
                            ? item?.book?.book_author?.name
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {item?.book?.age_range
                            ? item?.book?.age_range?.text.split(" ")[1]
                            : "Not provided"}
                        </TableCell>
                        <TableCell>
                          {item?.book?.genres ? genre : "Not Provided"}
                        </TableCell>
                        <TableCell>
                          {item?.book?.language
                            ? item?.book?.language?.name
                            : "Not Provided"}
                        </TableCell>
                        <TableCell>{item?.book?.total_pages}</TableCell>
                        <TableCell>
                          {moment(item?.schedule?.assign_date).format(
                            "DD-MM-YYYY"
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(new Date()).isSameOrBefore(
                            item?.schedule?.review_date
                          ) ? (
                            <span className={styles.statusApproved}>
                              {moment(item?.schedule?.review_date).format(
                                "DD-MM-YYYY"
                              )}
                            </span>
                          ) : (
                            <span className={styles.statusRejected}>
                              {moment(item?.schedule?.review_date).format(
                                "DD-MM-YYYY"
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.schedule?.submitted_date
                            ? moment(item?.schedule?.submitted_date).format(
                                "D-MM-YYYY"
                              )
                            : "Not submitted yet."}
                        </TableCell>
                        {/* <TableCell>
                          {item?.author?.name
                            ? item?.author?.name
                            : "Not Provided"}
                        </TableCell> */}
                        {/* <TableCell>{item?.genres[0]?.name}</TableCell> */}

                        {/* <TableCell>
                          <span
                            className={
                              item?.status === 4 || item?.status === 5
                                ? styles.statusApproved
                                : styles.statusRejected
                            }
                          >
                            {
                              BOOK_STATUS.find(
                                (itm: any) => itm?.id === Number(item?.status)
                              )?.label
                            }
                          </span>
                        </TableCell> */}

                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip title="View Book Detail">
                              <button
                                onClick={() =>
                                  navigate(
                                    routeConstant.bookDetail.path.replace(
                                      ":id",
                                      item?.book?.id
                                    )
                                  )
                                }
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allBooks?.length > 0) && !bookLoader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}

        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allBooks?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
      <Confirmation
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleClick={handleClick}
        text="Are you sure, you want to delete this review?"
      />
    </div>
  );
}

export default BookReviewer;
