import { Avatar, Box, Grid, Tooltip, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "shared/components/card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PreviewIcon from "@mui/icons-material/Preview";
import styles from "./style.module.scss";
import { Icons } from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "shared/components/pagination";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import useWindowSize from "shared/customHooks/windowSize";
import CustomButton from "shared/components/customButton";
import CreateBookReviwer from "shared/modals/createBookReviewer";
import SkeletonLoader from "./skeletonLoader";
import Loader from "shared/components/loader/animation";

import {
  blockReviewer,
  getAllBookReviewersService,
  getBookReviewerStats,
  unBlockReviewer,
} from "shared/services/adminService";
import { toastMessage } from "shared/components/toast";
import { rolesRoutes } from "shared/routes/allRoutes";
import { useSelector } from "react-redux";
import {
  getHODBookReviewerStats,
  getHODBookReviewersService,
} from "shared/services/hodService";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import Confirmation from "shared/modals/confirmation";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";

function BookReviewers() {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<number>(1);
  const screenWidth = useWindowSize();
  const [openBookReviewer, setOpenBookReviewer] = useState(false);
  const [totalPage, setTotalPage] = useState<number>(10);
  const widthTablet = useMediaQuery("(max-width:767px)");
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [allBookReviewers, setAllBookReviewers] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirm, setOpenConfirmation] = useState<boolean>(false);
  const [selectedReviewer, setSelectedReviewer] = useState<any>(null);
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const [action, setAction] = useState<{ label: string; type: string }>({
    label: "",
    type: "",
  });
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [isBookReviewerAdded, setIsBookReviewerAdded] =
    useState<boolean>(false);

  const {
    user: { user },
  } = useSelector((state: any) => state.root);
  const current_role = rolesRoutes.find(
    (role) => role.role_id === Number(user?.role)
  );

  const cardDataAdmin = [
    {
      title: "Total book reviewers",
      icon: MenuBookIcon,
      stats: dashboardData?.total_reviewers,
    },
    {
      title: "Total book approval pending",
      icon: PendingActionsIcon,
      stats: dashboardData?.total_book_approval_pending,
    },
    {
      title: "Total books in review process",
      icon: PreviewIcon,
      stats: dashboardData?.total_books_in_review,
    },
  ];

  const cardDataHOD = [
    {
      title: "Total book reviewers",
      icon: MenuBookIcon,
      stats: dashboardData?.total_reviewers,
    },
    {
      title: "Total book reviewed",
      icon: PendingActionsIcon,
      stats: dashboardData?.total_books_reviewed,
    },
    {
      title: "Total books in review process",
      icon: PreviewIcon,
      stats: dashboardData?.total_books_in_review,
    },
  ];

  const getAllStats = () => {
    setIsLoading(true);

    const statsPromise =
      current_role?.title === "Admin"
        ? getBookReviewerStats()
        : getHODBookReviewerStats();

    statsPromise
      .then(({ data }) => {
        setIsLoading(false);
        setDashboardData(data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const getAllBookReviewers = () => {
    setLoader(true);

    const getData =
      current_role?.title === "Admin"
        ? getAllBookReviewersService(search, activePage, itemsPerPage)
        : getHODBookReviewersService(search, activePage, itemsPerPage);

    getData
      .then(({ data }) => {
        setTotalPage(data?.data?.last_page);
        setAllBookReviewers(data?.data?.data);
        setLoader(false);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllStats();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllBookReviewers();
    // eslint-disable-next-line
  }, [search, activePage, itemsPerPage, isBookReviewerAdded]);

  const handleConfirmation = (label: string, type: string, item: any) => {
    setOpenConfirmation(true);
    setAction({ label, type });
    setSelectedReviewer(item);
  };

  const handleActions = (
    action: { label: string; type: string },
    item: any
  ) => {
    const service = action.type === "block" ? blockReviewer : unBlockReviewer;
    setActionLoader(true);

    service(item?.id)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        setActionLoader(false);
        getAllBookReviewers();
        handleCloseConfirmation();
        setIsBookReviewerAdded(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setActionLoader(false);
      });
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAction({ label: "", type: "" });
    setSelectedReviewer(null);
  };

  useBreadcrumbs({
    title: "Book Reviewers",
    path: routeConstant.bookReviewer.path,
  });
  return (
    <div>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        {current_role?.title !== "Admin"
          ? // cardDataAdmin?.map((item) => {
            //     return (
            //       <Grid item xs={widthTablet ? 12 : 4}>
            //         <Card
            //           Icon={item?.icon}
            //           stats={item?.stats}
            //           title={item?.title}
            //           isLoading={isLoading}
            //         />
            //       </Grid>
            //     );
            //   })
            // :
            cardDataHOD?.map((item) => {
              return (
                <Grid item xs={widthTablet ? 12 : 4}>
                  <Card
                    Icon={item?.icon}
                    stats={item?.stats}
                    title={item?.title}
                    isLoading={isLoading}
                  />
                </Grid>
              );
            })
          : null}
      </Grid>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Book Reviewers</div>
            <div className={styles.tableSubHeading}>
              Manage book reviewers from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
            {current_role?.title === "Admin" && (
              <CustomButton
                text="Create new"
                buttonStyle={styles.createBtn}
                handleClick={() => setOpenBookReviewer(true)}
              />
            )}
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book Reviewer Name</TableCell>
                {current_role?.title === "Admin" && (
                  <>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                  </>
                )}
                {/* <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell> */}
                {current_role?.title === "HOD" && (
                  <>
                    <TableCell>Expertise</TableCell>
                    <TableCell>Total Books Reviewed</TableCell>
                    <TableCell>Books Assgined</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                  </>
                )}
                {/* {current_role?.title !== "Admin" ? (
                  <TableCell>Status</TableCell>
                ) : null} */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader
                ? Array.from({ length: 8 }).map((_, index) => {
                    return (
                      <SkeletonLoader
                        actionsCount={
                          current_role?.title === "Admin"
                            ? 2
                            : current_role?.title === "HOD"
                            ? 1
                            : 1
                        }
                        rectangularCells={
                          current_role?.title === "Admin"
                            ? 2
                            : current_role?.title === "HOD"
                            ? 4
                            : 1
                        }
                      />
                    );
                  })
                : allBookReviewers?.map((item: any) => {
                    const genreNames = item?.genres.map((genre: any) => {
                      return `${genre.genre?.name}, `;
                    });
                    const genreString = genreNames.join(" ").slice(0, -1);
                    const genreStringLength = genreString.length;
                    const genre = genreString.substring(
                      0,
                      genreStringLength - 1
                    );

                    return (
                      <TableRow>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <Avatar
                              alt={item?.name}
                              src={item?.profile_picture}
                            />
                            <span
                              className={styles.tableItem}
                              style={{ marginLeft: "10px" }}
                            >
                              {item?.full_name}
                            </span>
                          </Box>
                        </TableCell>
                        {current_role?.title === "Admin" ? (
                          <>
                            <TableCell>{item?.email}</TableCell>
                            <TableCell>
                              {item?.phone ? item?.phone : "Not Provided"}
                            </TableCell>
                          </>
                        ) : null}

                        {current_role?.title === "HOD" ? (
                          <>
                            <TableCell>
                              {item.genres.length > 0 ? genre : "Not Provided"}
                            </TableCell>
                            <TableCell>
                              {item?.books_count
                                ? item?.books_count?.reviewed
                                : "Not Provided"}
                            </TableCell>
                            <TableCell>
                              {item?.books_count
                                ? item?.books_count?.assigned
                                : "Not Provided"}
                            </TableCell>
                            {/* <TableCell>Status</TableCell> */}
                          </>
                        ) : null}

                        {/* {current_role?.title !== "Admin" ? (
                          <TableCell>
                            {item?.is_blocked ? "Blocked" : "Active"}
                          </TableCell>
                        ) : null} */}
                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip arrow title="View details">
                              <button
                                onClick={() => {
                                  navigate(
                                    routeConstant.bookReview.path.replace(
                                      ":id",
                                      item?.id
                                    )
                                  );
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>

                            {/*only for Admin to block and unblock reviewer*/}
                            {current_role?.title === "Admin" ? (
                              <Tooltip
                                arrow
                                title={
                                  item?.is_blocked === 1 ? "unblock" : "block"
                                }
                              >
                                <button
                                  onClick={() => {
                                    handleConfirmation(
                                      `Are you sure you want to ${
                                        item?.is_blocked ? "unblock" : "block"
                                      } this reviewer?`,
                                      item?.is_blocked === 1
                                        ? "unblock"
                                        : "block",
                                      item
                                    );
                                  }}
                                >
                                  {item?.is_blocked ? (
                                    <FlashOffIcon />
                                  ) : (
                                    <FlashOnIcon />
                                  )}
                                </button>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {!allBookReviewers?.length && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {/* ROWS PER PAGE */}
        {allBookReviewers?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
      <CreateBookReviwer
        open={openBookReviewer}
        handleClose={() => setOpenBookReviewer(false)}
        setIsBookReviewerAdded={setIsBookReviewerAdded}
      />
      <Confirmation
        open={openConfirm}
        handleClose={handleCloseConfirmation}
        handleClick={() => handleActions(action, selectedReviewer)}
        text={action.label}
      />

      {actionLoader && <Loader />}
    </div>
  );
}

export default BookReviewers;
