import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { useState } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ConfirmationProps {
  open: boolean;
  handleClose: any;
  loader: boolean;
  text: any;
  handleClick: any;
  showTextArea?: boolean;
}

export default function Confirmation({
  open,
  handleClose,
  loader,
  handleClick,
  text,
  showTextArea,
}: Partial<ConfirmationProps>) {
  const [input, setInput] = useState("");
  return (
    <div>
      <Dialog
        open={open ? open : false}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={`${styles.content} ${styles.flexGrid}`}>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          />

          <div className={styles.contentLabel}>
            {showTextArea ? text?.label : text}
          </div>
          {showTextArea && (
            <div className={styles.textAreaWrapper}>
              <div className={styles.formGroup}>
                <label className="styles.label" htmlFor="textArea">
                  Leave your message here !
                </label>
                <textarea
                  className={styles.input}
                  onChange={(e) => setInput(e.target.value)}
                  id="textArea"
                  value={input}
                ></textarea>
              </div>
            </div>
          )}
          <div className={styles.buttonsContainer}>
            <CustomButton
              text={showTextArea ? "Confirm" : "Yes"}
              handleClick={() => handleClick(input)}
              loader={loader}
            />
            <CustomButton
              text={showTextArea ? "Deny" : "No"}
              buttonStyle={styles.noBtn}
              handleClick={handleClose}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
