import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import useWindowSize from "shared/customHooks/windowSize";
import Loader from "shared/components/loader/animation";
import Pagination from "shared/components/pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Confirmation from "shared/modals/confirmation";
import Popover from "shared/components/PopOver";
import { toastMessage } from "shared/components/toast";
import SkeletonLoader from "./skeletonLoader";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { routeConstant } from "shared/routes/routesConstant";
import useDebounce from "shared/customHooks/useDebounce";
import {
  approvedBookService,
  declineBookService,
  deleteBookService,
  historyBookLists,
  pendingBooksLists,
} from "shared/services/productManagerService";
import CustomButton from "shared/components/customButton";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { searchHandlerHelper } from "shared/utils/helpers";
import ItemsPerPage from "shared/components/itemsPerPage";

function ReportedUsers() {
  const screenWidth = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const [activePage, setActivePage] = useState<number>(1);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [reports, setReports] = useState<any>(null);
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const [status, setStatus] = useState<any>(0);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const getHistory = () => {
    setLoader(true);
    historyBookLists(id, searchText, itemsPerPage)
      .then(({ data, status }) => {
        if (status) {
          setReports(data?.data?.data);
          setTotalPage(data?.data?.last_page);
          setLoader(false);
          setTotalItems(data?.data?.total);
          setItemsPerPage(data?.data?.per_page);
          setCurrentPage(data?.data?.current_page);
        }
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getReports = () => {
    setLoader(true);
    pendingBooksLists(id, activePage, itemsPerPage)
      .then(({ data, status }) => {
        if (status) {
          setReports(data?.data?.data);
          setTotalPage(data?.data?.last_page);
          setLoader(false);
          setTotalItems(data?.data?.total);
          setItemsPerPage(data?.data?.per_page);
          setCurrentPage(data?.data?.current_page);
        }
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (status === 0) {
      getReports();
    } else {
      getHistory();
    }
    // eslint-disable-next-line
  }, [activePage, searchText, status, itemsPerPage]);

  const handleOpenConfirm = (label: string, type: string, item?: any) => {
    setAction({
      label: label,
      type: type,
    });
    setSelectedItem(item);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedItem(null);
  };

  const admin_user_roles = [
    { id: 1, label: "School" },
    { id: 2, label: "Teacher" },
    { id: 3, label: "Parent" },
    { id: 4, label: "Reader" },
    { id: 5, label: "Author" },
    { id: 6, label: "Publisher" },
  ];

  const handleAction = (action: any) => {
    if (action.type === "deleteBook") {
      deleteBookService(id)
        .then(({ data, status }) => {
          if (status) {
            toastMessage("success", data?.message);
            handleCloseConfirm();
            setActionLoader(false);
            navigate(routeConstant.requiredBooks.path);
          }
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setActionLoader(false);
        });
    } else {
      let service =
        action.type === "approve" ? approvedBookService : declineBookService;

      setActionLoader(true);
      service(selectedItem?.id)
        .then(({ data, status }) => {
          if (status) {
            toastMessage("success", data?.message);
            handleCloseConfirm();
            setActionLoader(false);
            navigate(routeConstant.requiredBooks.path);
          }
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setActionLoader(false);
        });
    }
  };

  const handleChange = (event: any, newValue: number) => {
    setStatus(newValue);
  };

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs(
    {
      title: location?.state?.item?.title + "'s reports",
      path: routeConstant.reportBookDetails.path.replace(":id", id),
    },
    true,
    location
  );

  return (
    <div>
      <Box sx={{ width: "100%", marginBottom: "40px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={status}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#9A469B",
                color: "red !important",
              },
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#9A469B",
              },
            }}
          >
            <Tab label="Pending" className={styles.tabs} />
            <Tab label="History" className={styles.tabs} />
          </Tabs>
        </Box>
      </Box>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Reported Book detail</div>
            <div className={styles.tableSubHeading}>
              All reported book lists here.
            </div>
          </Box>

          {status === 1 ? (
            <Box className={styles.filterContainer}>
              <div className={styles.gridContainer}>
                <Icons.SearchIcon className={styles.searchIcon} />
                <input
                  placeholder="Search"
                  className={styles.input}
                  value={search}
                  onChange={(e) =>
                    searchHandlerHelper(e, setSearch, setActivePage)
                  }
                />
              </div>
            </Box>
          ) : (
            <Box className={styles.filterContainer}>
              {status === 0 && reports?.length > 0 && (
                <CustomButton
                  text="Delete Book"
                  buttonStyle={styles.btnDelete}
                  handleClick={() =>
                    handleOpenConfirm(
                      "Are you sure, you want to delete this book?",
                      "deleteBook"
                    )
                  }
                />
              )}
            </Box>
          )}
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>User name</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Report reason</TableCell>
                {status === 0 && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                reports?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>{item?.reported_by?.name}</TableCell>
                        <TableCell>
                          {
                            admin_user_roles?.find(
                              (role) =>
                                role?.id === Number(item?.reported_by?.role)
                            )?.label
                          }
                        </TableCell>
                        <TableCell>
                          {moment(item?.create_at).format("Do MMMM YYYY")}
                        </TableCell>
                        <TableCell>
                          <Popover reason={item?.report_reason} />
                        </TableCell>

                        {status === 0 && (
                          <TableCell>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              className={styles.actionBtn}
                            >
                              <Tooltip title={"Approve"} arrow>
                                <button
                                  onClick={() =>
                                    handleOpenConfirm(
                                      "Are you sure, you want to approve this report?",
                                      "approve",
                                      item
                                    )
                                  }
                                >
                                  <DoneIcon />
                                </button>
                              </Tooltip>
                              <Tooltip title={"Reject"} arrow>
                                <button
                                  onClick={() =>
                                    handleOpenConfirm(
                                      "Are you sure, you want to reject this report?",
                                      "reject",
                                      item
                                    )
                                  }
                                >
                                  <CloseIcon />
                                </button>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(reports?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {reports?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
        <Confirmation
          text={action.label}
          open={openConfirm}
          handleClose={() => handleCloseConfirm()}
          handleClick={() => handleAction(action)}
        />
        {actionLoader && <Loader />}
      </Box>
    </div>
  );
}

export default ReportedUsers;
