const enums: any = [
  {
    label: "Explicit content",
    value: 1,
  },
  {
    label: "Age-inappropriate",
    value: 2,
  },
  {
    label: "Cultural insentivity",
    value: 3,
  },
  {
    label: "Other",
    value: 4,
  },
];

export { enums };
