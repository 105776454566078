import {
  Box,
  Checkbox,
  FormControl,
  InputBase,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import BestSellers from "pages/bestSeller";
import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import {
  getAllAgeRangesService,
  getAllLanguagesService,
  getGenresService,
} from "shared/services/general";
import { toastMessage } from "shared/components/toast";

function TopSales() {
  const [allGenres, setAllGenres] = useState<any>([]);
  const [allAgeRange, setAllAgeRange] = useState<any>([]);
  const [allLanguage, setAllLanguage] = useState<any>([]);
  const [ageRange, setAgeRange] = useState<any>("");
  const [languages, setLanguages] = useState<any>([]);
  const [genres, setGenres] = useState<any>([]);
  const [allGenresLoader, setAllGenresLoader] = useState<boolean>(false);
  const [allAgeRangeLoader, setAllAgeRangeLoader] = useState<boolean>(false);
  const [languageLoader, setLanguageLoader] = useState<boolean>(false);
  const [selectedSaleType, setSelectedSaleType] = useState<string>("");

  const getAllGenres = () => {
    setAllGenresLoader(true);
    getGenresService()
      .then(({ data }) => {
        setAllGenres(
          data?.data?.map((item: any) => ({ ...item, checked: false }))
        );
        setAllGenresLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        setAllGenresLoader(false);
        console.log("err", err?.response?.data?.message);
      });
  };

  const getAllAgeRanges = () => {
    setAllAgeRangeLoader(true);
    getAllAgeRangesService()
      .then(({ data }) => {
        setAllAgeRange(
          data?.data?.map((item: any) => ({ ...item, checked: false }))
        );
        setAllAgeRangeLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setAllAgeRangeLoader(false);
      });
  };

  const getAllLanguages = () => {
    setLanguageLoader(true);
    getAllLanguagesService()
      .then(({ data }) => {
        setAllLanguage(
          data?.data?.map((item: any) => ({ ...item, checked: false }))
        );
        setLanguageLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLanguageLoader(false);
      });
  };

  useEffect(() => {
    getAllGenres();
    getAllAgeRanges();
    getAllLanguages();
  }, []);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      "&:hover": {
        borderColor: "#9a469b",
      },
      "&:focusVisible": {
        borderRadius: 1,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  const handleSetSelector = (event: any, check: string) => {
    if (check === "age") setAgeRange(event.target.value);
    if (check === "language") setLanguages(event.target.value);
    if (check === "genres") setGenres(event.target.value);
  };

  return (
    <div>
      <Box className={styles.wrapper}>
        <Box className={styles.title}>Top sales of Book</Box>
        <Box className={styles.filtersContainer}>
          <FormControl className={styles.filterWrapper}>
            <Select
              className={styles.selectElement}
              input={<BootstrapInput />}
              value={ageRange}
              onChange={(event) => handleSetSelector(event, "age")}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                const genre = allAgeRange.find(
                  (age: any) => age.id === selected
                );
                if (selected) {
                  return <p className={styles.message}>{genre.text}</p>;
                }
                return <p className={styles.message}>Select Age Range</p>;
              }}
            >
              {allAgeRange.map((item: any) => {
                return (
                  <MenuItem key={item?.id} value={item?.id}>
                    <ListItemText>{item?.text}</ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={styles.filterWrapper}>
            <Select
              className={styles.selectElement}
              input={<BootstrapInput />}
              multiple
              displayEmpty
              renderValue={(selected) => {
                const message =
                  selected.length > 1
                    ? `${selected.length} Languages Selected`
                    : `${selected.length} Language Selected`;
                if (selected.length === 0) {
                  return <p className={styles.message}>Select Language(s)</p>;
                }
                return <p className={styles.message}>{message}</p>;
              }}
              value={languages}
              onChange={(event) => handleSetSelector(event, "language")}
            >
              {allLanguage.map((language: any) => (
                <MenuItem key={language?.id} value={language?.id}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={languages.includes(language?.id)}
                  ></Checkbox>
                  <ListItemText primary={language?.name}></ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles.filterWrapper}>
            <Select
              input={<BootstrapInput />}
              className={styles.selectElement}
              multiple
              displayEmpty
              renderValue={(selected) => {
                const message =
                  selected.length > 1
                    ? `${selected.length} Genres Selected`
                    : `${selected.length} Genre Selected`;
                if (selected.length === 0) {
                  return <p className={styles.message}>Select Genre(s)</p>;
                }
                return <p className={styles.message}>{message}</p>;
              }}
              value={genres}
              onChange={(event) => handleSetSelector(event, "genres")}
            >
              {allGenres.map((genre: any) => (
                <MenuItem key={genre?.id} value={genre?.id}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={genres.includes(genre?.id)}
                  ></Checkbox>
                  <ListItemText primary={genre?.name}></ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <BestSellers
        genres={genres}
        ageRange={ageRange}
        languages={languages}
        sales_type={selectedSaleType}
      />
    </div>
  );
}

export default TopSales;
