import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./style.module.scss";
import { Icons } from "assets";
import { Box, Button } from "@mui/material";
import GetEmailSection from "./getEmail";
import OTPSection from "./Otp";
import ChangePasswordSection from "./changePasswordSection";
import { SendOTP, VerifyOTP, ResetPassword } from "shared/services/authService";
import { toastMessage } from "shared/components/toast";

enum ResetStep {
  Email,
  OTP,
  ChangePassword,
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ForgetPasswordProps {
  open: boolean;
  handleClose: () => void;
}

export default function ForgetPassword({
  open,
  handleClose,
}: ForgetPasswordProps) {
  const [resetStep, setResetStep] = useState<ResetStep>(ResetStep.Email);
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<number | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const handlePrevious = () => {
    switch (resetStep) {
      case ResetStep.OTP:
        setResetStep(ResetStep.Email);
        break;
      case ResetStep.ChangePassword:
        setResetStep(ResetStep.OTP);
        break;
      default:
        break;
    }
  };

  const handleSendOTP = (email: string) => {
    setLoader(true);
    setEmail(email);
    SendOTP({ email })
      .then(({ data }) => {
        setLoader(false);
        if (data?.statusCode === 200) {
          setResetStep(ResetStep.OTP);
          toastMessage("success", data?.message);
        } else {
          toastMessage("error", data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  const handleVerifyOTP = (otp: number) => {
    setLoader(true);
    setOtp(otp);
    VerifyOTP({ email, otp, isPasswordReset: true })
      .then(({ data }) => {
        setLoader(false);
        if (data?.statusCode === 200) {
          setResetStep(ResetStep.ChangePassword);
          toastMessage("success", data?.message);
        } else {
          toastMessage("error", data?.message);
        }
      })
      .catch((err) => {
        toastMessage("error", err?.resp?.data?.message);
        setLoader(false);
      });
  };

  const handleResetPassword = (password: string) => {
    setLoader(true);
    ResetPassword({ email, password, password_confirmation: password, otp })
      .then(({ data }) => {
        setLoader(false);
        if (data?.statusCode === 200) {
          toastMessage("success", data?.message);
          onClose();
        } else {
          toastMessage("error", data?.message);
        }
      })
      .catch((err) => {
        toastMessage("error", err?.resp?.data?.message);
        setLoader(false);
      });
  };

  const onClose = () => {
    handleClose();
    setResetStep(ResetStep.Email);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent className={styles.dialogContent}>
        <div className={styles.crossIcon}>
          <Icons.CrossIcon onClick={() => onClose()} />
        </div>
        {resetStep !== ResetStep.Email && (
          <Button className={styles.prevBtn} onClick={handlePrevious}>
            <Icons.PrevIcon />
          </Button>
        )}
        <Box display={"flex"} justifyContent={"center"}>
          {resetStep === ResetStep.Email ? (
            <GetEmailSection
              handleSendOTP={handleSendOTP}
              open={open}
              loader={loader}
            />
          ) : resetStep === ResetStep.OTP ? (
            <OTPSection
              handleVerifyOTP={handleVerifyOTP}
              open={open}
              handleSendOTP={handleSendOTP}
              loader={loader}
              email={email}
            />
          ) : (
            <ChangePasswordSection
              open={open}
              handleResetPassword={handleResetPassword}
              loader={loader}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
