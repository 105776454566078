import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Cropper from "react-easy-crop";
import getCroppedImg from "shared/utils/cropper";
import { Box } from "@mui/material";
import { Icons } from "assets";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CropImageProps {
  open: boolean;
  handleClose: any;
  image: any;
  setImage: any;
  handleImage: any;
}

export default function CropImage({
  open,
  handleClose,
  image,
  setImage,
  handleImage,
}: CropImageProps) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<any>(1);
  const [croppedArea, setCroppedArea] = useState<any>(null);
  const [slider, setSlider] = useState<number>(0);
  const [ImageCropFile, setImageCropFile] = useState("");

  useEffect(() => {
    // @ts-ignore
    if (document.getElementById("cropperInput")) {
      // @ts-ignore
      document.getElementById("cropperInput").oninput = function () {
        // @ts-ignore
        var value = ((this.value - this.min) / (this.max - this.min)) * 100;
        // @ts-ignore
        this.style.background =
          "linear-gradient(to right, #EF437B 0%, #EF437B " +
          value +
          "%, #F0F3F6 " +
          value +
          "%, #F0F3F6 100%)";
      };
    }
  });

  const CROP_AREA_ASPECT = 848 / 286;
  const onCropComplete = async (croppedArea: any, croppedAreaPixels: any) => {
    if (image) {
      let imgFile = await getCroppedImg(image, croppedAreaPixels);
      setImageCropFile(imgFile);
    }
  };

  const saveCrop = () => {
    handleImage(ImageCropFile);
    handleClose();
  };

 
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent>
          {/* "d-flex flex-column w-100 align-items-center px-5 pb-4 pt-3" */}

          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
          >
            <Cropper
              image={image}
              aspect={CROP_AREA_ASPECT}
              crop={crop}
              zoom={zoom}
              onCropChange={(size) => {
                setCrop(size);
              }}
              onZoomChange={(size) => {
                setZoom(size);
              }}
              showGrid={false}
              onCropComplete={onCropComplete}
              onCropAreaChange={(croppedArea) => {
                setCroppedArea(croppedArea);
              }}
              cropShape={"rect"}
              objectFit={"contain"}
            />

            <div className={styles.magnifyContainer}>
              <Icons.ZoomIn style={{ marginRight: "10px" }} />
              <input
                id="cropperInput"
                min="1"
                step={0.5}
                max="60"
                onChange={(e: any) => {
                  if (e.target.value !== 0) {
                    setZoom(e.target.value);
                    setSlider(e.target.value);
                    setCrop({ x: -e.target.value, y: -e.target.value });
                  } else {
                    setZoom(1);
                  }
                }}
                type="range"
                value={slider}
              />
              <Icons.ZoomOut style={{ marginLeft: "10px" }} />
            </div>

            <div className={styles.heading}>Adjust Cover Photo</div>

            <div>
              <CustomButton
                text={"Cancel"}
                buttonStyle={styles.btnInsideOut}
                handleClick={handleClose}
              />{" "}
              <CustomButton
                text="Save"
                buttonStyle={styles.btnCustom}
                handleClick={saveCrop}
              />
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
