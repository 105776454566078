import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Grid, Skeleton } from "@mui/material";
import Cards from "./../featuredBooks/card";
import { getBestSellersService } from "shared/services/projectHeadService";
import { toastMessage } from "shared/components/toast";
import { useOnScroll } from "shared/customHooks/useOnScroll";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import { useSelector } from "react-redux";
import { getSalesBooks } from "shared/services/productManagerService";
import { Icons } from "assets";
import { roles } from "shared/utils/constants";

interface BestSellersProps {
  genres?: any;
  ageRange?: any;
  languages?: any;
  sales_type?: any;
}

function BestSellers({
  genres,
  ageRange,
  languages,
  sales_type,
}: BestSellersProps) {
  const { user } = useSelector((state: any) => state.root);
  const [endReach, onScroll] = useOnScroll("topDiv");
  const [allBooks, setAllBooks] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (endReach && currentPage !== lastPage && !loader) {
      console.log("Reached end !!");
      setLoader(true);
      getBestSellers(
        currentPage + 1,
        languages,
        ageRange,
        genres,
        sales_type,
        true
      );
    }
    // eslint-disable-next-line
  }, [endReach, currentPage, lastPage]);

  const getBestSellers = (
    page: number,
    languages: any,
    ageRange: any,
    genres: any,
    sales_type: any,
    end_page_bool?: boolean
  ) => {
    let service =
      Number(user?.role?.role_id) === roles.productManager
        ? getSalesBooks
        : getBestSellersService;
    setLoader(true);
    service(page, languages, ageRange, genres, sales_type)
      .then(({ data }) => {
        setLoader(false);
        if (end_page_bool) {
          setAllBooks((prevBooks: any) => [
            ...(prevBooks || []),
            ...(data?.data?.data || []),
          ]);
        } else {
          setAllBooks(data?.data?.data);
        }
        setCurrentPage(page);
        setLastPage(data?.data?.last_page || null);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
    setLoader(true);

    getBestSellers(1, languages, ageRange, genres, sales_type);
    // eslint-disable-next-line
  }, [ageRange, languages, genres, sales_type]);

  useBreadcrumbs({
    title: "Best Sellers",
    path: routeConstant.bestSellers.path,
  });

  return (
    <div onScroll={onScroll} id="topDiv" className={styles.topDiv}>
      {allBooks?.length ? (
        <Grid container spacing={2}>
          {allBooks?.map((item: any) => {
            return <Cards bestSellers={true} item={item} key={item.id} />;
          })}
        </Grid>
      ) : !loader ? (
        <div className={styles.noDataCard}>
          <Icons.NoDataIcon />
          <h5>No Data Found</h5>
        </div>
      ) : (
        Array.from({ length: 10 }).map((_, index) => (
          <Grid md={12} lg={6} xl={6} xs={12}>
            <Skeleton
              variant="rectangular"
              height={"36vh"}
              // width="95%"
              sx={{ borderRadius: "4px", marginLeft: "16px" }}
            />
          </Grid>
        ))
      )}
    </div>
  );
}

export default BestSellers;
