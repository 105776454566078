import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { toastMessage } from "shared/components/toast";
import Loader from "shared/components/loader/animation";

import {
  createGenreService,
  updateGenresService,
} from "shared/services/productManagerService";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AddCategoryProps {
  open: boolean;
  handleClose: any;
  selectedCategory: any;
  getAllGenres: any;
}

export default function AddCategory({
  open,
  handleClose,
  selectedCategory,
  getAllGenres,
}: AddCategoryProps) {
  const [category, setCategory] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);

  useEffect(() => {
    if (selectedCategory) {
      setCategory(selectedCategory?.name);
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  const handleSubmit = () => {
    let service = selectedCategory ? updateGenresService : createGenreService;

    let resp: any = {
      name: category,
    };

    if (selectedCategory) {
      resp["id"] = selectedCategory?.id;
    }

    setLoader(true);
    if (category.length) {
      service(resp)
        .then(({ data }) => {
          setLoader(false);
          toastMessage("success", data?.message);
          onClose();
          getAllGenres();
        })
        .catch((err) => {
          console.log("Err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setLoader(false);
        });
    } else {
      toastMessage("error", "Please type in something to proceed.");
      setLoader(false);
    }
  };

  const onClose = () => {
    handleClose();
    setCategory("");
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent>
          <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          />

          <div className={styles.categoryTitle}>
            {selectedCategory ? "Update" : "Add"} Category
          </div>
          <input
            className={styles.inputCategory}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />

          <div className={styles.btnContainer}>
            <CustomButton
              text={selectedCategory ? "Update" : "Add"}
              buttonStyle={styles.btnCustom}
              handleClick={() => handleSubmit()}
            />
            <CustomButton
              text="Cancel"
              buttonStyle={styles.btnInsideOut}
              handleClick={() => handleClose()}
            />
          </div>
        </DialogContent>

        {loader && <Loader />}
      </Dialog>
    </div>
  );
}
