import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Cards from "shared/components/card";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Icons } from "assets";
import useWindowSize from "shared/customHooks/windowSize";
import Pagination from "shared/components/pagination";
import { routeConstant } from "shared/routes/routesConstant";
import { useNavigate } from "react-router-dom";
import {
  getReviewersListProjectHead,
  getStatsReviewerProjectHead,
} from "shared/services/projectHeadService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./skeletonLoader";
import { searchHandlerHelper } from "shared/utils/helpers";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import ItemsPerPage from "shared/components/itemsPerPage";

function PartnerAccount() {
  const screenWidth = useWindowSize();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(0);
  const [reviewers, setAllReviewers] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const cardData = [
    {
      title: "Total Book Reviewers",
      icon: GroupIcon,
      stats: dashboardData?.total_reviewers,
    },
    {
      title: "Total books in review",
      icon: MenuBookIcon,
      stats: dashboardData?.total_books_in_review,
    },
    {
      title: "Total books reviewed",
      icon: LibraryBooksIcon,
      stats: dashboardData?.total_books_reviewed,
    },
  ];

  const getDashboardStats = () => {
    setIsLoading(true);
    getStatsReviewerProjectHead()
      .then(({ data }) => {
        setDashboardData(data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDashboardStats();
  }, []);

  const getAllReviewers = () => {
    setLoader(true);
    getReviewersListProjectHead(searchText, activePage, itemsPerPage)
      .then(({ data }) => {
        setLoader(false);
        setTotalPage(data?.data?.last_page);
        setAllReviewers(data?.data?.data);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllReviewers();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage]);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useBreadcrumbs({
    title: "Reviewers Performace",
    path: routeConstant.reviewersPerformance.path,
  });

  return (
    <div>
      <Grid container spacing={2}>
        {cardData?.map((item) => {
          return (
            <Grid item xs={12} sm={4}>
              <Cards
                Icon={item?.icon}
                stats={item?.stats}
                title={item?.title}
                style={styles.card}
                isLoading={isLoading}
              />
            </Grid>
          );
        })}
      </Grid>

      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Reviewers</div>
            <div className={styles.tableSubHeading}>
              Manage reviewers from here.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book Reviewer name</TableCell>
                <TableCell>Books Assigned</TableCell>
                <TableCell>Email</TableCell>
                {/* <TableCell>Assgined books</TableCell> */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                reviewers?.map((item: any) => {
                  return (
                    <>
                      <TableRow className={styles.tableRow}>
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"}>
                            <Avatar
                              alt={item?.full_name}
                              src={item?.profile_picture}
                            />
                            <span
                              className={styles.tableItem}
                              style={{ marginLeft: "10px" }}
                            >
                              {item?.full_name}
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell>{item?.books_count?.assigned}</TableCell>
                        <TableCell>{item?.email}</TableCell>
                        {/* <TableCell>{item?.assigned_books}</TableCell> */}

                        <TableCell>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={styles.actionBtn}
                          >
                            <Tooltip arrow title="View details">
                              <button
                                onClick={() => {
                                  navigate(
                                    routeConstant.reviewerPerformance.path.replace(
                                      ":id",
                                      item?.id
                                    ),
                                    {
                                      state: {
                                        item,
                                      },
                                    }
                                  );
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </button>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(reviewers.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoUsersData />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {reviewers?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
    </div>
  );
}

export default PartnerAccount;
