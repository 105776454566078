import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Skeleton } from "@mui/material";
import moment from "moment";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { toastMessage } from "shared/components/toast";
import {
  allMouService,
  assignToHeadService,
  createMOUService,
  deleteMOUService,
  updateMOUService,
} from "shared/services/adminService";
import Loader from "shared/components/loader/animation";
import Confirmation from "shared/modals/confirmation";
import CustomButton from "shared/components/customButton";
import { MOUSchema } from "shared/utils/validationSchemas";
import styles from "./style.module.scss";
import { Icons } from "assets";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";

const MOU = () => {
  const { id } = useParams<{ id: string }>() as { id: string };
  const [allMous, setAllMous] = useState<any[]>([]);
  const [selectedMOU, setSelectedMOU] = useState<any | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirm, setOpenConfirmation] = useState<boolean>(false);
  const [editAllowed, setEditAllowed] = useState<boolean>(true);
  const [singleLoader, setSingleLoader] = useState<boolean>(false);
  const [action, setAction] = useState<{ label: string; type: string }>({
    label: "",
    type: "",
  });

  const initialValues = {
    description: "",
    freeBooksLimit: "",
    premiumBooksLimit: "",
    platformFee: "",
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema: MOUSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getAllMous();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllMous = async () => {
    setLoader(true);
    try {
      const { data } = await allMouService(id);
      setAllMous(data?.data || []);
    } catch (err: any) {
      toastMessage("error", err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  async function handleSubmit(values: any) {
    const resp: any = {
      user_id: Number(id),
      agreement: values?.description,
      free_book_ratio: values?.freeBooksLimit,
      total_books: values?.premiumBooksLimit,
      platform_commission: values?.platformFee,
      mou_id: selectedMOU?.id,
    };

    const service = selectedMOU ? updateMOUService : createMOUService;

    setLoader(true);
    try {
      const { data } = await service(resp);
      toastMessage("success", data?.message);
      getAllMous();
      formik.resetForm();
      setSelectedMOU(null);
    } catch (err: any) {
      toastMessage("error", err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (selectedMOU) {
      const {
        free_book_ratio,
        agreement,
        total_books,
        platform_commission,
        status,
      } = selectedMOU;
      formik.setValues({
        freeBooksLimit: free_book_ratio,
        description: agreement,
        premiumBooksLimit: total_books,
        platformFee: platform_commission,
      });
      setEditAllowed(status === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMOU]);

  const handleConfirmation = (label: string, type: string) => {
    setOpenConfirmation(true);
    setAction({ label, type });
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAction({ label: "", type: "" });
  };

  const handleActions = async (action: { label: string; type: string }) => {
    const service =
      action.type === "delete" ? deleteMOUService : assignToHeadService;
    setLoader(true);
    try {
      const { data } = await service(selectedMOU?.id);
      toastMessage("success", data?.message);
      getAllMous();
      formik.resetForm();
      setSelectedMOU(null);
      setEditAllowed(true);
    } catch (err: any) {
      toastMessage("error", err?.response?.data?.message);
    } finally {
      setLoader(false);
      handleCloseConfirmation();
    }
  };

  const MOU_STATUS = [
    { label: "Draft", value: 0 },
    { label: "Review", value: 1 },
    { label: "Pending", value: 2 },
    { label: "Accepted", value: 3 },
    { label: "Declined", value: 4 },
    { label: "Rejected", value: 5 },
  ];

  const MOU_STATUSES = {
    draft: 0,
    review: 1,
    pending: 2,
    accepted: 3,
    declined: 4,
    rejected: 5,
  };

  useBreadcrumbs(
    {
      title: "All MOUs",
      path: routeConstant.mou.path.replace(":id", id),
    },
    true,
    selectedMOU
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          {loader ? (
            Array.from({ length: 3 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height="120px"
                sx={{ borderRadius: "10px", marginBottom: "20px" }}
              />
            ))
          ) : (
            <>
              {allMous?.length ? (
                <>
                  <Box>
                    <div
                      className={styles.singleMOU}
                      onClick={() => {
                        setSelectedMOU(null);
                        formik.resetForm();
                        setEditAllowed(true);
                      }}
                    >
                      Create new
                    </div>
                  </Box>
                  <Box>
                    {allMous.map((item, index, array) => (
                      <div
                        key={index}
                        className={`${styles.singleMOU} ${
                          item.id === selectedMOU?.id ? styles.activeMOU : ""
                        }`}
                        onClick={() => setSelectedMOU(item)}
                      >
                        <div className={styles[statusClass(item.status)]}>
                          {
                            MOU_STATUS.find((itm) => item.status === itm.value)
                              ?.label
                          }
                        </div>
                        <div
                          className={styles.singleMOUWrapper}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={styles.mouHeading}>
                            MOU {array.length - index}
                          </div>
                          <div className={styles.mouDate}>
                            {moment(item.created_at).format("DD-MM-YYYY")}
                          </div>
                        </div>
                        <div className={styles.agreement}>{item.agreement}</div>
                      </div>
                    ))}
                  </Box>
                </>
              ) : (
                <div className={styles.noDataCard}>
                  <Icons.NoDataIcon />
                  <h5>No Data Found</h5>
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <Card className={styles.mouEditorContainer}>
            <div className={styles.mouInnerContainer}>
              <div className={styles.mouLabel}>
                {selectedMOU ? "Update" : "Create"} MOU
              </div>
              <div className={styles.mouHeading}>
                Date:{" "}
                {moment(selectedMOU?.created_at || new Date()).format(
                  "D/M/yyyy"
                )}
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.mouHeading}>
                MOU Description <span>*</span>
              </div>
              <textarea
                className={styles.textarea}
                id="description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                disabled={!editAllowed}
              />
              {editAllowed &&
              formik.touched.description &&
              formik.errors.description ? (
                <div className={styles.errorText}>
                  {formik.errors.description}
                </div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}

              <Grid container>
                {["freeBooksLimit", "premiumBooksLimit", "platformFee"].map(
                  (field, index) => (
                    <Grid key={index} xs={12} sm={4} md={4}>
                      <div className={styles.titleHead}>
                        {getFieldLabel(field)}
                      </div>
                      <input
                        className={styles.input}
                        id={field}
                        name={field}
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values[field]}
                        disabled={!editAllowed}
                      />
                      {editAllowed &&
                      formik.touched[field] &&
                      formik.errors[field] ? (
                        <div className={styles.errorText}>
                          {formik.errors[field]}
                        </div>
                      ) : (
                        <div style={{ height: "20px" }}></div>
                      )}
                    </Grid>
                  )
                )}
              </Grid>

              <div className={styles.signatureContainer}>
                {selectedMOU?.admin_signature && (
                  <div>
                    {singleLoader ? (
                      <Skeleton
                        variant="rectangular"
                        width="155px"
                        height="65px"
                      />
                    ) : (
                      <img
                        src={selectedMOU?.admin_signature}
                        className={styles.signatureImg}
                        alt="signature"
                      />
                    )}
                    <div className={styles.line}></div>
                    <div className={styles.titleHead}>Admin Signature</div>
                  </div>
                )}
                {selectedMOU?.user_signature && (
                  <div>
                    {" "}
                    {singleLoader ? (
                      <Skeleton
                        variant="rectangular"
                        width="155px"
                        height="65px"
                      />
                    ) : (
                      <img
                        src={selectedMOU?.user_signature}
                        className={styles.signatureImg}
                        alt="signature"
                      />
                    )}
                    <div className={styles.line}></div>
                    <div className={styles.titleHead}>User Signature</div>
                  </div>
                )}
              </div>

              {!selectedMOU ? (
                <Box display="flex" justifyContent="end" marginTop="10px">
                  <CustomButton
                    text="Create"
                    buttonStyle={styles.btn}
                    type="Submit"
                  />
                </Box>
              ) : selectedMOU?.status === MOU_STATUSES?.draft ? (
                <Box display="flex" justifyContent="end" marginTop="10px">
                  <CustomButton
                    text="Update"
                    buttonStyle={styles.btn}
                    type="Submit"
                  />
                  <CustomButton
                    text="Delete"
                    buttonStyle={styles.btnInside}
                    handleClick={(e: any) => {
                      e.preventDefault();
                      handleConfirmation(
                        "Are you sure you want to delete this MOU?",
                        "delete"
                      );
                    }}
                    type=""
                  />
                  <CustomButton
                    text="Send to Project Head"
                    buttonStyle={styles.btnInside}
                    handleClick={(e: any) => {
                      e.preventDefault();
                      handleConfirmation(
                        "Are you sure you want to assign this to Head?",
                        "assign"
                      );
                    }}
                  />
                </Box>
              ) : selectedMOU?.status === MOU_STATUSES.rejected ? (
                <Box display="flex" justifyContent="end" marginTop="10px">
                  <CustomButton
                    text="Delete"
                    buttonStyle={styles.btn}
                    handleClick={(e: any) => {
                      e.preventDefault();
                      handleConfirmation(
                        "Are you sure you want to delete this MOU?",
                        "delete"
                      );
                    }}
                    type=""
                  />
                </Box>
              ) : null}
            </form>{" "}
            {loader && <Loader />}
          </Card>
          <Confirmation
            open={openConfirm}
            handleClose={handleCloseConfirmation}
            handleClick={() => handleActions(action)}
            text={action.label}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MOU;

function getFieldLabel(field: string): string {
  const labels: Record<string, string> = {
    freeBooksLimit: "Free Books Limit",
    premiumBooksLimit: "Premium Books Limit",
    platformFee: "Platform Fee (%)",
  };
  return labels[field] || "";
}

function statusClass(status: number): string {
  const statusClasses: Record<number, string> = {
    3: "agreed",
    4: "declined",
  };
  return statusClasses[status] || "pending";
}
