import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { Icons } from "assets";
import moment from "moment";
import { ticketStatus } from "shared/utils/constants";

interface SingleCardProps {
  item: any;
  handleSelectTicket: any;
  activeTicket: any;
}

function SingleTicketCard({
  item,
  handleSelectTicket,
  activeTicket,
}: SingleCardProps) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <div
        className={
          activeTicket?.id === item?.id ? styles.active : styles.ticketContainer
        }
        onClick={() => handleSelectTicket(item)}
      >
        {item?.status !== ticketStatus.Closed ? (
          <div className={styles.statusOpen}>Open</div>
        ) : (
          <div className={styles.statusClose}>Close</div>
        )}
        <div className={styles.headingContainer}>
          <div className={styles.ticketHeading}>
            {/* {item?.status === 2 ? (
              <Icons.FilledCheckedIcon />
            ) : (
              <Icons.CheckedIcon />
            )} */}

            <span>{item?.title}</span>
          </div>
          <div className={styles.date}>
            {moment(item?.created_at).format("D MMM, yyyy")}
          </div>
        </div>
        {/* <div className={styles.ticketReason}>{item?.subject}</div> */}
        <div className={styles.ticketDescription}>
          {item?.last_comment?.comment.length > 35
            ? item?.last_comment?.comment?.substring(0, 35) + "..."
            : item?.last_comment?.comment}
        </div>
      </div>
    </>
  );
}

export default SingleTicketCard;
