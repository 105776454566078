import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";

import styles from "./style.module.scss";

interface SchoolApprovedModalPropsType {
  open: boolean;
  closeModal: any;
  content: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SchoolApprovedModal = ({
  open,
  closeModal,
  content,
}: SchoolApprovedModalPropsType) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <span className={styles.iconWrapper} onClick={closeModal}>
          <Icons.CrossIcon />
        </span>
        <div className={styles.checkIconWrapper}>
          <Icons.Check />
        </div>
        <h3 className={styles.heading}>Account is Successfully Approved</h3>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <span className={styles.content}>{content}</span>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SchoolApprovedModal;
