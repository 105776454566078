import {
  Grid,
  Skeleton,
  // useMediaQuery
} from "@mui/material";
import React from "react";
import styles from "./style.module.scss";

interface BookInfoCardProps {
  book?: any;
  loader: boolean;
}

function BookInfoCard({ book, loader }: Partial<BookInfoCardProps>) {
  // const gridSizing = useMediaQuery("(max-width:500px)");
  const BOOK_TYPE = [
    { value: 1, text: "Free" },
    { value: 2, text: "Premium" },
  ];

  return (
    <div className={styles.contentContainer}>
      <div className={styles.picContainer}>
        {loader ? (
          <Skeleton variant="rectangular" width={"250px"} height={"100%"} />
        ) : (
          <img
            src={book?.cover_photo}
            alt="book-cover"
            className={styles.book}
          />
        )}
      </div>
      <div className={styles.bookInfo}>
        <Grid container spacing={2}>
          <Grid xs={6} md={4} padding={"10px"}>
            {" "}
            <div className={styles.title}>Book title</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                book?.title
              )}
            </div>{" "}
          </Grid>
          <Grid xs={6} md={4} padding={"10px"}>
            {" "}
            <div className={styles.title}>Age Range</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                book?.age_range?.text
              )}
            </div>{" "}
          </Grid>
          <Grid xs={6} md={4} padding={"10px"}>
            <div className={styles.title}>Language</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                book?.language?.name
              )}
            </div>
          </Grid>
          <Grid xs={6} md={4} padding={"10px"}>
            <div className={styles.title}>Author Name</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                book?.book_author?.name
              )}
            </div>
          </Grid>
          <Grid xs={6} md={4} padding={"10px"}>
            <div className={styles.title}>Book Type</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                BOOK_TYPE.find((item: any) => item?.value === book?.type)?.text
              )}
            </div>
          </Grid>
          {book?.type === 2 ? (
            <>
              {" "}
              <Grid xs={6} md={4} padding={"10px"}>
                <div className={styles.title}>Borrow Coins</div>
                <div className={styles.subTitle}>
                  {loader ? (
                    <Skeleton variant="rectangular" width={80} height={25} />
                  ) : book?.borrow_coins ? (
                    book?.borrow_coins
                  ) : (
                    "Not Provided"
                  )}
                </div>
              </Grid>
              <Grid xs={6} md={4} padding={"10px"}>
                {" "}
                <div className={styles.title}>Buy Coins</div>
                <div className={styles.subTitle}>
                  {loader ? (
                    <Skeleton variant="rectangular" width={80} height={25} />
                  ) : book?.buy_coins ? (
                    book?.buy_coins
                  ) : (
                    "Not Provided"
                  )}
                </div>
              </Grid>{" "}
            </>
          ) : null}
          <Grid xs={6} md={4} padding={"10px"}>
            <div className={styles.title}>Hard Copy</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.is_hard_copy ? (
                "Yes"
              ) : (
                "No"
              )}
            </div>
          </Grid>
          {book?.is_hard_copy ? (
            <>
              <Grid xs={6} md={4} padding={"10px"}>
                <div className={styles.title}>Book Price</div>
                <div className={styles.subTitle}>
                  {loader ? (
                    <Skeleton variant="rectangular" width={80} height={25} />
                  ) : book?.price ? (
                    book?.price + " Rs."
                  ) : (
                    "Not Provided"
                  )}
                </div>
              </Grid>
              <Grid xs={6} md={4} padding={"10px"}>
                <div className={styles.title}>Book Weight</div>
                <div className={styles.subTitle}>
                  {loader ? (
                    <Skeleton variant="rectangular" width={80} height={25} />
                  ) : book?.weight ? (
                    book?.weight + " Kg"
                  ) : (
                    "Not Provided"
                  )}
                </div>
              </Grid>
            </>
          ) : null}
          <Grid xs={6} md={4} padding={"10px"}>
            <div className={styles.title}>Quiz</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.is_quiz ? (
                "Yes"
              ) : (
                "No"
              )}
            </div>
          </Grid>
          <Grid xs={6} md={4} padding={"10px"}>
            <div className={styles.title}>Keywords</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.tags?.length ? (
                book?.tags?.map((item: any, inx: any, arr: any) => {
                  return (
                    <div>
                      {item?.name + (inx !== arr.length - 1 ? "," : ".")}
                    </div>
                  );
                })
              ) : (
                "Not Provided"
              )}
            </div>
          </Grid>
          <Grid xs={4} padding={"10px"}>
            <div className={styles.title}>Genres</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.genres?.length ? (
                book?.genres?.map((item: any, inx: any, arr: any) => {
                  return (
                    <div>
                      {item?.name + (inx !== arr.length - 1 ? "," : ".")}
                    </div>
                  );
                })
              ) : (
                "Not Provided"
              )}
            </div>
          </Grid>
        </Grid>
        {/* <Grid spacing={2} container>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Age Range</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                book?.age_range?.text
              )}
            </div>{" "}
          </Grid>
          <Grid xs={gridSizing ? 6 : 4}>
            {" "}
            <div className={styles.title}>Language</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                book?.language?.name
              )}
            </div>
          </Grid>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Hard Copy</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.is_hard_copy ? (
                "Yes"
              ) : (
                "No"
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={"25px"}>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Book Type</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                BOOK_TYPE.find((item: any) => item?.value === book?.type)?.text
              )}
            </div>
          </Grid>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Author Name</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                book?.author?.name
              )}
            </div>
          </Grid>

          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Quiz</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.is_quiz ? (
                "Yes"
              ) : (
                "No"
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={"25px"}>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Book Price</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.price ? (
                book?.price
              ) : (
                "Not Provided"
              )}
            </div>
          </Grid>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Book Weight</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.weight ? (
                book?.weight
              ) : (
                "Not Provided"
              )}
            </div>
          </Grid>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Buy Coins</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.buy_coins ? (
                book?.buy_coins
              ) : (
                "Not Provided"
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={"25px"}>
          <Grid xs={gridSizing ? 6 : 4}>
            <div className={styles.title}>Borrow Coins</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : book?.borrow_coins ? (
                book?.borrow_coins
              ) : (
                "Not Provided"
              )}
            </div>
          </Grid>
        </Grid> */}
      </div>
    </div>
  );
}

export default BookInfoCard;
